import http from "../http-common"
import authHeader from './auth-header';

class CompanyDataService {
    //Запрос записей
    get () {
        return http.get(`/companies`, { headers: authHeader() });
    }

}

export default new CompanyDataService()