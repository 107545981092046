<template>
    <div>


        <!--Сообщение об ошибке сохранения в БД-->
        <v-alert
                type="error"
                dismissible
                v-model="alertError"
                transition="scale-transition"
        >
            Невозможно сохранить данные. Возможно отсутствует соединение с базой данных. Обратитесь к Администратору.
        </v-alert>

        <!--Сообщение об успешном копировании данных-->
        <v-snackbar
                v-model="snackbarCopy"
                color="green darken-3"
                top
                :timeout="1000"
        >
            Скопировано!
            <template v-slot:action="{ attrs }">
                <v-btn
                        color="grey lighten-2"
                        icon
                        v-bind="attrs"
                        @click="snackbarCopy = false"
                >
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </template>
        </v-snackbar>


        <!--Сообщение об успешном копировании данных-->

<!--        <v-snackbar-->
<!--                v-model="snackbarCopy"-->
<!--                color="green darken-3"-->
<!--                top-->
<!--                :timeout="1000"-->
<!--        >-->
<!--            Скопировано!-->
<!--            <template v-slot:action="{ attrs }">-->
<!--                <v-btn-->
<!--                        color="grey lighten-2"-->
<!--                        icon-->
<!--                        v-bind="attrs"-->
<!--                        @click="snackbarCopy = false"-->
<!--                >-->
<!--                    <v-icon>mdi-close</v-icon>-->
<!--                </v-btn>-->
<!--            </template>-->
<!--        </v-snackbar>-->

        <!--Сообщение об успешном Сохранении данных-->
        <v-snackbar
                v-model="snackbarSave"
                color="green"
                top
                :timeout="1000"
        >
            Данные сохранены
            <template v-slot:action="{ attrs }">
                <v-btn
                        color="grey lighten-2"
                        icon
                        v-bind="attrs"
                        @click="snackbarSave = false"
                >
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </template>
        </v-snackbar>




                <v-row>
                    <v-col
                            cols="auto"
                    >
                        <h1 class="data-name" v-show="!nameInPlace">
                            {{ task.name }}
                            <v-icon
                                    @click="editTaskName"
                                    right
                            >
                                mdi-pencil
                            </v-icon>
                            <v-icon
                                    @click="copy(task.name)"
                                    right
                            >
                                mdi-content-copy
                            </v-icon>
                        </h1>
                        <div v-show="nameInPlace">
                            <v-form
                                    ref="name"
                                    v-model="valid"
                                    lazy-validation
                            >
                                <v-text-field
                                        label="Название задачи"
                                        v-model="editedItem.name"
                                        :rules="externalRules.requiredRules"
                                        ref="name"
                                ></v-text-field>
                            </v-form>
                            <v-btn
                                    color="blue darken-1"
                                    text
                                    @click="closeNameInPlace"
                            >
                                Отмена
                            </v-btn>
                            <v-btn
                                    :disabled="!valid"
                                    color="blue darken-1"
                                    text
                                    @click="saveNameInPlace"
                            >
                                Сохранить
                            </v-btn>
                        </div>
                    </v-col>

                    <v-col
                            cols="auto"
                    >


                            <v-btn-toggle
                                    v-model="task.status"
                                    class="ml-5"
                                    mandatory
                                    @change="updateTask"
                            >
                                <v-btn value="0"
                                       width="200px"
                                       max-height="40px"
                                       active-class="isWork"
                                >
                                    Ждет выполнения
                                </v-btn>
                                <v-btn value="1"
                                       width="150px"
                                       max-height="40px"
                                       active-class="isCompleted"
                                >
                                    Выполнен
                                </v-btn>
                            </v-btn-toggle>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col>
                        <v-card
                                outlined
                                class="mb-4"
                        >
                            <v-card-title>Описание задачи</v-card-title>
                            <v-card-text>
                                <v-row class="mx-0">
                                    <div class="data-row">
<!--                                        <div class="data-label">-->
<!--                                            Описание задачи-->
<!--                                        </div>-->
                                        <div
                                                class="data-text"
                                                v-show="!descriptionInPlace"
                                        >
                                            <span v-if="task.description"> {{ task.description }} </span>
                                            <span class="placeholder" v-else>Введите текст</span>
                                            <v-icon
                                                    @click="editDescription"
                                                    right
                                                    small
                                            >
                                                mdi-pencil
                                            </v-icon>
                                            <v-icon
                                                    v-show="task.description"
                                                    @click="copy(task.description)"
                                                    right
                                                    small
                                            >
                                                mdi-content-copy
                                            </v-icon>
                                        </div>
                                        <div v-show="descriptionInPlace">
                                            <v-textarea
                                                    v-model="editedItem.description"
                                                    ref="description"
                                            ></v-textarea>
                                            <v-btn
                                                    color="blue darken-1"
                                                    text
                                                    @click="closeDescriptionInPlace"
                                            >
                                                Отмена
                                            </v-btn>
                                            <v-btn
                                                    color="blue darken-1"
                                                    text
                                                    @click="saveDescriptionInPlace"
                                            >
                                                Сохранить
                                            </v-btn>
                                        </div>
                                    </div>
                                </v-row>
                            </v-card-text>
                        </v-card>
                    </v-col>
                    <v-col>
                        <v-card
                                outlined
                                class="mb-4"
                        >
                            <v-card-title>Карточка задачи</v-card-title>
                            <v-card-text>
                                <v-row class="mx-0">
                                    <div class="data-row">
                                        <v-row>
                                            <v-col>
                                                <p>Постановщик</p>
                                                <v-avatar size="30" style="margin-right: 5px">
                                                    <img :src="`${avatars}${task.create_employee_avatar}`">
                                                </v-avatar>
                                                {{ task.create_employee_name}}
                                            </v-col>
                                        </v-row>
                                        <v-row>
                                            <v-col>
                                                <p>Исполнитель</p>
                                                <v-avatar size="30" style="margin-right: 5px">
                                                    <img :src="`${avatars}${task.responsible_employee_avatar}`">
                                                </v-avatar>
                                                {{ task.responsible_employee_name}}
                                            </v-col>
                                        </v-row>
                                        <v-row>
                                            <v-col>
                                                <p>Дата постановки</p>
                                                {{ task.create_date.date | formatDateISOToRUS }}
                                            </v-col>
                                        </v-row>
                                        <v-row>
                                            <v-col>
                                                <p>Дедлайн</p>
                                                {{ getDeadline }}
                                            </v-col>
                                        </v-row>
                                        <v-row>
                                            <v-col>
                                                <p>Договор</p>
                                                <router-link :to="{ name: 'EditContract', params: { id: task.order_id }}" v-show="task.order_number !== ''">{{ task.order_number }}</router-link>
                                                <p v-show="task.order_number === ''">не установлено</p>
                                            </v-col>
                                        </v-row>

                                    </div>
                                </v-row>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>


    </div>



</template>

<script>
    import OrderDataService from "@/services/OrderDataService";
    import * as ExternalRules from "@/rules/ExternalRules";
    import TaskDataService from "@/services/TaskDataService";
    import NoticeDataService from "@/services/NoticeDataService";


    // let options = {
    //     year: 'numeric',
    //     month: 'numeric',
    //     day: 'numeric',
    //     timezone: 'UTC'
    // };

    export default {
        name: "EditTaskForm",
        props: {
            value: Boolean,
            taskid: Number,
        },


            data () {
            return {
                //Формат даты для календаря
                modelConfig: {
                    type: 'string',
                    // mask: 'YYYY-MM-DD',
                    mask: 'DD.MM.YYYY',
                },
                task: null,
                snackbarSave: false,
                alertError: false,
                nameInPlace: false,
                descriptionInPlace: false,
                editedItem: {}, //В этой переменной сохраняем объект редактирования
                valid: true,
                snackbarCopy: false,


                importance: 0,
                name: null,
                description: null,
                //date: new Date().toISOString().split('T')[0], //Дата в формате YYYY-MM-DD
                employeeItems: [],
                employeeId: null,
                orderid: null,
                //date: new Date().toLocaleString("ru", options),
                date: null,
                orderItems: [],
                externalRules: ExternalRules,
                publicPath: process.env.BASE_URL,
                avatars: process.env.VUE_APP_AVATARS,
                //dialog: false,
                validForm: true,
                orderSearch: null,
                loading: false
            }
        },

        watch: {
            orderSearch() {
                if (this.orderItems.length > 0) return

                if (this.orderSearch.length >= 3) {
                    let params = {
                        contractNumberString: this.orderSearch,
                        maxResults: 50
                    }
                    OrderDataService.find(params)
                        .then(response => {
                            this.orderItems = response.data
                        })
                        .catch(e => {
                            console.log(e)
                        })
                }
            },
        },
        computed: {
            getDeadline() {
                if (this.task.end_date === '') {
                    return 'без срока'
                } else {
                    return this.formatDateISOToRUS(this.task.end_date.date)
                }
            },
        },
        methods: {


            clearCustomerSearch() {
                this.customerItems = []
            },
            clearOrderSearch() {
                this.orderItems = []
            },

            editTaskName() {
                this.editedItem.name = this.task.name
                this.nameInPlace = true
                this.$nextTick(() => {
                    this.$refs.name.focus();
                })
            },
            editDescription() {
                this.editedItem.description = this.task.description
                this.descriptionInPlace = true
                this.$nextTick(() => {
                    this.$refs.description.focus();
                })
            },
            closeNameInPlace() {
                this.nameInPlace = false
                this.$nextTick(() => {
                    this.editedItem = {}
                })
            },
            closeDescriptionInPlace() {
                this.descriptionInPlace = false
                this.$nextTick(() => {
                    this.editedItem = {}
                })
            },
            saveNameInPlace () {
                this.task.name = this.editedItem.name
                this.closeNameInPlace()
                this.updateTask()
            },
            saveDescriptionInPlace() {
                this.task.description = this.editedItem.description
                this.closeDescriptionInPlace()
                this.updateTask()
            },
            formatDateISOToRUS(date) {
                return date.split(' ')[0].split("-").reverse().join(".") //Преобразуем ISO дату в дату rus формата DD.MM.YYYY
            },
            getTask(id) {
                TaskDataService.get(id)
                    .then(response => {
                        this.task = response.data
                        console.log(response.data);
                    })
                    .catch(error => {
                        console.log(error);
                    })
            },
            updateTask() {
                let data = {
                    name: this.task.name,
                    description: this.task.description,
                    status: this.task.status,
                }

                TaskDataService.update(this.task.id, data)
                    .then(response => {
                        this.snackbarSave = true
                        //Если задача выполнена - постановщику отправляет сообщение на экран
                        if (data.status === '1') {
                            if ((this.task.create_employee_id !== this.task.responsible_employee_id) && (this.task.create_employee_id !== this.$store.state.auth.user.userid)) { //Если поставлена задача самому себе - уведомление не создаем
                                NoticeDataService.create({creatorid: this.$store.state.auth.user.userid, employeesid: this.task.create_employee_id, noticetext: `Задача выполнена: <a href="/tasks/${this.task.id}">${data.name}</a>`});//Создаем уведомление
                            }
                        }
                        console.log(response.data)
                    })
                    .catch(e => {
                        console.log(e)
                        this.alertError = true
                    })
            },
            copy (text) {
                this.$copyText(text).then(response => {
                    this.snackbarCopy = true
                    console.log(response)
                })
                    .catch(error => {
                        console.log(error);
                    })
            },

        },
        mounted () {
            this.getTask(this.$route.params.id)
        },

        filters: {
            formatDateISOToRUS(date) {
                    return date.split(' ')[0].split("-").reverse().join(".") //Преобразуем ISO дату в дату rus формата DD.MM.YYYY
            }
        },

    }
</script>

<style scoped>

    .isWork {
        background: #1E88E5 !important;
        color: white !important;
    }

    .isCompleted{
        background: #43A047 !important;
        color: white !important;
    }

</style>