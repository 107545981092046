import http from "../http-common"
import authHeader from './auth-header';

class ContactFacesDataService {

    //Запрос  контактных лиц, привязанных к ЮЛ
    findContacts(companyid) {
        return http.get(`/contactfaces?companyid=${companyid}`, { headers: authHeader() });
    }

    //Запрос компаний, связанных с контактом (ФЛ)
    findCompanies(contactid) {
        return http.get(`/companyfaces?contactid=${contactid}`, { headers: authHeader() });
    }

    //Добавление записи
    bindcompany(companyid, contactid, post) {
        return http.post(`/bindcompany?companyid=${companyid}&contactid=${contactid}&post=${post}`, null, { headers: authHeader() });
    }

    //Удаление записи
    clearBind(companyid,contactid) {
        return http.delete(`/companyfaces?companyid=${companyid}&contactid=${contactid}`,{ headers: authHeader() });
    }

}

export default new ContactFacesDataService()