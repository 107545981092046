import http from "../http-common"
import authHeader from './auth-header';

class PrintDataService {

    //Печать документа
    // value - id документа (договора, счета ....)
    // templateid - id шаблона документа (из БД)
    // format - формат документа на выходе (doc, pdf)
    createDocument(value, templateid) {
        return http.post(`/printdocument?value=${value}&templateid=${templateid}`, null, { headers: authHeader() })
    }

}

export default new PrintDataService()