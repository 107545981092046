<template>
  <div>
<!--    <CustomersTable/>-->
    <p>Зарплата</p>
  </div>
</template>

<script>
// @ is an alias to /src
// import CustomersTable from '@/components/CustomersTable.vue'

export default {
  name: 'Salary',
  components: {
    // CustomersTable
  }
}
</script>
