import http from "../http-common"
import authHeader from './auth-header';

class EventsDataService {

    //Запрос записи
    findEvents(sourcecod, source) {
        return http.get(`/events?sourcecod=${sourcecod}&source=${source}`,{ headers: authHeader() });
    }

}

export default new EventsDataService()