import http from "../http-common"
import authHeader from './auth-header';

class NoticeDataService {
    //Создание записи
    create(data) {
        return http.post("/notices", data, { headers: authHeader() });
    }

    //Обновление записи
    // update(id, data) {
    //     return http.put(`accounts/${id}`, data, { headers: authHeader() })
    // }

    //Запрос всех записей
    find(employeesid) {
        return http.get(`/notices?employeesid=${employeesid}`,{ headers: authHeader() });
    }

    //Уведомления на экране
    getShowOnScreenNotices(employeesid) {
        return http.get(`/notices/showOnScreen?employeesid=${employeesid}`,{ headers: authHeader() });
    }

    //Количество уведомлений в центре уведомлений
    getNoticeCount(employeesid) {
        return http.get(`/notices/count?employeesid=${employeesid}`, { headers: authHeader() })
    }

    //Удаление
    delete(id) {
        return http.delete(`/notices/${id}`,{ headers: authHeader() });
    }

    //Скрытие уведомления c экрана (show_on_screen := 0)
    hide(id) {
        return http.post("/notices/hide", {id: id}, { headers: authHeader() });
    }



}

export default new NoticeDataService()