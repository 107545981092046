class FileNameFunction {

    //Получаем расширение из имени файла
    //filename - имя файла с расширением
    GetExtensionFromFileName (filename) {
        return filename.split('.').pop();
    }

    //Получаем имя файла из имени файла с разширением
    //filename - имя файла с расширением
    RemoveExtensionFromFileName (filename) {
        //return filename.substring(0, filename.lastIndexOf('.')) || filename;
        return filename.replace(/\.[^/.]+$/, "")  //Есть расширение
    }

}

export default new FileNameFunction()