//Rules for vuetify components



export let requiredRules = [
        v => !!v || 'Пожалуйста, заполните это поле',
        ]

export let phoneRules = [
    v => (!v || v.length === 18) || 'Пожалуйста, введите телефон',
]

export let emailRules = [
    v => (!v || /.+@.+\..+/.test(v)) || 'Пожалуйста, введите адрес электронной почты',
]

export let dateRules = [
    v => (!v || /^(((0[1-9]|[12]\d|3[01])\.(0[13578]|1[02])\.((19|[2-9]\d)\d{2}))|((0[1-9]|[12]\d|30)\.(0[13456789]|1[012])\.((19|[2-9]\d)\d{2}))|((0[1-9]|1\d|2[0-8])\.02\.((19|[2-9]\d)\d{2}))|(29\.02\.((1[6-9]|[2-9]\d)(0[48]|[2468][048]|[13579][26])|((16|[2468][048]|[3579][26])00))))$/.test(v)) || 'Пожалуйста, введите дату',
]

export let winFileNameRules = [
    v => (!v || /^[^*|"<>?\n\\/:]*$/.test(v)) || 'Имя файла не должно содержать следующих знаков: \\/:*?<>|',
]

//Функция для проверки переменной в коде VUE
export function dateRulesCheck(date) {
    if (/^(((0[1-9]|[12]\d|3[01])\.(0[13578]|1[02])\.((19|[2-9]\d)\d{2}))|((0[1-9]|[12]\d|30)\.(0[13456789]|1[012])\.((19|[2-9]\d)\d{2}))|((0[1-9]|1\d|2[0-8])\.02\.((19|[2-9]\d)\d{2}))|(29\.02\.((1[6-9]|[2-9]\d)(0[48]|[2468][048]|[13579][26])|((16|[2468][048]|[3579][26])00))))$/.test(date)) {
        return true
    } else
        return false
}


export let integerRules = [
    v => (!v || /^\d+$/.test(v)) || 'Пожалуйста, введите целое число',
]

//https://ru.stackoverflow.com/questions/575862/%D0%A0%D0%B5%D0%B3%D1%83%D0%BB%D1%8F%D1%80%D0%BD%D0%BE%D0%B5-%D0%B2%D1%8B%D1%80%D0%B0%D0%B6%D0%B5%D0%BD%D0%B8%D0%B5-%D0%B4%D0%BB%D1%8F-%D1%87%D0%B8%D1%81%D0%B5%D0%BB-%D1%81-%D0%BF%D0%BB%D0%B0%D0%B2%D0%B0%D1%8E%D1%89%D0%B5%D0%B9-%D1%82%D0%BE%D1%87%D0%BA%D0%BE%D0%B9
export let floatRules = [
    v => (!v || /^[0-9]*[.,]?[0-9]+$/.test(v)) || 'Пожалуйста, введите десятичное число',
]

//https://stackoverflow.com/questions/19605150/regex-for-password-must-contain-at-least-eight-characters-at-least-one-number-a
export let passwordRules = [
    v => (!v || /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/.test(v)) || 'Минимум 8 символов, как минимум одна цифра',
]

//https://stackoverflow.com/questions/7536755/regular-expression-for-matching-hhmm-time-format
export let timeRules = [
    v => (!v || /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/.test(v)) || 'Пожалуйста, введите время в формате чч:мм',
]

