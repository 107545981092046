<template>
  <div>
    <ContractsTable/>
  </div>
</template>

<script>
import ContractsTable from "@/components/ContractsTable";

export default {
  name: 'Contracts',
  components: {
    ContractsTable
  },


}
</script>
