import axios from "axios";

export default axios.create({
    baseURL: process.env.VUE_APP_API_URL,
    headers: {
        "Content-type": "application/json"
    }
});

//Заголовок для моего файлсервера
export let fileserver = axios.create({
    headers: {
        "Content-type": "application/json",
    }});