<template>
    <div>

        <!--Сообщение об успешной отправке e-mail или sms с рег данными ЛК-->
        <v-snackbar
                v-model="snackbarSendLogin"
                color="green"
                top
                :timeout="4000"
        >
            Логин и новый пароль успешно отправлены
            <template v-slot:action="{ attrs }">
                <v-btn
                        color="grey lighten-2"
                        icon
                        v-bind="attrs"
                        @click="snackbarSendLogin = false"
                >
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </template>
        </v-snackbar>

        <!--Сообщение об ОШИБКЕ отправке e-mail или sms с рег данными ЛК-->
        <v-snackbar
                v-model="snackbarErrorSendLogin"
                color="red"
                top
                :timeout="4000"
        >
            Ошибка отправки логина и пароля
            <template v-slot:action="{ attrs }">
                <v-btn
                        color="grey lighten-2"
                        icon
                        v-bind="attrs"
                        @click="snackbarErrorSendLogin = false"
                >
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </template>
        </v-snackbar>


        <!--      Диалоговое окно добавления телефона-->
        <v-dialog
                max-width="500"
                persistent
                v-model="addPhoneDataDialog"
        >
            <v-card>
                <v-card-title class="headline">
                    Добавление телефона
                    <v-spacer></v-spacer>
                    <v-btn
                            icon
                            @click="closePhoneDialog"
                    >
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-card-title>
                <v-card-text>
                    <v-container>
                        <v-row>
                            <v-col>
                                <v-text-field
                                        v-model="editedItem.phonenumber"
                                        label="Телефон"
                                        v-mask="'+7 (###) ###-##-##'"
                                        :rules="phoneRules"
                                ></v-text-field>
                                <v-text-field
                                        v-model="editedItem.description"
                                        label="Комментарий"
                                ></v-text-field>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                            color="blue darken-1"
                            text
                            @click="closePhoneDialog"
                    >
                        Отмена
                    </v-btn>
                    <v-btn
                            color="blue darken-1"
                            text
                            @click="savePhone"
                    >
                        Добавить
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <!--      Диалоговое окно редактирования телефона-->
        <v-dialog
                eager
                max-width="500"
                persistent
                v-model="editPhoneDataDialog"
        >
            <v-card>
                <v-card-title class="headline">
                    Редактирование
                    <v-spacer></v-spacer>
                    <v-btn
                            icon
                            @click="closePhoneDialog"
                    >
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-card-title>
                <v-card-text>
                    <v-container>
                        <v-row>
                            <v-col>
                                <v-form
                                        ref="phone"
                                        v-model="valid"
                                        lazy-validation
                                >
                                    <v-text-field
                                            v-model="editedItem.phonenumber"
                                            label="Телефон"
                                            v-mask="'+7 (###) ###-##-##'"
                                            :rules="phoneRules"
                                    ></v-text-field>
                                    <v-text-field
                                            v-model="editedItem.description"
                                            label="Комментарий"
                                    ></v-text-field>
                                </v-form>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                            color="blue darken-1"
                            text
                            @click="closePhoneDialog"
                    >
                        Отмена
                    </v-btn>
                    <v-btn
                            :disabled="!valid"
                            color="blue darken-1"
                            text
                            @click="savePhone"
                    >
                        Сохранить
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>




        <div class="data-text" style="margin-bottom: 10px" v-for="(item, index) in phones" :key="item.id">


            <!--                              {{ item.id }}-->
            <span style="color: #0d47a1; padding-right: 5px; font-weight: bold">{{ item.phonenumber }}</span>
            <span style="color: rgba(0, 0, 0, 0.6); font-size: 13px">{{ item.description }}</span>
            <v-icon
                    @click="editPhone(index)"
                    right
                    small
            >
                mdi-pencil
            </v-icon>
            <v-icon
                    @click="deletePhone(item.id)"
                    right
                    small
            >
                mdi-trash-can-outline
            </v-icon>
<!--            <v-tooltip bottom>-->
<!--                <template v-slot:activator="{ on, attrs }">-->
<!--                    <v-icon-->
<!--                            @click="sendLoginToSms(item.phonenumber)"-->
<!--                            right-->
<!--                            small-->
<!--                            v-bind="attrs"-->
<!--                            v-on="on"-->
<!--                    >-->
<!--                        mdi-form-textbox-password-->
<!--                    </v-icon>-->
<!--                </template>-->
<!--                <span>Отправить логин и пароль в смс</span>-->
<!--            </v-tooltip>-->
            <v-icon
                    @click="copy(item.phonenumber)"
                    right
                    small
            >
                mdi-content-copy
            </v-icon>
        </div>
        <v-btn
                x-small
                rounded
                color="primary"
                depressed
                @click="addPhone"
        >
            Добавить
        </v-btn>
    </div>
</template>

<script>
    import PhonesDataService from "@/services/PhonesDataService";
    import CustomerDataService from "@/services/CustomerDataService";
    export default {
        name: "Phones",
        data: function () {
            return {
                snackbarSendLogin: false,
                snackbarErrorSendLogin : false,
                valid: true,
                editPhoneDataDialog: false,
                addPhoneDataDialog: false,
                phones: {}, // массив телефонов
                phoneRules: [
                    v => (!v || v.length === 18) || 'Пожалуйста, введите телефон',
                    v => !!v || 'Пожалуйста, заполните это поле',
                ],
                editedItem: {},
                editedIndex: -1,
            }
        },
        methods: {
            //Отправка логина на E-mail
                sendLoginToSms(phone) {
                CustomerDataService.sendLoginToSms(this.$route.params.id, phone)
                    .then(response => {
                        console.log(response.data)
                         this.snackbarSendLogin = true
                    })
                    .catch(e => {
                        console.log(e)
                        this.snackbarErrorSendLogin = true
                    })
            },
            getPhones(id) {
                PhonesDataService.get(id)
                    .then(response => {
                        this.phones = response.data
                        console.log(response.data);
                    })
                    .catch(error => {
                        console.log(error);
                    })
            },
            updatePhone(item) {
                PhonesDataService.update(this.phones[item].id, this.phones[item])
                    .then(response => {
                        console.log(response.data)
                    })
                    .catch(e => {
                        console.log(e)
                        // this.alertError = true
                    })
            },
            createPhone(id, data) {
                PhonesDataService.create(id, data)
                    .then(response => {
                        console.log(response.data)
                        this.getPhones(this.$route.params.id)
                        // this.phones.push(this.editedItem)
                    })
                    .catch(e => {
                        console.log(e)
                        this.$store.dispatch('showAlert', {
                            type: 'error',
                            message: 'Ошибка добавления телефона. Обратитесь к Администратору!'
                        });
                    })
            },
            deletePhone(id) {
                PhonesDataService.delete(id)
                    .then(response => {
                        console.log(response.data)
                        this.getPhones(this.$route.params.id)
                    })
                    .catch(e => {
                        console.log(e)
                        // this.alertError = true
                    })
            },
            closePhoneDialog () {
                this.editPhoneDataDialog = false
                this.addPhoneDataDialog = false
                this.$nextTick(() => {
                    this.editedItem = {}
                    this.editedIndex = -1
                })
            },
            addPhone () {
                this.addPhoneDataDialog = true
            },
            editPhone(item) {
                this.editedIndex = item
                this.editedItem.phonenumber = this.phones[item].phonenumber
                this.editedItem.description = this.phones[item].description
                this.editPhoneDataDialog = true
            },
            savePhone () {
                if (this.$refs.phone.validate()) {
                    if (this.editedIndex > -1) {
                        this.phones[this.editedIndex].phonenumber = this.editedItem.phonenumber
                        this.phones[this.editedIndex].description = this.editedItem.description
                        this.updatePhone(this.editedIndex)
                    } else {
                        this.createPhone(this.$route.params.id, this.editedItem)
                    }
                    this.closePhoneDialog()
                }
            },
            copy (text) {
                this.$copyText(text).then(response => {
                    this.snackbarCopy = true
                    console.log(response)
                })
                    .catch(error => {
                        console.log(error);
                    })
            },
        },
        mounted() {
            this.getPhones(this.$route.params.id); //Код текущего клиента
        },
    }
</script>

<style scoped>

</style>