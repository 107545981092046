<template>
    <div class="text-right">
        <v-dialog
                v-model="dialog"
                persistent
                width="600"
        >
            <template v-slot:activator="{ on, attrs }">
                <v-btn
                        color="primary"
                        depressed
                        v-bind="attrs"
                        v-on="on"
                >
                    Создать
                </v-btn>
            </template>

            <v-card>
                <v-card-title class="headline">
                    Новое письмо
                    <v-spacer></v-spacer>
                    <v-btn
                            icon
                            @click="dialog = false"
                    >
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-card-title>
                <v-form
                        ref="form"
                        v-model="validForm"
                        lazy-validation
                >
                    <v-card-text>
                    <v-container>

                        <v-row>
                            <v-col
                                    cols="6"
                            >
                                <v-text-field
                                        v-model="corrnumber"
                                        label="Номер"
                                        :rules="requiredRules"
                                        required
                                ></v-text-field>
                            </v-col>
                            <v-col
                                    cols="6"
                            >
                                <vc-date-picker v-model="corrdate">
                                    <template v-slot="{ inputValue, inputEvents }">
                                        <v-text-field
                                                label="Дата"
                                                :rules="requiredRules"
                                                v-mask="'##.##.####'"
                                                required
                                                v-on="inputEvents"
                                                :value="inputValue"
                                                readonly
                                        ></v-text-field>
                                    </template>
                                </vc-date-picker>
                            </v-col>
                        </v-row>

                        <!--Тип письма - Бумажное / Электронное-->
                        <v-row>
                            <v-col>
                                <v-select
                                        v-model="corrCarrierSelect"
                                        :items="corrCarrierItems"
                                        item-text="label"
                                        item-value="value"
                                        label="Тип письма"
                                        :rules="requiredRules"
                                        required
                                ></v-select>
                            </v-col>
                        </v-row>

                        <!--Вид письма-->
                        <v-row>
                            <v-col>
                                <v-select
                                        v-model="viewTypeSelect"
                                        :items="viewTypeItems"
                                        item-text="name"
                                        item-value="id"
                                        label="Вид письма"
                                        :rules="requiredRules"
                                        required
                                ></v-select>
                            </v-col>
                        </v-row>




                        <v-row>
                            <v-col>
                                <v-autocomplete
                                        placeholder="Введите не менее 3-х символов для поиска"
                                        clearable
                                        v-model="customerid"
                                        :items="customerItems"
                                        :search-input.sync="customerSearch"
                                        item-value="customerid"
                                        item-text="fullname"
                                        label="Получатель"
                                        @click:clear="clearCustomerSearch"
                                        :rules="requiredRules"
                                ></v-autocomplete>
                            </v-col>
                        </v-row>
<!--                        Не показываем это поле, если вызываем диалог из договора.-->
<!--                        В дальнейшем исправить это.-->
                        <v-row v-show="!order_id">
                            <v-col>
                                <v-autocomplete
                                        placeholder="Введите не менее 3-х символов для поиска"
                                        clearable
                                        v-model="orderid"
                                        :items="orderItems"
                                        :search-input.sync="orderSearch"
                                        item-value="orderid"
                                        item-text="contractnumber"
                                        label="Договор"
                                        @click:clear="clearOrderSearch"
                                >
                                    <template v-slot:item="data">
                                        <v-list-item-content>
                                            <v-list-item-title v-html="data.item.contractnumber"></v-list-item-title>
                                            <v-list-item-subtitle v-html="data.item.estatecod.fulladdress"></v-list-item-subtitle>
                                        </v-list-item-content>
                                    </template>
                                </v-autocomplete>
                            </v-col>
                        </v-row>



                        <v-row>
                            <v-col>
                                <v-textarea
                                        v-model="contents"
                                        label="Краткое содержание"
                                        rows="2"
                                        :rules="requiredRules"
                                        required
                                ></v-textarea>
                            </v-col>
                        </v-row>

                        <v-row>
                            <v-col>
                                <v-textarea
                                        v-model="description"
                                        label="Примечание"
                                        rows="2"
                                ></v-textarea>
                            </v-col>
                        </v-row>


                        <v-row>
                            <v-col>
                                <v-btn
                                        class="mr-4 mt-4"
                                        color="success"
                                        :loading="loading"
                                        @click="submitForm"
                                >
                                    Создать
                                </v-btn>
                            </v-col>
                        </v-row>

                    </v-container>
                </v-card-text>
                </v-form>

            </v-card>



        </v-dialog>
    </div>
</template>

<script>
    import CustomerDataService from "@/services/CustomerDataService";
    import CorrespondenceDataService from "@/services/CorrespondenceDataService";
    import OrderDataService from "@/services/OrderDataService";

    // let options = {
    //     year: 'numeric',
    //     month: 'numeric',
    //     day: 'numeric',
    //     timezone: 'UTC'
    // };

    export default {
        name: "AddCorrespondenceForm",
        props: {
            order_id: {
                type: Number,
                default: null
            },
            ordernumber: {
                type: String,
                default: null
            }
        },
        data () {
            return {
                dialog: false,
                validForm: true,
                corrnumber: null,
                corrdate: new Date(),

                corrCarrierSelect: null,
                corrCarrierItems: [
                    { label: 'Электронное', value: '1' },
                    { label: 'Бумажное', value: '0' },
                ],

                viewTypeSelect: null,
                viewTypeItems: [],

                contents: null,
                description: null,
                customerid: null,
                customerItems: [],
                customerSearch: null,

                requiredRules: [
                    v => !!v || 'Пожалуйста, заполните это поле',
                ],
                dateRules: [
                    v => (!v || /^(((0[1-9]|[12]\d|3[01])\.(0[13578]|1[02])\.((19|[2-9]\d)\d{2}))|((0[1-9]|[12]\d|30)\.(0[13456789]|1[012])\.((19|[2-9]\d)\d{2}))|((0[1-9]|1\d|2[0-8])\.02\.((19|[2-9]\d)\d{2}))|(29\.02\.((1[6-9]|[2-9]\d)(0[48]|[2468][048]|[13579][26])|((16|[2468][048]|[3579][26])00))))$/.test(v)) || 'Пожалуйста, введите дату',
                ],
                loading: false,

                orderItems: [],
                orderSearch: this.ordernumber,
                orderid: this.order_id,
            }
        },

        watch: {
            customerSearch() {
                if (this.customerItems.length > 0) return

                if (this.customerSearch.length > 3) {
                    CustomerDataService.findBySurname(this.customerSearch)
                        .then(response => {
                            this.customerItems = response.data
                            console.log(response.data)
                        })
                        .catch(e => {
                            console.log(e)
                        })
                }
            },
            orderSearch() {
                if (this.orderItems.length > 0) return

                if (this.orderSearch.length >= 3) {
                    let params = {
                        contractNumberString: this.orderSearch,
                        maxResults: 50
                    }
                    OrderDataService.find(params)
                        .then(response => {
                            this.orderItems = response.data
                        })
                        .catch(e => {
                            console.log(e)
                        })
                }
            },
        },
        methods: {
            submitForm() {
                if (this.$refs.form.validate()) {
                    this.loading = true
                    let data = {
                        number: this.corrnumber,
                        date: this.corrdate.toISOString().substr(0, 10),
                        customer_code: this.customerid,
                        type_code: this.viewTypeSelect,
                        employee_code: this.$store.state.auth.user.userid, //Код пользователя
                        carrier: this.corrCarrierSelect,
                        contents: this.contents,
                        description: this.description,
                        order_id: this.orderid
                    }


                    CorrespondenceDataService.create(data)
                        .then(response => {
                            console.log(response.data)
                            this.$router.push({ name: 'EditCorrespondence', params: { id : response.data.id }})
                        })
                        .catch(error => {
                            console.log(error);
                        })
                }
            },
            clearCustomerSearch() {
                this.customerItems = []
            },
            clearOrderSearch() {
                this.orderItems = []
            },
            getNextCorrNumber() {
              CorrespondenceDataService.getNextNumber()
                  .then(response => {
                      this.corrnumber = response.data
                      console.log(response.data)
                  })
                  .catch(e => {
                      console.log(e)
                  })
            },
            getViewtype() {
                CorrespondenceDataService.getViewtype()
                    .then(response => {
                        this.viewTypeItems = response.data
                        console.log(response.data)
                    })
                    .catch(e => {
                        console.log(e)
                    })
            }
        },
        mounted () {
            this.getNextCorrNumber()
            this.getViewtype()
        }
    }
</script>

<style scoped>

</style>