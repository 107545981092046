<template>
    <div class="text-right">
        <v-dialog
                v-model="showDialog"
                persistent
                width="600"
        >
            <v-card>
                <v-card-title class="headline">
                    Новый документ
                    <v-spacer></v-spacer>
                    <v-btn
                            icon
                            @click="showDialog = false"
                    >
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-card-title>
                <v-form
                        ref="form"
                        v-model="validForm"
                        lazy-validation
                >
                    <v-card-text>
                    <v-container>
                        <v-row>
                            <v-col>
                                <v-text-field
                                        v-model="number"
                                        label="Номер*"
                                        :rules="externalRules.integerRules.concat(externalRules.requiredRules)"
                                ></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <v-text-field
                                        label="Дата*"
                                        v-model="date"
                                        v-mask="'##.##.####'"
                                        :rules="externalRules.dateRules.concat(externalRules.requiredRules)"
                                ></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <v-autocomplete
                                        placeholder="Введите не менее 3-х символов для поиска"
                                        clearable
                                        v-model="customerid"
                                        :items="customerItems"
                                        :search-input.sync="customerSearch"
                                        item-value="customerid"
                                        item-text="fullname"
                                        label="Контрагент*"
                                        @click:clear="clearCustomerSearch"
                                        :rules="externalRules.requiredRules"
                                ></v-autocomplete>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <v-autocomplete
                                        label="Счет, касса*"
                                        :rules="externalRules.requiredRules"
                                        v-model="accountid"
                                        :items="accountItems"
                                        item-value="id"
                                        item-text="name"
                                        clearable
                                >
                                </v-autocomplete>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <v-text-field
                                        v-model="sum"
                                        label="Сумма*"
                                        :rules="externalRules.floatRules.concat(externalRules.requiredRules)"
                                        required
                                ></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <v-autocomplete
                                        label="Статья*"
                                        :rules="externalRules.requiredRules"
                                        v-model="accountingid"
                                        :items="accountingItems"
                                        item-value="id"
                                        item-text="name"
                                        clearable
                                >
                                </v-autocomplete>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <v-autocomplete
                                        label="Направление деятельности"
                                        v-model="activityid"
                                        :items="activityItems"
                                        item-value="id"
                                        item-text="name"
                                        clearable
                                >
                                    <template v-slot:item="data">
                                        <v-list-item-content>
                                            <v-list-item-title v-html="data.item.name"></v-list-item-title>
                                            <v-list-item-subtitle v-html="data.item.description"></v-list-item-subtitle>
                                        </v-list-item-content>
                                    </template>
                                </v-autocomplete>
                            </v-col>
                        </v-row>
                        <!--                        Не показываем это поле, если вызываем диалог из договора.-->
                        <!--                        В дальнейшем исправить это.-->
                        <v-row v-show="!order_id">
                            <v-col>
                                <v-autocomplete
                                        placeholder="Введите не менее 3-х символов для поиска"
                                        clearable
                                        v-model="orderid"
                                        :items="orderItems"
                                        :search-input.sync="orderSearch"
                                        item-value="orderid"
                                        item-text="contractnumber"
                                        label="Договор"
                                        @click:clear="clearOrderSearch"
                                >
                                    <template v-slot:item="data">
                                        <v-list-item-content>
                                            <v-list-item-title v-html="data.item.contractnumber"></v-list-item-title>
                                            <v-list-item-subtitle v-html="data.item.estatecod.fulladdress"></v-list-item-subtitle>
                                        </v-list-item-content>
                                    </template>
                                </v-autocomplete>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <v-textarea
                                        v-model="purposepay"
                                        label="Назначение документа*"
                                        rows="2"
                                        :rules="externalRules.requiredRules"
                                ></v-textarea>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <v-textarea
                                        v-model="description"
                                        label="Примечания"
                                        rows="2"
                                ></v-textarea>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <v-btn
                                        class="mr-4 mt-4"
                                        color="success"
                                        :loading="loading"
                                        @click="submitForm"
                                >
                                    Создать
                                </v-btn>
                            </v-col>
                        </v-row>

                    </v-container>
                </v-card-text>
                </v-form>

            </v-card>



        </v-dialog>
    </div>
</template>

<script>
    import CustomerDataService from "@/services/CustomerDataService";
    import OrderDataService from "@/services/OrderDataService";
    import AccountingDataService from "@/services/AccountingDataService";
    import BankaccountDataService from "@/services/BankaccountDataService";
    import * as ExternalRules from "@/rules/ExternalRules";
    import ActivitiesDataService from "@/services/ActivitiesDataService";
    import DocumentsDataService from "@/services/DocumentsDataService";
    import EmployeeDataService from "@/services/EmployeeDataService";


    let options = {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        timezone: 'UTC'
    };

    export default {
        name: "AddDocumentForm",
        props: {
            value: Boolean,
            order_id: {
                type: Number,
                default: null
            }
        },
        data () {
            return {
                date: new Date().toLocaleString("ru", options),
                number: null,
                sum: null,
                description: null,
                purposepay: null,
                accountingid: null,
                customerid: null,
                accountid: null,
                activityid: null,
                orderid: this.order_id,
                accountingItems: [],
                customerItems: [],
                orderItems: [],
                accountItems: [],
                activityItems: [],
                externalRules: ExternalRules,
                operationType: 'customerType',

                publicPath: process.env.BASE_URL,
                dialog: false,
                validForm: true,

                customerSearch: null,
                orderSearch: null,
                loading: false

            }
        },

        watch: {
            showDialog(show) {
                //Если открываем диалог - обнуляем переменные
                if(show === true) {
                    this.getNextNumber() //Следующий номер документа
                    this.getAccounting() //Статьи учета
                    this.getAccounts() //Счет движения ДС (банк, касса)
                    this.getActivities () //Направления деятельности
                    this.getEmployees() //Сотрудники
                    // this.date = new Date().toISOString().split('T')[0].split("-").reverse().join(".") //Дата в формате DD.MM.YYYY
                    // this.loading_1 = false
                    // this.loading_2 = false
                    // this.deadline = this.addDays(7).toISOString().split('T')[0].split("-").reverse().join(".") //Дата в формате DD.MM.YYYY + 7 дн
                    // this.description = null
                    // this.sum = null
                    // this.orderSearch = null
                    // this.validForm = true
                    // this.orderItems = []
                }
            },

            customerSearch() {
                if (this.customerItems.length > 0) return

                if (this.customerSearch.length >= 3) {
                    CustomerDataService.findBySurname(this.customerSearch)
                        .then(response => {
                            this.customerItems = response.data
                            console.log(response.data)
                        })
                        .catch(e => {
                            console.log(e)
                        })
                }
            },
            orderSearch() {
                if (this.orderItems.length > 0) return

                if (this.orderSearch.length >= 3) {
                    let params = {
                        contractNumberString: this.orderSearch,
                        maxResults: 50
                    }
                    OrderDataService.find(params)
                        .then(response => {
                            this.orderItems = response.data
                        })
                        .catch(e => {
                            console.log(e)
                        })
                }
            },
        },
        computed: {
            showDialog: {
                get () {
                    return this.value
                },
                set (value) {
                    this.$emit('input', value)
                }
            }
        },
        methods: {
            submitForm() {
                if (this.$refs.form.validate()) {
                    this.loading = true
                    let data = {
                        number: this.number,
                        date: this.date,
                        sum: this.sum,
                        purposepay: this.purposepay,
                        accountingid: this.accountingid,
                        orderid: this.orderid,
                        customerid: this.customerid,
                        activityid: this.activityid,
                        accountid: this.accountid,
                        description: this.description
                    }

                    DocumentsDataService.create(data)
                        .then(response => {
                            this.$router.push({ name: 'EditDocument', params: { id : response.data.id }})
                        })
                        .catch(error => {
                            console.log(error);
                        })
                }
            },

            clearCustomerSearch() {
                this.customerItems = []
            },
            clearOrderSearch() {
                this.orderItems = []
            },

            //Запрос статей учета
            getAccounting () {
                AccountingDataService.get()
                    .then(response => {
                        this.accountingItems = response.data
                    })
                    .catch(e => {
                        console.log(e)
                    })
            },
            //Запрос счета движения ДС
            getAccounts () {
                BankaccountDataService.find()
                    .then(response => {
                        this.accountItems = response.data
                    })
                    .catch(e => {
                        console.log(e)
                    })
            },

            //Запрос направлений деятельности
            getActivities () {
                ActivitiesDataService.find()
                    .then(response => {
                        this.activityItems = response.data
                    })
                    .catch(e => {
                        console.log(e)
                    })
            },
            getEmployees() {
                EmployeeDataService.find()
                    .then(response => {
                        this.employeeItems = response.data
                    })
                    .catch(e => {
                        console.log(e)
                    })
            },
            //Следующий номер документа
            getNextNumber() {
                DocumentsDataService.getNextNumber()
                    .then(response => {
                        this.number = response.data
                    })
                    .catch(e => {
                        console.log(e)
                    })
            },
        },
        mounted () {

        }
    }
</script>

<style scoped>


</style>