import http from "../http-common"
import authHeader from './auth-header';

//Отправка СМС сообщений через сторонний сервис (https://sms-prosto.ru)
class SendSMSDataService {

    //Отправка логина на телефон (СМС)
    //phone - номер телефона в любом формате
    //text - текст смс (1 смс - 160 символов)
    sendSMS(data) {
        return http.post(`/sendsms`, data, { headers: authHeader() });
    }

}

export default new SendSMSDataService()