import NoticeDataService from "@/services/NoticeDataService";

//Уведомления
export default {
    actions: {
        noticesCount({ commit }, userid) {
            NoticeDataService.getNoticeCount(userid)
                .then( response => {
                    commit('noticesCount', response.data)
                })
                .catch(error => console.log(error));
        }
    },
    mutations: {
        //Установка
        noticesCount (state, count) {
            state.noticesCount = count
        }
    },
    state: {
        noticesCount: 0, //Количество уведомлений для пользователя
    }
}