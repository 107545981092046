//В этом вуексе механизм работы с экономическим показателями
//(стоимость договора, расходы и т.п)
//Необходимо для передачи этих показетелей между различными компонентами через Вуекс

export default {
    state: {
        showAlert: false,
        alertType: 'info', //Тип по умолчанию (Варианты: success, info, warning, error)
        alertMessage: '',
    },
    actions: {
        showAlert({ commit }, payload) {
            commit('setAlert', payload);
        },
        hideAlert({ commit }) {
            commit('clearAlert');
        }
    },
    mutations: {
        setAlert(state, payload) {
            state.alertMessage = payload.message
            state.alertType = payload.type
            state.showAlert = true
        },
        clearAlert(state) {
            state.showAlert = false
            state.alertMessage = '';
            state.alertType = 'info';
        }
    },

    getters: {
        alertMessage: state => {
            return state.alertMessage
        },
        alertType: state => {
            return state.alertType
        },
        showAlert: state => {
            return state.showAlert
        },
    }
}