import OrderDataService from "@/services/OrderDataService";

//Договоры
export default {
    actions: {
        fireContractsCount({ commit }) {
            OrderDataService.getFireContractsCount()
                .then( response => {
                    commit('fireContractsCount', response.data)
                })
                .catch(error => console.log(error));
        },
        newStatusContractsCount({ commit }) {
            let params = {
                statusArr: 4,
                userid: 2,
                maxResults: 500,
            }
            OrderDataService.find(params)
                .then( response => {
                    commit('newStatusContractsCount', response.data.length)
                })
                .catch(error => console.log(error));
        },
        inworkStatusContractsCount({ commit }) {
            let params = {
                statusArr: 4,
                userid: 2,
                maxResults: 500,
            }
            OrderDataService.find(params)
                .then( response => {
                    commit('inworkStatusContractsCount', response.data.length)
                })
                .catch(error => console.log(error));
        },

    },
    mutations: {
        fireContractsCount (state, count) {
            state.fireContractsCount = count
        },
        newStatusContractsCount (state, count) {
            state.newStatusContractsCount = count
        },
        inworkStatusContractsCount (state, count) {
            state.inworkStatusContractsCount = count
        }
    },

    state: {
        fireContractsCount: 0, //Количество горящих договоров
        newStatusContractsCount: 0, //Количество договоров со статусом - Новые
        inworkStatusContractsCount: 0, //Количество договоров со статусом - В работе
    }
}