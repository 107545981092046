<template>
    <v-dialog
            v-model="NoticeShowdialog"
            width="1000"
            content-class="editdialog"
    >
        <template v-slot:activator="{ on, attrs }">
            <v-badge
                    color="green"
                    :content="noticesCount"
                    overlap
                    style="margin-right: 15px"
            >
                <v-icon
                        color="#3f4c6b"
                        size="30"
                        v-bind="attrs"
                        v-on="on"
                >
                    mdi-bell-outline
                </v-icon>
            </v-badge>
        </template>
            <v-card>
                <v-card-title class="text-h5 lighten-2" style="color: white; background-color: #3f4c6b">
                    Центр уведомлений
                    <v-spacer></v-spacer>
                    <v-btn
                            icon
                            @click="NoticeShowdialog = false"
                            color="white"
                    >
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-card-title>
                <v-progress-linear
                        indeterminate
                        color="yellow darken-2"
                        v-show="loading"
                ></v-progress-linear>
                <v-card-text>
                    <v-list three-line>
                        <template  v-for="(item,index) in notices" >

                            <v-list-item :key="item.noticeid">
                                <v-list-item-avatar
                                        size="50"
                                >
                                    <v-img
                                            v-show="item.creatoravatar"
                                            :src="`${avatars}${item.creatoravatar}`"
                                    ></v-img>
<!--                                    <v-icon-->
<!--                                            class="grey lighten-1"-->
<!--                                            dark-->
<!--                                    >-->
<!--                                        mdi-bell-->
<!--                                    </v-icon>-->
                                    <v-img
                                            :src="`${avatars}hue_1.png`"
                                    ></v-img>
                                </v-list-item-avatar>
                                <v-list-item-content>
                                    <span class="noticecenterstyle">
                                        <p v-if="item.creatorname">{{item.creatorname}} {{item.creatorsurname}} <span>{{ formatDateISOToRUS(item.noticedate.date) }} в {{ formatTimeISOToRUS(item.noticetime.date) }} </span></p>
                                        <p v-else>Кот Хью <span>{{ formatDateISOToRUS(item.noticedate.date) }} в {{ formatTimeISOToRUS(item.noticetime.date) }}</span></p>
                                        <span  v-html="item.notice"></span>
                                    </span>
                                </v-list-item-content>
                                <v-list-item-action>
                                    <v-btn icon>
                                        <v-icon
                                                color="grey lighten-1"
                                                @click="deleteNotice(item.noticeid)"
                                        >
                                            mdi-close
                                        </v-icon>
                                    </v-btn>
                                </v-list-item-action>
                            </v-list-item>
                            <v-divider
                                    :key="index"
                                    inset="true"
                            ></v-divider>
                        </template>
                    </v-list>
                </v-card-text>


            </v-card>
    </v-dialog>
</template>

<script>
    import NoticeDataService from "@/services/NoticeDataService";

    export default {
        name: "NoticeCenterModal",
        data() {
            return {
                notices: null,
                publicPath: process.env.BASE_URL,
                NoticeShowdialog: false,
                loading: false,
                avatars: process.env.VUE_APP_AVATARS
            }
        },
        watch: {
            NoticeShowdialog(val) {
                //Запрос уведомлений при открытии диалогового окна
                if (val === true) {
                    this.getNotices()
                    this.loading = true
                }
                //Очистка уведомлений при закрытии диалогового окна
                if (val === false) this.notices = null
            }
        },
        computed: {
            noticesCount() {
                return this.$store.state.notice.noticesCount
            }
        },

        methods: {

            formatDateISOToRUS: function (date) {
                return date.split(' ')[0].split("-").reverse().join(".") //Преобразуем ISO дату в дату rus формата DD.MM.YYYY
            },

            formatTimeISOToRUS(time) {
                return time.slice(10,16) //Преобразуем ISO время в дату rus формата hh:mm
            },

            deleteNotice(noticeid) {
                NoticeDataService.delete(noticeid)
                    .then(response => {
                        this.getNotices()
                        console.log(response);
                    })
                    .catch(error => {
                        console.log(error);
                    })
            },

            getNotices() {
                NoticeDataService.find(this.$store.state.auth.user.userid)
                    .then( response => {
                        this.notices = response.data
                        this.loading = false
                        this.getNoticeCount()
                    })
                    .catch(error => console.log(error));
            },

            //Количество уведомлений в центре уведомлений
            getNoticeCount() {
                this.$store.dispatch('noticesCount', this.$store.state.auth.user.userid)
            },
        },
        mounted() {
            this.getNoticeCount() //Количество уведомлений в центре уведомлений
        }
    }
</script>

<style scoped>

</style>