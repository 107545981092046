<!--ПКО таблица-->
<template>
    <div>
        <v-card
                outlined
                class="mb-4"
        >
            <v-card-title>
                Поступление
                <v-spacer></v-spacer>
                <v-btn
                        icon
                        @click="addCredit"
                >
                    <v-icon color="primary">mdi-plus</v-icon>
                </v-btn>
            </v-card-title>
            <v-card-text>

        <!--      Диалоговое окно добавления счета-->
        <v-dialog
                eager
                max-width="500"
                persistent
                v-model="creditEditDialog"
        >
            <v-card>
                <v-card-title class="headline">
                    <span v-show="editedIndex === -1">Добавление</span>
                    <span v-show="editedIndex !== -1">Редактирование</span>
                    <v-spacer></v-spacer>
                    <v-btn
                            icon
                            @click="closeDialog"
                    >
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-card-title>
                <v-card-text>
                    <v-container>
                        <v-row>
                            <v-col>
                                <v-form
                                        ref="credit"
                                        v-model="valid"
                                        lazy-validation
                                >
                                    <v-switch
                                            v-if="editedIndex !== -1"
                                            v-model="isRegistered"
                                            inset
                                            flat
                                            label="Зарегистрирован"
                                    ></v-switch>
                                    <v-text-field
                                            label="Номер"
                                            v-model="number"
                                            :rules="externalRules.integerRules.concat(externalRules.requiredRules)"
                                    ></v-text-field>
                                    <vc-date-picker v-model="date" :model-config="modelConfig">
                                        <template v-slot="{ inputValue, inputEvents }">
                                            <v-text-field
                                                    label="Дата"
                                                    required
                                                    v-on="inputEvents"
                                                    :value="inputValue"
                                                    readonly
                                                    :rules="externalRules.dateRules.concat(externalRules.requiredRules)"
                                            ></v-text-field>
                                        </template>
                                    </vc-date-picker>
                                    <v-select
                                            label="Счет/Касса"
                                            v-model="bankAccountId"
                                            :items="bankAccounts"
                                            item-text="name"
                                            item-value="id"
                                            :rules="externalRules.requiredRules"
                                            return-object
                                            required
                                    ></v-select>
                                    <v-text-field
                                            v-model="sum"
                                            :rules="externalRules.floatRules.concat(externalRules.requiredRules)"
                                            required
                                            label="Сумма"
                                    ></v-text-field>
                                    <v-textarea
                                            style="margin-bottom: 100px"
                                            v-model="description"
                                            label="Примечания"
                                    ></v-textarea>
                                </v-form>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                            color="blue darken-1"
                            text
                            @click="closeDialog"
                    >
                        Отмена
                    </v-btn>
                    <v-btn
                            :disabled="!valid"
                            color="blue darken-1"
                            text
                            @click="saveCredit(false)"
                    >
                        Сохранить
                    </v-btn>
                    <v-btn
                            :disabled="!valid"
                            v-if="editedIndex === -1"
                            color="primary"
                            depressed
                            @click="saveCredit(true)"
                    >
                        Зарегистрировать
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <div class="text-center">
            <v-progress-circular
                    indeterminate
                    color="gray"
                    v-show="loadingTable"
            ></v-progress-circular>
        </div>

        <v-simple-table dense>
            <template v-slot:default>
                <thead>
                <tr>
                    <th class="text-left">
                    <!--Иконка-->
                    </th>
                    <th class="text-left">
                        Номер
                    </th>
                    <th class="text-left">
                        Дата
                    </th>
                    <th class="text-left" >
                        Сумма
                    </th>
                    <th class="text-left" >
                        Счет/касса
                    </th>
                    <th class="text-left" >
                        Примечания
                    </th>
                    <th class="text-left" width="30px">
                    <!--Редактировать-->
                    </th>
                    <th class="text-left" width="30px">
                        <!--Печать-->
                    </th>
                    <th class="text-left" width="50px">
                        <!--Удалить-->
                    </th>
                </tr>
                </thead>
                <tfoot>
                    <tr>
                        <td colspan="2">Итого: <span style="background-color: rgb(230, 230, 230); padding: 5px 5px; border-radius: 4px; margin-left: 10px; font-weight: 500;">{{ new Intl.NumberFormat('ru-RU', { style: 'currency', currency: 'RUB' }).format(getCreditSumm()) }}</span></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>
                </tfoot>
                <tbody>
                <tr
                        v-for="(item, index) in credits"
                        :key="item.id"
                >
                    <td>
                        <v-icon
                                size="20"
                                color="green darken-2"
                                v-if="item.isregistered"
                                title="Документ зарегистрирован"
                        >
                            mdi-file-document-check-outline
                        </v-icon>
                        <v-icon
                                size="20"
                                color="red"
                                v-if="!item.isregistered"
                                title="Документ не зарегистрирован"
                        >
                            mdi-file-document-alert-outline
                        </v-icon>
                    </td>
                    <td>{{ item.number }}</td>
                    <td>{{ item.date.date | formatCreditDate}}</td>
                    <td>{{ item.sum }}</td>
                    <td>{{ item.bankaccount.name }}</td>
                    <td>{{ item.description }}</td>
                    <td>
                        <v-icon
                                title="Редактировать"
                                @click="editCredit(index)"
                                size="20"
                        >
                            mdi-pencil-outline
                        </v-icon>
                    </td>
                    <td>
                        <v-icon
                                title="Печать"
                                size="20"
                                @click="printCredit(item.id)"
                        >
                            mdi-printer-outline

                        </v-icon>

                    </td>
                    <td>
                        <v-icon
                                title="Удалить"
                                @click="deleteCredit(item.id)"
                                size="20"
                        >
                            mdi-trash-can-outline
                        </v-icon>
                    </td>
                </tr>
                </tbody>
            </template>
        </v-simple-table>

            </v-card-text>
        </v-card>

    </div>
</template>


<script>
    import CreditDataService from "@/services/CreditDataService";
    import * as ExternalRules from "@/rules/ExternalRules";
    import BankaccountDataService from "@/services/BankaccountDataService";
    import NoticeDataService from "@/services/NoticeDataService";

    // let options = {
    //     year: 'numeric',
    //     month: 'numeric',
    //     day: 'numeric',
    //     timezone: 'UTC'
    // };

    export default {
        name: "CreditTable",
        props: ['company','order'],
        data () {
            return {
                //Формат даты для календаря
                modelConfig: {
                    type: 'string',
                    mask: 'YYYY-MM-DD 00:00:00.000000',
                },
                credits: null,
                creditEditDialog: false,
                valid: true,
                editedIndex: -1,
                number: null,
                date: null,
                isRegistered: null,
                sum: null,
                description: null,
                externalRules: ExternalRules,
                loadingTable: true,
                bankAccounts: null,
                bankAccountId: null
            }
        },
        methods: {
            addCredit() {
                this.getNextCreditNumber()
                this.date = new Date().toISOString().substr(0, 10) + ' 00:00:00.000000'
                this.creditEditDialog = true

            },
            editCredit(index) {
                this.editedIndex = index
                this.number = this.credits[index].number
                this.date = this.credits[index].date.date
                this.sum = this.credits[index].sum
                this.description = this.credits[index].description
                this.bankAccountId = this.credits[index].bankaccount
                this.isRegistered = this.credits[index].isregistered
                this.creditEditDialog = true
            },
            getNextCreditNumber() {
                CreditDataService.getNextNumber()
                    .then(response => {
                        this.number = response.data
                    })
                    .catch(e => {
                        console.log(e)
                    })
            },
            closeDialog() {
                this.creditEditDialog = false
                this.$nextTick(() => {
                    this.number = null
                    this.date = null
                    this.sum = null
                    this.description = null
                    this.bankAccountId = null
                    this.editedIndex = -1
                })
            },

            updateCredit(item) {
                CreditDataService.update(this.credits[item].id, this.credits[item])
                    .then(response => {
                        console.log(response.data)
                        this.getCredits(this.$route.params.id)
                    })
                    .catch(e => {
                        console.log(e)
                        // this.alertError = true
                    })
            },

            getCredits(orderid) {
                CreditDataService.find(orderid)
                    .then( response => {
                        this.credits = response.data
                        //Добавляем в массив оьбъектов свойства, необходимые для реализации редактирования значений таблицы на лету
                        //Сейчас это тут не нужно
                        // let index;
                        // for (index = 0; index < this.credits.length; ++index) {
                        //     this.$set(this.credits[index],'isNumberEditing',false) //Номер
                        //     this.$set(this.credits[index],'isDateEditing',false) //Дата
                        //     this.$set(this.credits[index],'isSummEditing',false) //Сумма
                        // }
                        this.loadingTable = false
                    })
                    .catch(error => console.log(error));
            },

            getBankAccounts() {
                BankaccountDataService.find()
                    .then( response => {
                        this.bankAccounts = response.data
                    })
                    .catch(error => console.log(error));
            },


            getCreditSumm() {
                let summ = 0
                this.credits.forEach( function (item) {
                   summ = summ + Number(item.sum)
               })
                return summ
            },


            saveCredit(isRegistered) {
                if (this.$refs.credit.validate()) {
                    if (this.editedIndex > -1) {
                        this.credits[this.editedIndex].number = this.number
                        this.credits[this.editedIndex].date = this.date
                        this.credits[this.editedIndex].sum = this.sum
                        this.credits[this.editedIndex].description = this.description
                        this.credits[this.editedIndex].bankaccount = this.bankAccountId.id
                        this.credits[this.editedIndex].isregistered = this.isRegistered
                        this.updateCredit(this.editedIndex)
                    } else {
                        let data = {
                            orderid: this.$route.params.id,
                            number: this.number,
                            date: this.date,
                            sum: this.sum,
                            description: this.description,
                            bankaccountid: this.bankAccountId.id,
                            registered: isRegistered //Зарегистрировать Да/Нет
                        }
                        this.createCredit(data)
                    }
                    this.closeDialog()
                }
            },
            createCredit(data) {
                CreditDataService.create(data)
                    .then(response => {
                        console.log(response.data)
                        NoticeDataService.create({creatorid: null, employeesid: this.order.managerid, noticetext: `Поступила оплата по объекту: ${this.order.estatecod.fulladdress}.<br/><a href="/contracts/${this.order.orderid}"> Договор №${this.order.contractnumber} от ${this.formatDate(this.order.orderdata.date)}</a>`});//Создаем уведомление руководителю проекта
                        this.getCredits(this.$route.params.id)
                        this.creditEditDialog = false
                    })
                    .catch(e => {
                        console.log(e)
                        // this.alertError = true
                    })
            },
            deleteCredit(id) {
                CreditDataService.delete(id)
                    .then(response => {
                        console.log(response.data)
                        this.getCredits(this.$route.params.id)
                    })
                    .catch(e => {
                        console.log(e)
                        // this.alertError = true
                    })
            },

            printCredit(creditid) {
                CreditDataService.printCredit(this.company.companyid, creditid)
                    .then( response => {
                        let headers = response.headers;
                        let fileURL = window.URL.createObjectURL(new Blob([response.data],{ type : headers['content-type']}));
                        let fileLink = document.createElement('a');
                        fileLink.href = fileURL;
                        fileLink.setAttribute('download', 'ПКО');
                        document.body.appendChild(fileLink);
                        fileLink.click();
                    })
                    .catch(error => console.log(error))
            },
            formatDate: function (creditdate) {
                return [creditdate.slice(8,10),creditdate.slice(5,7),creditdate.slice(0,4)].join('.')
            },
        },
        mounted () {
            this.getCredits(this.$route.params.id) //Код текущего
            this.getBankAccounts() //Запрос счетов и касс
        },
        watch: {
        },
        filters: {
            formatCreditDate: function (creditdate) {
                return [creditdate.slice(8,10),creditdate.slice(5,7),creditdate.slice(0,4)].join('.')
            },
        },
    }
</script>

<style scoped>
    /*.summ {*/
    /*    color: #A83C0F;*/
    /*    font-weight: bold;*/
    /*}*/

</style>