<template>
    <div>
        <v-simple-table>
            <template v-slot:default>
                <thead>
                <tr>
                    <th class="text-left">
                        Имя файла
                    </th>
                    <th class="text-left" width="150px">
                        Дата изменения
                    </th>
                    <th class="text-left" width="120px">
                        Размер
                    </th>
                    <th class="text-left" width="120px">
                        Действия
                    </th>
                </tr>
                </thead>
                <tbody>
                <tr
                        v-for="(item, index) in files"
                        :key="item.name"
                >
                    <td>{{ item.name }}</td>
                    <td>{{ item.mdate }} {{ item.mtime.slice(0,-3) }}</td>
                    <td>{{ item.size | formatBytes }}</td>
                    <td>
                        <v-progress-circular
                                indeterminate
                                :width="2"
                                :size="20"
                                color="primary"
                                class="mr-3"
                                v-show="item.showLoadingFileProgress"
                        ></v-progress-circular>
                        <v-icon
                                title="Скачать"
                                class="mr-2"
                                @click="downloadfile(item.name, index)"
                                v-show="!item.showLoadingFileProgress"
                        >
                            mdi-file-download-outline
                        </v-icon>


                        <v-progress-circular
                                indeterminate
                                :width="2"
                                :size="20"
                                color="primary"
                                class="mr-3"
                                v-show="item.showLoadingFileGoogledocs"
                        ></v-progress-circular>
                        <v-icon
                                title="Редактировать в Google Documents"
                                class="mr-2"
                                @click="editFileInGoogledocs(item.name, index)"
                                v-show="!item.showLoadingFileGoogledocs"
                                :disabled="!allowExtension.includes(item.extension)"
                        >
                            mdi-file-edit-outline
                        </v-icon>


                        <v-icon
                                title="Удалить"
                                @click="deletefile(item.name)"
                        >
                            mdi-trash-can-outline
                        </v-icon>
                    </td>
                </tr>
                </tbody>
            </template>
        </v-simple-table>



        <v-file-input
                chips
                v-model="selectedFiles"
                placeholder="Выберите файл..."
                @change="handleFileUpload"
                show-size
                :disabled="isLoading"
        >
        </v-file-input>

        <div
                v-show="selectedFiles"
        >
            <v-row>
                <v-col>
                    <v-progress-linear
                            reactive
                            v-model="progress"
                            color="yellow darken-2"
                            height="10"
                            :indeterminate="isLoading"
                    >
<!--                        {{ progress }} %-->
                    </v-progress-linear>
                </v-col>
            </v-row>
            <v-row
                    align="center"
                    justify="end"
            >
                <v-btn
                        text
                        color="#616161"
                        @click="closeUploadFile"
                        :disabled="isLoading"
                >
                    Отмена
                </v-btn>

                <v-btn
                        style="margin-right: 12px"
                        @click="uploadFiles"
                        color="primary"
                        :disabled="isLoading"
                >
                    Загрузить
                </v-btn>
            </v-row>
        </div>
    </div>
</template>

<script>
    import CorrespondenseFileTableService from "@/services/CorrespondenseFileTableService";
    import GoogleDataService from "@/services/GoogleDataService";
    import FileNameFunction from "@/services/library/FileNameFunction";

    export default {
        name: "CorrespondenceFileTable",
        data () {
            return {
                selectedFiles: undefined, //Выбранный пользователем файл для загрузки
                progress: 0,
                files: [], //Список документов
                isLoading: false,
                section: 'correspondence', //Имя секции
                allowExtension: ["doc", "docx"] //Разрешенные расширения для файла
            }
        },
        methods: {
            formatDate: function (date) {
                return [date.slice(8,10),date.slice(5,7),date.slice(0,4)].join('.')
            },
            formatTime: function (time) {
                return time.slice(10,16)
            },
            getFiles(id) {
                CorrespondenseFileTableService.getFiles(id)
                    .then(response => {
                        //Добавляем в массив новое свойство: флаг скачивания файла с сервера, редактирование в Gooledocs (для отображения индикатора скачивания файла)
                        this.files = response.data.map(current => {
                            let item = Object.assign({}, current)
                            item.showLoadingFileProgress = false
                            item.showLoadingFileGoogledocs = false
                            return item
                        })
                    })
                    .catch(error => console.log(error));
            },
            //Считываем выбранные файлы
            handleFileUpload: function (file) {
                this.progress = 0
                this.selectedFiles = file; //Событие change v-file-input возвращает сюда выбранные File[]. см доку.
            },
            //Загрузка файла на сервер
            uploadFiles: function() {
                    this.upload(this.selectedFiles)
            },
            upload: function (file) {
                //Блокируем на время загрузки компоненты выбора файла и т.п.
                this.isLoading = true
                CorrespondenseFileTableService.uploadfile(file, { correspondenceid: this.$route.params.id }, progressEvent => {
                    this.progress = Math.round((progressEvent.loaded * 100) / progressEvent.total)
                })
                    .then(response => {
                        this.closeUploadFile()
                        this.getFiles(this.$route.params.id)
                        this.progress = 0
                        this.isLoading = false
                        console.log(response)
                    })
                    // .then(files => {
                    //     // this.files = files.data
                    //     // this.progress.splice(this.progress[j], 1)
                    // })
                    .catch( () => {
                        this.progress = 0
                        this.selectedFiles = undefined;
                        error => console.log(error)
                    })

            },

            //Радактирование файла в GooleDocs - Закидываем файл на сервер и открываем в Документе
            //filename - имя файла с расширением
            editFileInGoogledocs (filename, index) {
                this.files[index].showLoadingFileGoogledocs = true
                let data = {
                    fileNameWithoutExtension: FileNameFunction.RemoveExtensionFromFileName(filename), //Имя файла (без расширения)
                    extension: FileNameFunction.GetExtensionFromFileName(filename),//Расширение файла
                    value: this.$route.params.id, //Код документа
                    section: this.section //Секция документа
                }
                GoogleDataService.loadFileOnGoogleDrive(data)
                    .then( response => {
                        this.files[index].showLoadingFileGoogledocs = false
                        window.open(`/googledocs/${response.data.fileid}?fileNameWithoutExtension=${data.fileNameWithoutExtension}&extension=${data.extension}&section=${data.section}&value=${data.value}`,"_blank")
                    })
                    .catch(error => console.log(error));
            },

            downloadfile: function(filename, index) {
                this.files[index].showLoadingFileProgress = true
                CorrespondenseFileTableService.downloadfile(this.$route.params.id, filename)
                    .then( response => {
                        let headers = response.headers;
                        let fileURL = window.URL.createObjectURL(new Blob([response.data],{ type : headers['content-type']}));
                        let fileLink = document.createElement('a');
                        fileLink.href = fileURL;
                        fileLink.setAttribute('download', filename);
                        document.body.appendChild(fileLink);
                        fileLink.click();
                        this.files[index].showLoadingFileProgress = false
                    })
                    .catch(error => console.log(error));
            },

            deletefile: function (filename) {
                CorrespondenseFileTableService.deletefile(this.$route.params.id, filename)
                    .then(response => {
                        console.log(response.data)
                        this.getFiles(this.$route.params.id);
                    })
                    .catch(e => {
                        console.log(e)
                        // this.alertError = true
                    })
            },
            closeUploadFile() {
                this.description = ''
                this.selectedFiles = undefined
            },
        },
        mounted () {
            this.getFiles(this.$route.params.id); //Код текущего договора
        },
        filters: {
            //Форматирование байт
            formatBytes: function (bytes, decimals = 2) {
                if (bytes === 0) return '0 байт'
                const units = ['байт', 'Кб', 'Мб', 'Гб', 'Тб', 'PB', 'EB', 'ZB', 'YB']
                const k = 1024
                const dm = decimals < 0 ? 0 : decimals
                const i = Math.floor(Math.log(bytes) / Math.log(k))
                return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + units[i]
            }
        },
    }
</script>

<style scoped>

</style>