import http from "../http-common"
import authHeader from './auth-header';

class ActivitiesDataService {
    //Запрос всех направлений деятельности
    find() {
        return http.get(`/activities`,{ headers: authHeader() });
    }

}

export default new ActivitiesDataService()