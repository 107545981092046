<template>
    <div>
        <v-row>
            <v-col>
                <span style="font-size: 25px; font-weight: 300">Сотрудники</span>
            </v-col>
            <v-col>
                <AddEmployeeForm @updateEmploeesTable="get"/>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="2">
                <v-card flat>
                    <v-card-text>
                    <v-text-field
                            label="ФИО"
                            placeholder="Введите данные для поиска"
                            v-model="searchString"
                            @keyup.enter="get"
                            hint="Несколько слов разделять пробелом"
                    ></v-text-field>

                        <v-row>

                            <v-col style="margin-bottom: 50px; padding-top: 0; margin-top: 15px">
                                <div class="data-label">Дополнительные фильтры</div>
                                <v-checkbox
                                        v-model="isWorks"
                                        style="margin-top: 3px"
                                        label="Работает"
                                        hide-details
                                ></v-checkbox>
                            </v-col>

                        </v-row>

                        <v-row
                                align="center"
                                justify="space-around"
                        >
                            <v-btn
                                    depressed
                                    @click="resetForm"
                            >
                                Сбросить
                            </v-btn>
                            <v-btn
                                    depressed
                                    color="primary"
                                    @click="get"
                            >
                                Применить
                            </v-btn>
                        </v-row>
                    </v-card-text>

                </v-card>
            </v-col>
            <v-col cols="10">
                <v-data-table
                        :headers="headers"
                        :items="items"
                        :items-per-page="50"
                        :footer-props="{
                             itemsPerPageOptions: [ 50, 100, -1],
                             itemsPerPageAllText: 'Все',
                             itemsPerPageText: 'Строк на странице:'
                        }"
                        :loading="loading"
                        loading-text="Загрузка данных... Пожалуйста подождите"
                >
                    <template v-slot:item.avatar="{ item }">
                        <v-avatar
                                size="35"
                                class="float-left"
                        >
                            <img
                                    :src="`${avatars}${item.avatar}`"
                            >
                        </v-avatar>
                    </template>

                    <template v-slot:item.fullname="{ item }">
                        <router-link class="itemlink" :to="{ name: 'EditEmployee', params: { id: item.employeesid }}">{{ item.fullname }}</router-link>
                    </template>
                </v-data-table>
            </v-col>
        </v-row>
    </div>
</template>

<script>
    import EmployeeDataService from "@/services/EmployeeDataService";
     import AddEmployeeForm from "@/components/AddEmployeeForm";

    export default {
        name: "EmployeesTable",
        components: {AddEmployeeForm},
        // components: {AddCustomerForm},
        data () {
            return {
                publicPath: process.env.BASE_URL,
                avatars: process.env.VUE_APP_AVATARS,
                searchString: '', //Строка поиска
                loading: true,
                isWorks: false,
                headers: [
                    {
                        text: '',
                        value: 'avatar',
                        sortable: false,
                        width: 50,
                    },
                    {
                        text: 'ФИО',
                        value: 'fullname',
                        width: 500
                    },
                    {
                        text: 'Должность',
                        value: 'post',
                        width: 300,
                        sortable: false
                    },
                    {
                        text: 'Телефон',
                        value: 'phone',
                        sortable: false
                    },
                    {
                        text: 'E-mail',
                        value: 'email',
                        sortable: false
                    },


                ],
                items: [],
            }
        },
        mounted () {
            //read Localstorage
            if (localStorage.employearchstring) {
                this.searchString = localStorage.employearchstring
            }
            if (localStorage.isworks) {
                this.isWorks = JSON.parse(localStorage.isworks)
            }

            //Запрашиваем данные с сервера
            this.get()
        },
        filters: {

        },
        methods: {
            resetForm: function () {
                this.searchString = ''
                this.isWorks = false
            },
            get() {
                //Save Localstorage
                localStorage.employearchstring = this.searchString
                localStorage.setItem('isworks', JSON.stringify(this.isWorks))
                this.loading = true
                EmployeeDataService.find(this.searchString, this.isWorks)
                    .then(response => {
                        this.items = response.data
                        this.loading = false
                        console.log(response.data)
                    })
                    .catch(e => {
                        console.log(e)
                    })
            }
        }
    }
</script>

<style scoped>

</style>