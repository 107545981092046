import http from "../http-common"
import authHeader from './auth-header';
import QueryString from "qs";

class ActDataService {
    //Создание записи
    create(data) {
        return http.post("/acts", data, { headers: authHeader() });
    }

    //Обновление записи
    update(id, data) {
        return http.put(`acts/${id}`, data, { headers: authHeader() })
    }

    //Запрос всех актов по номеру договора
    find(orderid) {
        return http.get(`/acts?orderid=${orderid}`,{ headers: authHeader() });
    }

    //Новый запрос (Фильтры объединил в один запрос)
    findItems(params) {
        return http.get(`/acts`,{ params: params, paramsSerializer: params => {return QueryString.stringify(params, {arrayFormat: 'comma'})}, headers: authHeader() });
    }


    //Запрос всех актов периоду дат
    findByDatePeriod(starDate, endDate) {
        return http.get(`/acts-by-date?startdate=${starDate}&enddate=${endDate}`,{ headers: authHeader() });
    }

    //Запрос следующего номера акта (при создании нового)
    getNextNumber() {
        return http.get(`/actnextnumber`,{ headers: authHeader() });
    }

    //Удаление
    delete(id) {
        return http.delete(`/acts/${id}`,{ headers: authHeader() });
    }

    //Номенклатура по акту
    getNomenclature(actkod) {
        return http.get(`/actnomenclature?actkod=${actkod}`,{ headers: authHeader() });
    }

    addNomenclatureByBill(actkod,orderkod) {
        return http.put(`/nomenclatureaddbybill?actkod=${actkod}&orderkod=${orderkod}`, null,{ headers: authHeader() });
    }

    deleteNomenclature(serviceviewkod, actkod) {
        return http.delete(`/nomenclaturedelete?serviceviewkod=${serviceviewkod}&actkod=${actkod}`, { headers: authHeader() });
    }

    //Обновление записи
    updateNomenclature(actkod, serviceviewkod, servicecount, serviceprice) {
        return http.put(`nomenclatureupdate?actkod=${actkod}&serviceviewkod=${serviceviewkod}&servicecount=${servicecount}&serviceprice=${serviceprice}`,  null, { headers: authHeader() })
    }

    //Печать акта сдачи работ
    //Код перемещен в PrintDataService
    // printAct(companyid, actid) {
    //     return http.get(`/printact?companyid=${companyid}&actid=${actid}`, { headers: authHeader(), responseType: 'arraybuffer' })
    // }



}

export default new ActDataService()