<!--https://webdevblog.ru/zagruzka-fajlov-s-pomoshhju-vuejs-i-axios/?ysclid=lb5aq989d2301691057-->
<template>
    <div>
        <v-dialog
                v-model="dialog"
                persistent
                width="600"
        >
            <template v-slot:activator="{ on, attrs }">
                <v-btn
                        color="primary"
                        depressed
                        v-bind="attrs"
                        v-on="on"
                >
                    Импорт
                </v-btn>
            </template>

            <v-card>
                <v-card-title class="headline">
                    Импорт клиентов
                    <v-spacer></v-spacer>
                    <v-btn
                            icon
                            @click="dialog = false"
                    >
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-card-title>

                <v-card-text>
                    <v-container>
                        <v-row>
                            <v-col>
                                <p>Тестовый сервис импорта Юридических лиц.</p>
                                <p>Испортирует *.csv файл со следующей структурой:</p>
                                <p>Заголовки:</p>
                                <ul>
                                    <li>Наименование ЮЛ (обязательно)</li>
                                    <li>ИНН (обязательно)</li>
                                    <li>Телефон (несколько телефонов должны быть разделены запятой)</li>
                                    <li>E-mail (несколько e-mail должны быть разделены запятой)</li>
                                </ul>
                                <br/>
                                <input type="file" id="file" ref="file" @change="handleFileUpload">
                                <div class="mt-5">
                                <v-btn
                                    @click="submitFile"
                                >
                                    Импорт
                                </v-btn>
                                </div>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
    import ImportCustomersService from "@/services/ImportCustomersService";
    export default {
        name: "ImportCustomers",
        data() {
            return {
                dialog: false,
                selectedFiles: undefined, //Выбранный пользователем файл для загрузки
            }
        },
        methods: {
            handleFileUpload() {
                this.selectedFiles = this.$refs.file.files[0]
                this.submitFile(this.$refs.file.files[0])
                //console.log(this.selectedFiles.name)

            },
            submitFile(file) {
                ImportCustomersService.uploadfile(file)
                    .then(response => {
                    console.log(response.data);
                    })
                    .catch(error => {
                        console.log(error);
                    })
            }
        }
    }
</script>

<style scoped>

</style>