<!--ПКО таблица-->
<template>
    <div>
        <v-card
                outlined
                class="mb-4"
        >
            <v-card-title>
                Начисление исполнителям
                <v-spacer></v-spacer>
                <v-btn
                        icon
                        @click="addAccrual"
                >
                    <v-icon color="primary">mdi-plus</v-icon>
                </v-btn>
            </v-card-title>
            <v-card-text>

        <!--      Диалоговое окно добавления исполнителя-->
        <v-dialog
                eager
                max-width="500"
                persistent
                v-model="accrualEditDialog"
        >
            <v-card>
                <v-card-title class="headline">
                    <span v-show="editedIndex === -1">Добавление</span>
                    <span v-show="editedIndex !== -1">Редактирование</span>
                    <v-spacer></v-spacer>
                    <v-btn
                            icon
                            @click="closeDialog"
                    >
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-card-title>
                <v-card-text>
                    <v-container>
                        <v-row>
                            <v-col>
                                <v-form
                                        ref="accrual"
                                        v-model="valid"
                                        lazy-validation
                                >
                                    <v-autocomplete
                                            label="Сотрудник"
                                            v-model="employeesid"
                                            :items="employeesItems"
                                            item-value="employeesid"
                                            item-text="fullname"
                                            :rules="externalRules.floatRules.concat(externalRules.requiredRules)"
                                            clearable
                                    >
                                        <template v-slot:item="data">
                                            <v-list-item-content>
                                                <v-list-item-title v-html="data.item.fullname"></v-list-item-title>
                                            </v-list-item-content>
                                        </template>
                                    </v-autocomplete>

                                    <v-autocomplete
                                            label="Направление деятельности"
                                            v-model="activityid"
                                            :items="activitiesItems"
                                            item-value="id"
                                            item-text="name"
                                            :rules="externalRules.floatRules.concat(externalRules.requiredRules)"
                                            clearable
                                    >
                                        <template v-slot:item="data">
                                            <v-list-item-content>
                                                <v-list-item-title v-html="data.item.name"></v-list-item-title>
                                            </v-list-item-content>
                                        </template>
                                    </v-autocomplete>

                                    <v-text-field
                                            v-model="sum"
                                            :rules="externalRules.floatRules.concat(externalRules.requiredRules)"
                                            required
                                            label="Сумма"
                                    ></v-text-field>

                                </v-form>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                            color="blue darken-1"
                            text
                            @click="closeDialog"
                    >
                        Отмена
                    </v-btn>
                    <v-btn
                            :disabled="!valid"
                            color="blue darken-1"
                            text
                            @click="saveAccrual"
                    >
                        Сохранить
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <div class="text-center">
            <v-progress-circular
                    indeterminate
                    color="gray"
                    v-show="loadingTable"
            ></v-progress-circular>
        </div>

        <v-simple-table dense>
            <template v-slot:default>
                <thead>
                <tr>
                    <th class="text-left">
                        Сотрудник
                    </th>
                    <th class="text-left">
                        Напр.деятельности
                    </th>
                    <th class="text-left" >
                        Сумма
                    </th>
                    <th class="text-left" width="50px">
                        <!--Удалить-->
                    </th>
                </tr>
                </thead>
                <tfoot>
                    <tr>
                        <td colspan="2">Итого: <span style="background-color: rgb(230, 230, 230); padding: 5px 5px; border-radius: 4px; margin-left: 10px; font-weight: 500;">{{ new Intl.NumberFormat('ru-RU', { style: 'currency', currency: 'RUB' }).format(getSumm()) }}</span></td>
                        <td></td>
                        <td></td>

                    </tr>
                </tfoot>
                <tbody>
                <tr
                        v-for="(item) in accrualByOrder"
                        :key="item.id"
                >
                    <td>{{ item.fullemployees }}</td>
                    <td>{{ item.activityname }}</td>
                    <td>{{ item.sum }}</td>
                    <td>
                        <v-icon
                                title="Удалить"
                                @click="deleteAccrual(item.id)"
                                size="20"
                        >
                            mdi-trash-can-outline
                        </v-icon>
                    </td>
                </tr>
                </tbody>
            </template>
        </v-simple-table>

            </v-card-text>
        </v-card>

    </div>
</template>


<script>
    import * as ExternalRules from "@/rules/ExternalRules";
    import AccrualByOrderDataService from "@/services/AccrualByOrderDataService";
    import EmployeeDataService from "@/services/EmployeeDataService";
    import ActivitiesDataService from "@/services/ActivitiesDataService";

    export default {
        name: "AccrualByOrderTable",
        props: ['order'],
        data () {
            return {
                accrualByOrder: null,
                accrualEditDialog: false,
                valid: true,
                sum: null,
                externalRules: ExternalRules,
                loadingTable: true,
                employeesItems: null,
                activitiesItems: null,
                editedIndex: 0,
                employeesid: null,
                activityid: null,
            }
        },
        methods: {
            addAccrual() {
                this.editedIndex = -1
                this.accrualEditDialog = true
            },


            // editCredit(index) {
            //     this.editedIndex = index
            //     this.number = this.credits[index].number
            //     this.date = this.credits[index].date.date
            //     this.sum = this.credits[index].sum
            //     this.description = this.credits[index].description
            //     this.bankAccountId = this.credits[index].bankaccount
            //     this.creditEditDialog = true
            // },

            closeDialog() {
                this.accrualEditDialog = false
                this.$nextTick(() => {
                    this.sum = null
                    this.employeesid = null
                    this.activityid = null
                    this.editedIndex = -1
                })
            },
            getAccrualByOrder(orderid) {
                AccrualByOrderDataService.find(orderid)
                    .then( response => {
                        this.accrualByOrder = response.data
                        this.loadingTable = false
                    })
                    .catch(error => console.log(error));
            },
            getSumm() {
                let summ = 0
                this.accrualByOrder.forEach( function (item) {
                   summ = summ + Number(item.sum)
               })
                this.$store.dispatch('setWageSum', summ) //Сохраняем сумму расходов на ЗП в хранилище (для экономики)
                return summ
            },


            saveAccrual() {
                if (this.$refs.accrual.validate()) {
                    // if (this.editedIndex > -1) {
                    //     this.credits[this.editedIndex].number = this.number
                    //     this.credits[this.editedIndex].date = this.date
                    //     this.credits[this.editedIndex].sum = this.sum
                    //     this.credits[this.editedIndex].description = this.description
                    //     this.credits[this.editedIndex].bankaccount = this.bankAccountId.id
                    //     this.updateCredit(this.editedIndex)
                    // } else {
                        let data = {
                            orderid: this.$route.params.id,
                            sum: this.sum,
                            employeesid: this.employeesid,
                            activityid: this.activityid
                        }
                        this.createAccrual(data)
                    // }
                    this.closeDialog()
                }
            },
            createAccrual(data) {
                AccrualByOrderDataService.create(data)
                    .then(response => {
                        console.log(response.data)
                        this.getAccrualByOrder(this.$route.params.id)
                        this.accrualEditDialog = false
                    })
                    .catch(e => {
                        console.log(e)
                    })
            },
            deleteAccrual(id) {
                AccrualByOrderDataService.delete(id)
                    .then(response => {
                        console.log(response.data)
                        this.getAccrualByOrder(this.$route.params.id)
                    })
                    .catch(e => {
                        console.log(e)
                    })
            },
            getEmployees() {
                EmployeeDataService.find()
                    .then(response => {
                        this.employeesItems = response.data
                    })
                    .catch(e => {
                        console.log(e)
                    })
            },
            getActivities() {
                ActivitiesDataService.find()
                    .then(response => {
                        this.activitiesItems = response.data
                    })
                    .catch(e => {
                        console.log(e)
                    })
            },
        },
        mounted () {
            this.getAccrualByOrder(this.$route.params.id)
            this.getEmployees() //Сотрудники
            this.getActivities() //Направления деятельности
        },
    }
</script>

<style scoped>

</style>