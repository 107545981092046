import Vue from 'vue'
import Vuex from 'vuex'
import { auth } from './modules/auth'
import economic from './modules/economicIndicators'
import  task  from './modules/task'
import notice from "@/store/modules/notice";
import contract from "@/store/modules/contract";
import account from "@/store/modules/account";
import permission from "@/store/modules/permission";
import alert from "@/store/modules/alert";
import dialog from "@/store/modules/dialog";

Vue.use(Vuex)

const store = new Vuex.Store({
    modules: {
        auth,
        economic,
        task,
        notice,
        contract,
        account,
        alert,
        dialog,
        permission
    },
})

export default store;
