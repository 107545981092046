<template>
    <div>
        <v-row>
            <v-col>
                <span style="font-size: 25px; font-weight: 300">Отчет по выполненным работам</span>
            </v-col>
            <v-col class="d-flex justify-end">
                <v-btn
                        color="primary"
                        depressed
                        @click="printTable"
                        class="mr-3"
                        :disabled="!workItems.length > 0"
                >
                    Печать
                </v-btn>
                <AddContractForm/>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="2">
                <v-card flat>
                    <v-card-text>
                        <v-form
                                v-model="valid"
                                lazy-validation
                        >
                            <v-row>
                                <v-col cols="6" style="padding-top: 0; padding-bottom: 0">
                                    <v-text-field
                                            label="Период"
                                            placeholder="Начало"
                                            v-model="startDate"
                                            v-mask="'##.##.####'"
                                            :rules="externalRules.dateRules"
                                            ref="startDate"
                                    ></v-text-field>
                                </v-col>

                                <v-col cols="6" style="padding-top: 0; padding-bottom: 0">
                                    <v-text-field
                                            placeholder="Окончание"
                                            v-model="endDate"
                                            v-mask="'##.##.####'"
                                            :rules="externalRules.dateRules"
                                            ref="endDate"
                                    ></v-text-field>
                                </v-col>
                            </v-row>

                            <v-row
                                    align="center"
                                    justify="space-around"
                            >
                                <v-btn
                                        depressed
                                        color="primary"
                                        @click="getWorks"
                                        :loading="loadingTable"
                                        class="mt-8"
                                >
                                    Сформировать
                                </v-btn>
                            </v-row>
                        </v-form>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col cols="10">
                <v-card flat>
                    <v-card-text id="printableTable">

                        <h1 class="text-center" style="color: #9f9f9f; padding-top: 100px" v-show="workItems.length <= 0">Отчет не сформирован</h1>
                        <h2 v-if="workItems.length > 0" class="mb-7">Отчет по выполненным работам в период с {{ startDate }} по {{ endDate }}</h2>
                        <div
                                v-for="(groupItem, index) in accItems"
                                :key="groupItem"
                                class="mb-7 print-content"
                        >
                            <div style="background-color: #5386c6; display: inline-block; padding: 2px 5px; border-radius: 3px; color: white">Договор: № <router-link :to="{ name: 'EditContract', params: { id: groupItem[0].orderid }}"><span style="color: white; text-decoration: underline">{{index}}</span></router-link> от {{ dateFunction.DateIsoToRUS(groupItem[0].orderdate.date) }}
                                - {{ groupItem[0].objectname }}
                            </div>

                            <v-simple-table dense>
                                <thead>
                                <tr>
                                    <th width="150px">Дата</th>
                                    <th width="150px">Время</th>
                                    <th width="400px">Вид работ</th>
                                    <th width="150px">Объем</th>
                                    <th width="250px">Исполнители</th>
                                    <th>Примечание</th>
                                </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="workItem in groupItem" :key="workItem">
                                        <td>{{ formatDate(workItem.date.date) }}</td>
                                        <td>{{ formatTime(workItem.start_time.date)}} - {{ formatTime(workItem.end_time.date) }}</td>
                                        <td>{{ workItem.servicename }}</td>
                                        <td>{{ new Intl.NumberFormat('ru-RU', { style: 'decimal'}).format(workItem.volume)  }}</td>
                                        <td>
                                            <div
                                                    v-for="employeeItem in workItem.tableworkscrossingemployees"
                                                    :key="employeeItem"
                                                    class="mb-1 mt-1"
                                            >
                                                {{employeeItem.employee.initialsemployees }}
                                                <span class="font-italic red--text">{{ employeeItem.description }}</span>
                                            </div>
                                        </td>
                                        <td><span style="white-space: pre-line">{{workItem.description}}</span></td>
                                    </tr>
                                </tbody>
                            </v-simple-table>
                        </div>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>


    </div>
    </template>
<script>
    import WorksDataService from "@/services/WorksDataService";
    import DateFunction from "@/services/library/DateFunction";
    import * as ExternalRules from "@/rules/ExternalRules";
    export default {
        name: "WorksReport",
        data () {
            return {
                workItems: [],
                accItems: null,
                startDate: '',
                endDate: '',
                loadingTable: false,
                dateFunction: DateFunction,
                externalRules: ExternalRules,
            }
        },

        mounted() {
            //Запрашиваем данные с сервера
            //this.get()
        },

        methods: {
            getWorks() {
                let startDate = DateFunction.DateRUSToIso(this.startDate)
                let endDate = DateFunction.DateRUSToIso(this.endDate)

                this.loadingTable = true
                WorksDataService.findByDate(startDate, endDate)
                    .then( response => {
                        this.workItems = response.data
                        this.loadingTable = false
                    })
                    .then(
                        this.groupWorksByOrderNumber
                    )
                    .catch(error => console.log(error));
            },

            //Группируем записи по номеру договора
            groupWorksByOrderNumber() {
                this.accItems  = this.workItems.reduce((acc, item) => {
                    const orderNumber = item.ordernumber
                    if (acc[orderNumber]) {
                        acc[orderNumber].push(item)
                    } else {
                        acc[orderNumber] = [item]
                    }
                    return acc
                }, {})

            },
            formatDate: function (date) {
                return [date.slice(8,10),date.slice(5,7),date.slice(0,4)].join('.')
            },
            formatTime: function (time) {
                return time.slice(10,16)
            },

            //Печать таблицы
            printTable() {
                //let printCSS = '<link rel="stylesheet" href="../../assets/css/style.css" type="text/css"/>';
                let printCSS = '<link rel="stylesheet" href="../../assets/css/style.css" type="text/css"/>';
                let printContent = document.getElementById('printableTable')
                let WinPrint = window.open('','','width=900, height=650')
                WinPrint.document.write(printCSS);
                WinPrint.document.write(printContent.innerHTML)
                WinPrint.document.close()
                WinPrint.focus()
                WinPrint.print()
                WinPrint.close()
            },

        }
    }

</script>

<style scoped>
    /*Уменьшаем отступ в чекбоксе*/
    .v-input--selection-controls {
        margin-top: 0px;
        padding-top: 4px;
    }


    /*Стили для печати отчетов*/
    @media print {
        .print-content {
            font-size: 150px;
            background-color: #00b028;
            color: #00b028;

        }
    }
</style>