//В этом вуексе механизм работы с экономическим показателями
//(стоимость договора, расходы и т.п)
//Необходимо для передачи этих показетелей между различными компонентами через Вуекс

export default {
    actions: {
        //Сумма по договору (По смете)
        setOrderSum({ commit }, sum) {
            commit('updateOrderSum', sum)
        },
        //Расходы по договору
        setExpensesSum({ commit }, sum) {
            commit('updateExpensesSum', sum)
        },
        //Расходы по зарплате
        setWageSum({ commit }, sum) {
            commit('updateWageSum', sum)
        }
    },
    mutations: {
        //Обновление суммы по договору
        updateOrderSum(state, sum) {
            state.ordersum = sum
        },
        //Расходы по договору
        updateExpensesSum(state, sum) {
            state.expensessum = sum
        },
        //Расходы по зарплате
        updateWageSum(state, sum) {
            state.wagesum = sum
        }
    },
    state: {
        ordersum: 0, //Сумма по договору (по смете)
        expensessum: 0, //Расходы по договору
        wagesum: 0, //Сдельная ЗП
    },
    getters: {
        //Прибыль (доходы - расходы - ЗП)
        profit: state => {
            return state.ordersum - state.expensessum - state.wagesum
        },
    }
}