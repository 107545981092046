<template>
  <div>
      <EmployeesTable/>
  </div>
</template>

<script>
// @ is an alias to /src
import EmployeesTable from "@/components/EmployeesTable";

export default {
  name: 'Employees',
  components: {
    EmployeesTable
  }
}
</script>
