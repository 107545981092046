<template>
  <div>
      <!--Сообщение об ошибке сохранения в БД-->
      <v-alert
              type="error"
              dismissible
              v-model="alertError"
              transition="scale-transition"
      >
          Невозможно сохранить данные. Возможно отсутствует соединение с базой данных. Обратитесь к Администратору.
      </v-alert>


      <!--Сообщение об успешном копировании данных-->
          <v-snackbar
                  v-model="snackbarCopy"
                  color="green darken-3"
                  top
                  :timeout="1000"
          >
             Скопировано!
              <template v-slot:action="{ attrs }">
                  <v-btn
                          color="grey lighten-2"
                          icon
                          v-bind="attrs"
                          @click="snackbarCopy = false"
                  >
                      <v-icon>mdi-close</v-icon>
                  </v-btn>
              </template>
          </v-snackbar>

      <!--Сообщение об успешном Сохранении данных-->
      <v-snackbar
              v-model="snackbarSave"
              color="green"
              top
              :timeout="1000"
      >
          Данные сохранены
          <template v-slot:action="{ attrs }">
              <v-btn
                      color="grey lighten-2"
                      icon
                      v-bind="attrs"
                      @click="snackbarSave = false"
              >
                  <v-icon>mdi-close</v-icon>
              </v-btn>
          </template>
      </v-snackbar>

      <div>
          <v-row>
              <v-col
                      cols="auto"
              >
                  <h1 class="data-name" v-show="!numdateInPlace">
                      Письмо № {{ correspondence.number }} от {{ formatOrderDate(correspondence.date.date) }}
                      <v-icon
                              @click="editCorrenpondencenumdate"
                              right
                      >
                          mdi-pencil
                      </v-icon>
                      <v-icon
                              @click="copy(correspondence.number + ` от ` + formatOrderDate(correspondence.date.date))"
                              right
                      >
                          mdi-content-copy
                      </v-icon>
                  </h1>
                  <div v-show="numdateInPlace">
                      <v-form
                              ref="numdate"
                              v-model="valid"
                              lazy-validation
                      >
                          <v-text-field
                                  label="Номер письма"
                                  v-model="editedItem.corrnumber"
                                  :rules="requiredRules"
                                  ref="corrnumber"
                          ></v-text-field>

                          <vc-date-picker v-model="editedItem.corrdate">
                              <template v-slot="{ inputValue, inputEvents }">
                                  <v-text-field
                                          label="Дата"
                                          v-mask="'##.##.####'"
                                          required
                                          v-on="inputEvents"
                                          :value="inputValue"
                                          readonly
                                          :rules="requiredRules"
                                          ref="corrdate"
                                  ></v-text-field>
                              </template>
                          </vc-date-picker>
                      </v-form>
                      <v-btn
                              color="blue darken-1"
                              text
                              @click="closeNumdateInPlace"
                      >
                          Отмена
                      </v-btn>
                      <v-btn
                              :disabled="!valid"
                              color="blue darken-1"
                              text
                              @click="saveNumdateInPlace"
                      >
                          Сохранить
                      </v-btn>
                  </div>
              </v-col>
              <v-spacer></v-spacer>
              <v-col cols="auto">
                  <v-menu offset-y>
                      <template v-slot:activator="{ on, attrs }">
                          <v-btn
                                  color="primary"
                                  depressed
                                  v-bind="attrs"
                                  v-on="on"
                          >
                              Документ
                              <v-icon
                                      right
                                      size="20"
                              >
                                  mdi-chevron-down
                              </v-icon>
                          </v-btn>
                      </template>
                      <v-list dense light>
                          <v-list-item
                                  v-for="(item, i) in templates"
                                  :key="i"
                                  @click="createDocumentFromTemplate(item.id)"
                          >
                              <v-list-item-title>{{ item.name }}</v-list-item-title>
                          </v-list-item>
                      </v-list>
                  </v-menu>



              </v-col>
          </v-row>
          <v-row>
              <v-col>
                  <v-card
                          outlined
                          class="mb-4"
                  >
                      <v-card-title>Основное</v-card-title>
                      <v-card-text>
                          <v-row class="mx-0">
                              <div class="data-row">
                                  <div class="data-label">
                                      Тип письма
                                  </div>
                                  <div class="data-text" v-show="!carrierInPlace">
                                      <span>  {{ correspondence.carrier | formatCarrier }} </span>
                                      <v-icon
                                              @click="editCarrier"
                                              right
                                              small
                                      >
                                          mdi-pencil
                                      </v-icon>
                                  </div>
                                  <div v-show="carrierInPlace">
                                      <v-select
                                              v-model="corrCarrierSelect"
                                              :items="corrCarrierItems"
                                              item-text="label"
                                              item-value="value"
                                              :rules="requiredRules"
                                              required
                                      ></v-select>
                                      <v-btn
                                              color="blue darken-1"
                                              text
                                              @click="closeCarrierInPlace"
                                      >
                                          Отмена
                                      </v-btn>
                                      <v-btn
                                              color="blue darken-1"
                                              text
                                              @click="saveCarrierInPlace"
                                      >
                                          Сохранить
                                      </v-btn>
                                  </div>
                              </div>
                          </v-row>

                          <v-row class="mx-0">
                              <div class="data-row">
                                  <div class="data-label">
                                      Вид письма
                                  </div>
                                  <div class="data-text" v-show="!typeInPlace">
                                      <span>  {{ correspondence.typecode.name }} </span>
                                      <v-icon
                                              @click="editType"
                                              right
                                              small
                                      >
                                          mdi-pencil
                                      </v-icon>
                                  </div>
                                  <div v-show="typeInPlace">
                                      <v-select
                                              v-model="viewTypeSelect"
                                              :items="viewTypeItems"
                                              item-text="name"
                                              item-value="id"
                                              :rules="requiredRules"
                                              required
                                              return-object
                                      ></v-select>
                                      <v-btn
                                              color="blue darken-1"
                                              text
                                              @click="closeTypeInPlace"
                                      >
                                          Отмена
                                      </v-btn>
                                      <v-btn
                                              color="blue darken-1"
                                              text
                                              @click="saveTypeInPlace"
                                      >
                                          Сохранить
                                      </v-btn>
                                  </div>
                              </div>
                          </v-row>

                          <v-row class="mx-0">
                              <div class="data-row">
                                  <div class="data-label">
                                      Получатель
                                  </div>
                                  <div class="data-text" v-show="!customerInPlace">
                                      <span>  {{ correspondence.customercode.fullname }} </span>
                                      <v-icon
                                              @click="clearCustomer"
                                              right
                                              small
                                      >
                                          mdi-close-circle-outline
                                      </v-icon>
                                      <v-icon
                                              @click="goToCustomer"
                                              right
                                              small
                                      >
                                          mdi-arrow-top-right
                                      </v-icon>
                                      <v-icon
                                              @click="copy(correspondence.customercode.fullname)"
                                              right
                                              small
                                      >
                                          mdi-content-copy
                                      </v-icon>
                                  </div>
                                  <div v-show="customerInPlace">
                                      <v-autocomplete
                                              placeholder="Введите не менее 3-х символов для поиска"
                                              clearable
                                              return-object
                                              v-model="customer"
                                              :items="customerItems"
                                              :search-input.sync="customerSearch"
                                              item-value="customerid"
                                              item-text="fullname"
                                              @click:clear="clearCustomerSearch"
                                              :rules="requiredRules"
                                      ></v-autocomplete>
                                      <v-btn
                                              color="blue darken-1"
                                              text
                                              @click="closeCustomerInPlace"
                                      >
                                          Отмена
                                      </v-btn>
                                      <v-btn
                                              color="blue darken-1"
                                              text
                                              @click="saveCustomerInPlace"
                                      >
                                          Сохранить
                                      </v-btn>
                                  </div>
                              </div>
                          </v-row>
                          <v-row class="mx-0">
                              <div class="data-row">
                                  <div class="data-label">
                                      Договор
                                  </div>
                                  <div class="data-text" v-show="!orderInPlace">
                                      <span v-if="correspondence.ordernumber"> {{ correspondence.ordernumber }} </span>
                                      <span class="placeholder" v-else>Выберите договор</span>
                                      <v-icon
                                              v-show="!correspondence.ordernumber"
                                              @click="editOrder"
                                              right
                                              small
                                      >
                                          mdi-pencil
                                      </v-icon>
                                      <v-icon
                                              v-show="correspondence.ordernumber"
                                              @click="clearOrder"
                                              right
                                              small
                                      >
                                          mdi-close-circle-outline
                                      </v-icon>
                                      <v-icon
                                              v-show="correspondence.ordernumber"
                                              @click="goToOrder"
                                              right
                                              small
                                      >
                                          mdi-arrow-top-right
                                      </v-icon>
                                      <v-icon
                                              v-show="correspondence.ordernumber"
                                              @click="copy(correspondence.ordernumber)"
                                              right
                                              small
                                      >
                                          mdi-content-copy
                                      </v-icon>
                                  </div>
                                  <div v-show="orderInPlace">
                                      <v-autocomplete
                                              placeholder="Введите не менее 3-х символов для поиска"
                                              clearable
                                              v-model="orderid"
                                              :items="orderItems"
                                              :search-input.sync="orderSearch"
                                              item-value="orderid"
                                              item-text="contractnumber"
                                              ref="order"
                                              @click:clear="clearOrderSearch"
                                              return-object
                                      ></v-autocomplete>
                                      <v-btn
                                              color="blue darken-1"
                                              text
                                              @click="closeOrderInPlace"
                                      >
                                          Отмена
                                      </v-btn>
                                      <v-btn
                                              color="blue darken-1"
                                              text
                                              @click="saveOrderInPlace"
                                      >
                                          Сохранить
                                      </v-btn>
                                  </div>
                              </div>

                          </v-row>

                          <v-row class="mx-0">
                              <div class="data-row">
                                  <div class="data-label">
                                      Краткое содержание
                                  </div>
                                  <div
                                          class="data-text"
                                          v-show="!contentsInPlace"
                                  >
                                      <span v-if="correspondence.contents"> {{ correspondence.contents }} </span>
                                      <span class="placeholder" v-else>Введите текст</span>
                                      <v-icon
                                              @click="editContentsInPlace"
                                              right
                                              small
                                      >
                                          mdi-pencil
                                      </v-icon>
                                      <v-icon
                                              v-show="correspondence.contents"
                                              @click="copy(correspondence.contents)"
                                              right
                                              small
                                      >
                                          mdi-content-copy
                                      </v-icon>
                                  </div>
                                  <div v-show="contentsInPlace">
                                      <v-textarea
                                              v-model="editedItem.contents"
                                              ref="contents"
                                      ></v-textarea>
                                      <v-btn
                                              color="blue darken-1"
                                              text
                                              @click="closeContentsInPlace"
                                      >
                                          Отмена
                                      </v-btn>
                                      <v-btn
                                              color="blue darken-1"
                                              text
                                              @click="saveContentsInPlace"
                                      >
                                          Сохранить
                                      </v-btn>
                                  </div>
                              </div>
                          </v-row>

                          <v-row class="mx-0">
                              <div class="data-row">
                                  <div class="data-label">
                                      Исполнитель
                                  </div>
                                  <div class="data-text">
                                      <span>  {{ correspondence.employeecode.fullname }} </span>
                                  </div>
                              </div>
                          </v-row>


                          <v-row class="mx-0">
                              <div class="data-row">
                                  <div class="data-label">
                                      Примечания
                                  </div>
                                  <div
                                          class="data-text"
                                          v-show="!descriptionInPlace"
                                  >
                                      <span v-if="correspondence.description"> {{ correspondence.description }} </span>
                                      <span class="placeholder" v-else>Введите текст</span>
                                      <v-icon
                                              @click="editDescriptionInPlace"
                                              right
                                              small
                                      >
                                          mdi-pencil
                                      </v-icon>
                                      <v-icon
                                              v-show="correspondence.description"
                                              @click="copy(correspondence.description)"
                                              right
                                              small
                                      >
                                          mdi-content-copy
                                      </v-icon>
                                  </div>
                                  <div v-show="descriptionInPlace">
                                      <v-textarea
                                              v-model="editedItem.description"
                                              ref="description"
                                      ></v-textarea>
                                      <v-btn
                                              color="blue darken-1"
                                              text
                                              @click="closeDescriptionInPlace"
                                      >
                                          Отмена
                                      </v-btn>
                                      <v-btn
                                              color="blue darken-1"
                                              text
                                              @click="saveDescriptionInPlace"
                                      >
                                          Сохранить
                                      </v-btn>
                                  </div>
                              </div>
                          </v-row>
                      </v-card-text>
                  </v-card>

              </v-col>
              <v-col>

                  <v-card
                          outlined
                          class="mb-4"
                  >
                      <v-card-title>Вложения</v-card-title>

                      <v-card-text>
                        <v-row class="mx-0">
                          <div class="data-row">
                            <CorrespondenceFileTable
                                ref="refCorrespondenceFileTable"
                            >
                            </CorrespondenceFileTable>
                          </div>

                        </v-row>




                      </v-card-text>
                  </v-card>


              </v-col>
          </v-row>
      </div>

  </div>
</template>
<script>
    import CorrespondenceDataService from "@/services/CorrespondenceDataService";
    import CorrespondenceFileTable from "@/components/CorrespondenceFileTable";
    import CustomerDataService from "@/services/CustomerDataService";
    import OrderDataService from "@/services/OrderDataService";
    import TemplatesDataService from "@/services/TemplatesDataService";
    import PrintDataService from "@/services/PrintDataService";

    function pad(number) {
        if (number < 10) {
            return '0' + number;
        }
        return number;
    }

    export default {
      name: 'EditCorrespondence',
        components: {CorrespondenceFileTable},
      data: function() {
            return {
                //Редактирование на месте
                numdateInPlace: false,
                carrierInPlace: false,
                typeInPlace: false,
                customerInPlace: false,
                contentsInPlace: false,
                descriptionInPlace: false,
                orderInPlace: false,

                corrCarrierSelect: null,
                corrCarrierItems: [
                    { label: 'Электронное', value: '1' },
                    { label: 'Бумажное', value: '0' },
                ],

                customerItems: [],
                customer: null,
                customerSearch: null,
                templates: null, //Document templates
                viewTypeSelect: null,
                viewTypeItems: [],

                formatAddress: null,
                alertError: false,

                valid: true,

                orderid: null,
                orderItems: [],
                orderSearch: null,

                correspondence: {},//Объект текущего объекта

                snackbarCopy: false,
                snackbarSave: false,

                editedItem: {}, //В этой переменной сохраняем объект редактирования

                requiredRules: [
                    v => !!v || 'Пожалуйста, заполните это поле',
                ],
            }
        },
        methods: {
            formatOrderDate: function (date) {
                if (date !== null) {
                    return [date.slice(8, 10), date.slice(5, 7), date.slice(0, 4)].join('.')
                }
            },

            //Редактирование на лету
            editCorrenpondencenumdate() {
                this.editedItem.corrnumber = this.correspondence.number
                this.editedItem.corrdate = this.correspondence.date.date
                this.numdateInPlace = true
                this.$nextTick(() => {
                    this.$refs.corrnumber.focus();
                })
            },

            saveNumdateInPlace () {
                if (this.$refs.numdate.validate()) {
                    this.correspondence.number = this.editedItem.corrnumber
                    //замена toISOString()
                    this.correspondence.date.date = this.editedItem.corrdate.getFullYear() + '-' + pad(this.editedItem.corrdate.getMonth() + 1) + '-' + pad(this.editedItem.corrdate.getDate() + 'T00:00:00.000Z')
                    this.closeNumdateInPlace()
                    this.update()
                }
            },

            clearOrderSearch() {
                this.orderItems = []
            },

            editOrder() {
                this.orderInPlace = true
                this.$nextTick(() => {
                    this.$refs.order.focus();
                })
            },

            closeNumdateInPlace() {
                this.numdateInPlace = false
                this.$nextTick(() => {
                    this.editedItem = {}
                })
            },

            editCarrier() {
                this.carrierInPlace = true
                this.corrCarrierSelect = this.correspondence.carrier
            },

            closeCarrierInPlace() {
                this.carrierInPlace = false
                this.corrCarrierSelect = null
            },

            saveCarrierInPlace() {
                this.correspondence.carrier = this.corrCarrierSelect
                this.closeCarrierInPlace()
                this.update()
            },


            editType() {
                this.typeInPlace = true
                this.viewTypeSelect = this.correspondence.typecode
            },

            closeTypeInPlace() {
                this.typeInPlace = false
                this.viewTypeSelect = null
            },

            saveTypeInPlace() {
                this.correspondence.typecode =  this.viewTypeSelect
                this.closeTypeInPlace()
                this.update()
            },

            clearCustomer() {
                this.customerInPlace = true
            },

            clearOrder() {
                this.correspondence.orderid = null
                this.correspondence.ordernumber = null
                this.update()
            },

            clearCustomerSearch() {
                this.customerItems = []
            },

            closeCustomerInPlace() {
                this.customerInPlace = false
                this.customerItems = []
            },

            saveCustomerInPlace() {
                this.correspondence.customercode =  this.customer
                this.closeCustomerInPlace()
                this.update()
            },

            closeOrderInPlace() {
                this.orderInPlace = false
                this.orderItems = []
            },

            saveOrderInPlace() {
                this.correspondence.orderid =  this.orderid.orderid
                this.correspondence.ordernumber =  this.orderid.contractnumber
                this.closeOrderInPlace()
                this.update()
            },

            editContentsInPlace() {
                this.editedItem.contents = this.correspondence.contents
                this.contentsInPlace = true
                this.$nextTick(() => {
                    this.$refs.contents.focus();
                })
            },

            closeContentsInPlace() {
                this.contentsInPlace = false
                this.$nextTick(() => {
                    this.editedItem = {}
                })
            },

            saveContentsInPlace () {
                this.correspondence.contents = this.editedItem.contents
                this.closeContentsInPlace()
                this.update()
            },


            editDescriptionInPlace() {
                this.editedItem.description = this.correspondence.description
                this.descriptionInPlace = true
                this.$nextTick(() => {
                    this.$refs.description.focus();
                })
            },

            closeDescriptionInPlace() {
                this.descriptionInPlace = false
                this.$nextTick(() => {
                    this.editedItem = {}
                })
            },

            saveDescriptionInPlace() {
                this.correspondence.description = this.editedItem.description
                this.closeDescriptionInPlace()
                this.update()
            },


            copy (text) {
                this.$copyText(text).then(response => {
                    this.snackbarCopy = true
                    console.log(response)
                })
                .catch(error => {
                    console.log(error);
                })
            },

            goToCustomer() {
                this.$router.push({name: 'EditCustomer', params: { id: this.correspondence.customercode.customerid}})
            },

            goToOrder() {
                this.$router.push({name: 'EditContract', params: { id: this.correspondence.orderid}})
            },


            get(id) {
                CorrespondenceDataService.get(id)
                    .then(response => {
                        this.correspondence = response.data
                        console.log(response.data);
                    })
                    .catch(error => {
                        console.log(error);
                    })
            },

            getViewtype() {
                CorrespondenceDataService.getViewtype()
                    .then(response => {
                        this.viewTypeItems = response.data
                        console.log(response.data)
                    })
                    .catch(e => {
                        console.log(e)
                    })
            },

            getTemplates() {
                TemplatesDataService.get('correspondence')
                    .then(response => {
                        this.templates = response.data
                        console.log(response.data);
                    })
                    .catch(error => {
                        console.log(error);
                    })
            },

            update() {
                CorrespondenceDataService.update(this.correspondence.id, this.correspondence)
                    .then(response => {
                        this.snackbarSave = true
                        console.log(response.data)
                    })
                    .catch(e => {
                        console.log(e)
                        this.alertError = true
                    })
            },
            createDocumentFromTemplate(templateid) {
                PrintDataService.createDocument(this.$route.params.id, templateid)
                    .then(response => {
                        this.$refs.refCorrespondenceFileTable.getFiles(this.$route.params.id) //Обновляем таблицу файлов корреспонденции в дочернем компоненте через refs
                        console.log(response)
                    })
                    .catch(e => {
                        console.log(e)
                    })
            }
        },
        filters: {
            formatCarrier: function (carrier) {
                switch (carrier) {
                    case '0' :
                        return 'Бумажное'
                    case '1' :
                        return 'Электронное'
                }
            }
        },
        watch: {
            customerSearch() {
                if (this.customerItems.length > 0) return

                if (this.customerSearch.length > 3) {
                    CustomerDataService.findBySurname(this.customerSearch)
                        .then(response => {
                            this.customerItems = response.data
                        })
                        .catch(e => {
                            console.log(e)
                        })
                }
            },
            orderSearch() {
                if (this.orderItems.length > 0) return

                if (this.orderSearch.length > 3) {
                    let params = {
                        contractNumberString: this.orderSearch,
                        maxResults: 50
                    }
                    OrderDataService.find(params)
                        .then(response => {
                            this.orderItems = response.data
                        })
                        .catch(e => {
                            console.log(e)
                        })
                }
            },
        },
        mounted() {
            this.get(this.$route.params.id); //Код текущего объекта
            this.getViewtype()
            this.getTemplates()
        },
    }
</script>

<style scoped>

</style>