<template>
    <div>
        <v-dialog
                v-model="dialog"
                persistent
                max-width="1600"
        >
            <template v-slot:activator="{ on, attrs }">
                <v-btn
                        color="primary"
                        depressed
                        v-bind="attrs"
                        v-on="on"
                >
                    <v-icon
                            size="17"
                            class="mr-1"
                    >
                        mdi-upload-multiple
                    </v-icon>
                    Загрузить файлы
                </v-btn>
            </template>

            <v-card>
                <v-card-title class="headline">
                    Загрузка файлов
                    <v-spacer></v-spacer>
                    <v-btn
                            icon
                            @click="closeDialog"
                            :disabled="isLoading"
                    >
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-card-title>
                <v-form
                        ref="form"
                        v-model="validForm"
                        lazy-validation
                >
                    <v-card-text>
                        <v-container>
                            <v-row>
                                <v-col style="margin-top: 0; padding-top: 0">

                                    <v-simple-table
                                            fixed-header
                                    >
                                        <template v-slot:default>
                                            <thead>
                                            <tr>
                                                <th class="text-left" width="50">
                                                </th>
                                                <th class="text-left">
                                                    Имя файла
                                                </th>
                                                <th class="text-left" width="150">
                                                    Размер
                                                </th>
<!--                                                <th class="text-left" width="150">-->
<!--                                                    Тип-->
<!--                                                </th>-->
                                                <th class="text-left" width="400">
                                                    Примечания
                                                </th>
                                                <th class="text-left" width="100">
                                                    Статус
                                                </th>
                                                <th class="text-left" width="100">
                                                    Загрузка
                                                </th>
                                                <th width="50">
                                                </th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            <tr
                                                    v-for="(item, index) in files"
                                                    :key="item.name"
                                            >
                                                <td>{{ index + 1 }}</td>
                                                <td>{{ item.name }}</td>
                                                <td>{{ BitesFunction.FormatBytes(item.size) }}</td>
<!--                                                <td>{{ item.type }}</td>-->
                                                <td>
                                                    <input
                                                            type="text"
                                                            style="border: 1px #a1a4a3 solid; border-radius: 2px; width: 100%"
                                                            v-model="advInfFiles[index].description"
                                                    />
                                                </td>
                                                <td>
                                                    <v-icon
                                                        size="22"
                                                        color="green"
                                                        v-show="advInfFiles[index].status === 1"
                                                        title="Успешно"
                                                    >
                                                        mdi-check-circle-outline
                                                    </v-icon>
                                                    <v-icon
                                                            size="22"
                                                            color="red"
                                                            v-show="advInfFiles[index].status === 0"
                                                            title="Ошибка"
                                                    >
                                                        mdi-close-circle-outline
                                                    </v-icon>
                                                </td>
                                                <td><span v-text="advInfFiles[index].progress"></span>%</td>
                                                <td>
                                                    <v-icon
                                                            size="22"
                                                            @click="deleteFile(index)"
                                                            title="Удалить"
                                                            :disabled="isLoading"
                                                    >
                                                        mdi-trash-can-outline
                                                    </v-icon>
                                                </td>
                                            </tr>
                                            </tbody>
                                        </template>
                                    </v-simple-table>

<!--                                    @drop.prevent="drop($event)"-->


                                    <div>
                                        <div class="dropzone"
                                            @dragenter.prevent="toggleActive"
                                            @dragleave.prevent="toggleActive"
                                            @dragover.prevent
                                             @drop.prevent="toggleActive(); drop($event)"
                                             :class="{'active-dropzone': active}"
                                        >
                                            <span>Перетащите файлы в эту область</span>
                                            <span>ИЛИ</span>
                                            <input
                                                    type="file"
                                                    id="input_file"
                                                    class="d-none"
                                                    multiple
                                                    @change="handleFileUploads($event)"
                                                    :disabled="isLoading"
                                            >
                                            <label for="input_file" class="input_file_button">Выберите файлы</label>
                                        </div>
                                    </div>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col>
                                    <v-btn
                                            @click="uploadFiles"
                                            class="mr-4"
                                            color="primary"
                                            depressed
                                            :loading="isLoading"
                                            :disabled="files.length === 0"
                                    >
                                        Загрузка
                                    </v-btn>
                                    <v-btn
                                            color="primary"
                                            depressed
                                            @click="closeDialog"
                                    >
                                        Закрыть
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card-text>
                </v-form>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
    import BytesFunction from "@/services/library/BytesFunction";
    import LKUploadFile from "@/services/LKUploadFile";
    export default {
        name: "UploadFileDialog",
        data () {
            return {
                dialog: false,
                validForm: true,
                active: false,
                BitesFunction: BytesFunction,
                files: [], //Массив выбранных пользователем файлов
                advInfFiles: [], //массив для дополнительной информации по файлам (описание файла, % загрузки, статус загрузки - 0 -неуспех, 1-успех)
                progress: 0,
                isLoading: false //Флаг процесса загрузки
            }
        },
        methods: {
            //Загрузка файлов
            handleFileUploads(event) {
                let uploadedFiles = event.target.files //Объект FileList
                uploadedFiles.forEach((item) => { //Переводим в массив, чтобы была возможность удалять файлы
                     if (this.files.find(item2 => item2.name === item.name)) { return } else {//Если файл с таким именем уже есть в списке, не добавляем его
                         this.files.push(item)
                         this.advInfFiles.push({description: '', progress: 0, status: null})
                     }
                })
            },

            // Загрузка файлов через перетягивание
            drop(event) {
                let uploadedFiles = event.dataTransfer.files //Объект FileList
                uploadedFiles.forEach((item) => {
                    if (this.files.find(item2 => item2.name === item.name)) { return } else {//Если файл с таким именем уже есть в списке, не добавляем его
                        this.files.push(item)
                        this.advInfFiles.push({description: '', progress: 0, status: null})
                    }
                })
            },

            toggleActive() {
                this.active = !this.active
            },

            //Удаляем файл из списка загрузок
            deleteFile(index) {
                this.files.splice(index, 1)
                this.advInfFiles.splice(index, 1)
            },

            //Загрузка файлов на сервер
            uploadFiles() {
                this.isLoading = true
                this.files.forEach((item, index) => {
                    this.uploadFile(item, index)
                })
            },


            uploadFile(fileItem, index) {
                //Блокируем на время загрузки компоненты выбора файла и т.п.
                // this.isLoading = true
                LKUploadFile.uploadfile(fileItem, { orderid: this.$route.params.id, employeesid: this.$store.state.auth.user.userid, filedescription: this.advInfFiles[index].description }, progressEvent => {
                    this.advInfFiles[index].progress = Math.round((progressEvent.loaded * 100) / progressEvent.total)
                })
                    .then(response => {
                        //this.closeUploadFile()
                        if ( this.files.length === index + 1) { //Если последний цикл
                            this.isLoading = false
                        }
                        this.advInfFiles[index].status = 1 //Успешно
                        console.log(response)
                    })
                    .catch( () => {
                        if ( this.files.length === index + 1) { //Если последний цикл
                            this.isLoading = false
                        }
                        this.advInfFiles[index].status = 0 //Ошибка
                        error => console.log(error)
                    })
            },

            closeDialog() {
                this.$emit('closeUploadFileDialog') //Генерируем событие - Обновить список файлов в родительстком компоненте
                this.dialog = false
            }
        }
    }
</script>

<style scoped>

    .input_file_button {
        padding: 10px;
        background-color: dodgerblue;
        border-radius: 5px;
        color: white;
        cursor: pointer;
    }

    .dropzone {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 200px;
        width: 400px;
        border: 2px dashed #00b0ff;
        background-color: #cce3ff;
        margin: 50px auto;
        border-radius: 5px;
        row-gap: 16px;
    }

    .active-dropzone {
        background-color: #9fbfff;
    }

</style>