import http from "../http-common"
import authHeader from './auth-header';

class ServiceviewEmployeerDataService {

    //Запрос  записей (услуги и исполнители)
    find(orderid) {
        return http.get(`/serviceviewemployeers?orderid=${orderid}`, { headers: authHeader() });
    }

    //Добавление записи
    create(serviceid, employeesid, orderid) {
        return http.post(`/serviceviewemployeers?orderid=${orderid}&employeesid=${employeesid}&serviceid=${serviceid}`, null, { headers: authHeader() });
    }

    //Удаление записи
    delete(serviceid, employeesid, orderid) {
        return http.delete(`/serviceviewemployeers?serviceid=${serviceid}&employeesid=${employeesid}&orderid=${orderid}`,{ headers: authHeader() });
    }

}

export default new ServiceviewEmployeerDataService()