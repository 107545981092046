import http from "../http-common"
import authHeader from './auth-header';
import QueryString from "qs";

//Создание записи
class OfferDataService {
    create(data) {
        return http.post("/offer", data,{ headers: authHeader() });
    }

    //Запрос записи
    get(id) {
        return http.get(`/offer/${id}`,{ headers: authHeader() });
    }

    //Обновление записи
    update(id, data) {
        return http.put(`offer/${id}`, data, { headers: authHeader() })
    }

    //Поиск записей по критерию
    find(params) {
        return http.get(`/offer`, {params: params, paramsSerializer: params => {return QueryString.stringify(params, {arrayFormat: 'comma'})}, headers: authHeader() });
    }


    //Запрос следующего номера Предложения (при создании нового)
    getNextNumber() {
        return http.get(`/offernextnumber`,{ headers: authHeader() });
    }

    //Изменение статуса
    setStatus(offerid, status) {
        return http.post(`/offer/setstatus?offerid=${offerid}&status=${status}`, null, { headers: authHeader() })
    }

}

export default new OfferDataService()