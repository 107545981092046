<template>
    <div>
        <h1>Маркетинг</h1>
        <h3>Выгрузка E-mail для рассылки</h3>

        <h3 class="mt-6">Физические лица</h3>
        <v-checkbox
                label="Клиент"
                hide-details="true"
                class="ml-4"
                value="PCL"
                v-model="selectArr['PCL']"
        >
        </v-checkbox>
        <v-checkbox
                label="Подрядчик"
                hide-details="true"
                class="ml-4"
                value="PPD"
                v-model="selectArr['PPD']"
        >
        </v-checkbox>
        <v-checkbox
                label="Контактное лицо"
                hide-details="true"
                class="ml-4"
                value="PKF"
                v-model="selectArr['PKF']"
        >
        </v-checkbox>

        <h3 class="mt-6">Юридические лица</h3>
        <v-checkbox
                label="Клиент"
                hide-details="true"
                class="ml-4"
                value="LCL"
                v-model="selectArr['LCL']"
        >
        </v-checkbox>
        <v-checkbox
                label="Подрядчик"
                hide-details="true"
                class="ml-4"
                value="LPD"
                v-model="selectArr['LPD']"
        >
        </v-checkbox>
        <v-checkbox
                label="Эксплуатирующая организация"
                hide-details="true"
                class="ml-4"
                value="LOU"
                v-model="selectArr['LOU']"
        >
        </v-checkbox>

        <v-btn class="mt-5">
            Выгрузить
        </v-btn>
    </div>
</template>

<script>
    export default {
        name: "marketing",
        data () {
            return {
                selectArr: {
                    PCL: null, //ФЛ.Клиент
                    PPD: null, //ФЛ.Подрядчик
                    PKF: null, //ФЛ.Контактное лицо
                    LCL: null, //ЮЛ.Клиент
                    LPD: null, //ЮЛ.Подрядчик
                    LOU: null  //ЮЛ.Эксплуатирующая организация
                },
            }
        }
    }
</script>

<style scoped>

</style>