<template>
  <div>
      <!--Сообщение об ошибке сохранения в БД-->
      <v-alert
              type="error"
              dismissible
              v-model="alertError"
              transition="scale-transition"
      >
          Невозможно сохранить данные. Возможно отсутствует соединение с базой данных. Обратитесь к Администратору.
      </v-alert>


      <!--Сообщение об успешном копировании данных-->

          <v-snackbar
                  v-model="snackbarCopy"
                  color="green darken-3"
                  top
                  :timeout="1000"
          >
             Скопировано!
              <template v-slot:action="{ attrs }">
                  <v-btn
                          color="grey lighten-2"
                          icon
                          v-bind="attrs"
                          @click="snackbarCopy = false"
                  >
                      <v-icon>mdi-close</v-icon>
                  </v-btn>
              </template>
          </v-snackbar>

      <!--Сообщение об успешном Сохранении данных-->
      <v-snackbar
              v-model="snackbarSave"
              color="green"
              top
              :timeout="1000"
      >
          Данные сохранены
          <template v-slot:action="{ attrs }">
              <v-btn
                      color="grey lighten-2"
                      icon
                      v-bind="attrs"
                      @click="snackbarSave = false"
              >
                  <v-icon>mdi-close</v-icon>
              </v-btn>
          </template>
      </v-snackbar>


<!--      ДИАЛОГОВЫЕ ОКНА РЕДАКТИРОВАНИЯ-->
<!--      Диалоговое окно редактирования ФИО ФЛ-->
      <v-dialog
              max-width="500"
              persistent
              v-model="dialog"
      >
          <v-card>
              <v-card-title class="headline">
                  Редактирование
                  <v-spacer></v-spacer>
                  <v-btn
                          icon
                          @click="close"
                  >
                      <v-icon>mdi-close</v-icon>
                  </v-btn>
              </v-card-title>
              <v-card-text>
                  <v-container>
                      <v-row>
                          <v-col>
                              <v-form
                                      ref="flform"
                                      v-model="valid"
                                      lazy-validation
                              >
                                  <v-text-field
                                          v-model="editedItem.surname"
                                          label="Фамилия*"
                                          :rules="requiredRules"
                                          required
                                  ></v-text-field>
                                  <v-text-field
                                          v-model="editedItem.name"
                                          label="Имя*"
                                          :rules="requiredRules"
                                          required
                                  ></v-text-field>
                                  <v-text-field
                                          v-model="editedItem.secondname"
                                          label="Отчество"
                                  ></v-text-field>
                              </v-form>
                          </v-col>
                      </v-row>
                  </v-container>
                  <small>* поля обязательны для заполнения</small>
              </v-card-text>
              <v-divider></v-divider>
              <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                          color="blue darken-1"
                          text
                          @click="close"
                  >
                      Отмена
                  </v-btn>
                  <v-btn
                          :disabled="!valid"
                          color="blue darken-1"
                          text
                          @click="save"
                  >
                      Сохранить
                  </v-btn>
              </v-card-actions>
          </v-card>
      </v-dialog>


      <div>
          <v-row>
              <v-col>
                  <v-card
                          outlined
                          class="mb-4"
                  >
                      <v-card-title>Основное</v-card-title>
                      <v-card-text>

                        <v-row class="mx-0">
                          <div class="data-row">
                            <div class="data-label">
                              Адрес
                            </div>
                            <div class="data-text" v-show="!addressInPlace">
                                <span v-if="realestate.fulladdress"> {{ realestate.fulladdress }} </span>
                                <span class="placeholder" v-else>Введите адрес</span>
                                <v-icon
                                      @click="editAddress"
                                      right
                                      small
                              >
                                mdi-pencil
                              </v-icon>
                              <v-icon
                                      v-show="realestate.fulladdress"
                                      @click="copy(realestate.fulladdress)"
                                      right
                                      small
                              >
                                mdi-content-copy
                              </v-icon>
                            </div>
                              <div v-show="addressInPlace">

                                  <v-row>
                                      <v-col>
                                          <v-radio-group
                                                  v-model="formatAddress"
                                                  row
                                          >
                                              <v-radio
                                                      label="Стандартный формат"
                                                      value="1"
                                              ></v-radio>
                                              <v-radio
                                                      label="Произвольный формат"
                                                      value="2"
                                              ></v-radio>
                                          </v-radio-group>
                                      </v-col>
                                  </v-row>


                                  <div v-show="formatAddress === '1'">
                                      <v-row>
                                          <v-col>
                                              <v-text-field
                                                      v-model="editedItem.adressregion"
                                                      label="Регион"
                                                      ref="address"
                                              ></v-text-field>
                                          </v-col>
                                      </v-row>
                                      <v-row>
                                          <v-col>
                                              <v-text-field
                                                      v-model="editedItem.adressdistrict"
                                                      label="Район"
                                              ></v-text-field>
                                          </v-col>
                                      </v-row>
                                      <v-row>
                                          <v-col>
                                              <v-text-field
                                                      v-model="editedItem.adresssettlementpoint"
                                                      label="Населенный пункт"
                                              ></v-text-field>
                                          </v-col>
                                      </v-row>
                                      <v-row>
                                          <v-col>
                                              <v-text-field
                                                      v-model="editedItem.adressstreet"
                                                      label="Улица"
                                              ></v-text-field>
                                          </v-col>
                                          <v-col>
                                              <v-text-field
                                                      v-model="editedItem.adresshouse"
                                                      label="Дом"
                                              ></v-text-field>
                                          </v-col>
                                      </v-row>
                                  </div>

                                  <div v-show="formatAddress === '2'">
                                      <v-row>
                                          <v-col>
                                              <v-textarea
                                                      v-model="editedItem.adressoptionalformat"
                                                      label="Произвольный формат адреса"
                                                      rows="2"
                                              ></v-textarea>
                                          </v-col>
                                      </v-row>
                                  </div>


                                  <v-btn
                                          color="blue darken-1"
                                          text
                                          @click="closeAddressInPlace"
                                  >
                                      Отмена
                                  </v-btn>
                                  <v-btn
                                          color="blue darken-1"
                                          text
                                          @click="saveAddressInPlace"
                                  >
                                      Сохранить
                                  </v-btn>
                              </div>
                          </div>
                        </v-row>
                      </v-card-text>
                  </v-card>

              </v-col>
              <v-col>

                  <v-card
                          outlined
                          class="mb-4"
                  >
                      <v-card-title>Дополнительная информация</v-card-title>

                      <v-card-text>
                        <v-row class="mx-0">
                          <div class="data-row">
                            <div class="data-label">
                                Кадастровый номер
                            </div>
                            <div class="data-text" v-show="!knInPlace">
                                <span v-if="realestate.kadnumber"> {{ realestate.kadnumber }} </span>
                                <span v-else class="placeholder">Введите данные</span>
                                <v-icon
                                        @click="editKadnumber"
                                        right
                                        small
                                >
                                  mdi-pencil
                                </v-icon>
                                <v-icon
                                        v-show="realestate.kadnumber"
                                        @click="copy(realestate.kadnumber)"
                                        right
                                        small
                                >
                                  mdi-content-copy
                                </v-icon>
                            </div>
                              <div v-show="knInPlace">
                                  <v-text-field
                                          v-model="editedItem.kadnumber"
                                          ref="kadnumber"
                                  ></v-text-field>
                                  <v-btn
                                          color="blue darken-1"
                                          text
                                          @click="closeKnInPlace"
                                  >
                                      Отмена
                                  </v-btn>
                                  <v-btn
                                          color="blue darken-1"
                                          text
                                          @click="saveKnInPlace"
                                  >
                                      Сохранить
                                  </v-btn>
                              </div>
                          </div>

                        </v-row>
                      </v-card-text>
                  </v-card>


              </v-col>
          </v-row>
      </div>

  </div>
</template>
<script>
    import RealestateDataService from "@/services/RealestateDataService";
    export default {
      name: 'EditRealestate',

      data: function() {
            return {
                //Редактирование на месте
                addressInPlace: false,
                knInPlace: false,

                formatAddress: null,
                alertError: false,

                valid: true,

                realestate: {},//Объект текущего объекта

                snackbarCopy: false,
                snackbarSave: false,

                editedItem: {}, //В этой переменной сохраняем объект редактирования
            }
        },
        methods: {
            closeAddressInPlace () {
                this.addressInPlace = false
                this.$nextTick(() => {
                    this.editedItem = {}
                })
            },
            closeKnInPlace () {
                this.knInPlace = false
                this.$nextTick(() => {
                    this.editedItem = {}
                })
            },

            saveAddressInPlace () {
                this.realestate.adressregion = this.editedItem.adressregion
                this.realestate.adressdistrict = this.editedItem.adressdistrict
                this.realestate.adresssettlementpoint = this.editedItem.adresssettlementpoint
                this.realestate.adressstreet = this.editedItem.adressstreet
                this.realestate.adresshouse = this.editedItem.adresshouse
                this.realestate.adressoptionalformat = this.editedItem.adressoptionalformat
                this.realestate.addressformat = this.formatAddress
                this.closeAddressInPlace()
                this.updateRealestate()
            },
            saveKnInPlace () {
                this.realestate.kadnumber = this.editedItem.kadnumber
                this.closeKnInPlace()
                this.updateRealestate()
            },

            editAddress() {
                this.editedItem.adressregion = this.realestate.adressregion
                this.editedItem.adressdistrict = this.realestate.adressdistrict
                this.editedItem.adresssettlementpoint = this.realestate.adresssettlementpoint
                this.editedItem.adressstreet = this.realestate.adressstreet
                this.editedItem.adresshouse = this.realestate.adresshouse
                this.editedItem.adressoptionalformat = this.realestate.adressoptionalformat
                this.formatAddress = this.realestate.addressformat
                this.addressInPlace = true
                this.$nextTick(() => {
                    this.$refs.address.focus();
                })
            },
            editKadnumber() {
                this.editedItem.kadnumber = this.realestate.kadnumber
                this.knInPlace = true
                this.$nextTick(() => {
                    this.$refs.kadnumber.focus();
                })
            },



            copy (text) {
                this.$copyText(text).then(response => {
                    this.snackbarCopy = true
                    console.log(response)
                })
                .catch(error => {
                    console.log(error);
                })
            },


            getRealestate(id) {
                RealestateDataService.get(id)
                    .then(response => {
                        this.realestate = response.data
                        console.log(response.data);
                    })
                    .catch(error => {
                        console.log(error);
                    })
            },


            updateRealestate() {
                RealestateDataService.update(this.realestate.estateid, this.realestate)
                    .then(response => {
                        this.snackbarSave = true
                        this.getRealestate(this.$route.params.id)
                        console.log(response.data)
                    })
                    .catch(e => {
                        console.log(e)
                        this.alertError = true
                    })
            },
        },
        mounted() {
            this.getRealestate(this.$route.params.id); //Код текущего объекта
        },
    }
</script>
<style scoped>
.data-name .v-icon:hover {
  color: #0d47a1;
}
</style>