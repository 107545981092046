var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',[_c('v-col',[_c('span',{staticStyle:{"font-size":"25px","font-weight":"300"}},[_vm._v("Клиенты")])]),_c('v-col',{staticClass:"d-flex justify-end"},[_c('ImportCustomers',{staticClass:"mr-3"}),_c('AddCustomerForm')],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"2"}},[_c('v-card',{attrs:{"flat":""}},[_c('v-card-text',[_c('v-text-field',{attrs:{"label":"Фамилия ФЛ/ Наименование ЮЛ","placeholder":"Введите данные для поиска","hint":"Несколько слов разделять пробелом"},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.get($event)}},model:{value:(_vm.searchString),callback:function ($$v) {_vm.searchString=$$v},expression:"searchString"}}),_c('v-text-field',{attrs:{"label":"ИНН","placeholder":"Введите данные для поиска","hint":"Точное совпадение","rules":_vm.externalRules.integerRules},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.get($event)}},model:{value:(_vm.innString),callback:function ($$v) {_vm.innString=$$v},expression:"innString"}}),_c('v-row',{staticClass:"mt-5",attrs:{"align":"center","justify":"space-around"}},[_c('v-btn',{attrs:{"depressed":""},on:{"click":_vm.resetForm}},[_vm._v(" Сбросить ")]),_c('v-btn',{attrs:{"depressed":"","color":"primary"},on:{"click":_vm.get}},[_vm._v(" Применить ")])],1)],1)],1)],1),_c('v-col',{attrs:{"cols":"10"}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"items-per-page":50,"footer-props":{
                         itemsPerPageOptions: [ 50, 100, -1],
                         itemsPerPageAllText: 'Все',
                         itemsPerPageText: 'Строк на странице:'
                    },"loading":_vm.loading,"loading-text":"Загрузка данных... Пожалуйста подождите"},scopedSlots:_vm._u([{key:"item.clienttype",fn:function(ref){
                    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatClientType")(item.clienttype))+" ")]}},{key:"item.fullname",fn:function(ref){
                    var item = ref.item;
return [_c('router-link',{staticClass:"itemlink",attrs:{"to":{ name: 'EditCustomer', params: { id: item.customerid }}}},[_vm._v(_vm._s(item.fullname))])]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }