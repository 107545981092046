<template>
    <div class="text-right">
        <v-dialog
                v-model="dialog"
                persistent
                max-width="600"
                hide-overlay
        >
            <template v-slot:activator="{ on, attrs }">
                <v-btn
                        color="primary"
                        depressed
                        v-bind="attrs"
                        v-on="on"
                >
                    Создать
                </v-btn>
            </template>

            <v-card>
                <v-card-title class="headline">
                    Новый сотрудник
                    <v-spacer></v-spacer>
                    <v-btn
                            icon
                            @click="dialog = false"
                    >
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-card-title>
                <v-form
                        ref="form"
                        v-model="validForm"
                        lazy-validation
                >
                    <v-card-text>
                    <v-container>


                        <!-- https://advanced-cropper.github.io/vue-advanced-cropper/-->
                        <cropper
                                class="cropper"
                                src="https://drive.google.com/uc?export=view&id=0B6wwyazyzml-OGQ3VUo0Z2thdmc"
                                :stencil-props="{previewClass: 'preview'}"
                                stencil-component="circle-stencil"
                        ></cropper>


                        <v-row>
                            <v-col>
                                <v-text-field
                                        v-model="surname"
                                        label="Фамилия*"
                                        :rules="externalRules.requiredRules"
                                ></v-text-field>
                            </v-col>
                            <v-col
                                    cols="12"
                                    sm="6"
                                    md="4"
                            >
                                <v-text-field
                                        v-model="name"
                                        label="Имя*"
                                        :rules="externalRules.requiredRules"
                                ></v-text-field>
                            </v-col>
                            <v-col
                                    cols="12"
                                    sm="6"
                                    md="4"
                            >
                                <v-text-field
                                        v-model="secondname"
                                        label="Отчество"
                                ></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <v-select
                                        v-model="gender"
                                        :items="genderSelect"
                                        label="Пол*"
                                        item-text="label"
                                        item-value="value"
                                        :rules="externalRules.requiredRules"
                                ></v-select>
                                <v-input>

                                </v-input>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <v-text-field
                                        v-model="password"
                                        type="password"
                                        label="Пароль*"
                                        :rules="externalRules.requiredRules.concat(externalRules.passwordRules)"
                                ></v-text-field>
                            </v-col>
                            <v-col>
                                <v-text-field
                                        v-model="passwordConfirm"
                                        type="password"
                                        label="Подтверждение*"
                                        :rules="externalRules.requiredRules.concat(passwordConfirmationRule)"
                                ></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <vc-date-picker v-model="birthday" :model-config="modelConfig">
                                    <template v-slot="{ inputValue, togglePopover }">
                                        <v-text-field
                                                label="Дата рождения"
                                                :value="inputValue"
                                                :rules="externalRules.dateRules.concat(externalRules.requiredRules)"
                                                @click="togglePopover()"
                                                v-mask="'##.##.####'"
                                                clearable
                                                v-model="birthday"
                                        />
                                    </template>
                                </vc-date-picker>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <v-text-field
                                        v-model="post"
                                        label="Должность"
                                        :rules="externalRules.requiredRules"
                                ></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <v-text-field
                                        v-model="phone"
                                        label="Телефон"
                                        :rules="externalRules.requiredRules.concat(externalRules.phoneRules)"
                                        v-mask="'+7 (###) ###-##-##'"
                                ></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <v-text-field
                                        v-model="email"
                                        label="E-mail"
                                        :rules="externalRules.requiredRules.concat(externalRules.emailRules)"
                                ></v-text-field>
                            </v-col>
                        </v-row>
<!--                        <v-row>-->
<!--                            <v-col>-->
<!--                                <v-text-field-->
<!--                                        v-model="role"-->
<!--                                        label="Роль"-->
<!--                                        :rules="externalRules.requiredRules"-->
<!--                                ></v-text-field>-->
<!--                            </v-col>-->
<!--                        </v-row>-->
                        <v-row>
                            <v-col>
                                <v-btn
                                        class="mr-4 mt-4"
                                        color="primary"
                                        :loading="loading"
                                        @click="submitForm"
                                >
                                    Создать
                                </v-btn>
                                <v-btn
                                        class="mr-4 mt-4"
                                        color="primary"
                                        :loading="loading"
                                        @click="submitForm"
                                >
                                    Создать и редактировать
                                </v-btn>
                            </v-col>
                        </v-row>

                    </v-container>
                </v-card-text>
                </v-form>

            </v-card>



        </v-dialog>
    </div>
</template>

<script>

    import * as ExternalRules from "@/rules/ExternalRules";
    // import EmployeeDataService from "@/services/EmployeeDataService";
    import { Cropper } from 'vue-advanced-cropper'
    import 'vue-advanced-cropper/dist/style.css';
    import EmployeeDataService from "@/services/EmployeeDataService";


    // let options = {
    //     year: 'numeric',
    //     month: 'numeric',
    //     day: 'numeric',
    //     timezone: 'UTC'
    // };

    export default {
        name: "AddEmployeeForm",
        components: {
            Cropper
        },
        data () {
            return {
                // img: 'https://images.unsplash.com/photo-1600984575359-310ae7b6bdf2?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=700&q=80',
                img: 'https://drive.google.com/uc?export=view&id=0B6wwyazyzml-OGQ3VUo0Z2thdmc',
                //Формат даты для календаря
                modelConfig: {
                    type: 'string',
                    mask: 'DD.MM.YYYY',
                },

                name: null,
                surname: null,
                secondname: null,
                gender: null,
                password: null,
                passwordConfirm: null,
                birthday: null,
                post: null,
                phone: null,
                email: null,
                externalRules: ExternalRules,
                genderSelect: [
                    { label: 'Мужской', value: 'M' },
                    { label: 'Женский', value: 'F' }
                ],


                //date: new Date().toISOString().split('T')[0], //Дата в формате YYYY-MM-DD


                //date: new Date().toLocaleString("ru", options),

                publicPath: process.env.BASE_URL,
                avatars: process.env.VUE_APP_AVATARS,
                dialog: false,
                validForm: true,
                loading: false
            }
        },

        watch: {
            dialog(dialog) {
                //Если открываем диалог - обнуляем переменные
                if(dialog === true) {
                    // this.loading = false
                    // this.name = null
                    // this.description = null
                    // this.orderSearch = null
                    // this.validForm = true
                    // this.orderItems = []
                    // this.endDate = null
                    // this.employeeId = null
                    // this.importance = 0
                    // this.editTask = true
                }
            }

        },
        computed: {
            passwordConfirmationRule() {
                return () =>
                    this.password === this.passwordConfirm || "Пароли должны совпадать";
            }
        },
        methods: {
            submitForm() {
                if (this.$refs.form.validate()) {
                    this.loading = true
                    let data = {
                        name: this.name,
                        surname: this.surname,
                        secondname: this.secondname,
                        gender: this.gender,
                        username: this.email,
                        password: this.password,
                        birthday: this.birthday.split(".").reverse().join("-"), //Преобразуем в дату ISO формата
                        post: this.post,
                        phone: this.phone,
                        email: this.email,
                    }

                    EmployeeDataService.create(data)
                        .then(response => {
                            this.$router.push({ name: 'EditEmployee', params: { id : response.data.id }})
                            // this.$emit('update-accounts-table') //Генерируем событие - Обновить лист счетов
                            // this.showDialog = false // Закрываем диалог
                        })
                        .catch(error => {
                            console.log(error);
                        })
                }
            },

        },
        mounted () {

        }
    }
</script>

<style scoped>


</style>