import http from "../http-common"
import authHeader from './auth-header';

class ServiceViewDataService {

    //Запрос (поиск) записей
    find() {
        return http.get(`/serviceview`, { headers: authHeader() });
    }

}

export default new ServiceViewDataService()