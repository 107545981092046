<template>
    <div>
        <!--Сообщение об успешном Сохранении данных-->
        <v-snackbar
                v-model="snackbarSave"
                color="green"
                top
                :timeout="1000"
        >
            Данные сохранены
            <template v-slot:action="{ attrs }">
                <v-btn
                        color="grey lighten-2"
                        icon
                        v-bind="attrs"
                        @click="snackbarSave = false"
                >
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </template>
        </v-snackbar>
        <!--End Сообщение об успешном Сохранении данных-->

        <!--Сообщение об ошибке сохранения в БД-->
        <v-alert
                type="error"
                dismissible
                v-model="alertError"
                transition="scale-transition"
        >
            Невозможно сохранить данные. Возможно отсутствует соединение с базой данных. Обратитесь к Администратору.
        </v-alert>
        <!--End Сообщение об ошибке сохранения в БД-->


        <v-row>
            <v-col cols="auto">
                <h1 class="data-name">
                    Документ № {{ document.number }} от {{ DateFunction.DateIsoToRUS(document.date.date) }}
                    <v-icon
                            @click="editNumberAndDate"
                            right
                    >
                        mdi-pencil
                    </v-icon>
                </h1>
                <div v-show="isEditNumberAndDate">
                    <v-form
                            v-model="valid"
                            lazy-validation
                    >
                        <v-text-field
                                label="Номер"
                                v-model="editedItem.number"
                                :rules="externalRules.requiredRules.concat(externalRules.integerRules)"
                                ref="number"
                        ></v-text-field>
                        <vc-date-picker v-model="editedItem.date" :model-config="modelConfig">
                            <template v-slot="{ inputValue, togglePopover }">
                                <v-text-field
                                        label="Дата"
                                        :value="inputValue"
                                        :rules="externalRules.dateRules.concat(externalRules.requiredRules)"
                                        @click="togglePopover()"
                                        v-mask="'##.##.####'"
                                        clearable
                                        v-model="editedItem.date"
                                        ref="date"
                                />
                            </template>
                        </vc-date-picker>
                    </v-form>
                    <v-btn
                            color="blue darken-1"
                            text
                            @click="closeNumberAndDate"
                    >
                        Отмена
                    </v-btn>
                    <v-btn
                            :disabled="!valid"
                            color="blue darken-1"
                            text
                            @click="saveNumberAndDate"
                    >
                        Сохранить
                    </v-btn>
                </div>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-card
                        outlined
                        class="mb-4"
                >
                    <v-card-title>Основное</v-card-title>
                    <v-card-text>
                        <v-row class="mx-0">
                            <div class="data-row">
                                <div class="data-label">
                                    Контрагент
                                </div>
                                <div class="data-text" v-show="!isEditCustomer">
                                    <span>{{ document.customername }}</span>
                                    <v-icon
                                            @click="clearCustomer"
                                            right
                                            small
                                    >
                                        mdi-close-circle-outline
                                    </v-icon>
                                    <v-icon
                                            @click="goToCustomer"
                                            right
                                            small
                                    >
                                        mdi-arrow-top-right
                                    </v-icon>
                                    <v-icon
                                            @click="copy(document.customername)"
                                            right
                                            small
                                    >
                                        mdi-content-copy
                                    </v-icon>
                                </div>
                                <div v-show="isEditCustomer">
                                    <v-autocomplete
                                            placeholder="Введите не менее 3-х символов для поиска"
                                            clearable
                                            return-object
                                            v-model="customer"
                                            :items="customerItems"
                                            :search-input.sync="customerSearch"
                                            item-value="customerid"
                                            item-text="fullname"
                                            @click:clear="clearCustomerSearch"
                                            :rules="externalRules.requiredRules"
                                            ref="customer"
                                    ></v-autocomplete>
                                    <v-btn
                                            color="blue darken-1"
                                            text
                                            @click="closeCustomerInPlace"
                                    >
                                        Отмена
                                    </v-btn>
                                    <v-btn
                                            color="blue darken-1"
                                            text
                                            @click="saveCustomerInPlace"
                                    >
                                        Сохранить
                                    </v-btn>
                                </div>
                            </div>
                        </v-row>
                        <v-row class="mx-0">
                            <div class="data-row">
                                <div class="data-label">
                                    Счет, касса
                                </div>
                                <div class="data-text" v-show="!isEditAccountname">
                                    <span>{{ document.accountname }}</span>
                                    <v-icon
                                            @click="editAccountName"
                                            right
                                            small
                                    >
                                        mdi-pencil
                                    </v-icon>
                                </div>
                                <div v-show="isEditAccountname">
                                    <v-select
                                            v-model="accountSelect"
                                            :items="accountItems"
                                            item-text="name"
                                            item-value="id"
                                            :rules="externalRules.requiredRules"
                                            required
                                            return-object
                                    ></v-select>
                                    <v-btn
                                            color="blue darken-1"
                                            text
                                            @click="closeAccountname"
                                    >
                                        Отмена
                                    </v-btn>
                                    <v-btn
                                            color="blue darken-1"
                                            text
                                            @click="saveAccountname"
                                    >
                                        Сохранить
                                    </v-btn>
                                </div>
                            </div>
                        </v-row>
                        <v-row class="mx-0">
                            <div class="data-row">
                                <div class="data-label">
                                    Сумма
                                </div>
                                <div class="data-text" v-show="!isEditSum">
                                    <span>{{ new Intl.NumberFormat('ru-RU', { style: 'decimal'}).format(document.sum) }}</span>
                                    <v-icon
                                            @click="editSum"
                                            right
                                            small
                                    >
                                        mdi-pencil
                                    </v-icon>
                                </div>
                                <div v-show="isEditSum">
                                    <v-form
                                            v-model="valid"
                                            lazy-validation
                                    >
                                        <v-text-field
                                                style="width: 200px"
                                                v-model="editedItem.sum"
                                                :rules="externalRules.requiredRules.concat(externalRules.floatRules)"
                                                ref="sum"
                                        />
                                    </v-form>
                                    <v-btn
                                            color="blue darken-1"
                                            text
                                            @click="closeSum"
                                    >
                                        Отмена
                                    </v-btn>
                                    <v-btn
                                            :disabled="!valid"
                                            color="blue darken-1"
                                            text
                                            @click="saveSum"
                                    >
                                        Сохранить
                                    </v-btn>
                                </div>
                            </div>
                        </v-row>
                        <v-row class="mx-0">
                            <div class="data-row">
                                <div class="data-label">
                                    Статья
                                </div>
                                <div class="data-text" v-show="!isEditAccountingname">
                                    <span>{{ document.accountingname }}</span>
                                    <v-icon
                                            @click="editAccountingName"
                                            right
                                            small
                                    >
                                        mdi-pencil
                                    </v-icon>
                                </div>
                                <div v-show="isEditAccountingname">
                                    <v-select
                                            v-model="accountingSelect"
                                            :items="accountingItems"
                                            item-text="name"
                                            item-value="id"
                                            :rules="externalRules.requiredRules"
                                            required
                                            return-object
                                    ></v-select>
                                    <v-btn
                                            color="blue darken-1"
                                            text
                                            @click="closeAccountingname"
                                    >
                                        Отмена
                                    </v-btn>
                                    <v-btn
                                            color="blue darken-1"
                                            text
                                            @click="saveAccountingname"
                                    >
                                        Сохранить
                                    </v-btn>
                                </div>
                            </div>
                        </v-row>
                        <v-row class="mx-0">
                            <div class="data-row">
                                <div class="data-label">
                                    Назначение документа
                                </div>
                                <div class="data-text" v-show="!isEditPurposepay">
                                    <span v-if="document.purposepay">{{ document.purposepay }}</span>
                                    <span v-else class="placeholder">Введите текст</span>
                                    <v-icon
                                            @click="editPurposepay"
                                            right
                                            small
                                    >
                                        mdi-pencil
                                    </v-icon>
                                </div>
                                <div v-show="isEditPurposepay">
                                    <v-textarea
                                            v-model="editedItem.purposepay"
                                            ref="purposepay"
                                    ></v-textarea>
                                    <v-btn
                                            color="blue darken-1"
                                            text
                                            @click="closePurposepay"
                                    >
                                        Отмена
                                    </v-btn>
                                    <v-btn
                                            color="blue darken-1"
                                            text
                                            @click="savePurposepay"
                                    >
                                        Сохранить
                                    </v-btn>
                                </div>
                            </div>
                        </v-row>
                        <v-row class="mx-0">
                            <div class="data-row">
                                <div class="data-label">
                                    Примечания
                                </div>
                                <div class="data-text" v-show="!isEditDescription">
                                    <span v-if="document.description">{{ document.description }}</span>
                                    <span v-else class="placeholder">Введите текст</span>
                                    <v-icon
                                            @click="editDescription"
                                            right
                                            small
                                    >
                                        mdi-pencil
                                    </v-icon>
                                </div>
                                <div v-show="isEditDescription">
                                    <v-textarea
                                            v-model="editedItem.description"
                                            ref="description"
                                    ></v-textarea>
                                    <v-btn
                                            color="blue darken-1"
                                            text
                                            @click="closeDescription"
                                    >
                                        Отмена
                                    </v-btn>
                                    <v-btn
                                            color="blue darken-1"
                                            text
                                            @click="saveDescription"
                                    >
                                        Сохранить
                                    </v-btn>
                                </div>
                            </div>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col>
                <v-card
                        outlined
                        class="mb-4"
                >
                    <v-card-title>Аналитика</v-card-title>
                    <v-card-text>
                        <v-row class="mx-0">
                            <div class="data-row">
                                <div class="data-label">
                                    Направление деятельности
                                </div>
                                <div class="data-text" v-show="!isEditActivity">
                                    <span v-if="document.activityid">{{ document.activityname }}</span>
                                    <span class="placeholder" v-else>Выберите направление деятельности</span>
                                    <v-icon
                                            @click="editActivity"
                                            right
                                            small
                                    >
                                        mdi-pencil
                                    </v-icon>
                                    <v-icon
                                            v-show="document.activityid"
                                            @click="clearActivity"
                                            right
                                            small
                                    >
                                        mdi-close-circle-outline
                                    </v-icon>
                                </div>
                                <div v-show="isEditActivity">
                                    <v-select
                                            v-model="activitySelect"
                                            :items="activityItems"
                                            item-text="name"
                                            item-value="id"
                                            :rules="externalRules.requiredRules"
                                            required
                                            return-object
                                    ></v-select>
                                    <v-btn
                                            color="blue darken-1"
                                            text
                                            @click="closeActivity"
                                    >
                                        Отмена
                                    </v-btn>
                                    <v-btn
                                            color="blue darken-1"
                                            text
                                            @click="saveActivity"
                                    >
                                        Сохранить
                                    </v-btn>
                                </div>
                            </div>
                        </v-row>
                        <v-row class="mx-0">
                            <div class="data-row">
                                <div class="data-label">
                                    Договор
                                </div>
                                <div class="data-text" v-show="!isEditOrder">
                                    <span v-if="document.orderid">{{ document.ordernumber }}</span>
                                    <span class="placeholder" v-else>Выберите договор</span>
                                    <v-icon
                                            v-show="!document.orderid"
                                            @click="editOrder"
                                            right
                                            small
                                    >
                                        mdi-pencil
                                    </v-icon>
                                    <v-icon
                                            v-show="document.orderid"
                                            @click="clearOrder"
                                            right
                                            small
                                    >
                                        mdi-close-circle-outline
                                    </v-icon>
                                    <v-icon
                                            v-show="document.orderid"
                                            @click="goToOrder"
                                            right
                                            small
                                    >
                                        mdi-arrow-top-right
                                    </v-icon>
                                </div>
                                <div v-show="isEditOrder">
                                    <v-autocomplete
                                            placeholder="Введите не менее 3-х символов для поиска"
                                            clearable
                                            v-model="orderid"
                                            :items="orderItems"
                                            :search-input.sync="orderSearch"
                                            item-value="orderid"
                                            item-text="contractnumber"
                                            ref="order"
                                            @click:clear="clearOrderSearch"
                                            return-object
                                    ></v-autocomplete>
                                    <v-btn
                                            color="blue darken-1"
                                            text
                                            @click="closeOrder"
                                    >
                                        Отмена
                                    </v-btn>
                                    <v-btn
                                            color="blue darken-1"
                                            text
                                            @click="saveOrder"
                                    >
                                        Сохранить
                                    </v-btn>
                                </div>
                            </div>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </div>
</template>
<script>
import DocumentsDataService from "@/services/DocumentsDataService";
import DateFunction from "@/services/library/DateFunction";
import CustomerDataService from "@/services/CustomerDataService";
import BankaccountDataService from "@/services/BankaccountDataService";
import AccountingDataService from "@/services/AccountingDataService";
import ActivitiesDataService from "@/services/ActivitiesDataService";
import OrderDataService from "@/services/OrderDataService";
import * as ExternalRules from "@/rules/ExternalRules";

    export default {
      name: 'EditDocument',

      data() {
            return {
                //Формат даты для календаря
                modelConfig: {
                    type: 'string',
                    mask: 'DD.MM.YYYY',
                },
                document: {}, //объект документа
                snackbarSave: false,
                alertError: false,
                valid: true,
                isEditNumberAndDate: false,
                isEditPurposepay: false,
                isEditDescription: false,
                isEditCustomer: false,
                isEditSum: false,
                isEditAccountname: false,
                isEditAccountingname: false,
                isEditActivity: false,
                isEditOrder: false,
                editedItem: {}, //В этой переменной сохраняем объект при редактировании
                DateFunction: DateFunction,
                externalRules: ExternalRules,

                customer: null,
                customerItems: [],
                customerSearch: null,

                //Банк или счет
                accountSelect: null,
                accountItems: [],

                //Статьи учета
                accountingSelect: null,
                accountingItems: [],

                //Направления деятельности
                activitySelect: null,
                activityItems: [],

                //Договор
                orderid: null,
                orderItems: [],
                orderSearch: null,
            }
        },
        methods: {
            getDocument(id) {
                DocumentsDataService.get(id)
                    .then(response => {
                        this.document = response.data
                    })
                    .catch(error => {
                        console.log(error);
                    })
            },

            editNumberAndDate() {
                this.isEditNumberAndDate = true
                this.editedItem.number = this.document.number
                this.editedItem.date = DateFunction.DateIsoToRUS(this.document.date.date)
                this.$nextTick(() => {
                    this.$refs.number.focus();
                })
            },
            closeNumberAndDate() {
                this.isEditNumberAndDate = false
                this.$nextTick(() => {
                    this.editedItem = {}
                })
            },
            saveNumberAndDate() {
                this.document.number = this.editedItem.number
                this.document.date.date = DateFunction.DateRUSToIso(this.editedItem.date)
                this.closeNumberAndDate()
                this.updateDocument()
            },
            editPurposepay() {
                this.isEditPurposepay = true
                this.editedItem.purposepay = this.document.purposepay
                this.$nextTick(() => {
                    this.$refs.purposepay.focus();
                })
            },
            closePurposepay() {
                this.isEditPurposepay = false
                this.$nextTick(() => {
                    this.editedItem = {}
                })
            },
            savePurposepay() {
                this.document.purposepay = this.editedItem.purposepay
                this.closePurposepay()
                this.updateDocument()
            },
            editDescription() {
                this.isEditDescription = true
                this.editedItem.description = this.document.description
                this.$nextTick(() => {
                    this.$refs.description.focus();
                })
            },
            closeDescription() {
                this.isEditDescription = false
                this.$nextTick(() => {
                    this.editedItem = {}
                })
            },
            saveDescription() {
                this.document.description = this.editedItem.description
                this.closeDescription()
                this.updateDocument()
            },
            editSum() {
                this.isEditSum = true
                this.editedItem.sum = Math.round(this.document.sum)
                this.$nextTick(() => {
                    this.$refs.sum.focus();
                })
            },
            closeSum() {
                this.isEditSum = false
                this.$nextTick(() => {
                    this.editedItem = {}
                })
            },
            saveSum() {
                this.document.sum = this.editedItem.sum
                this.closeSum()
                this.updateDocument()
            },
            clearCustomer() {
                this.isEditCustomer = true
            },
            goToCustomer() {
                this.$router.push({name: 'EditCustomer', params: { id: this.document.customerid }})
            },
            clearCustomerSearch() {
                this.customerItems = []
            },
            closeCustomerInPlace() {
                this.isEditCustomer = false
                this.customerItems = []
            },
            saveCustomerInPlace() {
                this.document.customerid =  this.customer.customerid
                this.document.customername = this.customer.fullname
                this.closeCustomerInPlace()
                this.updateDocument()
            },

            editAccountName() {
                this.getAccountItems ();
                this.isEditAccountname = true
                this.accountSelect = this.document.accountid
            },
            //Запрос счетов движения ДС
            getAccountItems () {
                BankaccountDataService.find()
                    .then(response => {
                        this.accountItems = response.data
                    })
                    .catch(e => {
                        console.log(e)
                    })
            },
            closeAccountname() {
                this.isEditAccountname = false
                this.accountSelect = null
                this.accountItems = []
            },
            saveAccountname() {
                this.document.accountid = this.accountSelect.id
                this.document.accountname = this.accountSelect.name
                this.closeAccountname()
                this.updateDocument()
            },

            editAccountingName() {
                this.getAccountingItems ();
                this.isEditAccountingname = true
                this.accountingSelect = this.document.accountingid
            },
            //Запрос статей учета
            getAccountingItems () {
                AccountingDataService.get()
                    .then(response => {
                        this.accountingItems = response.data
                    })
                    .catch(e => {
                        console.log(e)
                    })
            },
            closeAccountingname() {
                this.isEditAccountingname = false
                this.accountingSelect = null
                this.accountingItems = []
            },
            saveAccountingname() {
                this.document.accountingid = this.accountingSelect.id
                this.document.accountingname = this.accountingSelect.name
                this.closeAccountingname()
                this.updateDocument()
            },

            editActivity() {
                this.getActivityItems ();
                this.isEditActivity = true
                this.activitySelect = this.document.activityid
            },
            //Запрос списка направлений деятельности
            getActivityItems () {
                ActivitiesDataService.find()
                    .then(response => {
                        this.activityItems = response.data
                    })
                    .catch(e => {
                        console.log(e)
                    })
            },
            closeActivity() {
                this.isEditActivity = false
                this.activitySelect = null
                this.activityItems = []
            },
            saveActivity() {
                this.document.activityid = this.activitySelect.id
                this.document.activityname = this.activitySelect.name
                this.closeActivity()
                this.updateDocument()
            },
            clearActivity() {
                this.document.activityid = null
                this.document.activityname = null
                this.updateDocument()
            },

            editOrder() {
                this.isEditOrder = true
                this.$nextTick(() => {
                    this.$refs.order.focus();
                })
            },
            clearOrder() {
                this.document.orderid = null
                this.document.ordernumber = null
                this.updateDocument()
            },
            goToOrder() {
                this.$router.push({name: 'EditContract', params: { id: this.document.orderid }})
            },
            clearOrderSearch() {
                this.orderItems = []
            },
            closeOrder() {
                this.isEditOrder = false
                this.orderItems = []
            },
            saveOrder() {
                this.document.orderid =  this.orderid.orderid
                this.document.ordernumber =  this.orderid.contractnumber
                this.closeOrder()
                this.updateDocument()
            },

            copy (text) {
                this.$copyText(text).then(response => {
                    this.snackbarCopy = true
                    console.log(response)
                })
                    .catch(error => {
                        console.log(error);
                    })
            },
            updateDocument() {
                let date = {
                    number: this.document.number,
                    date: this.document.date.date,
                    purposepay: this.document.purposepay, //Назначение документа
                    description: this.document.description,
                    sum: this.document.sum,
                    customerid: this.document.customerid,
                    accountid: this.document.accountid,
                    accountingid: this.document.accountingid,
                    activityid: this.document.activityid,
                    orderid: this.document.orderid,
                }
                DocumentsDataService.update(this.document.id, date)
                    .then(response => {
                        this.snackbarSave = true
                        console.log(response.data)
                    })
                    .catch(e => {
                        console.log(e)
                        this.alertError = true
                    })
            },

        },

        watch: {
            customerSearch() {
                if (this.customerItems.length > 0) return

                if (this.customerSearch.length > 3) {
                    CustomerDataService.findBySurname(this.customerSearch)
                        .then(response => {
                            this.customerItems = response.data
                            console.log(response.data)
                        })
                        .catch(e => {
                            console.log(e)
                        })
                }
            },

            orderSearch() {
                if (this.orderItems.length > 0) return

                if (this.orderSearch.length > 3) {
                    let params = {
                        contractNumberString: this.orderSearch,
                        maxResults: 50
                    }
                    OrderDataService.find(params)
                        .then(response => {
                            this.orderItems = response.data
                        })
                        .catch(e => {
                            console.log(e)
                        })
                }
            },
        },

        mounted() {
            this.getDocument(this.$route.params.id) //Запрос данных документа
        },
    }
</script>
<style scoped>


</style>