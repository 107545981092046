import http from "../http-common"
import authHeader from './auth-header';

class NomenclatureDataService {
    //Создание записи
    create(orderid,servicekod) {
        return http.post(`/servicevieworder?orderid=${orderid}&servicekod=${servicekod}`, null, { headers: authHeader() });
    }

    // //Запрос записи
    // get(id) {
    //     return http.get(`/contracts/${id}`,{ headers: authHeader() });
    // }

    //Обновление записи
    update(orderkod, servicekod, servicecount, serviceprice) {
        return http.put(`servicevieworder?orderkod=${orderkod}&servicekod=${servicekod}&servicecount=${servicecount}&serviceprice=${serviceprice}`,  null, { headers: authHeader() })
    }

    //Запрос всех записей
    find(orderid) {
        return http.get(`/servicevieworder?orderid=${orderid}`,{ headers: authHeader() });
    }

    //Удаление записи
    delete(orderid, serviceid) {
        return http.delete(`/servicevieworder?orderkod=${orderid}&servicekod=${serviceid}`,{ headers: authHeader() });
    }

}

export default new NomenclatureDataService()