<template>
    <div>
        <v-dialog
                v-model="showDialog"
                width="250"
        >
            <v-card>
                <v-card-title class="grey lighten-2">
                    Выберите результат
                </v-card-title>
                <v-card-text style="padding-top: 20px" class="d-flex justify-space-between">
                    <v-btn
                            color="success"
                            depressed
                            small
                            @click="setStatus(2)"
                    >
                        Завершено
                    </v-btn>
                    <v-btn
                            color="error"
                            depressed
                            small
                            @click="setStatus(3)"
                    >
                        Провалено
                    </v-btn>
                </v-card-text>
            </v-card>
        </v-dialog>



        <div style="margin: 15px 0px;" v-if="statusid == 1">
            <table  class="order-stepper">
                <tbody>
                <tr>
                    <td v-tooltip="'Рассматривается'" @click="setStatus(1)" style="background-color: #44ddbf; border-bottom-left-radius: 0px !important;"></td>
                    <td v-tooltip="'Завершить'" @click="showDialog = true" style=" border-bottom-left-radius: 0px !important;"></td>
                </tr>
                </tbody>
            </table>
            <div class="step-name">Рассматривается</div>
        </div>

        <div style="margin: 15px 0px;" v-if="statusid == 2">
            <table class="order-stepper">
                <tbody>
                <tr>
                    <td v-tooltip="'Рассматривается'" @click="setStatus(1)" style="background-color: #65b168; border-bottom-left-radius: 0px !important;"></td>
                    <td v-tooltip="'Завершить'" @click="showDialog = true" style=" background-color: #65b168; border-bottom-left-radius: 0px !important;"></td>
                </tr>
                </tbody>
            </table>
            <div class="step-name">Завершено</div>
        </div>

        <div style="margin: 15px 0px;" v-if="statusid == 3">
            <table class="order-stepper">
                <tbody>
                <tr>
                    <td v-tooltip="'Рассматривается'" @click="setStatus(1)"  style=" background-color: #e95d5a; border-bottom-left-radius: 0px !important;"></td>
                    <td v-tooltip="'Завершить'" @click="showDialog = true" style="background-color: #e95d5a; border-bottom-left-radius: 0px !important;"></td>
                </tr>
                </tbody>
            </table>
            <div class="step-name">Провалено</div>
        </div>
    </div>
</template>

<script>
    import OfferDataService from "@/services/OfferDataService";

    export default {
        props: ['status', 'offerid'],
        name: "OfferStepper",
        data () {
            return {
                statusid: this.status,
                showDialog: false,
            }
        },
        watch: {
            //Использую слежение за входным параметром. Без этого был глюк, показывал не правильные статусы
            status: function (val) {
                this.statusid = val
            }
        },
        methods: {
            //Установка нового статуса
            setStatus(code) {
                if (this.$store.state.auth.user.roles[0] ===  'ROLE_SUPER_ADMIN' || this.$store.state.auth.user.roles[0] ===  'ROLE_ADMIN') {
                    OfferDataService.setStatus(this.offerid, code)
                        .then(response => {
                            this.statusid = code
                            console.log(response.data)
                            this.showDialog = false //Если открыт диалог, закрываем
                        })
                        .catch(e => {
                            console.log(e)
                        })
                }
            },
        }

    }
</script>

<style scoped>
    .order-stepper {
        border-spacing: 0;
        height: 9px;
        width: 100%;
        border: 1px solid #ccc;
        border-collapse: collapse;
        min-width: 130px;
        max-width: 350px;
    }

    .order-stepper td {
        border: 1px solid #ccc;
    }

    .order-stepper td:hover {
        border: 1px solid #bdb9b9;
        cursor: pointer;
    }

    .step-name {
        color: rgb(140, 143, 145);
        font-size: 11px;
        padding: 5px 0 0 0px;
        line-height: 14px !important;
        font-weight: 300;
    }

</style>