import http from "../http-common"
import authHeader from './auth-header';

class ImportCustomersService {
    //Загрузка файлов на сервер
    // file - загружаемый файл
    // params - параметры, передаваемые в запросе
    // onUploadProgress - событие прогресса при загрузке файла
    uploadfile(file) {
        //let arr = file.name.split('.'); //Разбиваем имя файла с разширением на составляющие
        //let fname = file.name.split('.').slice(0, -1).join('.'); //Получаем Имя файла без расширения
        //let fextens = file.name.split('.').pop(); // Расширение файла

        let formData = new FormData()
        formData.append('file', file)
        //formData.append('file_name', file.name);
        // formData.append('file_ext', fextens);
        // formData.append('file_size', file.size);
        // formData.append('file_type', file.type);

        return http.post('/importcustomers', formData, {
            headers: Object.assign(authHeader(), {"Content-Type": "multipart/form-data"}) //Объединяем 2 заголовка
        })
    }
}

export default new ImportCustomersService();