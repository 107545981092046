<template>
    <div class="text-right">
        <v-dialog
                v-model="showDialog"
                persistent
                width="600"
        >
            <v-card>
                <v-card-title class="headline">
                    Новый счет
                    <v-spacer></v-spacer>
                    <v-btn
                            icon
                            @click="showDialog = false"
                    >
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-card-title>
                <v-form
                        ref="form"
                        v-model="validForm"
                        lazy-validation
                >
                    <v-card-text>
                        <v-container>
                            <v-row>
                                <v-col>
                                    <v-text-field
                                            v-model="number"
                                            label="Номер"
                                            :rules="externalRules.integerRules.concat(externalRules.requiredRules)"
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col>
                                    <vc-date-picker v-model="date" :model-config="modelConfig">
                                        <template v-slot="{ inputValue, togglePopover }">
                                            <v-text-field
                                                    label="Дата"
                                                    :value="inputValue"
                                                    :rules="externalRules.dateRules.concat(externalRules.requiredRules)"
                                                    @click="togglePopover()"
                                                    v-mask="'##.##.####'"
                                                    clearable
                                                    v-model="date"
                                            />
                                        </template>
                                    </vc-date-picker>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col>
                                    <vc-date-picker v-model="deadline" :model-config="modelConfig">
                                        <template v-slot="{ inputValue, togglePopover }">
                                            <v-text-field
                                                    label="Срок оплаты"
                                                    :value="inputValue"
                                                    :rules="externalRules.dateRules.concat(externalRules.requiredRules)"
                                                    @click="togglePopover()"
                                                    v-mask="'##.##.####'"
                                                    clearable
                                                    v-model="deadline"
                                            />
                                        </template>
                                    </vc-date-picker>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col>
                                    <v-text-field
                                            v-model="sum"
                                            label="Сумма"
                                            :rules="externalRules.floatRules.concat(externalRules.requiredRules)"
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                            <!--                        Не показываем это поле, если вызываем диалог из договора.-->
                            <!--                        В дальнейшем исправить это.-->
                            <v-row v-show="!order_id">
                                <v-col>
                                    <v-autocomplete
                                            placeholder="Введите не менее 3-х символов для поиска"
                                            clearable
                                            v-model="orderid"
                                            :items="orderItems"
                                            :search-input.sync="orderSearch"
                                            item-value="orderid"
                                            item-text="contractnumber"
                                            label="Номер договора"
                                            @click:clear="clearOrderSearch"
                                            :rules="externalRules.requiredRules"
                                    >
                                        <template v-slot:item="data">
                                            <v-list-item-content>
                                                <v-list-item-title v-html="data.item.contractnumber"></v-list-item-title>
                                                <v-list-item-subtitle v-html="data.item.estatecod.fulladdress"></v-list-item-subtitle>
                                            </v-list-item-content>
                                        </template>
                                    </v-autocomplete>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col>
                                    <v-textarea
                                            v-model="description"
                                            label="Примечания"
                                            rows="2"
                                    ></v-textarea>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col>
                                    <v-btn
                                            class="mr-4 mt-4"
                                            color="success"
                                            :loading="loading_1"
                                            @click="submitForm(false)"
                                    >
                                        Создать
                                    </v-btn>
                                    <v-btn
                                            class="mr-4 mt-4"
                                            color="success"
                                            :loading="loading_2"
                                            @click="submitForm(true)"
                                    >
                                        Создать и редактировать
                                    </v-btn>
                                </v-col>
                            </v-row>

                        </v-container>
                    </v-card-text>
                </v-form>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
    import * as ExternalRules from "@/rules/ExternalRules";
    import AccountDataService from "@/services/AccountDataService";
    import OrderDataService from "@/services/OrderDataService";

    export default {
        name: "AddAccountForm",
        props: {
            value: Boolean,
            order_id: {
                type: Number,
                default: null
            }
        },
        data() {
            return {
                //Формат даты для календаря
                modelConfig: {
                    type: 'string',
                    mask: 'DD.MM.YYYY',
                },

                validForm: true,
                externalRules: ExternalRules,
                loading_1: false,
                loading_2: false,
                number: null,
                sum: null,
                description: null,
                date: null,
                deadline: null,
                orderid: this.order_id,
                orderItems: [],
                orderSearch: null,
            }
        },
        computed: {
            showDialog: {
                get () {
                    return this.value
                },
                set (value) {
                    this.$emit('input', value)
                }
            }
        },
        watch: {
            showDialog(show) {
                //Если открываем диалог - обнуляем переменные
                if(show === true) {
                    this.getNextAccountNumber() //Следующий номер счета
                    this.date = new Date().toISOString().split('T')[0].split("-").reverse().join(".") //Дата в формате DD.MM.YYYY
                    this.loading_1 = false
                    this.loading_2 = false
                    this.deadline = this.addDays(5).toISOString().split('T')[0].split("-").reverse().join(".") //Дата в формате DD.MM.YYYY + 5 дн
                    this.description = null
                    this.sum = null
                    this.orderSearch = null
                    this.validForm = true
                    this.orderItems = []
                }
            },
            orderSearch() {
                if (this.orderItems.length > 0) return

                if (this.orderSearch.length >= 3) {
                    let params = {
                        contractNumberString: this.contractNumberString,
                        maxResults: 50
                    }
                    OrderDataService.find(params)
                        .then(response => {
                            this.orderItems = response.data
                        })
                        .catch(e => {
                            console.log(e)
                        })
                }
            },
        },
        methods: {
            //Следующий номер счета
            getNextAccountNumber() {
                AccountDataService.getNextNumber()
                    .then(response => {
                        this.number = response.data
                    })
                    .catch(e => {
                        console.log(e)
                    })
            },
            clearOrderSearch() {
                this.orderItems = []
            },
            addDays(days) {
                let result = new Date();
                result.setDate(result.getDate() + days);
                return result;
            },
            submitForm(editAccount) {
                if (this.$refs.form.validate()) {
                    if (editAccount === false) { this.loading_1 = true} else {this.loading_2 = true}
                    let data = {
                        number: this.number,
                        date: this.date.split(".").reverse().join("-"), //Преобразуем в дату ISO формата
                        deadline: this.deadline.split(".").reverse().join("-"), //Преобразуем в дату ISO формата
                        sum: this.sum,
                        description: this.description,
                        status: 0,
                        orderid: this.orderid,
                    }

                    AccountDataService.create(data)
                        .then(response => {
                            if (editAccount === true) {
                                this.$router.push({ name: 'EditAccount', params: { id : response.data.id }})
                            } else {
                                this.$emit('update-accounts-table') //Генерируем событие - Обновить лист счетов
                                this.showDialog = false // Закрываем диалог
                            }
                        })
                        .catch(error => {
                            console.log(error);
                        })
                }
            },
        },

        mounted () {

        }
    }
</script>

<style scoped>

</style>