import http from "../http-common"
import authHeader from './auth-header';

class CalendarDataService {
    //Поиск выездов по дате начала и окончания
    getDeparture(startdate, enddate) {
        return http.get(`/calendar?startdate=${startdate}&enddate=${enddate}`,{ headers: authHeader() });
    }

}

export default new CalendarDataService()