class Security {

    //Генерирует пароль
    //lendth - длина пароля
    genPassword(lendth) {
        // Обратите внимание, что в числовом массиве отсутствует 0, а в массивах с алфавитом нет буквы O, это сделано намеренно, из за того что может быть путаница с этими символами, так как буква 'O' очень похожа на цифру '0'.
        let digits = [1,2,3,4,5,6,7,8,9]
        let lower = ['a','b','c','d','e','f','g','h','i','j','k','l','m','n','p','q','r','s','t','u','v','w','x','y','z']
        let upper = ['A','B','C','D','E','F','G','H','I','J','K','L','M','N','P','Q','R','S','T','U','V','W','X','Y','Z']
        //let symb = ['!','@','#','$','%','?','*','+'] //Если используем символы - пароль иногда отправляется в api с ошибкой (не полный). Разобраться

        const compareRandom = ( ) => Math.random() - 0.5;
        const randomInteger = ( min, max ) => Math.round(min - 0.5 + Math.random() * (max - min + 1));

        let arr = []
        //arr = arr.concat(digits,lower,upper,symb)
        arr = arr.concat(digits,lower,upper)
        arr.sort(compareRandom);

        let password = '';

        for (let i = 0; i < lendth; i++) {
            password += arr[randomInteger(0, arr.length - 1)];
        }

        return password;
    }

}

export default new Security()