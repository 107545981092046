<template>
    <v-dialog
            v-model="fireContractsShowDialog"
            width="1000"
            style="position: absolute; left: 0"
    >
        <template v-slot:activator="{ on, attrs }">

            <v-badge
                    color="red"
                    :content="fireContractsCount"
                    overlap
                    style="margin-right: 20px"
                    v-show="fireContractsCount > 0"
            >
                <v-icon
                        color="#3f4c6b"
                        size="30"
                        v-bind="attrs"
                        v-on="on"
                >
                    mdi-fire
                </v-icon>
            </v-badge>

        </template>

        <v-card>
            <v-card-title class="text-h5 red lighten-2" style="color: white">
                Горящие договоры
            </v-card-title>
            <v-progress-linear
                    indeterminate
                    color="yellow darken-2"
                    v-show="loading"
            ></v-progress-linear>
            <v-card-text>
                <div
                        v-for="item in firecontracts"
                        :key="item.orderid"
                        @click="goToContract(item.orderid)"
                        class="blocklink"
                >

                    <v-row>
                        <v-col>
                            <p style="font-weight: 500; color: #2b2b2b; margin-bottom: 0;">Договор №{{ item.contractnumber }} от {{ item.orderdata.date | formatDate }}</p>
                        </v-col>
                        <v-col align="right">
                            <p style="margin-bottom: 0;"><span :class="{orderdeadlineorange:getDateDiff(item.orderdeadline.date) >= 0, orderdeadlinered:getDateDiff(item.orderdeadline.date) < 0 }" style="padding: 3px 5px; border-radius: 3px; color: white; font-size: 13px;">{{ getDateDiff(item.orderdeadline.date) | formatDateDiff}}</span></p>
                        </v-col>
                    </v-row>
                    <p style="margin-bottom: 0px;">{{ getFullName(item.customerkod.name, item.customerkod.secondname, item.customerkod.surname, item.customerkod.customertype) }}</p>
                    <p>{{ getFullAddress(item.estatecod.addressformat, item.estatecod.adressdistrict, item.estatecod.adresshouse, item.estatecod.adressoptionalformat, item.estatecod.adressregion, item.estatecod.adresssettlementpoint, item.estatecod.adressstreet) }}</p>
                    <v-divider></v-divider>

                </div>


            </v-card-text>



            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                        color="primary"
                        text
                        @click="fireContractsShowDialog = false"
                >
                    Закрыть
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    import OrderDataService from "@/services/OrderDataService";

    export default {
        name: "FireContractsModal",
        data() {
            return {
                firecontracts: null,
                firecontractscount: 0,
                fireContractsShowDialog: false,
                loading: false
            }
        },

        watch: {
            fireContractsShowDialog(val) {
                //Запрос уведомлений при открытии диалогового окна
                if (val === true) {
                    this.getFireContracts()
                    this.loading = true
                }
                //Очистка уведомлений при закрытии диалогового окна
                if (val === false) this.firecontracts = null
            }
        },

        computed: {
            fireContractsCount() {
                return this.$store.state.contract.fireContractsCount
            }
        },

        methods: {
            getFireContracts() {
                OrderDataService.findFireContracts()
                    .then( response => {
                        this.firecontracts = response.data
                        this.firecontractscount = this.firecontracts.length
                        this.loading = false
                    })
                    .catch(error => console.log(error));
            },

            goToContract(orderid) {
                this.$router.push({name: 'EditContract', params: { id: orderid }})
                this.fireContractsShowDialog = false
            },
            //Полный адрес
            getFullAddress(addressformat, adressdistrict, adresshouse, adressoptionalformat, adressregion, adresssettlementpoint, adressstreet) {
                //1 - полный формат, 2-произвольный формат
                if (addressformat == '1') {
                    let _adressregion = (adressregion) ? `${adressregion}, ` : null
                    let _adressdistrict = (adressdistrict) ? `${adressdistrict}, ` : null
                    let _adresssettlementpoint = (adresssettlementpoint) ? `${adresssettlementpoint}, ` : null
                    let _adressstreet = (adressstreet) ? `${adressstreet}, ` : null
                    let _adresshouse = (adresshouse) ? `${adresshouse}` : null
                    return [_adressregion, _adressdistrict, _adresssettlementpoint, _adressstreet, _adresshouse].join('')
                } else {
                    return adressoptionalformat
                }
            },

            //ФИО или наименование организации
            getFullName(name, secondname, surname, customertype) {
                //P - ФЛ, L - ЮЛ
                if (customertype == 'P') {
                    return [surname, name, secondname].join(' ')
                } else {
                    return surname
                }
            },



            //Расчет количества простороченных дней
            getDateDiff(orderdeadline) {
                let datediff = Math.floor((new Date(orderdeadline) - new Date()) / (60 * 60 * 24 * 1000)) + 1  //Прибавляем +1( один день) чтобы день сдачи работ был включительно
                return datediff
            },
        },

        filters: {
            formatDate: function (datetime) {
                return [datetime.slice(8,10),datetime.slice(5,7),datetime.slice(0,4)].join('.')
            },
            formatDateDiff: function (datediff) {
                if (datediff >= 0) {
                    return `Осталось ${datediff} дн.`
                }
                if (datediff < 0) {
                    return `Просрочено ${Math.abs(datediff)} дн.`
                }
            },
        },

        mounted() {

        }
    }
</script>

<style scoped>

</style>