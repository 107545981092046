<template>
    <div>
        <v-simple-table>
            <template v-slot:default>
                <thead>
                <tr>
                    <th class="text-left">
                        Имя файла
                    </th>
                    <th class="text-left" width="100">
                        Размер
                    </th>
                    <th class="text-left" width="150">
                        Дата загрузки
                    </th>
                    <th class="text-left" width="150">
                        Время загрузки
                    </th>
                    <th class="text-left" width="150">
                        Загрузил
                    </th>
                    <th class="text-left" width="600">
                        Примечания
                    </th>
                    <th class="text-left" width="100">
                        Действия
                    </th>
                </tr>
                </thead>
                <tbody>
                <tr
                        v-for="(item, index) in documents"
                        :key="item.filename"
                >
                    <td>{{ item.originalname }}.{{ item.dimensions }}</td>
                    <td>{{ item.size | formatBytes }}</td>
                    <td>{{ formatDate(item.date.date) }}</td>
                    <td>{{ formatTime(item.times.date) }}</td>
                    <td>{{ item.employeeskod.initialsemployees }}</td>
                    <td>{{ item.description }}</td>
                    <td>
                        <v-progress-circular
                                indeterminate
                                :width="2"
                                :size="20"
                                color="primary"
                                class="mr-3"
                                v-show="item.showLoadingFileProgress"
                        ></v-progress-circular>
                        <v-icon
                                title="Скачать"
                                class="mr-2"
                                @click="downloadfile(item.filename, item.originalname, item.dimensions, index)"
                                v-show="!item.showLoadingFileProgress"
                        >
                            mdi-file-download-outline
                        </v-icon>
                        <v-icon
                                title="Удалить"
                                @click="deletefile(item.filename)"
                        >
                            mdi-trash-can-outline
                        </v-icon>
                    </td>
                </tr>
                </tbody>
            </template>
        </v-simple-table>



<!--        <v-file-input-->
<!--                chips-->
<!--                v-model="selectedFiles"-->
<!--                placeholder="Выберите файл..."-->
<!--                @change="handleFileUpload"-->
<!--                show-size-->
<!--                :disabled="isLoading"-->
<!--        >-->

<!--        </v-file-input>-->

<!--    Диалог загрузки файлов-->
        <div class="text-center mt-10">
            <UploadFileDialog @closeUploadFileDialog="getFiles($route.params.id)"/>
        </div>

        <div
                v-show="selectedFiles"
                class="d-none"
        >
            <v-row>
                <v-col>
                    <v-text-field
                            class="ml-8"
                            label="Примечания"
                            v-model="description"
                            :disabled="isLoading"
                    ></v-text-field>
                    <v-progress-linear
                            reactive
                            v-model="progress"
                            color="yellow darken-2"
                            height="10"
                            :indeterminate="isLoading"
                    >
<!--                        {{ progress }} %-->
                    </v-progress-linear>
                </v-col>
            </v-row>
            <v-row
                    align="center"
                    justify="end"
            >
                <v-btn
                        text
                        color="#616161"
                        @click="closeUploadFile"
                        :disabled="isLoading"
                >
                    Отмена
                </v-btn>

                <v-btn
                        style="margin-right: 12px"
                        @click="uploadFiles"
                        color="primary"
                        :disabled="isLoading"
                >
                    Загрузить
                </v-btn>
            </v-row>
        </div>
    </div>
</template>

<script>
    import LKUploadFile from "@/services/LKUploadFile";
    import UploadFileDialog from "@/components/File/UploadFileDialog";
    export default {
        name: "LKFileTable",
        components: {UploadFileDialog},
        data () {
            return {
                selectedFiles: undefined, //Выбранный пользователем файл для загрузки
                progress: 0,
                documents: [], //Список документов
                description: '',
                isLoading: false
            }
        },
        methods: {
            formatDate: function (date) {
                return [date.slice(8,10),date.slice(5,7),date.slice(0,4)].join('.')
            },
            formatTime: function (time) {
                return time.slice(10,16)
            },
            getFiles(id) {
                LKUploadFile.getDocuments(id)
                    .then( response => {
                        //Добавляем в массив новое свойство: флаг скачивания файла с сервера (для отображения индикатора скачивания файла)
                        this.documents = response.data.map(current => {
                            let item = Object.assign({}, current)
                            item.showLoadingFileProgress = false
                            return item
                        })
                    })
                    .catch(error => console.log(error));
            },
            //Считываем выбранные файлы
            handleFileUpload: function (file) {
                this.progress = 0
                this.selectedFiles = file; //Событие change v-file-input возвращает сюда выбранные File[]. см доку.
            },
            //Загрузка файла на сервер
            uploadFiles: function() {
                    this.upload(this.selectedFiles)
            },
            upload: function (file) {
                //Блокируем на время загрузки компоненты выбора файла и т.п.
                this.isLoading = true
                LKUploadFile.uploadfile(file, { orderid: this.$route.params.id, employeesid: this.$store.state.auth.user.userid, filedescription: this.description }, progressEvent => {
                    this.progress = Math.round((progressEvent.loaded * 100) / progressEvent.total)
                })
                    .then(response => {
                        this.closeUploadFile()
                        this.getFiles(this.$route.params.id)
                        this.progress = 0
                        this.isLoading = false
                        console.log(response)
                    })
                    // .then(files => {
                    //     // this.files = files.data
                    //     // this.progress.splice(this.progress[j], 1)
                    // })
                    .catch( () => {
                        this.progress = 0
                        this.selectedFiles = undefined;
                        error => console.log(error)
                    })

            },
            downloadfile: function(filename,originalname, dimensions,index) {
                this.documents[index].showLoadingFileProgress = true
                LKUploadFile.downloadfile(filename)
                    .then( response => {
                        let headers = response.headers;
                        let fileURL = window.URL.createObjectURL(new Blob([response.data],{ type : headers['content-type']}));
                        let fileLink = document.createElement('a');
                        fileLink.href = fileURL;
                        fileLink.setAttribute('download', originalname + '.' + dimensions);
                        document.body.appendChild(fileLink);
                        fileLink.click();
                        this.documents[index].showLoadingFileProgress = false
                    })
                    .catch(error => console.log(error));
            },
            deletefile: function (filename) {
                LKUploadFile.deletefile(filename)
                    .then(response => {
                        console.log(response.data)
                        this.getFiles(this.$route.params.id); //Код текущего договора
                    })
                    .catch(e => {
                        console.log(e)
                        // this.alertError = true
                    })
            },
            closeUploadFile() {
                this.description = ''
                this.selectedFiles = undefined
            },
        },
        mounted () {
            this.getFiles(this.$route.params.id); //Код текущего договора
        },
        filters: {
            //Форматирование байт
            formatBytes: function (bytes, decimals = 2) {
                if (bytes === 0) return '0 байт'
                const units = ['байт', 'Кб', 'Мб', 'Гб', 'Тб', 'PB', 'EB', 'ZB', 'YB']
                const k = 1024
                const dm = decimals < 0 ? 0 : decimals
                const i = Math.floor(Math.log(bytes) / Math.log(k))
                return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + units[i]
            }
        },
    }
</script>

<style scoped>

</style>