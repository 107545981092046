import http from "../http-common"
import authHeader from './auth-header';


class GoogleDataService {
    //Загружаем файл на GoogleDrive
    loadFileOnGoogleDrive(data) {
        return http.post(`/loadFileOnGoogleDrive`, data, { headers: authHeader()});
    }

    //Сохраняем файл с Google Drive к себе на сервер
    //fileId - ID файла, находящегося на Goole Drive
    downloadFileFromGoogleDrive(fileId,fileNameWithoutExtension,extension,section,value) {
        return http.get(`/downloadFileFromGoogleDrive?fileId=${fileId}&fileNameWithoutExtension=${fileNameWithoutExtension}&extension=${extension}&section=${section}&value=${value}`, { headers: authHeader()});
    }

    //Удаляем файл с Google Drive
    //fileId - ID файла, находящегося на Google Drive
    deleteFileFromGoogleDrive(fileId) {
        return http.delete(`/deleteFileFromGoogleDrive?fileId=${fileId}`, { headers: authHeader()});
    }

    //Получаем
    getGoogle() {
        return http.get(`/googleapi`,{ headers: authHeader(), responseType: 'arraybuffer' });
    }

    //Получаем
    getGooglegetGoogleOAuth() {
        return http.get(`/googleapiOAuth`,{ headers: authHeader()});
    }



}

export default new GoogleDataService()