<template>
    <div>
        <!--Сообщение об успешном Сохранении данных-->
        <v-snackbar
                v-model="snackbarSave"
                color="green"
                top
                :timeout="1000"
        >
            Данные сохранены
            <template v-slot:action="{ attrs }">
                <v-btn
                        color="grey lighten-2"
                        icon
                        v-bind="attrs"
                        @click="snackbarSave = false"
                >
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </template>
        </v-snackbar>
        <!--End Сообщение об успешном Сохранении данных-->

        <!--Сообщение об ошибке сохранения в БД-->
        <v-alert
                type="error"
                dismissible
                v-model="alertError"
                transition="scale-transition"
        >
            Невозможно сохранить данные. Возможно отсутствует соединение с базой данных. Обратитесь к Администратору.
        </v-alert>
        <!--End Сообщение об ошибке сохранения в БД-->

        <v-row>
            <v-col cols="auto">
                <h1 class="data-name" v-show="!isEditNumberAndDate">
                    Счет № {{ account.accountnumber }} от {{ DateFunction.DateIsoToRUS(account.accountdate.date) }}
                    <v-icon
                            @click="editNumberAndDate"
                            right
                    >
                        mdi-pencil
                    </v-icon>
                </h1>
                <div v-show="isEditNumberAndDate">
                    <v-form
                            v-model="valid"
                            lazy-validation
                    >
                        <v-text-field
                                label="Номер счета"
                                v-model="editedItem.accountnumber"
                                :rules="externalRules.requiredRules.concat(externalRules.integerRules)"
                                ref="number"
                        ></v-text-field>
                        <vc-date-picker v-model="editedItem.accountdate" :model-config="modelConfig">
                            <template v-slot="{ inputValue, togglePopover }">
                                <v-text-field
                                        label="Дата"
                                        :value="inputValue"
                                        :rules="externalRules.dateRules.concat(externalRules.requiredRules)"
                                        @click="togglePopover()"
                                        v-mask="'##.##.####'"
                                        clearable
                                        v-model="editedItem.accountdate"
                                        ref="date"
                                />
                            </template>
                        </vc-date-picker>
                    </v-form>
                    <v-btn
                            color="blue darken-1"
                            text
                            @click="closeNumberAndDate"
                    >
                        Отмена
                    </v-btn>
                    <v-btn
                            :disabled="!valid"
                            color="blue darken-1"
                            text
                            @click="saveNumberAndDate"
                    >
                        Сохранить
                    </v-btn>
                </div>
            </v-col>
            <v-col cols="auto">
                <v-btn-toggle
                        v-model="account.status"
                        class="ml-5"
                        mandatory
                        @change="updateAccount"
                >
                    <v-btn value="0"
                           width="200px"
                           max-height="40px"
                           active-class="isNew"
                    >
                        Ожидает оплаты
                    </v-btn>
                    <v-btn value="1"
                           width="200px"
                           max-height="40px"
                           active-class="isCompleted"
                    >
                        Оплачен
                    </v-btn>
                    <v-btn value="2"
                           width="200px"
                           max-height="40px"
                           active-class="isRefuse"
                    >
                        Не оплачен
                    </v-btn>
                </v-btn-toggle>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-card
                        outlined
                        class="mb-4"
                >
                    <v-card-title>Основное</v-card-title>
                    <v-card-text>
                        <v-row class="mx-0">
                            <div class="data-row">
                                <div class="data-label">
                                    Срок оплаты
                                </div>
                                <div class="data-text" v-show="!isEditDeadline">
                                    <span>{{ DateFunction.DateIsoToRUS(account.deadline.date) }}</span>
                                    <v-icon
                                            @click="editDeadline"
                                            right
                                            small
                                    >
                                        mdi-pencil
                                    </v-icon>
                                </div>
                                <div v-show="isEditDeadline">
                                    <v-form
                                            v-model="valid"
                                            lazy-validation
                                    >
                                        <vc-date-picker v-model="editedItem.deadline" :model-config="modelConfig">
                                            <template v-slot="{ inputValue, togglePopover }">
                                                <v-text-field
                                                        style="width: 200px;"
                                                        :value="inputValue"
                                                        :rules="externalRules.dateRules.concat(externalRules.requiredRules)"
                                                        @click="togglePopover()"
                                                        v-mask="'##.##.####'"
                                                        clearable
                                                        v-model="editedItem.deadline"
                                                        ref="deadline"
                                                />
                                            </template>
                                        </vc-date-picker>
                                    </v-form>
                                    <v-btn
                                            color="blue darken-1"
                                            text
                                            @click="closeDeadline"
                                    >
                                        Отмена
                                    </v-btn>
                                    <v-btn
                                            :disabled="!valid"
                                            color="blue darken-1"
                                            text
                                            @click="saveDeadline"
                                    >
                                        Сохранить
                                    </v-btn>
                                </div>
                            </div>
                        </v-row>
                        <v-row class="mx-0">
                            <div class="data-row">
                                <div class="data-label">
                                    Сумма
                                </div>
                                <div class="data-text" v-show="!isEditSum">
                                    <span>{{ new Intl.NumberFormat('ru-RU', { style: 'decimal'}).format(account.accountsum) }}</span>
                                    <v-icon
                                            @click="editSum"
                                            right
                                            small
                                    >
                                        mdi-pencil
                                    </v-icon>
                                </div>
                                <div v-show="isEditSum">
                                    <v-form
                                            v-model="valid"
                                            lazy-validation
                                    >
                                        <v-text-field
                                                style="width: 200px"
                                                v-model="editedItem.accountsum"
                                                :rules="externalRules.requiredRules.concat(externalRules.floatRules)"
                                                ref="sum"
                                        />
                                    </v-form>
                                    <v-btn
                                            color="blue darken-1"
                                            text
                                            @click="closeSum"
                                    >
                                        Отмена
                                    </v-btn>
                                    <v-btn
                                            :disabled="!valid"
                                            color="blue darken-1"
                                            text
                                            @click="saveSum"
                                    >
                                        Сохранить
                                    </v-btn>
                                </div>
                            </div>
                        </v-row>
                        <v-row class="mx-0">
                            <div class="data-row">
                                <div class="data-label">
                                    Примечания
                                </div>
                                <div class="data-text" v-show="!isEditDescription">
                                    <span v-if="account.description">{{ account.description }}</span>
                                    <span v-else class="placeholder">Введите текст</span>
                                    <v-icon
                                            @click="editDescription"
                                            right
                                            small
                                    >
                                        mdi-pencil
                                    </v-icon>
                                </div>
                                <div v-show="isEditDescription">
                                    <v-textarea
                                            v-model="editedItem.description"
                                            ref="description"
                                    ></v-textarea>
                                    <v-btn
                                            color="blue darken-1"
                                            text
                                            @click="closeDescription"
                                    >
                                        Отмена
                                    </v-btn>
                                    <v-btn
                                            color="blue darken-1"
                                            text
                                            @click="saveDescription"
                                    >
                                        Сохранить
                                    </v-btn>
                                </div>
                            </div>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col>
                <v-card
                        outlined
                        class="mb-4"
                >
                    <v-card-title>Дополнительно</v-card-title>
                    <v-card-text>
                        <v-row class="mx-0">
                            <div class="data-row">
                                <div class="data-label">
                                    Договор
                                </div>
                                <div class="data-text">
                                    <span>
                                        <router-link class="itemlink" :to="{ name: 'EditContract', params: { id: account.orderid }}">{{ account.contractnumber }}</router-link>
                                    </span>
                                </div>
                            </div>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </div>
</template>

<script>
    import AccountDataService from "@/services/AccountDataService";
    import * as ExternalRules from "@/rules/ExternalRules";
    import DateFunction from "@/services/library/DateFunction";
    export default {
        name: "EditAccount",
        data() {
            return {
                //Формат даты для календаря
                modelConfig: {
                    type: 'string',
                    mask: 'DD.MM.YYYY',
                },
                externalRules: ExternalRules,
                account: {}, //Объект счета
                editedItem: {}, //В этой переменной сохраняем объект при редактировании
                snackbarSave: false,
                alertError: false,
                valid: true,
                isEditNumberAndDate: false,
                isEditDeadline: false,
                isEditSum: false,
                isEditDescription: false,
                DateFunction: DateFunction
            }
        },
        methods: {
            getAccount(id) {
                AccountDataService.get(id)
                    .then(response => {
                        this.account = response.data
                    })
                    .catch(error => {
                        console.log(error);
                    })
            },
            updateAccount() {
                let data = {
                    accountnumber: this.account.accountnumber,
                    accountdate: this.account.accountdate.date,
                    deadline: this.account.deadline.date,
                    accountsum: this.account.accountsum,
                    description: this.account.description,
                    status: this.account.status,
                }
                AccountDataService.update(this.account.accountid, data)
                    .then(response => {
                        this.snackbarSave = true
                        this.getAccountOverdue()//Обновляем количество просроченнных счетов
                        console.log(response.data)
                    })
                    .catch(e => {
                        console.log(e)
                        this.alertError = true
                    })
            },
            getAccountOverdue() {
                this.$store.dispatch('accountOverdueCount')
            },
            editDeadline() {
                this.isEditDeadline = true
                this.editedItem.deadline = DateFunction.DateIsoToRUS(this.account.deadline.date)
                this.$nextTick(() => {
                    this.$refs.deadline.focus();
                })
            },
            closeDeadline() {
                this.isEditDeadline = false
                this.$nextTick(() => {
                    this.editedItem = {}
                })
            },
            saveDeadline() {
                this.account.deadline.date = DateFunction.DateRUSToIso(this.editedItem.deadline)
                this.closeDeadline()
                this.updateAccount()
            },
            editSum() {
                this.isEditSum = true
                this.editedItem.accountsum = Math.round(this.account.accountsum)
                this.$nextTick(() => {
                    this.$refs.sum.focus();
                })
            },
            closeSum() {
                this.isEditSum = false
                this.$nextTick(() => {
                    this.editedItem = {}
                })
            },
            saveSum() {
                this.account.accountsum = this.editedItem.accountsum
                this.closeSum()
                this.updateAccount()
            },
            editDescription() {
                this.isEditDescription = true
                this.editedItem.description = this.account.description
                this.$nextTick(() => {
                    this.$refs.description.focus();
                })
            },
            closeDescription() {
                this.isEditDescription = false
                this.$nextTick(() => {
                    this.editedItem = {}
                })
            },
            saveDescription() {
                this.account.description = this.editedItem.description
                this.closeDescription()
                this.updateAccount()
            },
            editNumberAndDate() {
                this.isEditNumberAndDate = true
                this.editedItem.accountnumber = this.account.accountnumber
                this.editedItem.accountdate = DateFunction.DateIsoToRUS(this.account.accountdate.date)
                this.$nextTick(() => {
                    this.$refs.number.focus();
                })
            },
            closeNumberAndDate() {
                this.isEditNumberAndDate = false
                this.$nextTick(() => {
                    this.editedItem = {}
                })
            },
            saveNumberAndDate() {
                this.account.accountnumber = this.editedItem.accountnumber
                this.account.accountdate.date = DateFunction.DateRUSToIso(this.editedItem.accountdate)
                this.closeNumberAndDate()
                this.updateAccount()
            }
        },
        mounted() {
            this.getAccount(this.$route.params.id) //Запрос данных счета
        }
    }
</script>

<style scoped>

</style>