import http from "../http-common"
import authHeader from './auth-header';

class LKUploadFile {
    //Загрузка файлов на сервер
    // file - загружаемый файл
    // params - параметры, передаваемые в запросе
    // onUploadProgress - событие прогресса при загрузке файла
    uploadfile(file, params, onUploadProgress) {
        //let arr = file.name.split('.'); //Разбиваем имя файла с разширением на составляющие
        let fname = file.name.split('.').slice(0, -1).join('.'); //Получаем Имя файла без расширения
        let fextens = file.name.split('.').pop(); // Расширение файла

        let formData = new FormData()
        formData.append('file', file)
        formData.append('file_name', fname);
        formData.append('file_ext', fextens);
        formData.append('file_size', file.size);
        formData.append('file_type', file.type);

        return http.post('/personalcabinetdocumentstable/uploadfile', formData, {
            headers: Object.assign(authHeader(), {"Content-Type": "multipart/form-data"}), //Объединяем 2 заголовка
            params, //Код записи в БД
            onUploadProgress
        })
    }

    //Возвращает список файлов ЛК
    getDocuments(orderid) {
        return http.get(`/personalcabinetdocumentstable?orderid=${orderid}`, { headers: authHeader() });
    }

    //Скачивание файла из ЛК
    downloadfile(filename) {
        return http.get(`/personalcabinetdocumentstable/downloadfile?filename=${filename}`,  { headers: authHeader(), responseType: 'arraybuffer' });
    }

    //Удаление файла из ЛК
    deletefile(filename) {
        return http.delete(`/personalcabinetdocumentstable/deletefile?filename=${filename}`,  { headers: authHeader() });
    }
}

export default new LKUploadFile();