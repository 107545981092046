<template>
  <div>

    <p>Отчет о прибылях и убытках (по отгрузке)</p>
      <!--Выручка-->
      <v-simple-table dense>
          <template v-slot:default>
              <thead>
              <tr>
                  <th class="text-left">
                      Направление деятельности/Статья
                  </th>
                  <th class="text-left">
                      Январь 2021
                  </th>
                  <th class="text-left" >
                      Февраль 2021
                  </th>
                  <th >
                      Март 2021
                  </th>
                  <th >
                      Апрель 2021
                  </th>
                  <th >
                      Май 2021
                  </th>
                  <th >
                      Июнь 2021
                  </th>
                  <th >
                      Июль 2021
                  </th>
                  <th >
                      Август 2021
                  </th>
                  <th >
                      Сентябрь 2021
                  </th>
                  <th >
                      Октябрь 2021
                  </th>
                  <th >
                      Ноябрь 2021
                  </th>

                  <th >
                      Декабрь 2021
                  </th>
                  <th >
                     Итого
                  </th>
              </tr>
              </thead>
              <tbody>
              <!--Сумма актов за месяц по всем направлениям-->
              <tr style="background-color: #fff2cc; font-weight: 500;">
                  <td>Выручка (общ)</td>
                  <td
                          v-for="n in 12" :key="n"
                          style="font-weight: 500"
                  >
                      {{ new Intl.NumberFormat('ru-RU', { style: 'decimal'}).format( sum = getActsSum(n-1))}}
                  </td>
                  <!--Сумма всех актов-->
                  <td style="font-weight: 500">{{ new Intl.NumberFormat('ru-RU', { style: 'decimal'}).format(getActsAllSum()) }}</td>
              </tr>

              <!--Цикл по направлениям деятельности-->
              <tr
                      v-for="(item) in activitiesItem"
                      :key="item.id"
              >
                  <td>{{item.name}}</td>
                  <td>{{ new Intl.NumberFormat('ru-RU', { style: 'decimal'}).format( month0 = getActivitiesSumm(item.id, 0))}}</td>
                  <td>{{ new Intl.NumberFormat('ru-RU', { style: 'decimal'}).format( month1 = getActivitiesSumm(item.id, 1)) }}</td>
                  <td>{{ new Intl.NumberFormat('ru-RU', { style: 'decimal'}).format( month2 = getActivitiesSumm(item.id, 2)) }}</td>
                  <td>{{ new Intl.NumberFormat('ru-RU', { style: 'decimal'}).format( month3 = getActivitiesSumm(item.id, 3)) }}</td>
                  <td>{{ new Intl.NumberFormat('ru-RU', { style: 'decimal'}).format( month4 = getActivitiesSumm(item.id, 4)) }}</td>
                  <td>{{ new Intl.NumberFormat('ru-RU', { style: 'decimal'}).format( month5 = getActivitiesSumm(item.id, 5)) }}</td>
                  <td>{{ new Intl.NumberFormat('ru-RU', { style: 'decimal'}).format( month6 = getActivitiesSumm(item.id, 6)) }}</td>
                  <td>{{ new Intl.NumberFormat('ru-RU', { style: 'decimal'}).format( month7 = getActivitiesSumm(item.id, 7)) }}</td>
                  <td>{{ new Intl.NumberFormat('ru-RU', { style: 'decimal'}).format( month8 = getActivitiesSumm(item.id, 8)) }}</td>
                  <td>{{ new Intl.NumberFormat('ru-RU', { style: 'decimal'}).format( month9 = getActivitiesSumm(item.id, 9)) }}</td>
                  <td>{{ new Intl.NumberFormat('ru-RU', { style: 'decimal'}).format( month10 = getActivitiesSumm(item.id, 10)) }}</td>
                  <td>{{ new Intl.NumberFormat('ru-RU', { style: 'decimal'}).format( month11 = getActivitiesSumm(item.id, 11)) }}</td>
                  <td>{{ new Intl.NumberFormat('ru-RU', { style: 'decimal'}).format(month0 + month1 + month2 + month3 + month4 + month5 + month6 + month7 + month8 + month9 + month10 + month11)}}</td>
              </tr>
              </tbody>
          </template>
      </v-simple-table>
        <br>
      <!--Себестоимость ИЭИ-->
<!--      <v-simple-table dense>-->
<!--          <template v-slot:default>-->
<!--              <thead>-->
<!--              <tr>-->
<!--                  <th class="text-left">-->
<!--                      Направление деятельности/Статья-->
<!--                  </th>-->
<!--                  <th class="text-left">-->
<!--                      Январь 2021-->
<!--                  </th>-->
<!--                  <th class="text-left" >-->
<!--                      Февраль 2021-->
<!--                  </th>-->
<!--                  <th >-->
<!--                      Март 2021-->
<!--                  </th>-->
<!--                  <th >-->
<!--                      Апрель 2021-->
<!--                  </th>-->
<!--                  <th >-->
<!--                      Май 2021-->
<!--                  </th>-->
<!--                  <th >-->
<!--                      Июнь 2021-->
<!--                  </th>-->
<!--                  <th >-->
<!--                      Июль 2021-->
<!--                  </th>-->
<!--                  <th >-->
<!--                      Август 2021-->
<!--                  </th>-->
<!--                  <th >-->
<!--                      Сентябрь 2021-->
<!--                  </th>-->
<!--                  <th >-->
<!--                      Октябрь 2021-->
<!--                  </th>-->
<!--                  <th >-->
<!--                      Ноябрь 2021-->
<!--                  </th>-->

<!--                  <th >-->
<!--                      Декабрь 2021-->
<!--                  </th>-->
<!--                  <th >-->
<!--                      Итого-->
<!--                  </th>-->
<!--              </tr>-->
<!--              </thead>-->
<!--              <tbody>-->
<!--              &lt;!&ndash;Сумма актов за месяц по всем направлениям&ndash;&gt;-->
<!--              <tr style="background-color: #fff2cc; font-weight: 500;">-->
<!--                  <td>Себестоимость ИЭИ</td>-->
<!--                  <td-->
<!--                          v-for="n in 12" :key="n"-->
<!--                          style="font-weight: 500"-->
<!--                  >-->
<!--&lt;!&ndash;                      {{ new Intl.NumberFormat('ru-RU', { style: 'decimal'}).format( sum = getActsSum(n-1))}}&ndash;&gt;-->
<!--                  </td>-->
<!--                  &lt;!&ndash;Сумма всех актов&ndash;&gt;-->
<!--&lt;!&ndash;                  <td style="font-weight: 500">{{ new Intl.NumberFormat('ru-RU', { style: 'decimal'}).format(getActsAllSum()) }}</td>&ndash;&gt;-->
<!--              </tr>-->


<!--              <tr>-->
<!--                  <td>Субподрядные работы</td>-->
<!--                  <td-->
<!--                          v-for="n in 12" :key="n"-->
<!--                  >-->
<!--                    {{ getDocumentsSumm(4,20, n-1) }}-->
<!--                  </td>-->


<!--              </tr>-->
<!--              <tr>-->
<!--                  <td>Лабораторные исследования</td>-->
<!--                  <td-->
<!--                          v-for="n in 12" :key="n"-->
<!--                  >-->
<!--                      {{ getDocumentsSumm(4,18, n-1) }}-->
<!--                  </td>-->

<!--              </tr>-->
<!--              <tr>-->
<!--                  <td>Инструментальные исследования</td>-->
<!--                  <td-->
<!--                          v-for="n in 12" :key="n"-->
<!--                  >-->
<!--                      {{ getDocumentsSumm(4,17, n-1) }}-->
<!--                  </td>-->

<!--              </tr>-->
<!--              </tbody>-->







<!--          </template>-->
<!--      </v-simple-table>-->



      <br>
      <!--Себестоимость ИГИ-->
      <v-simple-table dense>
          <template v-slot:default>
              <thead>
              <tr>
                  <th class="text-left">
                      Направление деятельности/Статья
                  </th>
                  <th class="text-left">
                      Январь 2021
                  </th>
                  <th class="text-left" >
                      Февраль 2021
                  </th>
                  <th >
                      Март 2021
                  </th>
                  <th >
                      Апрель 2021
                  </th>
                  <th >
                      Май 2021
                  </th>
                  <th >
                      Июнь 2021
                  </th>
                  <th >
                      Июль 2021
                  </th>
                  <th >
                      Август 2021
                  </th>
                  <th >
                      Сентябрь 2021
                  </th>
                  <th >
                      Октябрь 2021
                  </th>
                  <th >
                      Ноябрь 2021
                  </th>

                  <th >
                      Декабрь 2021
                  </th>
                  <th >
                      Итого
                  </th>
              </tr>
              </thead>
              <tbody>
              <!--Сумма актов за месяц по всем направлениям-->
              <tr style="background-color: #fff2cc; font-weight: 500;">
                  <td>Себестоимость ИГИ</td>
                  <td
                          v-for="n in 12" :key="n"
                          style="font-weight: 500"
                  >

                  </td>

              </tr>


              <tr>
                  <td>Субподрядные работы</td>
                  <td
                          v-for="n in 12" :key="n"
                  >
                      {{ getDocumentsSumm(3,20, n-1) }}
                  </td>
              </tr>

              </tbody>







          </template>
      </v-simple-table>

  </div>
</template>

<script>
import ActDataService from "@/services/ActDataService";
import ActivitiesDataService from "@/services/ActivitiesDataService";


export default {
    name: 'Profitandloss',
    data () {
        return {
            activitiesItem: null,
            actsItem: null,
            sum: 0,
        }
    },

    methods: {
        //Выборка всех актов сдачи работ за период
        getActs(stardate, enddate) {
            ActDataService.findByDatePeriod(stardate, enddate)
                .then( response => {
                    this.actsItem = response.data
                    //this.loadingTable = false
                })
                .catch(error => console.log(error));
        },
        //Выборка всех Направлений деятельности
        getActivities() {
            ActivitiesDataService.find()
                .then( response => {
                    this.activitiesItem = response.data
                })
                .catch(error => console.log(error));
        },


        //Цикл по документам для формирования себестоимости
        getDocumentsSumm(activityid,accountingid,month) {
            let sum //Сумма документов, руб
            let docArr = []  //Массив документов
            let filterDocArray = []//Массив отфильтрованных документов

            this.actsItem.forEach((item) => { //Цикл по актам
                if (new Date(item.actdate.date).getMonth() === month) {

                    item.documents.forEach((item) => { //Цикл по документам акта
                        docArr.push(item)//Загоняем документ в массив
                    })

                    filterDocArray = docArr.filter(qitem => (qitem.activityid == activityid && qitem.accountingid == accountingid)) //Фильтруем документы по статье и виду деятельности

                    //Получаем сумму документов
                    let initialValue = 0;
                    sum = filterDocArray.reduce(
                        (accumulator, currentValue) => Number(accumulator) + Number(currentValue.sum),
                        initialValue
                    );
                }
            })

            return sum
        },

        //Возвращаем сумму актов по направлению деятельности и месяцу
        //month - номер месяца, с 0..11
        getActivitiesSumm(activitiid, month) {
            let sum = 0
            this.actsItem.forEach(function (item) { //Цикл по актам
                if (new Date(item.actdate.date).getMonth() === month) {
                    for (let i = 0; i < item.tablecrossingserviceviewact.length; i++) //Цикл по номенклатуре акта
                    {
                        if (item.tablecrossingserviceviewact[i].serviceviewkod.activitiid === activitiid) {
                            sum = sum + Number(item.tablecrossingserviceviewact[i].serviceprice * item.tablecrossingserviceviewact[i].servicecount)
                        }
                    }
                }
            })
            return sum
        },
        //Возвращаем сумму актов по месяцам, без разделения на направления
        getActsSum(month) {
            let sum = 0
            this.actsItem.forEach(function (item) { //Цикл по актам
                if (new Date(item.actdate.date).getMonth() === month) {
                    for (let i = 0; i < item.tablecrossingserviceviewact.length; i++) //Цикл по номенклатуре акта
                    {
                       sum = sum + Number(item.tablecrossingserviceviewact[i].serviceprice * item.tablecrossingserviceviewact[i].servicecount)
                    }
                }
            })
            return sum
        },
        //Возвращаем сумму всех актов в выборке
        getActsAllSum() {
            let sum = 0
            this.actsItem.forEach(function (item) { //Цикл по актам
                    for (let i = 0; i < item.tablecrossingserviceviewact.length; i++) //Цикл по номенклатуре акта
                    {
                        sum = sum + Number(item.tablecrossingserviceviewact[i].serviceprice * item.tablecrossingserviceviewact[i].servicecount)
                    }
            })
            return sum
        },


    },
    mounted () {
        this.getActivities()
        this.getActs('2021-12-01','2021-12-31')
    },
}
</script>
