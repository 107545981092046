<template>
    <div>
        <div v-for="(item, index) in messages" :key="item.id" class="message-container">
            <v-avatar
                    size="35"
            >
                <img
                        :src="`${avatars}${item.employeeskod.avatar}`"
                >
            </v-avatar>
            <div class="message-post" :class="{'my-message-post': item.employeeskod.employeesid == $store.state.auth.user.userid, 'message-post-editing' : messageInPlace && index == editedMessageIndex}">
                <p class="user-message-line">{{ item.employeeskod.name }} {{ item.employeeskod.surname}} <span class="user-message-date">{{ formatDate(item.messagedate.date) }} в {{ formatTime(item.messagetime.date)}}</span></p>
                <p
                        class="user-message-text"
                        v-show="!messageInPlace || index != editedMessageIndex"
                >
                    <span>{{ item.messagebody }}</span>
                </p>
                <div v-show="messageInPlace  && index == editedMessageIndex">
                    <v-textarea
                            v-model="editedItem.message"
                            ref="message"
                    ></v-textarea>
                    <v-btn
                            color="blue darken-1"
                            text
                            @click="closeMessageInPlace"
                    >
                        Отмена
                    </v-btn>
                    <v-btn
                            color="blue darken-1"
                            text
                            @click="saveMessageInPlace(item.messageid)"
                    >
                        Сохранить
                    </v-btn>
                </div>

                <!--Иконка действий с сообщениями, появляется при наведении на сообщение. Отображаем только для собственников сообщения-->
                <span
                    v-if="item.employeeskod.employeesid == currentUser.userid"
                >
                    <v-menu
                            bottom
                            left
                    >
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                                  dark
                                  icon
                                  v-bind="attrs"
                                  v-on="on"
                                  class="message-edit-button"
                          >
                            <v-icon
                                  size="20"

                                  color="#828282"
                            >
                                  mdi-dots-vertical
                            </v-icon>
                      </v-btn>
                    </template>
                        <v-list dense>
                <v-list-item @click="editMessage(index)">
                    <v-list-item-title>Редактировать</v-list-item-title>
                </v-list-item>
                <v-list-item @click="deleteMessage(item.messageid)">
                    <v-list-item-title>Удалить</v-list-item-title>
                </v-list-item>
            </v-list>
                    </v-menu>
                </span>
            </div>
        </div>
        <v-textarea
                v-model="messageInput"
                placeholder="Введите сообщение"
                rows="1"
                @focus="focusedMessageInput = true"
        >
        </v-textarea>

        <v-row
                align="center"
                justify="end"
                v-show="focusedMessageInput"
        >
            <v-btn
                    text
                    color="#616161"
                    @click="closeMessageInput"
            >
                Отмена
            </v-btn>
            <v-btn
                    color="primary"
                    style="margin-right: 12px"
                    :disabled="messageInput == 0"
                    @click="addMessage()"
            >
                Оставить сообщение
            </v-btn>
        </v-row>
    </div>
</template>

<script>
    import MessageTableDataService from "@/services/MessageTableDataService";
    import ProjectTeamDataService from "@/services/ProjectTeamDataService";
    import NoticeDataService from "@/services/NoticeDataService";

    export default {
        name: "OrderMessages",
        props: {
          order: Object //Объект заказа
        },
        data() {
            return {
                messages: {},//Объект текущих сообщений
                messageInput: '',
                focusedMessageInput: false, //флаг фокуса ввода текстового сообщения
                publicPath: process.env.BASE_URL,
                avatars: process.env.VUE_APP_AVATARS,
                messageInPlace: false,
                editedMessageIndex: null, //Индекс редактируемого сообщения
                editedItem: {}, //В этой переменной сохраняем объект редактирования
                team: null, //Команда проекта (для уведомлений)
                alertArr: [this.order.managerid, this.order.creatorid], //Массив с id сотрудников, кому будем отправлять уведомления о создании сообщения (создатель заказа и менеджер заказа, получаем из входного параметра)
            }
            },
        methods: {
            getMessages(orderid) {
                MessageTableDataService.find(orderid)
                    .then(response => {
                        this.messages = response.data
                        console.log(response.data);
                    })
                    .catch(error => {
                        console.log(error);
                    })
            },
            closeMessageInput() {
                this.messageInput = ''
                this.focusedMessageInput = false
            },
            addMessage() {
                //Данные для БД
                let data = {
                    orderkod: this.$route.params.id,
                    employeeskod: this.currentUser.userid,
                    messagebody: this.messageInput
                }

                //Формируем массив из id сотрудников, которым будем отправлять уведомление о сообщении
                let arr = this.alertArr
                this.team.forEach(item => {    //ДОбавляем к массиву команду проекта
                    arr.push(item.employeeid)
                })
                let uniqueArr = [...new Set(arr)] //Удаляем дублирующиеся элементы из массива
                let arrayAlert =  uniqueArr.filter(item => item !== this.$store.state.auth.user.userid)//Удаляем, если есть создателя сообщения, чтобы не создавать уведомление самому себе


                MessageTableDataService.create(data)
                    .then(response => {
                        console.log(response.data);
                        this.closeMessageInput()
                        this.getMessages(this.$route.params.id)
                        //ОТправляем уведомление о добавленном сообщении команде проекта, создателю и менеджеру
                        arrayAlert.forEach(item => {
                            NoticeDataService.create({creatorid: this.$store.state.auth.user.userid, employeesid: item, noticetext: `Новый комментарий по объекту: ${this.order.estatecod.fulladdress} .<br/><a href="/contracts/${this.order.orderid}"> Договор №${this.order.contractnumber} от ${this.formatDate(this.order.orderdata.date)}</a> со следующим текстом: <span class="notice-message">"${data.messagebody}"</span>`});//Создаем уведомление члену команды
                        })
                    })
                    .catch(error => {
                        console.log(error);
                    })
            },
            editMessage(index) {
                this.editedItem.message = this.messages[index].messagebody
                this.editedMessageIndex = index
                this.messageInPlace = true
                this.$nextTick(() => {
                    this.$refs.message.focus();
                })
            },
            closeMessageInPlace() {
                this.messageInPlace = false
                this.editedMessageIndex = null
                this.$nextTick(() => {
                    this.editedItem = {}
                })

            },
            saveMessageInPlace(messageid) {
                let data = {
                    messagebody: this.editedItem.message
                }
                MessageTableDataService.update(messageid, data)
                    .then(response => {
                        console.log(response.data);
                        this.closeMessageInPlace()
                        this.getMessages(this.$route.params.id)
                    })
                    .catch(error => {
                        console.log(error);
                    })
            },
            deleteMessage(messageid) {
                MessageTableDataService.delete(messageid)
                    .then(response => {
                        console.log(response.data);
                        this.getMessages(this.$route.params.id)
                    })
                    .catch(error => {
                        console.log(error);
                    })
            },
            //Команда проекта
            getTeam() {
                ProjectTeamDataService.get(this.order.orderid)
                    .then(response => {
                        this.team = response.data
                    })
                    .catch(e => {
                        console.log(e)
                    })
            },
            formatDate: function (date) {
                return [date.slice(8,10),date.slice(5,7),date.slice(0,4)].join('.')
            },
            formatTime: function (time) {
                return time.slice(10,16)
            },
        },
        mounted() {
            //Запрос сообщений к договору
            this.getMessages(this.$route.params.id)
            this.getTeam()
        },
        computed: {
            currentUser() {
                return this.$store.state.auth.user;
            }
        },
    }

</script>

<style scoped>

    .message-container {
        margin-bottom: 10px;
        display: flex;
    }

    .message-post {
        padding-left: 15px;
        padding-right: 15px;
        background-color: #e9edf0;
        border-radius: 8px;
        padding-top: 10px;
        margin-left: 7px;
        padding-bottom: 10px;
        position: relative;
    }

    .message-post > span {
        display: none;
    }

    .message-post:hover > span {
        display: block !important;
    }

    .message-post-editing {
        width: 100% !important;
    }

    .my-message-post {
        background-color: #e2f2ca !important;
    }

    .user-message-line {
        margin-bottom: -3px;
        color: #0d47a1;
    }

    .user-message-text {
        margin: 0;
    }
    .user-message-text span {
        white-space: pre-line;
    }

    .user-message-date {
        font-size: 11px;
        color: #9c9c9c;
        padding-left: 7px;
        font-weight: normal;
    }

    .message-edit-button {
        position: absolute;
        right: -9px;
        top: -4px;
        padding: 0;
        margin: 0;
    }



</style>