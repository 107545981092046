<template>
    <div>
        <v-row style="height: 100vh">
            <v-col class="d-flex justify-center align-center">
                <v-card width="400" style="opacity: 0.9">
                    <v-card-title style="justify-content: center; padding-top: 30px;">
                    <img src="../assets/images/logoza-ru-hd.png">
                    </v-card-title>
                    <v-card-text>
                        <v-row>
                            <v-col class="text-center">
                                <v-form
                                        ref="form"
                                        v-model="validForm"
                                        lazy-validation
                                >
                                    <v-text-field
                                            outlined
                                            label="Логин"
                                            v-model="user.username"
                                            :rules="requiredRules"
                                            required
                                    ></v-text-field>
                                    <v-text-field
                                            outlined
                                            label="Пароль"
                                            v-model="user.password"
                                            :rules="requiredRules"
                                            required
                                            type="password"
                                    ></v-text-field>
                                    <v-btn
                                            color="primary"
                                            depressed
                                            @click="login"
                                            :loading="loading"
                                    >
                                        Войти
                                    </v-btn>
                                    <div v-show="message" style="padding-top: 15px">{{ message.message }}</div>
                                </v-form>
                            </v-col>
                        </v-row>
                    </v-card-text>

                </v-card>
            </v-col>
        </v-row>
    </div>
</template>

<script>
    export default {
        name: "Login",
        data() {
            return {
                user: {
                    username: '',
                    password: ''
                },
                message: '',
                loading: false,
                requiredRules: [
                    v => !!v || 'Пожалуйста, заполните это поле',
                ],
                validForm: true,
            }
        },
        computed: {
          loggedIn() {
              return this.$store.state.auth.status.loggedIn
          }
        },
        created () {
            if (this.loggedIn) {
                this.$router.push('/dashboard')
            }
        },
        methods: {
            login () {
                this.message = ''
                if (this.$refs.form.validate()) {
                    if (this.user.username && this.user.password) {
                        this.loading = true
                        this.$store.dispatch('auth/login', this.user)
                            .then(() => {
                                    this.$router.push('/dashboard')
                                },
                                error => {
                                    this.loading = false
                                    this.message =
                                        (error.response && error.response.data) ||
                                        error.message ||
                                        error.toString();
                                }
                            )
                    }
                }


            }
        }
    }
</script>

<style scoped>

</style>