import http from "../http-common"
import authHeader from './auth-header';
import QueryString from "qs";

class OrderDataService {
    // //Создание записи
    create(data) {
        return http.post("/contracts", data,{ headers: authHeader() });
    }

    //Запрос записи
    get(id) {
        return http.get(`/contracts/${id}`,{ headers: authHeader() });
    }

    //Обновление записи
    update(id, data) {
        return http.put(`contracts/${id}`, data, { headers: authHeader() })
    }

    //Смена Руководителя проекта
    //id- код договора
    //employeeId - код сотрудника
    changeProjectManager(id,employeeId) {
        return http.put(`changeprojectmanager/${id}`, { employeeid: employeeId}, { headers: authHeader() })
    }

    //Запрос/поиск записей
    find(params) {
        //return http.get(`/contracts?contractNumberString=${contractNumberString}&customerString=${customerString}&addressString=${addressString}&startContractDate=${startContractDate}&endContractDate=${endContractDate}&startDeadlineDate=${startDeadlineDate}&endDeadlineDate=${endDeadlineDate}&status=${statusArr}&favorite=${favorite}&userid=${userid}&maxResults=${maxResults}&managerid=${managerid}`,{ headers: authHeader() });
        return http.get(`/contracts`,{ params: params, paramsSerializer: params => {return QueryString.stringify(params, {arrayFormat: 'comma'})}, headers: authHeader() });
    }



    //Добавляем договор в избранное
    addContractToFavorite(orderid, employeesid) {
        return http.post(`/contracts/addtofavorite?orderid=${orderid}&employeesid=${employeesid}`, null, { headers: authHeader() })
    }

    //Удаляем договор из избранного
    deleteContractFromFavorite(orderid, employeesid) {
        return http.delete(`/contracts/deletefromfavorite?orderid=${orderid}&employeesid=${employeesid}`, { headers: authHeader() })
    }

    //Поиск горящих договоров (для уведомлений в трее)
    findFireContracts() {
        return http.get(`/getfirecontracts`, { headers: authHeader() })
    }

    //Количество горящих договоров (для уведомлений в трее)
    getFireContractsCount() {
        return http.get(`/fireContractsCount`, { headers: authHeader() })
    }

    //Печать договора
    printContract(companyid, contractname, contractid) {
        return http.get(`/printcontract?companyid=${companyid}&contractname=${contractname}&contractid=${contractid}`, { headers: authHeader(), responseType: 'arraybuffer' })
    }

    //Изменение статуса договора
    setOrderStatus(orderid, status) {
        return http.post(`/contracts/setstatus?orderid=${orderid}&status=${status}`, null, { headers: authHeader() })
    }
}

export default new OrderDataService()