import { fileserver } from "../http-common" //Импортируем без BASEURL
//import authHeader from './auth-header';

//Отправка СМС сообщений через сторонний сервис (https://sms-prosto.ru)
class FnsAPIDataService {

    //Данные о компании
    //inn - ИНН компании
    getCompany(inn) {
        return fileserver.get(`${process.env.VUE_APP_FNS}/company?key=gfn9rVSMDxIJogpf&inn=${inn}`);

    }
}

export default new FnsAPIDataService()