<!--Участники проекта-->
<template>
    <div>

        <!--      Диалоговое окно добавления руководителя проекта-->
        <v-dialog
                eager
                max-width="600"
                persistent
                v-model="addEmployeerDialog"
        >
            <v-card>
                <v-card-title class="headline">
                    <span>Смена руководителя проекта</span>
                    <v-spacer></v-spacer>
                    <v-btn
                            icon
                            @click="closeDialog"
                    >
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-card-title>
                <v-card-text>
                    <v-container>
                        <v-row>
                            <v-col>
                                <v-autocomplete
                                        label="Выберите сотрудника"
                                        v-model="employeesid"
                                        :items="employeesItems"
                                        item-value="employeesid"
                                        item-text="fullname"
                                        clearable
                                >
                                    <template v-slot:item="data">
                                        <v-list-item-content>
                                            <v-list-item-title v-html="data.item.fullname"></v-list-item-title>
                                        </v-list-item-content>
                                    </template>
                                </v-autocomplete>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                            color="blue darken-1"
                            text
                            @click="closeDialog"
                    >
                        Отмена
                    </v-btn>
                    <v-btn
                            color="blue darken-1"
                            text
                            @click="addEmploeer"
                    >
                        Изменить
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <!--      Диалоговое окно добавления Члена комманды-->
        <v-dialog
                eager
                max-width="600"
                persistent
                v-model="addComandMemberDialog"
        >
            <v-card>
                <v-card-title class="headline">
                    <span>Добавление в команду</span>
                    <v-spacer></v-spacer>
                    <v-btn
                            icon
                            @click="closeAddComandMemberDialog"
                    >
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-card-title>
                <v-card-text>
                    <v-container>
                        <v-row>
                            <v-col>
                                <v-autocomplete
                                        label="Выберите сотрудника"
                                        v-model="comandMemberid"
                                        :items="employeesItems"
                                        item-value="employeesid"
                                        item-text="fullname"
                                        clearable
                                >
                                    <template v-slot:item="data">
                                        <v-list-item-content>
                                            <v-list-item-title v-html="data.item.fullname"></v-list-item-title>
                                        </v-list-item-content>
                                    </template>
                                </v-autocomplete>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                            color="blue darken-1"
                            text
                            @click="closeAddComandMemberDialog"
                    >
                        Отмена
                    </v-btn>
                    <v-btn
                            color="blue darken-1"
                            text
                            @click="addComandMember"
                    >
                        Добавить
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

            <v-row justify="start">
                <v-col md="4" class="outer">
                    <div>
                        <p class="header" style="margin-bottom: 13px">Руководитель проекта</p>
                        <v-avatar
                                size="50"
                                class="float-left"
                        >
                            <img
                                    :src="`${avatars}${manager.avatar}`"
                            >
                        </v-avatar>
                        <div class="avatar-text" style="float: left">
                            <p class="fio">{{ manager.name }} {{ manager.surname }}</p>
                            <p class="post">{{ manager.post }}</p>
                        </div>
                        <v-icon
                                style="color: rgb(13, 71, 161);"
                                size="20"
                                class="float-right"
                                @click="changeManager"
                        >
                            mdi-swap-horizontal
                        </v-icon>
                    </div>
                </v-col>
                <v-col md="4">
                    <div>
                        <p class="header" style="margin-bottom: 13px">Создатель</p>
                        <v-avatar
                                size="50"
                                class="float-left"
                        >
                            <img
                                    :src="`${avatars}${creator.avatar}`"
                            >
                        </v-avatar>
                        <div class="avatar-text" style="float: left">
                            <p class="fio">{{ creator.name }} {{ creator.surname }}</p>
                            <p class="post">{{ creator.post }}</p>
                        </div>
                    </div>
                </v-col>
            </v-row>
            <div>
                <p class="header" style="margin-top: 10px; margin-bottom: 0">Команда проекта</p>
                <v-row justify="start" class="memberRow">
                    <v-col v-for="item in team" :key="item.employeeid" md="4" class="outer">
                                <v-avatar
                                        size="50"
                                        class="float-left"
                                >
                                    <img
                                            :src="`${avatars}${item.avatar}`"
                                    >
                                </v-avatar>
                                <div class="avatar-text" style="float: left">
                                    <p class="fio">{{ item.name }} {{ item.surname }}</p>
                                    <p class="post">{{ item.post }}</p>
                                </div>
                                <v-icon
                                        class="float-right"
                                        @click="deleteCommandMember(item.employeeid)"
                                        style="color: rgb(13, 71, 161);"
                                        size="20"
                                >
                                    mdi-trash-can-outline
                                </v-icon>
                    </v-col>
                    <v-col class="addMember">
                        <v-icon
                                style="color:#b0b0b0"
                                size="40"
                                @click="addCommandMember"
                        >
                            mdi-account-plus-outline
                        </v-icon>
                    </v-col>
                </v-row>
            </div>

    </div>
</template>


<script>
    //import * as ExternalRules from "@/rules/ExternalRules";
    import EmployeeDataService from "@/services/EmployeeDataService";
    import ProjectTeamDataService from "@/services/ProjectTeamDataService";
    import OrderDataService from "@/services/OrderDataService";
    import NoticeDataService from "@/services/NoticeDataService";

    export default {
        name: "Manager",
        props: ['managerid', 'creatorid', 'estate', 'contractnumber', 'orderdata'],
        data () {
            return {

                showChangeManager: false,

                addEmployeerDialog: false,
                addComandMemberDialog: false,
                employeesItems: [],
                employeesid: null,
                comandMemberid: null,
                publicPath: process.env.BASE_URL,
                avatars: process.env.VUE_APP_AVATARS,
                manager: null,
                creator: null,
                team: null,
            }
        },
        methods: {
            getManager(managerid) {
                EmployeeDataService.get(managerid)
                    .then(response => {
                        this.manager = response.data
                    })
                    .catch(e => {
                        console.log(e)
                    })
            },
            getCreator(creatorid) {
                EmployeeDataService.get(creatorid)
                    .then(response => {
                        this.creator = response.data
                    })
                    .catch(e => {
                        console.log(e)
                    })
            },
            //Команда проекта
            getTeam(orderid) {
                ProjectTeamDataService.get(orderid)
                    .then(response => {
                        this.team = response.data
                    })
                    .catch(e => {
                        console.log(e)
                    })
            },
            getEmployees() {
                EmployeeDataService.find()
                    .then(response => {
                        this.employeesItems = response.data
                    })
                    .catch(e => {
                        console.log(e)
                    })
            },
            //Смена руководителя проекта
            addEmploeer() {
                OrderDataService.changeProjectManager(this.$route.params.id, this.employeesid)
                    .then(response => {
                        //Отправляем сообщение предыдущему руководителю - до смены руководителя
                        NoticeDataService.create({creatorid: null, employeesid: this.manager.employeesid, noticetext: `Вы освобождены от обязанностей руководителя проекта по объекту: ${this.estate}.<br/><a href="/contracts/${this.$route.params.id}"> Договор №${this.contractnumber} от ${this.formatDate(this.orderdata.date)}</a>`});
                        this.getManager(this.employeesid)
                        //Отправляем сообщение новому руководителю
                        NoticeDataService.create({creatorid: null, employeesid: this.employeesid, noticetext: `Вы назначены руководителем проекта по объекту: ${this.estate}.<br/><a href="/contracts/${this.$route.params.id}"> Договор №${this.contractnumber} от ${this.formatDate(this.orderdata.date)}</a>`});
                        console.log(response.data);
                    })
                    .catch(error => {
                        console.log(error);
                    })
                this.closeDialog()
            },
            //Смена руководителя проекта
            changeManager() {
                this.getEmployees() //Сотрудники
                this.addEmployeerDialog = true
            },
            //ДОбавление члена в команду
            addComandMember() {
                ProjectTeamDataService.add(this.$route.params.id, this.comandMemberid)
                    .then(response => {
                        this.getTeam(this.$route.params.id)
                        //Отправляем сообщение новому члену команды
                        NoticeDataService.create({creatorid: null, employeesid: this.comandMemberid, noticetext: `Вы добавлены в команду проекта по объекту: ${this.estate}.<br/><a href="/contracts/${this.$route.params.id}"> Договор №${this.contractnumber} от ${this.formatDate(this.orderdata.date)}</a>`});
                        console.log(response.data);
                    })
                    .catch(error => {
                        console.log(error);
                    })
                this.closeAddComandMemberDialog()
            },

            //Удаление члена комманды
            deleteCommandMember(employeeid) {
                ProjectTeamDataService.delete(this.$route.params.id, employeeid)
                    .then(response => {
                        NoticeDataService.create({creatorid: null, employeesid: employeeid, noticetext: `Вы исключены из участников проекта по объекту: ${this.estate}.<br/><a href="/contracts/${this.$route.params.id}"> Договор №${this.contractnumber} от ${this.formatDate(this.orderdata.date)}</a>`});
                        this.getTeam(this.$route.params.id)
                        console.log(response.data);
                    })
                    .catch(e => {
                        console.log(e)
                    })
            },
            //Добавление сотрудника в команду
            addCommandMember() {
                this.getEmployees() //Сотрудники
                this.addComandMemberDialog = true
            },
            //Закрываем диалог без сохранения информации
            closeDialog () {
                this.addEmployeerDialog = false
            },
            closeAddComandMemberDialog() {
                this.addComandMemberDialog = false
            },
            formatDate: function (date) {
                return [date.slice(8,10),date.slice(5,7),date.slice(0,4)].join('.')
            },

        },
        mounted () {
            this.getManager(this.managerid)
            this.getCreator(this.creatorid)
            this.getTeam(this.$route.params.id)
        },
    }
</script>

<style scoped>
    .header {
        font-weight: 500;
        font-size: 13px;
        padding: 0;
        margin: 0;
        margin-bottom: 7px;
    }
    .avatar-text {
        padding-left: 10px;
    }
    .fio {
        margin-bottom: 0;
        font-size: 0.875rem;
        color: rgba(0, 0, 0, 0.87);
    }
    .post{
        font-size: 12px;
        color: #686868;
    }

    .outer .v-icon {
        visibility: hidden;
    }

    .outer:hover .v-icon {
        visibility: visible;
    }

    /*.memberRow .addMember .v-icon {*/
    /*    visibility: hidden;*/
    /*}*/

    /*.memberRow:hover  .addMember .v-icon {*/
    /*    visibility: visible;*/
    /*}*/

</style>