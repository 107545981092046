<template>
<!--    <v-alert-->
<!--            :type="alertType"-->
<!--            dismissible-->
<!--            v-model="showAlert"-->
<!--            transition="scale-transition"-->
<!--            @input="closeAlert"-->
<!--    >-->
<!--        {{ alertMessage }}-->
<!--    </v-alert>-->

    <v-dialog
            v-model="showDialog"
            max-width="290"
    >
        <v-card>
            <v-card-title class="text-h5">
                Use Google's location service?
            </v-card-title>

            <v-card-text>
                Let Google help apps determine location. This means sending anonymous location data to Google, even when no apps are running.
            </v-card-text>

            <v-card-actions>
                <v-spacer></v-spacer>

                <v-btn
                        color="green darken-1"
                        text
                >
                    Disagree
                </v-btn>

                <v-btn
                        color="green darken-1"
                        text
                >
                    Agree
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>


</template>

<script>
    export default {
        name: "Dialog",
        data() {
            return {
                //
            }
        },
        computed: {
            showDialog() {
                return this.$store.getters.showDialog;
            }
        },
        methods: {
            closeDialog() {
                // this.$store.commit('hideAlert');
                this.$store.dispatch('hideDialog');
            }
        }
    }
</script>

<style scoped>

</style>