<template>
  <div>
        <TasksTable/>
  </div>
</template>

<script>
// @ is an alias to /src
import TasksTable from "@/components/TasksTable";

export default {
  name: 'Tasks',
  components: {
        TasksTable
  }
}
</script>
