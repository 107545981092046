import http from "../http-common"
import authHeader from './auth-header';

class MessageTableDataService {
    //Создание записи
    create(data) {
        return http.post("/messages", data, { headers: authHeader() });
    }

    //Обновление записи
    update(messageid, data) {
        return http.put(`messages/${messageid}`, data, { headers: authHeader() })
    }

    //Запрос всех записей привязанных к договору
    find(orderid) {
        return http.get(`/messages?orderid=${orderid}`,{ headers: authHeader() });
    }

    delete(messageid) {
        return http.delete(`/messages/${messageid}`,{ headers: authHeader() });
    }

}

export default new MessageTableDataService()