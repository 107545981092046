<template>
    <div>
<!--        Диалог запроса сохранения файла на сервер-->
        <v-dialog
                v-model="saveDialog"
                width="500"
        >
            <v-card>
                <v-card-title class="text-h6 grey lighten-2">
                    Сохранение документа
                </v-card-title>
                <v-card-text class="mt-5">
                    <p>Вы хотите сохранить изменения в CRM системе?</p>
                    При выборе варианта "ДА" документ сохранится в CRM системе.<br>
                    При выборе варианта "НЕТ" в CRM системе останется документ без изменений.
                </v-card-text>
                <v-divider></v-divider>

                <v-card-actions>
                    <v-btn
                            color="primary"
                            depressed
                            @click="saveFileOnServer"
                            class="ml-4"
                    >
                        Да
                    </v-btn>
                    <v-btn
                            depressed
                            @click="closeDialogWithoutSaving"
                            color="primary"
                            text
                    >
                        Нет
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn
                            depressed
                            @click="saveDialog = false"
                            color="primary"
                            text
                    >
                        Отмена
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <!--Затемнение экрана при сохранении документа на сервер-->
        <div class="text-center">
            <v-overlay :value="saveDocumentOverlay">
                <v-sheet color="white" class="pa-10" rounded>
                    <div class="d-flex flex-column align-center mb-8">

<!--                        require('../assets/images/mainmenu/home.svg')-->
                        <v-img
                                :src="`${publicPath}avatars/11.svg`"
                                height="148"
                                width="150"
                        ></v-img>
                    </div>
                        <v-progress-circular
                                indeterminate
                                size="35"
                                color="blue"
                        ></v-progress-circular>
                        <div class="mt-10 grey--text text--darken-2">Идет сохранение документа. Пожалуйста, подождите...</div>

                </v-sheet>
            </v-overlay>
        </div>

        <!--Затемнение экрана - сообщение об успешном сохранении документа-->
        <div class="text-center">
            <v-overlay :value="succsessSaveDocOverlay">
                <v-sheet color="white" class="pa-10" rounded>
                    <v-icon
                            size="50"
                            color="green darken-1"
                            class="mb-6"
                    >
                        mdi-file-document-check-outline
                    </v-icon>
                    <div class="grey--text text--darken-2">Документ успешно сохранен. Теперь можете закрыть вкладку браузера.</div>
                </v-sheet>
            </v-overlay>
        </div>

        <!--Затемнение экрана - сообщение об несохранении документа-->
        <div class="text-center">
            <v-overlay :value="failureSaveDocOverlay">
                <v-sheet color="white" class="pa-10" rounded>
                    <v-icon
                            size="50"
                            color="red darken-1"
                            class="mb-6"
                    >
                        mdi-file-document-alert-outline
                    </v-icon>
                    <div class="grey--text text--darken-2">Документ несохранен. Теперь можете закрыть вкладку браузера.</div>
                </v-sheet>
            </v-overlay>
        </div>



        <v-row>
            <v-col>
                <v-btn
                        depressed
                        color="primary"
                        @click="closeWindow"
                >
                    Закрыть
                </v-btn>
                <v-btn
                        depressed
                        class="ml-4"
                        v-show="fasle"
                >
                    Скопировать ссылку
                </v-btn>
            </v-col>
        </v-row>
        <iframe
                :src="googleDocsLink"
                style="position: absolute; height: 94%; width:99%; border: none"
        >
        </iframe>
    </div>
</template>

<script>
    //import AccountsTable from "@/components/AccountsTable";
    import GoogleDataService from "@/services/GoogleDataService";
    export default {
        name: "Googledocs",
        data () {
            return {
                confirmStayInDirtyForm: true,
                googleDocsLink: null,
                saveDialog: false,
                saveDocumentOverlay: false,
                succsessSaveDocOverlay: false,
                failureSaveDocOverlay: false,
                publicPath: process.env.BASE_URL,
                GoogleDocsLink: process.env.VUE_APP_GOOGLEDOCS,
            }
        },
        methods: {
            confirmLeave() {
                return window.confirm('Do you really want to leave? you have unsaved changes!')
            },
            closeWindow() {
                //window.close()
                this.saveDialog = true
            },

            //Сохраняем файл в CRM системе
            saveFileOnServer() {
                this.saveDialog = false
                this.saveDocumentOverlay = true
                GoogleDataService.downloadFileFromGoogleDrive(this.$route.params.id, this.$route.query.fileNameWithoutExtension, this.$route.query.extension, this.$route.query.section, this.$route.query.value)
                    .then( response => {
                        this.deleteGoogleDocs(this.$route.params.id)
                        this.saveDocumentOverlay = false
                        this.succsessSaveDocOverlay = true
                        console.log(response)
                    })
                    .catch(error => console.log(error));
            },
            //Удаляем документ на GoogleDrive
            //DocId - ID документа
            deleteGoogleDocs(DocId) {
                GoogleDataService.deleteFileFromGoogleDrive(DocId)
                    .then( response => {
                        console.log(response)
                    })
                    .catch(error => console.log(error));
            },

            //Закрываем диалог без сохранения документа на сервер
            closeDialogWithoutSaving() {
                this.saveDialog = false
                this.failureSaveDocOverlay = true
            }
        },

        created() {

            // window.addEventListener('beforeunload', (event) => {
            //     // Cancel the event as stated by the standard.
            //     event.preventDefault();
            //     // Chrome requires returnValue to be set.
            //     event.returnValue = '';
            // })

        },

        mounted() {
            // window.addEventListener('beforeunload', (event) => {
            //     // Cancel the event as stated by the standard.
            //     event.preventDefault();
            //     // Chrome requires returnValue to be set.
            //     event.returnValue = 'Привет мой друг';
            // })

            this.googleDocsLink = this.GoogleDocsLink + this.$route.params.id + "/edit"; //Ссылка на редактирование Документа Google
        },

        // beforeRouteLeave (to, from, next) {
        //     // If the form is dirty and the user did not confirm leave,
        //     // prevent losing unsaved changes by canceling navigation
        //     if (this.confirmStayInDirtyForm){
        //         next(false)
        //     } else {
        //         // Navigate to next view
        //         next()
        //     }
        // },
    }
</script>

<style scoped>

</style>