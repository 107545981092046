import http from "../http-common"
import authHeader from './auth-header';

class RealestateDataService {
    //Создание записи
    create(data) {
        return http.post("/realestates", data,{ headers: authHeader() });
    }

    //Запрос записи
    get(id) {
        return http.get(`/realestates/${id}`,{ headers: authHeader() });
    }

    //Обновление записи
    update(id, data) {
        return http.put(`realestates/${id}`, data,{ headers: authHeader() })
    }

    //Поиск объекта по адресу
    find(searchstring = '', kadnumber = '') {
        return http.get(`/realestates?searchstring=${searchstring}&kadnumber=${kadnumber}`,{ headers: authHeader() });
    }


}

export default new RealestateDataService()