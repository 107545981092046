<template>
    <div>
        <AccountsTable/>
    </div>
</template>

<script>
    import AccountsTable from "@/components/AccountsTable";
    export default {
        name: "Accounts",
        components: {AccountsTable}
    }
</script>

<style scoped>

</style>