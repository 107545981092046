//В этом вуексе механизм работы с экономическим показателями
//(стоимость договора, расходы и т.п)
//Необходимо для передачи этих показетелей между различными компонентами через Вуекс

export default {
    state: {
        showDialog: false,
    },
    actions: {
        showDialog({ commit }, payload) {
            commit('setDialog', payload);
        },
        // hideDialog({ commit }) {
        //     commit('clearAlert');
        // }
    },
    mutations: {
        setDialog(state) {
            state.showDialog = true
        },
        clearAlert(state) {
            state.showDialog = false
        }
    },

    getters: {
        showDialog: state => {
            return state.showDialog
        },
    }
}