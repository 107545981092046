import http from "../http-common"
import authHeader from './auth-header';


class CorrespondenceDataService {
    //Создание записи
    create(data) {
        return http.post("/correspondence", data,{ headers: authHeader() });
    }

    //Запрос записи
    get(id) {
        return http.get(`/correspondence/${id}`,{ headers: authHeader() });
    }

    //Обновление записи
    update(id, data) {
        return http.put(`correspondence/${id}`, data,{ headers: authHeader() })
    }

    //Поиск записей по критерию
    find(numberString, customerString, contentsString, orderString, startDate = '', endDate = '', viewTypeArr='') {
        return http.get(`/correspondence?numberString=${numberString}&customerString=${customerString}&contentsString=${contentsString}&orderString=${orderString}&startDate=${startDate}&endDate=${endDate}&viewTypeArr=${viewTypeArr}`,{ headers: authHeader() });
    }

    //Поиск записей коду договора (для выборки в договоре)
    findByOrderId(orderid) {
        return http.get(`/correspondencebyorderid?orderid=${orderid}`,{ headers: authHeader() });
    }

    //Запрос следующего номера письма (при создании нового письма)
    getNextNumber() {
        return http.get(`/correspondencegetnextnumber`,{ headers: authHeader() });
    }

    //Запрос видов писем (КП, сопроводительное и т.п.)
    getViewtype() {
        return http.get(`/correspondencegetviewtype`,{ headers: authHeader() });
    }
}

export default new CorrespondenceDataService()