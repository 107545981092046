import http from "../http-common"
import authHeader from './auth-header';

class TemplatesDataService {
    //Запрос всех записей
    get(section) {
        return http.get(`/templates?section=${section}`,{ headers: authHeader() });
    }
}

export default new TemplatesDataService()