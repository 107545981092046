import http from "../http-common"
import authHeader from './auth-header';

class AccrualByOrderDataService {
    //Создание записи
    create(data) {
        return http.post("/accrualbyorder", data, { headers: authHeader() });
    }

    //Обновление записи
    // update(id, data) {
    //     return http.put(`credits/${id}`, data, { headers: authHeader() })
    // }

    //Запрос всех записей по номеру договора
    find(orderid) {
        return http.get(`/accrualbyorder?orderid=${orderid}`,{ headers: authHeader() });
    }


    //Удаление
    delete(id) {
        return http.delete(`/accrualbyorder/${id}`,{ headers: authHeader() });
    }





}

export default new AccrualByOrderDataService()