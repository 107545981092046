<template>
  <div>
    <CustomersTable/>
  </div>
</template>

<script>
// @ is an alias to /src
import CustomersTable from '@/components/CustomersTable.vue'

export default {
  name: 'Home',
  components: {
    CustomersTable
  }
}
</script>
