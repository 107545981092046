class BytesFunction {
    //Перевод байтов
    FormatBytes (bytes, decimals = 2) {
        if (bytes === 0) return '0 байт'
        const units = ['байт', 'Кб', 'Мб', 'Гб', 'Тб', 'PB', 'EB', 'ZB', 'YB']
        const k = 1024
        const dm = decimals < 0 ? 0 : decimals
        const i = Math.floor(Math.log(bytes) / Math.log(k))
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + units[i]
    }
}

export default new BytesFunction()