import http from "../http-common"
import authHeader from './auth-header';

class TaskDataService {
    //Создание записи
    create(data) {
        return http.post("/tasks", data, { headers: authHeader() });
    }

    //Запрос записи
    get(id) {
        return http.get(`/tasks/${id}`,{ headers: authHeader() });
    }


    //Запрос/поиск записей
    find(userid, statusArr = '', orderid = null) {
        return http.get(`/tasks?userid=${userid}&status=${statusArr}&orderid=${orderid}`,{ headers: authHeader() });
    }

    //Просроченные задачи
    getOverdue(userid) {
        return http.get(`/tasksoverdue?userid=${userid}`, { headers: authHeader() })
    }

    //Задачи в работе
    getTaskInWork(userid, orderid='') {
        return http.get(`/tasks/inworking?userid=${userid}&orderid=${orderid}`, { headers: authHeader() })
    }

    //Обновление записи
    update(id, data) {
        return http.put(`tasks/${id}`, data, { headers: authHeader() })
    }
}

export default new  TaskDataService()