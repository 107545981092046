<template>
  <div>
        <CorrespondenceTable/>
  </div>
</template>

<script>
// @ is an alias to /src
import CorrespondenceTable from "@/components/CorrespondenceTable";



export default {
  name: 'Correspondence',
  components: {
     CorrespondenceTable
  }
}
</script>
