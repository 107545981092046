<template>
  <div>
      <!--Сообщение об ошибке сохранения в БД-->
      <v-alert
              type="error"
              dismissible
              v-model="alertError"
              transition="scale-transition"
      >
          Невозможно сохранить данные. Возможно отсутствует соединение с базой данных. Обратитесь к Администратору.
      </v-alert>


      <!--Сообщение об успешном копировании данных-->
          <v-snackbar
                  v-model="snackbarCopy"
                  color="green darken-3"
                  top
                  :timeout="1000"
          >
             Скопировано!
              <template v-slot:action="{ attrs }">
                  <v-btn
                          color="grey lighten-2"
                          icon
                          v-bind="attrs"
                          @click="snackbarCopy = false"
                  >
                      <v-icon>mdi-close</v-icon>
                  </v-btn>
              </template>
          </v-snackbar>

      <!--Сообщение об успешном Сохранении данных-->
      <v-snackbar
              v-model="snackbarSave"
              color="green"
              top
              :timeout="1000"
      >
          Данные сохранены
          <template v-slot:action="{ attrs }">
              <v-btn
                      color="grey lighten-2"
                      icon
                      v-bind="attrs"
                      @click="snackbarSave = false"
              >
                  <v-icon>mdi-close</v-icon>
              </v-btn>
          </template>
      </v-snackbar>

      <v-row>
          <v-col
                      cols="auto"
              >
                  <h1 class="data-name" v-show="!numdateInPlace">
                      Предложение № {{ offers.number }} от {{ formatOrderDate(offers.date.date) }}
                      <v-icon
                              @click="editOffernumdate"
                              right
                      >
                          mdi-pencil
                      </v-icon>
                      <v-icon
                              @click="copy(offers.number + ` от ` + formatOrderDate(offers.date.date))"
                              right
                      >
                          mdi-content-copy
                      </v-icon>
                  </h1>
                  <div v-show="numdateInPlace">
                      <v-form
                              ref="numdate"
                              v-model="valid"
                              lazy-validation
                      >
                          <v-text-field
                                  label="Номер предложения"
                                  v-model="editedItem.offernumber"
                                  :rules="requiredRules"
                                  ref="offernumber"
                          ></v-text-field>

                          <vc-date-picker v-model="editedItem.offerdate">
                              <template v-slot="{ inputValue, inputEvents }">
                                  <v-text-field
                                          label="Дата"
                                          v-mask="'##.##.####'"
                                          required
                                          v-on="inputEvents"
                                          :value="inputValue"
                                          readonly
                                          :rules="requiredRules"
                                          ref="offerdate"
                                  ></v-text-field>
                              </template>
                          </vc-date-picker>
                      </v-form>
                      <v-btn
                              color="blue darken-1"
                              text
                              @click="closeNumdateInPlace"
                      >
                          Отмена
                      </v-btn>
                      <v-btn
                              :disabled="!valid"
                              color="blue darken-1"
                              text
                              @click="saveNumdateInPlace"
                      >
                          Сохранить
                      </v-btn>
                  </div>
              </v-col>
          <v-col cols="auto">
              <v-btn-toggle
                      v-model="offers.status"
                      class="ml-5"
                      mandatory
                      @change="update"
              >
                  <v-btn value="1"
                         width="200px"
                         max-height="40px"
                         active-class="isNew"
                  >
                      Рассматривается
                  </v-btn>
                  <v-btn value="2"
                         width="200px"
                         max-height="40px"
                         active-class="isCompleted"
                  >
                      Завершено
                  </v-btn>
                  <v-btn value="3"
                         width="200px"
                         max-height="40px"
                         active-class="isRefuse"
                  >
                      Провалено
                  </v-btn>
              </v-btn-toggle>
          </v-col>
      </v-row>
      <v-row>
              <v-col>
                  <v-card
                          outlined
                          class="mb-4"
                  >
                      <v-card-title>Основное</v-card-title>
                      <v-card-text>

                          <v-row class="mx-0">
                              <div class="data-row">
                                  <div class="data-label">
                                      Цена
                                  </div>
                                  <div
                                          class="data-text"
                                          v-show="!priceInPlace"
                                  >
                                      <span v-if="offers.sum" style="font-size: 25px; color: #ff8300;font-weight: 600;"> {{ new Intl.NumberFormat('ru-RU', { style: 'currency', currency: 'RUB' }).format( offers.sum ) }} </span>
                                      <span class="placeholder" v-else>Введите число</span>
                                      <v-icon
                                              @click="editPriceInPlace"
                                              right
                                              small
                                      >
                                          mdi-pencil
                                      </v-icon>
                                      <v-icon
                                              v-show="offers.sum"
                                              @click="copy(offers.sum)"
                                              right
                                              small
                                      >
                                          mdi-content-copy
                                      </v-icon>
                                  </div>
                                  <div v-show="priceInPlace">
                                      <v-text-field
                                              v-model="editedItem.price"
                                              :rules="externalRules.floatRules.concat(externalRules.requiredRules)"
                                              ref="price"
                                      ></v-text-field>
                                      <v-btn
                                              color="blue darken-1"
                                              text
                                              @click="closePriceInPlace"
                                      >
                                          Отмена
                                      </v-btn>
                                      <v-btn
                                              color="blue darken-1"
                                              text
                                              @click="savePriceInPlace"
                                      >
                                          Сохранить
                                      </v-btn>
                                  </div>
                              </div>
                          </v-row>

<!--                          <v-row class="mx-0">-->
<!--                              <div class="data-row">-->
<!--                                  <div class="data-label">-->
<!--                                      Договор-->
<!--                                  </div>-->
<!--                                  <div class="data-text" v-show="!customerInPlace">-->
<!--                                      <span>  {{ offers.ordernumber }} </span>-->
<!--                                      <v-icon-->
<!--                                              @click="clearCustomer"-->
<!--                                              right-->
<!--                                              small-->
<!--                                      >-->
<!--                                          mdi-close-circle-outline-->
<!--                                      </v-icon>-->
<!--                                      <v-icon-->
<!--                                              @click="goToCustomer"-->
<!--                                              right-->
<!--                                              small-->
<!--                                      >-->
<!--                                          mdi-arrow-top-right-->
<!--                                      </v-icon>-->
<!--                                      <v-icon-->
<!--                                              @click="copy(offers.customerfullname)"-->
<!--                                              right-->
<!--                                              small-->
<!--                                      >-->
<!--                                          mdi-content-copy-->
<!--                                      </v-icon>-->
<!--                                  </div>-->
<!--                                  <div v-show="customerInPlace">-->
<!--                                      <v-autocomplete-->
<!--                                              placeholder="Введите не менее 3-х символов для поиска"-->
<!--                                              clearable-->
<!--                                              return-object-->
<!--                                              v-model="customer"-->
<!--                                              :items="customerItems"-->
<!--                                              :search-input.sync="customerSearch"-->
<!--                                              item-value="customerid"-->
<!--                                              item-text="fullname"-->
<!--                                              @click:clear="clearCustomerSearch"-->
<!--                                              :rules="requiredRules"-->
<!--                                      ></v-autocomplete>-->
<!--                                      <v-btn-->
<!--                                              color="blue darken-1"-->
<!--                                              text-->
<!--                                              @click="closeCustomerInPlace"-->
<!--                                      >-->
<!--                                          Отмена-->
<!--                                      </v-btn>-->
<!--                                      <v-btn-->
<!--                                              color="blue darken-1"-->
<!--                                              text-->
<!--                                              @click="saveCustomerInPlace"-->
<!--                                      >-->
<!--                                          Сохранить-->
<!--                                      </v-btn>-->
<!--                                  </div>-->
<!--                              </div>-->
<!--                          </v-row>-->


                          <v-row class="mx-0">
                              <div class="data-row">
                                  <div class="data-label">
                                      Получатель
                                  </div>
                                  <div class="data-text" v-show="!customerInPlace">
                                      <span>  {{ offers.customerfullname }} </span>
                                      <v-icon
                                              @click="clearCustomer"
                                              right
                                              small
                                      >
                                          mdi-close-circle-outline
                                      </v-icon>
                                      <v-icon
                                              @click="goToCustomer"
                                              right
                                              small
                                      >
                                          mdi-arrow-top-right
                                      </v-icon>
                                      <v-icon
                                              @click="copy(offers.customerfullname)"
                                              right
                                              small
                                      >
                                          mdi-content-copy
                                      </v-icon>
                                  </div>
                                  <div v-show="customerInPlace">
                                      <v-autocomplete
                                              placeholder="Введите не менее 3-х символов для поиска"
                                              clearable
                                              return-object
                                              v-model="customer"
                                              :items="customerItems"
                                              :search-input.sync="customerSearch"
                                              item-value="customerid"
                                              item-text="fullname"
                                              @click:clear="clearCustomerSearch"
                                              :rules="requiredRules"
                                      ></v-autocomplete>
                                      <v-btn
                                              color="blue darken-1"
                                              text
                                              @click="closeCustomerInPlace"
                                      >
                                          Отмена
                                      </v-btn>
                                      <v-btn
                                              color="blue darken-1"
                                              text
                                              @click="saveCustomerInPlace"
                                      >
                                          Сохранить
                                      </v-btn>
                                  </div>
                              </div>
                          </v-row>

                          <v-row class="mx-0">
                              <div class="data-row">
                                  <div class="data-label">
                                      Ответственный
                                  </div>
                                  <div class="data-text">
                                      <span>  {{ offers.employeefullname }} </span>
                                  </div>
                              </div>
                          </v-row>

                          <v-row class="mx-0">
                              <div class="data-row">
                                  <div class="data-label">
                                      Краткое содержание
                                  </div>
                                  <div
                                          class="data-text"
                                          v-show="!contentsInPlace"
                                  >
                                      <span v-if="offers.contents"> {{ offers.contents }} </span>
                                      <span class="placeholder" v-else>Введите текст</span>
                                      <v-icon
                                              @click="editContentsInPlace"
                                              right
                                              small
                                      >
                                          mdi-pencil
                                      </v-icon>
                                      <v-icon
                                              v-show="offers.contents"
                                              @click="copy(offers.contents)"
                                              right
                                              small
                                      >
                                          mdi-content-copy
                                      </v-icon>
                                  </div>
                                  <div v-show="contentsInPlace">
                                      <v-textarea
                                              v-model="editedItem.contents"
                                              ref="contents"
                                      ></v-textarea>
                                      <v-btn
                                              color="blue darken-1"
                                              text
                                              @click="closeContentsInPlace"
                                      >
                                          Отмена
                                      </v-btn>
                                      <v-btn
                                              color="blue darken-1"
                                              text
                                              @click="saveContentsInPlace"
                                      >
                                          Сохранить
                                      </v-btn>
                                  </div>
                              </div>
                          </v-row>
                          <v-row class="mx-0">
                              <div class="data-row">
                                  <div class="data-label">
                                      Примечания
                                  </div>
                                  <div
                                          class="data-text"
                                          v-show="!descriptionInPlace"
                                  >
                                      <span v-if="offers.description"> {{ offers.description }} </span>
                                      <span class="placeholder" v-else>Введите текст</span>
                                      <v-icon
                                              @click="editDescriptionInPlace"
                                              right
                                              small
                                      >
                                          mdi-pencil
                                      </v-icon>
                                      <v-icon
                                              v-show="offers.description"
                                              @click="copy(offers.description)"
                                              right
                                              small
                                      >
                                          mdi-content-copy
                                      </v-icon>
                                  </div>
                                  <div v-show="descriptionInPlace">
                                      <v-textarea
                                              v-model="editedItem.description"
                                              ref="description"
                                      ></v-textarea>
                                      <v-btn
                                              color="blue darken-1"
                                              text
                                              @click="closeDescriptionInPlace"
                                      >
                                          Отмена
                                      </v-btn>
                                      <v-btn
                                              color="blue darken-1"
                                              text
                                              @click="saveDescriptionInPlace"
                                      >
                                          Сохранить
                                      </v-btn>
                                  </div>
                              </div>
                          </v-row>
                      </v-card-text>
                  </v-card>

              </v-col>
              <v-col>

                  <v-card
                          outlined
                          class="mb-4"
                  >
                      <v-card-title>Вложения</v-card-title>
                      <v-card-text>
                        <v-row class="mx-0">
                          <div class="data-row">
                            <OfferFileTable/>
                          </div>

                        </v-row>
                      </v-card-text>
                  </v-card>


              </v-col>
          </v-row>


  </div>
</template>
<script>
    import CustomerDataService from "@/services/CustomerDataService";
    import OfferDataService from "@/services/OfferDataService";
    import * as ExternalRules from "@/rules/ExternalRules";
    import OfferFileTable from "@/components/OfferFileTable";

    function pad(number) {
        if (number < 10) {
            return '0' + number;
        }
        return number;
    }

    export default {
      name: 'EditOffers',
        components: {OfferFileTable},
      data: function() {
            return {
                externalRules: ExternalRules,
                //Редактирование на месте
                numdateInPlace: false,
                customerInPlace: false,
                contentsInPlace: false,
                descriptionInPlace: false,
                priceInPlace: false,

                corrCarrierSelect: null,
                corrCarrierItems: [
                    { label: 'Электронное', value: '1' },
                    { label: 'Бумажное', value: '0' },
                ],

                customerItems: [],
                customer: null,
                customerSearch: null,




                formatAddress: null,
                alertError: false,

                valid: true,

                offers: {}, //Массив объектов предложений

                snackbarCopy: false,
                snackbarSave: false,

                editedItem: {}, //В этой переменной сохраняем объект редактирования

                requiredRules: [
                    v => !!v || 'Пожалуйста, заполните это поле',
                ],
            }
        },
        methods: {
            formatOrderDate: function (date) {
                if (date !== null) {
                    return [date.slice(8, 10), date.slice(5, 7), date.slice(0, 4)].join('.')
                }
            },

            //Редактирование на лету
            editOffernumdate() {
                this.editedItem.offernumber = this.offers.number
                this.editedItem.offerdate = this.offers.date.date
                this.numdateInPlace = true
                this.$nextTick(() => {
                    this.$refs.offernumber.focus();
                })
            },

            saveNumdateInPlace () {
                if (this.$refs.numdate.validate()) {
                    this.offers.number = this.editedItem.offernumber
                    //замена toISOString()
                    this.offers.date.date = this.editedItem.offerdate.getFullYear() + '-' + pad(this.editedItem.offerdate.getMonth() + 1) + '-' + pad(this.editedItem.offerdate.getDate() + 'T00:00:00.000Z')
                    this.closeNumdateInPlace()
                    this.update()
                }
            },

            closeNumdateInPlace() {
                this.numdateInPlace = false
                this.$nextTick(() => {
                    this.editedItem = {}
                })
            },

            editCarrier() {
                this.carrierInPlace = true
                this.corrCarrierSelect = this.offers.carrier
            },


            clearCustomer() {
                this.customerInPlace = true
            },

            clearCustomerSearch() {
                this.customerItems = []
            },

            closeCustomerInPlace() {
                this.customerInPlace = false
                this.customerItems = []
            },

            saveCustomerInPlace() {
                this.offers.customerid =  this.customer.customerid
                this.offers.customerfullname = this.customer.fullname
                this.closeCustomerInPlace()
                this.update()
            },

            editContentsInPlace() {
                this.editedItem.contents = this.offers.contents
                this.contentsInPlace = true
                this.$nextTick(() => {
                    this.$refs.contents.focus();
                })
            },

            closeContentsInPlace() {
                this.contentsInPlace = false
                this.$nextTick(() => {
                    this.editedItem = {}
                })
            },

            saveContentsInPlace () {
                this.offers.contents = this.editedItem.contents
                this.closeContentsInPlace()
                this.update()
            },

            editPriceInPlace() {
                this.editedItem.price = this.offers.sum
                this.priceInPlace = true
                this.$nextTick(() => {
                    this.$refs.contents.focus();
                })
            },
            closePriceInPlace() {
                this.priceInPlace = false
                this.$nextTick(() => {
                    this.editedItem = {}
                })
            },
            savePriceInPlace () {
                this.offers.sum = this.editedItem.price
                this.closePriceInPlace()
                this.update()
            },


            editDescriptionInPlace() {
                this.editedItem.description = this.offers.description
                this.descriptionInPlace = true
                this.$nextTick(() => {
                    this.$refs.description.focus();
                })
            },

            closeDescriptionInPlace() {
                this.descriptionInPlace = false
                this.$nextTick(() => {
                    this.editedItem = {}
                })
            },

            saveDescriptionInPlace() {
                this.offers.description = this.editedItem.description
                this.closeDescriptionInPlace()
                this.update()
            },


            copy (text) {
                this.$copyText(text).then(response => {
                    this.snackbarCopy = true
                    console.log(response)
                })
                .catch(error => {
                    console.log(error);
                })
            },

            goToCustomer() {
                this.$router.push({name: 'EditCustomer', params: { id: this.offers.customerid}})
            },


            get(id) {
                OfferDataService.get(id)
                    .then(response => {
                        this.offers = response.data
                        console.log(response.data);
                    })
                    .catch(error => {
                        console.log(error);
                    })
            },


            update() {
                OfferDataService.update(this.offers.id, this.offers)
                    .then(response => {
                        this.snackbarSave = true
                        console.log(response.data)
                    })
                    .catch(e => {
                        console.log(e)
                        this.alertError = true
                    })
            },
        },
        watch: {
            customerSearch() {
                if (this.customerItems.length > 0) return

                if (this.customerSearch.length > 3) {
                    CustomerDataService.findBySurname(this.customerSearch)
                        .then(response => {
                            this.customerItems = response.data
                            console.log(response.data)
                        })
                        .catch(e => {
                            console.log(e)
                        })
                }
            },
        },
        mounted() {
            this.get(this.$route.params.id); //Код текущего объекта
        },
    }
</script>

<style scoped>

</style>