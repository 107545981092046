<template>
    <v-row>
      <v-col>
        <div class="d-flex">
          <v-card
                  min-width="344"
                  :to="{ name: 'ContractsSum' }"
                  class="mr-6"
                  elevation="0"
          >
            <v-card-title>Отчет по сумме заключенных договоров</v-card-title>
<!--            <v-divider class="mx-4"></v-divider>-->
<!--            <v-card-text style="color: white">-->
<!--              <p style="font-weight: bold; font-size: 35px">{{ getTaskInWork }}</p>-->
<!--              <p style="font-size: 13px">Ожидают выполнения</p>-->
<!--              <p style="font-size: 25px">{{ getTaskOverdue }}</p>-->
<!--              <p style="font-size: 13px">Просрочены</p>-->
<!--            </v-card-text>-->
          </v-card>

          <v-card
                  min-width="344"
                  :to="{ name: 'Profitandloss' }"
                  class="mr-6"
                  elevation="0"
          >
            <v-card-title>Отчет о прибылях и убытках</v-card-title>
            <!--            <v-divider class="mx-4"></v-divider>-->
            <!--            <v-card-text style="color: white">-->
            <!--              <p style="font-weight: bold; font-size: 35px">{{ getTaskInWork }}</p>-->
            <!--              <p style="font-size: 13px">Ожидают выполнения</p>-->
            <!--              <p style="font-size: 25px">{{ getTaskOverdue }}</p>-->
            <!--              <p style="font-size: 13px">Просрочены</p>-->
            <!--            </v-card-text>-->
          </v-card>

          <v-card
                  min-width="344"
                  :to="{ name: 'WorksReport' }"
                  class="mr-6"
                  elevation="0"
          >
            <v-card-title>Отчет по выполненным работам</v-card-title>
          </v-card>

        </div>
      </v-col>
    </v-row>
</template>

<script>

export default {
  name: 'Reports'
}
</script>
