//Банковские счета и кассы

import http from "../http-common"
import authHeader from './auth-header';

class BankaccountDataService {
    //Запрос всех записей
    find() {
        return http.get(`/bankaccounts`,{ headers: authHeader() });
    }
}

export default new BankaccountDataService()