<template>
    <div>
        <v-card
                outlined
        >
            <v-card-title>События</v-card-title>
            <v-card-text>
                <v-simple-table height="300" fixed-header>
                    <template v-slot:default>
                        <thead>
                        <tr>
                            <th class="text-left">
                                Дата
                            </th>
                            <th class="text-left">
                                Время
                            </th>
                            <th class="text-left">
                                Категория
                            </th>
                            <th class="text-left">
                                Текст события
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr
                                v-for="item in events"
                                :key="item.id"
                        >
                            <td>{{ item.date }}</td>
                            <td>{{ item.time }}</td>
                            <td>{{ item.category }}</td>
                            <td>{{ item.message }}</td>
                        </tr>
                        </tbody>
                    </template>
                </v-simple-table>
            </v-card-text>
        </v-card>
    </div>
</template>

<script>
    import EventsDataService from "@/services/EventsDataService";
    export default {
        name: "Event",
        props: ['sourcecode'],
        data () {
            return {
                events: {}
            }
        },
        methods: {
            getEvents(sourcecod,source) {
                EventsDataService.findEvents(sourcecod,source)
                    .then(response => {
                        this.events = response.data
                        console.log(response.data);
                    })
                    .catch(error => {
                        console.log(error);
                    })
            }
        },
        mounted() {
            this.getEvents(this.sourcecode,'CUSTOMER'); //Код текущего клиента
        }

    }
</script>

<style scoped>

</style>