<template>
    <div class="text-right">
        <v-dialog
                v-model="dialog"
                persistent
                width="600"
        >
            <template v-slot:activator="{ on, attrs }">
                <v-btn
                        color="primary"
                        depressed
                        v-bind="attrs"
                        v-on="on"
                >
                    Создать
                </v-btn>
            </template>

            <v-card>
                <v-card-title class="headline">
                    Новое предложение
                    <v-spacer></v-spacer>
                    <v-btn
                            icon
                            @click="dialog = false"
                    >
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-card-title>
                <v-form
                        ref="form"
                        v-model="validForm"
                        lazy-validation
                >
                    <v-card-text>
                    <v-container>

                        <v-row>
                            <v-col
                                    cols="6"
                            >
                                <v-text-field
                                        v-model="offernumber"
                                        label="Номер"
                                        :rules="requiredRules"
                                        required
                                ></v-text-field>
                            </v-col>
                            <v-col
                                    cols="6"
                            >
                                <vc-date-picker v-model="offerdate">
                                    <template v-slot="{ inputValue, inputEvents }">
                                        <v-text-field
                                                label="Дата"
                                                :rules="requiredRules"
                                                v-mask="'##.##.####'"
                                                required
                                                v-on="inputEvents"
                                                :value="inputValue"
                                                readonly
                                        ></v-text-field>
                                    </template>
                                </vc-date-picker>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <v-autocomplete
                                        placeholder="Введите не менее 3-х символов для поиска"
                                        clearable
                                        v-model="customerid"
                                        :items="customerItems"
                                        :search-input.sync="customerSearch"
                                        item-value="customerid"
                                        item-text="fullname"
                                        label="Заказчик"
                                        @click:clear="clearCustomerSearch"
                                        :rules="requiredRules"
                                ></v-autocomplete>
                            </v-col>
                        </v-row>
                        <v-text-field
                                v-model="sum"
                                :rules="externalRules.floatRules"
                                label="Цена"
                        ></v-text-field>
                        <v-row>
                            <v-col>
                                <v-textarea
                                        v-model="contents"
                                        label="Краткое содержание"
                                        rows="2"
                                        :rules="requiredRules"
                                        required
                                ></v-textarea>
                            </v-col>
                        </v-row>

                        <v-row>
                            <v-col>
                                <v-textarea
                                        v-model="description"
                                        label="Примечание"
                                        rows="2"
                                ></v-textarea>
                            </v-col>
                        </v-row>


                        <v-row>
                            <v-col>
                                <v-btn
                                        class="mr-4 mt-4"
                                        color="success"
                                        :loading="loading"
                                        @click="submitForm"
                                >
                                    Создать
                                </v-btn>
                            </v-col>
                        </v-row>

                    </v-container>
                </v-card-text>
                </v-form>

            </v-card>



        </v-dialog>
    </div>
</template>

<script>
    import CustomerDataService from "@/services/CustomerDataService";
    import OfferDataService from "@/services/OfferDataService";
    import * as ExternalRules from "@/rules/ExternalRules";


    // let options = {
    //     year: 'numeric',
    //     month: 'numeric',
    //     day: 'numeric',
    //     timezone: 'UTC'
    // };

    export default {
        name: "AddOfferForm",
        data () {
            return {
                dialog: false,
                validForm: true,
                offernumber: null,
                offerdate: new Date(),
                externalRules: ExternalRules,
                contents: null,
                description: null,
                sum: null,
                customerid: null,
                customerItems: [],
                customerSearch: null,

                requiredRules: [
                    v => !!v || 'Пожалуйста, заполните это поле',
                ],
                dateRules: [
                    v => (!v || /^(((0[1-9]|[12]\d|3[01])\.(0[13578]|1[02])\.((19|[2-9]\d)\d{2}))|((0[1-9]|[12]\d|30)\.(0[13456789]|1[012])\.((19|[2-9]\d)\d{2}))|((0[1-9]|1\d|2[0-8])\.02\.((19|[2-9]\d)\d{2}))|(29\.02\.((1[6-9]|[2-9]\d)(0[48]|[2468][048]|[13579][26])|((16|[2468][048]|[3579][26])00))))$/.test(v)) || 'Пожалуйста, введите дату',
                ],
                loading: false

            }
        },

        watch: {
            customerSearch() {
                if (this.customerItems.length > 0) return

                if (this.customerSearch.length > 3) {
                    CustomerDataService.findBySurname(this.customerSearch)
                        .then(response => {
                            this.customerItems = response.data
                            console.log(response.data)
                        })
                        .catch(e => {
                            console.log(e)
                        })
                }
            },
        },
        methods: {
            submitForm() {
                if (this.$refs.form.validate()) {
                    this.loading = true
                    let data = {
                        number: this.offernumber,
                        date: this.offerdate.toISOString().substr(0, 10),
                        sum: this.sum,
                        customer_code: this.customerid,
                        employee_code: this.$store.state.auth.user.userid, //Код пользователя
                        contents: this.contents,
                        status: 1,
                        description: this.description
                    }


                    OfferDataService.create(data)
                        .then(response => {
                            console.log(response.data)
                            this.$router.push({ name: 'EditOffer', params: { id : response.data.id }})
                        })
                        .catch(error => {
                            console.log(error);
                        })
                }
            },
            clearCustomerSearch() {
                this.customerItems = []
            },
            getNextOfferNumber() {
              OfferDataService.getNextNumber()
                  .then(response => {
                      this.offernumber = response.data
                      console.log(response.data)
                  })
                  .catch(e => {
                      console.log(e)
                  })
            },
        },
        mounted () {
            this.getNextOfferNumber()
        }
    }
</script>

<style scoped>

</style>