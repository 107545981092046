<template>
    <div>
        <v-row>
            <v-col>
                <span style="font-size: 25px; font-weight: 300">Финансовый отчет по заключенным договорам</span>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="2">
                <v-card flat>
                    <v-card-text>
                        <v-form
                                v-model="valid"
                                lazy-validation
                        >
                            <v-date-picker
                                    v-model="picker"
                                    type="month"
                                    color="green lighten-1"
                                    full-width
                                    locale="ru"
                            ></v-date-picker>


                            <v-row>
                                <v-col style="padding-top: 0; padding-bottom: 50px">
                                    <div class="data-label">Статус</div>
                                    <div v-for="item in status" :key="item.val">
                                        <v-checkbox
                                                v-model="statusArr"
                                                :value="item.val"
                                                :label="`${item.text}`"
                                                hide-details="true"
                                        ></v-checkbox>
                                    </div>
                                </v-col>
                            </v-row>

                            <v-row
                                    align="center"
                                    justify="space-around"
                            >
                                <v-btn
                                        depressed
                                        color="primary"
                                        @click="get"
                                        :loading="loading"
                                >
                                    Сформировать
                                </v-btn>
                            </v-row>
                        </v-form>
                    </v-card-text>

                </v-card>
            </v-col>
            <v-col cols="10">
                <v-card flat>
                    <v-card-title>Общая сумма:<span style="padding-left: 10px; color: #1477a8; font-weight: 400; font-size: 26px"> {{ new Intl.NumberFormat('ru-RU', { style: 'decimal'}).format(getContractsSumm()) }}</span></v-card-title>
                    <v-card-text>
                        <p class="text-h6">По направлению деятельности</p>
                        <v-simple-table dense>
                            <template v-slot:default>
                                <thead>
                                <tr>
                                    <th class="text-left" width="350">
                                        Направление деятельности
                                    </th>
                                    <th class="text-left">
                                        Сумма
                                    </th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr
                                        v-for="item in getActivitySumm()"
                                        :key="item"
                                >
                                    <td>{{ item.activiti }}</td>
                                    <td>{{ new Intl.NumberFormat('ru-RU', { style: 'decimal'}).format(item.sum) }}</td>
                                </tr>
                                </tbody>
                            </template>
                        </v-simple-table>

                    </v-card-text>
                    <v-card-text>
                        <p class="text-h6">По видам услуг</p>
                        <v-simple-table dense>
                            <template v-slot:default>
                                <thead>
                                <tr>
                                    <th class="text-left">
                                        Код услуги
                                    </th>
                                    <th class="text-left">
                                        Наименование услуги
                                    </th>
                                    <th class="text-left">
                                        Направление деятельности
                                    </th>
                                    <th class="text-left">
                                        Количество
                                    </th>
                                    <th class="text-left">
                                        Сумма
                                    </th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr
                                        v-for="item in getSummByService()"
                                        :key="item.serviceid"
                                >
                                    <td>{{ item.serviceid }}</td>
                                    <td>{{ item.servicename }}</td>
                                    <td>{{ item.activiti }}</td>
                                    <td>{{ item.servicecount }}</td>
                                    <td>{{ new Intl.NumberFormat('ru-RU', { style: 'decimal'}).format(item.sum) }}</td>
                                </tr>
                                </tbody>
                            </template>
                        </v-simple-table>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>


    </div>
    </template>
<script>
    import OrderDataService from "@/services/OrderDataService";
    export default {
        name: "ContractsSum",
        data () {
            return {
                items: [],
                maxResults: 5000,
                startContractDate: null,
                endContractDate: null,
                activityArr: [],
                picker: new Date().toISOString().substr(0, 7),
                loading: false,
                statusArr: [],
                status: [
                    { text: 'Новый', val: '4' },
                    { text: 'В работе', val: '0' },
                    { text: 'Выполнен', val: '1' },
                    { text: 'Отказ', val: '3' },
                ],
                testArr: []
            }
        },

        mounted() {
            //Запрашиваем данные с сервера
            //this.get()
        },

        methods: {
            get: function () {
                //Обноляем переменные
                this.items = []
                this.activityArr= []
                this.loading = true

                //https://translated.turbopages.org/proxy_u/en-ru.ru.2cfbf13e-62e94e96-05e2a3b1-74722d776562/https/stackoverflow.com/questions/13571700/get-first-and-last-date-of-current-month-with-javascript-or-jquery
                this.startContractDate = new Date(this.picker.substr(0,4), this.picker.substr(5,2)-1, 2).toISOString().substr(0, 10);
                this.endContractDate = new Date(this.picker.substr(0,4), this.picker.substr(5,2), 1).toISOString().substr(0, 10);

                //Запрос договоров со статусом в работе и выполненых
                let params = {
                    startContractDate: this.startContractDate,
                    endContractDate: this.endContractDate,
                    status: this.statusArr,
                    userid: this.$store.state.auth.user.userid,
                    maxResults: this.maxResults
                }
                OrderDataService.find(params)
                    .then(response => {
                        this.items = response.data
                        this.loading = false
                    })
                    .catch(e => {
                        console.log(e)
                    })
            },
            getContractsSumm () {
                let Summ = 0
                this.items.forEach((item) => {
                    item.tablecrossingservicevieworders.forEach((item) => {
                       Summ = Summ + Number(item.servicecount * item.serviceprice)
                    })
                })
                return Summ
            },

            getActivitySumm() {
                //Старый вариант
                // let Arr = [];
                // let Arr2 = {};
                //
                // this.items.forEach((item) => {
                //     let rez = {};
                //     item.tablecrossingservicevieworders.map(obj => {
                //         rez[obj.servicekod.activiti] ? rez[obj.servicekod.activiti] += Number(obj.serviceprice * obj.servicecount) : rez[obj.servicekod.activiti] = Number(obj.serviceprice * obj.servicecount)
                //     })
                //     Arr.push(rez) //Массив сумм по каждому договору по видам деятельности
                // })
                //
                // //Суммируем дубликаты строк
                // Arr.map( curritem => {
                //     for(let key in curritem){
                //         let value = curritem[key];
                //         // Arr2.push(value)
                //             if(Arr2[key] === undefined){
                //                 Arr2[key] = value;
                //             }else{
                //                 Arr2[key] += value;
                //             }
                //     }
                // })
                // this.activityArr =  Arr2
                // return Arr2

                //Новый вариант

                //Формируем единый массив объектов со всеми услугами и суммами за выбранный период
                let serviceArr = [];
                this.items.forEach((item) => {
                    item.tablecrossingservicevieworders.map(obj => {
                        serviceArr.push({activiti:obj.servicekod.activiti, sum:Number(obj.serviceprice * obj.servicecount)})
                    })
                })

                //Объединяем массив объектов с одинаковыми свойствами (activiti) и суммируем стоимость
                let unionServiceArr = serviceArr.reduce((acc, item) => {
                    const pos = acc.findIndex(it => it.activiti === item.activiti);
                    if (pos === -1) acc.push(item)
                    else acc[pos].sum += item.sum;
                    return acc;
                }, [])

                return unionServiceArr.sort((a,b) => a.activiti.localeCompare(b.activiti)) //Сортировка по строкам
            },

            //Возвращает суммы за период по видам услуг
            getSummByService() {
                //Старый вариант
                // let Arr = [];
                // let Arr2 = {};
                //
                //
                // this.items.forEach((item) => {
                //     let rez = {};
                //     item.tablecrossingservicevieworders.map(obj => {
                //         rez[obj.servicekod.servicename] ? rez[obj.servicekod.servicename + `~` + obj.servicekod.activiti] += Number(obj.serviceprice * obj.servicecount) : rez[obj.servicekod.servicename + `~` + obj.servicekod.activiti]  = Number(obj.serviceprice * obj.servicecount)
                //     })
                //     Arr.push(rez) //Массив сумм по каждому договору по видам деятельности
                // })
                //
                //
                // //Суммируем дубликаты строк
                // Arr.map( curritem => {
                //     for(let key in curritem){
                //         let value = curritem[key];
                //         if(Arr2[key] === undefined){
                //             Arr2[key] = value;
                //         }else{
                //             Arr2[key] += value;
                //         }
                //     }
                // })
                // return Arr2

                //Новый вариант

                //Формируем единый массив объектов со всеми услугами и суммами за выбранный период
                let serviceArr = [];
                this.items.forEach((item) => {
                    item.tablecrossingservicevieworders.map(obj => {
                        serviceArr.push({serviceid:obj.servicekod.serviceid, servicename:obj.servicekod.servicename , activiti:obj.servicekod.activiti, servicecount:Number(obj.servicecount) ,sum:Number(obj.serviceprice * obj.servicecount)})
                    })
                })
                //Объединяем массив объектов с одинаковыми свойствами (serviceid) и суммируем стоимость
                let unionServiceArr = serviceArr.reduce((acc, item) => {
                        const pos = acc.findIndex(it => it.serviceid === item.serviceid);
                        if (pos === -1) acc.push(item)
                        else { acc[pos].sum += item.sum; acc[pos].servicecount += item.servicecount}
                        return acc;
                    }, [])

                //return unionServiceArr.sort((a,b) => a.serviceid - b.serviceid) //Сортировка по цифрам  https://www.techiedelight.com/ru/sort-array-of-objects-javascript/
                return unionServiceArr.sort((a,b) => a.activiti.localeCompare(b.activiti)) //Сортировка по строкам
            },
        }
    }

</script>

<style scoped>
    /*Уменьшаем отступ в чекбоксе*/
    .v-input--selection-controls {
        margin-top: 0px;
        padding-top: 4px;
    }
</style>