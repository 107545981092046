import http from "../http-common"
import authHeader from './auth-header';

//Расходы по отгрузке
class DocumentsDataService {


    //Запрос по id
    get(documentid) {
        return http.get(`/document?documentid=${documentid}`,{ headers: authHeader() });
    }


    find(startDocumentDate='',endDocumentDate='', documentCustomer, accounting = -1, activity = -1) {
        return http.get(`/documents?startDocumentDate=${startDocumentDate}&endDocumentDate=${endDocumentDate}&documentCustomer=${documentCustomer}&accounting=${accounting}&activity=${activity}`,{ headers: authHeader() });
    }

    findByOrderid(orderid) {
        return http.get(`/documentsbyorderid?orderid=${orderid}`,{ headers: authHeader() });
    }

    //Обновление записи
    update(id, data) {
        return http.put(`documents/${id}`, data, { headers: authHeader() })
    }

    //Создание записи
    create(data) {
        return http.post("/documents", data, { headers: authHeader() });
    }

    //Запрос следующего номера документа (при создании нового счета)
    getNextNumber() {
        return http.get(`/documentnextnumber`,{ headers: authHeader() });
    }

}

export default new DocumentsDataService()