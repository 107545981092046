<template>
    <v-alert
            :type="alertType"
            dismissible
            v-model="showAlert"
            transition="scale-transition"
            @input="closeAlert"
    >
        {{ alertMessage }}
    </v-alert>
</template>

<script>
    export default {
        name: "Alert",
        data() {
            return {
                //
            }
        },
        computed: {
            showAlert() {
                return this.$store.getters.showAlert;
            },
            alertMessage() {
                return this.$store.getters.alertMessage
            },
            alertType() {
                return this.$store.getters.alertType
            }
        },
        methods: {
            closeAlert() {
                // this.$store.commit('hideAlert');
                this.$store.dispatch('hideAlert');
            }
        }
    }
</script>

<style scoped>

</style>