//Счета на оплату
import AccountDataService from "@/services/AccountDataService";

export default {
    actions: {
        //Количество просроченных счетов
        accountOverdueCount({ commit }) {
            AccountDataService.getOverdue()
                .then( response => {
                    commit('accountOverdue', response.data)
                })
                .catch(error => console.log(error));
        },
        //Количество счетов со статусом - Ожидают оплату
        accountAwaitingPayment({ commit }) {
            let params = {
                // accountnumber: this.accountNumberString,
                // ordernumber: this.contractNumberString,
                // startDate: this.startDate,
                // endDate: this.endDate,
                // startDeadlineDate: this.startDeadlineDate,
                // endDeadlineDate: this.endDeadlineDate,
                status: [0],
                maxResults: 500,
            }
            AccountDataService.find(params)
                .then( response => {
                    commit('accountAwaitingPayment', response.data.length)
                })
                .catch(error => console.log(error));
        },
    },
    mutations: {
        //Установка количества просроченных задач
        accountOverdue (state, count) {
            state.accountOverdueCount = count
        },

        accountAwaitingPayment (state, count) {
            state.accountAwaitingPayment = count
        },
    },
    state: {
        accountOverdueCount: 0, //Количество просроченных счетов
        accountAwaitingPayment: 0 //Количество счетов со статусом - Ожидают оплату
    }
}