<template>
<div>
<!--    <v-dialog-->
<!--            v-model="showDialog"-->
<!--            width="250"-->
<!--    >-->
<!--        <v-card>-->
<!--            <v-card-title class="grey lighten-2">-->
<!--                Выберите результат-->
<!--            </v-card-title>-->
<!--            <v-card-text style="padding-top: 20px" class="d-flex justify-space-between">-->


<!--                        <v-btn-->
<!--                                color="success"-->
<!--                                depressed-->
<!--                                small-->
<!--                                @click="setStatus(1)"-->
<!--                        >-->
<!--                            Выполнен-->
<!--                        </v-btn>-->


<!--                        <v-btn-->
<!--                                color="error"-->
<!--                                depressed-->
<!--                                small-->
<!--                                @click="setStatus(3)"-->
<!--                        >-->
<!--                            Отказ-->
<!--                        </v-btn>-->



<!--            </v-card-text>-->
<!--        </v-card>-->
<!--    </v-dialog>-->


    <div style="margin: 15px 15px;" v-if="taskid == 0">
        <table class="order-stepper">
            <tbody>
            <tr>
                <td style="background-color: #479de9; border-bottom-left-radius: 0px !important;"></td>
                <td style="border-bottom-left-radius: 0px !important;"></td>
            </tr>
            </tbody>
        </table>
        <div class="step-name" >Ожидает выполнения</div>
    </div>

    <div style="margin: 15px 15px;" v-if="taskid == 1">
        <table class="order-stepper">
            <tbody>
            <tr>
                <td style=" background-color: #65b168; border-bottom-left-radius: 0px !important;"></td>
                <td style="background-color: #65b168; border-bottom-left-radius: 0px !important;"></td>
            </tr>
            </tbody>
        </table>
        <div class="step-name">Завершена</div>
    </div>


</div>
</template>

<script>
    // import OrderDataService from "@/services/OrderDataService";

    export default {
        props: ['statusid'],
        name: "TaskStepper",
        data () {
            return {
                taskid: this.statusid,
                // showDialog: false,
            }
        },
        computed: {


        },
        watch: {
            //Использую слежение за входным параметром. Без этого был глюк, показывал не правильные статусы
            // orderstatus: function (val) {
            //     this.statusid = val
            // }
        },
        methods: {
            //Установка нового статуса
            // setStatus(code) {
            //     if (this.$store.state.auth.user.roles[0] ===  'ROLE_SUPER_ADMIN' || this.$store.state.auth.user.roles[0] ===  'ROLE_ADMIN') {
            //         OrderDataService.setOrderStatus(this.orderid, code)
            //             .then(response => {
            //                 this.statusid = code
            //                 console.log(response.data)
            //                 this.showDialog = false //Если открыт диалог, закрываем
            //             })
            //             .catch(e => {
            //                 console.log(e)
            //             })
            //     }
            // },
        }

    }
</script>

<style scoped>
    .order-stepper {
        border-spacing: 0;
        height: 9px;
        width: 100%;
        border: 1px solid #ccc;
        border-collapse: collapse;
        min-width: 130px;
        max-width: 350px;
    }

    .order-stepper td {
        border: 1px solid #ccc;
    }

    .order-stepper td:hover {
        border: 1px solid #bdb9b9;
        cursor: pointer;
    }

    .step-name {
        color: rgb(140, 143, 145);
        font-size: 11px;
        padding: 5px 0 0 0px;
        line-height: 14px !important;
        font-weight: 300;
    }

</style>