<template>
    <div>
        <v-row>
            <v-col>
                <span style="font-size: 25px; font-weight: 300">Счета</span>
            </v-col>
            <v-col class="text-right">
                <v-btn
                        color="primary"
                        depressed
                        @click.stop="showNewAccountDialog = true"
                >
                    Создать
                </v-btn>
                <AddAccountForm v-model="showNewAccountDialog" @update-accounts-table="get"/>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="2">
                <v-card flat>
                    <v-card-text>
                        <v-form
                                v-model="valid"
                                lazy-validation
                        >
                            <v-text-field
                                    label="Номер счета"
                                    placeholder="Введите данные для поиска"
                                    v-model="accountNumberString"
                                    @keyup.enter="get"
                            ></v-text-field>
                            <v-text-field
                                    label="Номер договора"
                                    placeholder="Введите данные для поиска"
                                    v-model="contractNumberString"
                                    @keyup.enter="get"
                            ></v-text-field>
                                <v-row>
                                    <v-col cols="6" style="padding-top: 0; padding-bottom: 0">
                                        <v-text-field
                                                label="Дата счета"
                                                placeholder="Начало"
                                                v-model="startDate"
                                                v-mask="'##.##.####'"
                                                :rules="dateRules"
                                                ref="startDate"
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="6" style="padding-top: 0; padding-bottom: 0">
                                        <v-text-field
                                                placeholder="Окончание"
                                                v-model="endDate"
                                                v-mask="'##.##.####'"
                                                :rules="dateRules"
                                                ref="endDate"
                                        ></v-text-field>
                                    </v-col>
                                </v-row>

                                <v-row>
                                    <v-col cols="6" style="padding-top: 0; padding-bottom: 0">
                                        <v-text-field
                                                label="Срок оплаты"
                                                placeholder="Начало"
                                                v-model="startDeadlineDate"
                                                v-mask="'##.##.####'"
                                                :rules="dateRules"
                                                ref="startDeadlineDate"
                                        ></v-text-field>
                                    </v-col>

                                    <v-col cols="6" style="padding-top: 0; padding-bottom: 0">
                                        <v-text-field
                                                placeholder="Окончание"
                                                v-model="endDeadlineDate"
                                                v-mask="'##.##.####'"
                                                :rules="dateRules"
                                                ref="endDeadlineDate"
                                        ></v-text-field>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col style="padding-top: 0; padding-bottom: 15px">
                                        <div class="data-label">Статус</div>
                                        <div v-for="item in status" :key="item.val">
                                            <v-checkbox
                                                    v-model="statusArr"
                                                    :value="item.val"
                                                    :label="`${item.text}`"
                                                    hide-details="true"
                                            ></v-checkbox>
                                        </div>
                                    </v-col>
                                </v-row>
                                <v-row
                                        align="center"
                                        justify="space-around"
                                        class="mt-5"
                                >
                                    <v-btn
                                            depressed
                                            @click="resetForm"
                                    >
                                        Сбросить
                                    </v-btn>
                                    <v-btn
                                            :disabled="!valid"
                                            depressed
                                            color="primary"
                                            @click="get"
                                    >
                                        Применить
                                    </v-btn>
                                </v-row>
                        </v-form>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col cols="10">


                <v-data-table
                        :headers="computedHeaders"
                        :items="items"
                        :items-per-page="50"
                        :footer-props="{
                             itemsPerPageOptions: [ 50, 100, -1],
                             itemsPerPageAllText: 'Все',
                             itemsPerPageText: 'Строк на странице:'
                        }"
                        :loading="loading"
                        loading-text="Загрузка данных... Пожалуйста подождите"
                        @dblclick:row="toContract"
                        title="Двойной щелчок - Просмотреть"

                >


                    <template v-slot:item.accountnumber="{ item }">
                        <span>
                        <router-link class="itemlink" :to="{ name: 'EditAccount', params: { id: item.accountid }}">
                            {{ item.accountnumber }}
                        </router-link>
                        </span>
                    </template>
                    <template v-slot:item.accountdate.date="{ item }">
                        {{ formatDate(item.accountdate.date) }}
                    </template>
                    <template v-slot:item.contractnumber="{ item }">
                        <router-link class="itemlink" :to="{ name: 'EditContract', params: { id: item.orderid }}">
                            {{ item.contractnumber }}
                        </router-link>
                    </template>
                    <template v-slot:item.deadline.date="{ item }">
                        <div :class="{enddeadline: getDeadlineStatus(item.deadline.date, item.status) === true}">
                            {{ formatDate(item.deadline.date) }}
                        </div>
                    </template>
                    <template v-slot:item.accountsum="{ item }">
                        {{ new Intl.NumberFormat('ru-RU', { style: 'decimal'}).format(item.accountsum) }}
                    </template>
                    <template v-slot:item.status="{ item }">
                        <AccountStepper :status="item.status" :accountid="item.accountid"/>
                    </template>

                    <template slot="body.append"
                    >
                        <tr v-show="$store.state.auth.user.roles[0] == 'ROLE_SUPER_ADMIN'">
                            <th>Всего:</th>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th style="text-align: left">{{ new Intl.NumberFormat('ru-RU', { style: 'decimal'}).format(getAccountsSumm())}}</th>
                            <th></th>
                            <th></th>
                        </tr>
                    </template>
                    <template v-slot:item.customerfullname="{ item }">
                        <router-link class="itemlink" :to="{ name: 'EditCustomer', params: { id: item.customerid }}">
                            {{ item.customerfullname }}
                        </router-link>
                    </template>
                    <template v-slot:item.estatefulladdress="{ item }">
                        <router-link class="itemlink" :to="{ name: 'EditRealestates', params: { id: item.estateid }}">
                            {{ item.estatefulladdress }}
                        </router-link>
                    </template>

                </v-data-table>
            </v-col>
        </v-row>
    </div>
</template>

<script>
    import AccountDataService from "@/services/AccountDataService";
    import AccountStepper from "@/components/elements/AccountStepper";
    import AddAccountForm from "@/components/AddAccountForm";


    export default {
        name: "AccountsTable",
        components: { AddAccountForm, AccountStepper},
        data () {
            return {
                showNewAccountDialog: false,

                //Формат даты для календаря
                modelConfig: {
                    type: 'string',
                    mask: 'YYYY-MM-DD',
                },
                status: [
                    { text: 'Ожидает оплаты', val: '0' },
                    { text: 'Оплачен', val: '1' },
                    { text: 'Не оплачен', val: '2' }
                ],



                userRoles: null,

                valid: true,

                maxResults: 50,

                accountNumberString: '', //Строка поиска - номер счета
                contractNumberString: '', //Строка поиска - номер договора
                startDate: '', //Строка поиска - дата счета начало
                endDate: '', //Строка поиска - дата счета окончание
                startDeadlineDate: '', //Строка поиска - срок оплаты начало
                endDeadlineDate: '', //Строка поиска - срок оплаты окончание
                customerString: '', //Строка поиска - заказчик
                addressString: '', //Строка поиска - адрес
                statusArr: [], //Массив поиска - статусы

                loading: true,
                items: [],
                dateRules: [
                    v => (!v || /^(((0[1-9]|[12]\d|3[01])\.(0[13578]|1[02])\.((19|[2-9]\d)\d{2}))|((0[1-9]|[12]\d|30)\.(0[13456789]|1[012])\.((19|[2-9]\d)\d{2}))|((0[1-9]|1\d|2[0-8])\.02\.((19|[2-9]\d)\d{2}))|(29\.02\.((1[6-9]|[2-9]\d)(0[48]|[2468][048]|[13579][26])|((16|[2468][048]|[3579][26])00))))$/.test(v)) || 'Пожалуйста, введите дату',
                ],
            }
        },
        mounted () {
            //read Localstorage
            if (localStorage.accounts_accountNumberString) {
                this.accountNumberString = localStorage.accounts_accountNumberString
            }
            if (localStorage.accounts_contractNumberString) {
                this.contractNumberString = localStorage.accounts_contractNumberString
            }
            if (localStorage.accounts_startDate) {
                this.startDate = localStorage.accounts_startDate
            }
            if (localStorage.accounts_endDate) {
                this.endDate = localStorage.accounts_endDate
            }
            if (localStorage.accounts_startDeadlineDate) {
                this.startDeadlineDate = localStorage.accounts_startDeadlineDatee
            }
            if (localStorage.accounts_endDeadlineDate) {
                this.endDeadlineDate = localStorage.accounts_endDeadlineDate
            }
            if (localStorage.accounts_status) {
                this.statusArr = JSON.parse(localStorage.getItem('accounts_status'))
            }


            this.userRoles = this.$store.state.auth.user.roles //Роли зарегистрированного пользователя

            //Запрашиваем данные с сервера
            this.get()
        },

        computed: {
            computedHeaders () {
                const header =  [
                    {
                        text: 'Номер',
                        value: 'accountnumber',
                        width: 100
                    },
                    {
                        text: 'Дата',
                        value: 'accountdate.date',
                        width: 130
                    },
                    {
                        text: 'Срок оплаты',
                        value: 'deadline.date',
                        width: 130
                    },
                    {
                        text: 'Статус',
                        value: 'status',
                        width: 150,
                    },
                    {
                        text: 'Договор',
                        value: 'contractnumber',
                        width: 100
                    },
                    {
                        text: 'Сумма',
                        value: 'accountsum',
                        width: 120
                    },
                    {
                        text: 'Клиент',
                        value: 'customerfullname',
                    },
                    {
                        text: 'Адрес',
                        value: 'estatefulladdress',
                    },
                    ]
                return header
            }
        },


        methods: {
            //Возвращаем класс для Row таблицы (item-class)
            //Не использую пока
            // https://stackoverflow.com/questions/62969944/vuetify-data-table-item-class-doesnt-do-anything
            rowClass() {
                const rowClass = 'rowtitle'
                return rowClass;
            },

            formatDate(orderdate) {
                return [orderdate.slice(8,10),orderdate.slice(5,7),orderdate.slice(0,4)].join('.')
            },

            toContract(event, {item}) {
                this.$router.push({name: 'EditContract', params: { id: item.orderid }})
            },

            //Сумма в футере
            getAccountsSumm() {
                let Summ = 0
                this.items.forEach(function (item) {
                    Summ  = Summ + Number(item.accountsum)
                })
                return Summ
            },

            //Отображаем диалог создания Нового счета
            // showNewAccountDialog() {
            //
            // },

            resetForm() {
                this.accountNumberString = ''
                this.contractNumberString = ''
                this.startDate = ''
                this.endDate = ''
                this.startDeadlineDate = ''
                this.endDeadlineDate = ''
                this.statusArr = []
            },
            get: function () {
                //Save Localstorage
                localStorage.accounts_accountNumberString = this.accountNumberString
                localStorage.accounts_contractNumberString = this.contractNumberString
                localStorage.accounts_startDate = this.startDate
                localStorage.accounts_endDate = this.endDate
                localStorage.accounts_startDeadlineDate = this.startDeadlineDate
                localStorage.accounts_endDeadlineDate = this.endDeadlineDate
                localStorage.setItem('accounts_status', JSON.stringify(this.statusArr))

                this.loading = true

                let params = {
                    accountnumber: this.accountNumberString,
                    ordernumber: this.contractNumberString,
                    startDate: this.startDate,
                    endDate: this.endDate,
                    startDeadlineDate: this.startDeadlineDate,
                    endDeadlineDate: this.endDeadlineDate,
                    status: this.statusArr,
                    maxResults: this.maxResults,
                }

                AccountDataService.find(params)
                    .then(response => {
                        this.items = response.data
                        this.loading = false
                    })
                    .catch(e => {
                        console.log(e)
                    })

            },
            // formatDate (date) {
            //     if (!date) return null
            //
            //     const [year, month, day] = date.split('-')
            //     return `${day}.${month}.${year}`
            // },
            formatDateToIso(dateString) {
                if (!dateString) return ''
                return [dateString.slice(6,10),dateString.slice(3,5),dateString.slice(0,2)].join('-')
            },
            getColor (orderstatus) {
                if (orderstatus == 0) return 'blue'
                else if (orderstatus == 1) return 'green'
                else if (orderstatus == 3) return 'red'
            },

            //True - оплата счета просрочена
            getDeadlineStatus(date, status) {
                if (date)
                    if ((new Date(date).setHours(0,0,0,0) < new Date().setHours(0,0,0,0)) && ( status === 0) ) {
                        return true
                    } else {
                        return false
                    }
            },

        }
    }
</script>

<style scoped>
    .reportwarning {
        /*background-color: #fbaeae;*/
        /*padding: 2px 5px 2px 5px;*/
        /*border-radius: 3px;*/
        color: red;
    }

        /*Уменьшаем отступ в чекбоксе*/
    .v-input--selection-controls {
        margin-top: 0px;
        padding-top: 4px;
    }

    .igi {
        color: white;
        background-color: #00b028;
        padding: 1px 3px;
    }
</style>