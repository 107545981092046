import http from "../http-common"
import authHeader from './auth-header';

//Команда проекта
class ProjectTeamDataService {

    //Запрос (поиск) записей
    get(orderid) {
        return http.get(`/projectteam?orderid=${orderid}`, { headers: authHeader() });
    }

    //Удаление
    delete(orderid, employeeid) {
        return http.delete(`/projectteam?orderid=${orderid}&employeeid=${employeeid}`, { headers: authHeader() });
    }

    add(orderid, employeeid) {
        return http.post(`/projectteam?orderid=${orderid}&employeeid=${employeeid}`, null,{ headers: authHeader() });
    }

}

export default new ProjectTeamDataService()