import http from "../http-common"
import authHeader from './auth-header';

class PhonesDataService {
    //Создание записи
    create(id, data) {
        return http.post(`/phones?customerID=${id}`, data,{ headers: authHeader() });
    }

    //Запрос записи
    get(id) {
        return http.get(`/phones?customerID=${id}`,{ headers: authHeader() });
    }

    //Обновление записи
    update(id, data) {
        return http.put(`phones/${id}`, data,{ headers: authHeader() })
    }

    delete(id) {
        return http.delete(`/phones/${id}`,{ headers: authHeader() });
    }

}

export default new PhonesDataService()