import http from "../http-common"
import authHeader from './auth-header';
import QueryString from "qs";

class AccountDataService {
    //Создание записи
    create(data) {
        return http.post("/accounts", data, { headers: authHeader() });
    }

    //Обновление записи
    update(id, data) {
        return http.put(`accounts/${id}`, data, { headers: authHeader() })
    }

    //Запрос счета по id
    get(accountid) {
        return http.get(`/account?accountid=${accountid}`,{ headers: authHeader() });
    }

    find(params) {
        return http.get(`/accounts`, {params: params, paramsSerializer: params => {return QueryString.stringify(params, {arrayFormat: 'comma'})}, headers: authHeader() });
    }

    //Запрос следующего номера счета (при создании нового счета)
    getNextNumber() {
        return http.get(`/accountnextnumber`,{ headers: authHeader() });
    }

    //Удаление
    delete(id) {
        return http.delete(`/accounts/${id}`,{ headers: authHeader() });
    }

    //Печать счета.
    //Отдача на скачивание
    //Type, required - тип докумета. Допустимые значения word, pdf
    printAccount(companyid, accountid, type) {
        return http.get(`/printaccount?companyid=${companyid}&accountid=${accountid}&type=${type}`, { headers: authHeader(), responseType: 'arraybuffer' })
    }

    //Изменение статуса
    setStatus(accountid, status) {
        return http.post(`/accounts/setstatus?accountid=${accountid}&status=${status}`, null, { headers: authHeader() })
    }

    //Количество просроченных счетов
    getOverdue() {
        return http.get(`/accountsoverdue`, { headers: authHeader() })
    }



}

export default new AccountDataService()