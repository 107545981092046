<template>
  <div>
        <OffersTable/>
  </div>
</template>

<script>
// @ is an alias to /src
import OffersTable from "@/components/OffersTable";



export default {
  name: 'Offers',
  components: {
     OffersTable
  }
}
</script>
