//Задачи
import TaskDataService from "@/services/TaskDataService";

export default {
    actions: {
        //Количество просроченных задач
        taskOverdueCount({ commit }, userid) {
            TaskDataService.getOverdue(userid)
                .then( response => {
                    commit('taskOverdue', response.data)
                })
                .catch(error => console.log(error));
        },
        //Установка количества задач в работе
        taskInWorkCount({ commit }, userid) {
            TaskDataService.getTaskInWork(userid)
                .then( response => {
                    commit('taskInWork', response.data)
                })
                .catch(error => console.log(error));
        }
    },
    mutations: {
        //Установка количества просроченных задач
        taskOverdue (state, count) {
            state.taskOverdueCount = count
        },
        //Установка количества задач в работе
        taskInWork (state, count) {
            state.taskInWorkCount = count
        }
    },
    state: {
        taskOverdueCount: 0, //Количество просроченных задач
        taskInWorkCount: 0, //Количество задач в работе
    }
}