import http from "../http-common"
import authHeader from './auth-header';

class WorksDataService {
    //Создание записи
    create(data) {
        return http.post("/works", data, { headers: authHeader() });
    }


    //Обновление записи
    update(id, data) {
        return http.put(`works/${id}`, data, { headers: authHeader() })
    }

    //Запрос всех записей привязанных к договору
    find(orderid) {
        return http.get(`/works?orderid=${orderid}`,{ headers: authHeader() });
    }

    //Запрос всех записей привязанных к договору
    findByDate(startDate, endDate) {
        return http.get(`/worksByDate?startDate=${startDate}&endDate=${endDate}`,{ headers: authHeader() });
    }


    //Запрос всех исполнителей, привязанных к работе
    findEmployees(workid) {
        return http.get(`/workEmployees?workid=${workid}`,{ headers: authHeader() });
    }

    //Удаление записи
    delete(id) {
        return http.delete(`/works/${id}`,{ headers: authHeader() });
    }

    //Создание записи c исполнителем
    addEmployee(data) {
        return http.post("/works/addemployee", data, { headers: authHeader() });
    }

    //Удаление записи с исполнителем
    deleteEmployee(workid, employeeid) {
        return http.delete(`/deleteemployee?workid=${workid}&employeeid=${employeeid}`, { headers: authHeader() });
    }


}

export default new WorksDataService()