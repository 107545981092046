import http from "../http-common"
import authHeader from './auth-header';

class EmailsDataService {
    //Создание записи
    create(id, data) {
        return http.post(`/emails?customerID=${id}`, data,{ headers: authHeader() });
    }

    //Запрос записи
    get(id) {
        return http.get(`/emails?customerID=${id}`,{ headers: authHeader() });
    }

    //Обновление записи
    update(id, data) {
        return http.put(`emails/${id}`, data,{ headers: authHeader() })
    }

    delete(id) {
        return http.delete(`/emails/${id}`,{ headers: authHeader() });
    }

}

export default new EmailsDataService()