import http from "../http-common"
import authHeader from './auth-header';

class CreditDataService {
    //Создание записи
    create(data) {
        return http.post("/credits", data, { headers: authHeader() });
    }

    //Обновление записи
    update(id, data) {
        return http.put(`credits/${id}`, data, { headers: authHeader() })
    }

    //Запрос всех записей
    find(orderid) {
        return http.get(`/credits?orderid=${orderid}`,{ headers: authHeader() });
    }

    //Запрос следующего номера счета (при создании нового счета)
    getNextNumber() {
        return http.get(`/creditnextnumber`,{ headers: authHeader() });
    }

    //Удаление
    delete(id) {
        return http.delete(`/credits/${id}`,{ headers: authHeader() });
    }

    //Печать ПКО
    printCredit(companyid, creditid) {
        return http.get(`/printcredit?companyid=${companyid}&creditid=${creditid}`, { headers: authHeader(), responseType: 'arraybuffer' })
    }




}

export default new CreditDataService()