import http from "../http-common"
import authHeader from './auth-header';

class CustomerDataService {
    //Создание записи
    create(data) {
        return http.post("/customers", data,{ headers: authHeader() });
    }

    //Запрос записи
    get(id) {
        return http.get(`/customers/${id}`,{ headers: authHeader() });
    }

    //Обновление записи
    update(id, data) {
        return http.put(`customers/${id}`, data,{ headers: authHeader() })
    }

    //Поиск клиента по фамилии/наименованию
    findBySurname(searchstring, customerType = '', companyCode = '', inn = '') {
        return http.get(`/customers?searchstring=${searchstring}&customertype=${customerType}&companycode=${companyCode}&inn=${inn}`,{ headers: authHeader() });
    }

    //Изменение пароля клиента
    changePassword(customerid, newpassword) {
        return http.post(`/changepassword?customerid=${customerid}&newpassword=${newpassword}`, null, { headers: authHeader() })
    }

    //Отправка пароля на E-mail
    sendLoginToEmail(customerid, email, password) {
        return http.post(`/sendlogintoemail?customerid=${customerid}&email=${email}&password=${password}`, null, { headers: authHeader() });
    }

    //Отправка логина на телефон (СМС)
    sendLoginToSms(customerid, phone, password) {
        return http.post(`/sendlogintosms?customerid=${customerid}&phone=${phone}&password=${password}`, null, { headers: authHeader() });
    }

}

export default new CustomerDataService()