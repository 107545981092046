<!--Выполнненые работы. Таблица в форме редактирования ЗАКАЗА-->
<template>
    <div>

        <!--      Диалоговое окно добавления/редактирования работы-->
        <v-dialog
                eager
                max-width="800"
                persistent
                v-model="workEditDialog"
        >
            <v-card>
                <v-card-title class="headline">
                    <span v-show="editedIndex === -1">Добавление</span>
                    <span v-show="editedIndex !== -1">Редактирование</span>
                    <v-spacer></v-spacer>
                    <v-btn
                            icon
                            @click="closeDialog"
                    >
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-card-title>
                <v-card-text>
                    <v-container>
                        <v-row>
                            <v-col>
                                <v-form
                                        ref="work"
                                        v-model="valid"
                                        lazy-validation
                                >
                                    <vc-date-picker v-model="date" :model-config="modelConfig">
                                        <template v-slot="{ inputValue, togglePopover }">
                                            <v-text-field
                                                    label="Дата"
                                                    :value="inputValue"
                                                    :rules="externalRules.dateRules.concat(externalRules.requiredRules)"
                                                    @click="togglePopover()"
                                                    v-mask="'##.##.####'"
                                                    clearable
                                                    v-model="date"
                                            />
                                        </template>
                                    </vc-date-picker>
                                    <v-text-field
                                            v-model="startTime"
                                            label="Время начала"
                                            :rules="externalRules.timeRules.concat(externalRules.requiredRules)"
                                            v-mask="'##:##'"
                                    ></v-text-field>
                                    <v-text-field
                                            v-model="endTime"
                                            label="Время окончания"
                                            :rules="externalRules.timeRules.concat(externalRules.requiredRules)"
                                            v-mask="'##:##'"
                                    ></v-text-field>

                                    <div v-if="editedIndex == -1">
                                        <v-row v-for="row in employees" :key="row.employeeid">
                                        <v-col>
                                            <v-autocomplete
                                                    label="Исполнитель"
                                                    v-model="row.employeeid"
                                                    :items="employeesItems"
                                                    item-value="employeesid"
                                                    item-text="fullname"
                                                    clearable
                                            >
                                            </v-autocomplete>
                                        </v-col>
                                        <v-col>
                                            <v-text-field
                                                    v-model="row.description"
                                                    label="Комментарий"
                                                    :append-outer-icon="'mdi-plus'"
                                                    @click:append-outer="addEmployee"
                                            ></v-text-field>
                                        </v-col>
                                    </v-row>
                                    </div>

                                    <v-autocomplete
                                            label="Вид работ"
                                            v-model="serviceid"
                                            :items="serviseViewItems"
                                            :rules="externalRules.requiredRules"
                                            item-value="serviceid"
                                            item-text="servicename"
                                    >
                                        <template v-slot:item="data">
                                            <v-list-item-content>
                                                <v-list-item-title >{{ data.item.servicename }} <span style="color: #1f98ad;">[{{data.item.activiti}}]</span></v-list-item-title>
                                                <v-list-item-subtitle v-html="data.item.servicegroupkod.servicegroupname"></v-list-item-subtitle>
                                            </v-list-item-content>
                                        </template>

                                    </v-autocomplete>


                                    <v-text-field
                                            v-model="volume"
                                            :rules="externalRules.floatRules.concat(externalRules.requiredRules)"
                                            label="Объем вып. работ"
                                    ></v-text-field>
                                    <v-textarea
                                            v-model="description"
                                            label="Примечания"
                                    ></v-textarea>
                                </v-form>
                            </v-col>

                            <v-col v-if="editedIndex !== -1">
                                <h3 class="mt-3">Исполнители</h3>
                                <div v-for="row in works[editedIndex].tableworkscrossingemployees" :key="row.employee.employeesid" class="mt-3 mb-3">
                                    <v-avatar size="50">
                                        <img
                                                :src="`${avatars}${row.employee.avatar}`"
                                        >
                                    </v-avatar>
                                    {{row.employee.fullname}}
                                    <v-icon
                                            title="Удалить"
                                            @click="deleteEmployee(row.employee.employeesid)"
                                            size="20"
                                    >
                                        mdi-trash-can-outline
                                    </v-icon>
                                    <div class="font-italic red--text ml-13" style="padding: 0; margin: 0">{{row.description}}</div>
                                </div>
                                <v-btn
                                        small
                                        depressed
                                        color="primary"
                                        @click="addEmployeer"
                                >
                                    Добавить
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                            color="blue darken-1"
                            text
                            @click="closeDialog"
                    >
                        Отмена
                    </v-btn>
                    <v-btn
                            :disabled="!valid"
                            color="blue darken-1"
                            text
                            @click="saveWork"
                    >
                        Сохранить
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <!--      Диалоговое окно добавления сотрудника в список исполнителей-->
        <v-dialog
                eager
                max-width="500"
                persistent
                v-model="addEmployeeDialog"
        >
            <v-card>
                <v-card-title class="headline">
                    <span>Добавление сотрудника</span>
                    <v-spacer></v-spacer>
                    <v-btn
                            icon
                            @click="closeAddEmployeeDialog"
                    >
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-card-title>
                <v-card-text>
                    <v-container>
                        <v-row>
                            <v-col>
                                <v-form
                                        ref="addEmployeeForm"
                                        v-model="valid"
                                        lazy-validation
                                >
                                    <v-autocomplete
                                            label="Исполнитель"
                                            v-model="addEmployeeid"
                                            :items="employeesItems"
                                            item-value="employeesid"
                                            item-text="fullname"
                                            clearable
                                            :rules="externalRules.requiredRules"
                                    >
                                    </v-autocomplete>
                                    <v-textarea
                                            v-model="addEmployeeDescription"
                                            label="Примечания"
                                    ></v-textarea>
                                </v-form>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                            color="blue darken-1"
                            text
                            @click="closeAddEmployeeDialog"
                    >
                        Отмена
                    </v-btn>
                    <v-btn
                            :disabled="!valid"
                            color="blue darken-1"
                            text
                            @click="saveAddEmployeeDialog"
                    >
                        Добавить
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>





        <v-card
                outlined
                class="mb-4"
        >
            <v-card-title>
                Выполненные работы
                <v-spacer></v-spacer>
                <v-btn
                        icon
                        @click="addWork"
                >
                    <v-icon color="primary">mdi-plus</v-icon>
                </v-btn>
            </v-card-title>
            <v-card-text>

                <div class="text-center">
                    <v-progress-circular
                            indeterminate
                            color="gray"
                            v-show="loadingTable"
                    ></v-progress-circular>
                </div>

                <v-simple-table
                        dense
                        v-show="works.length > 0"
                >
                    <template v-slot:default>
                        <thead>
                        <tr>
                            <th class="text-left">
                                Дата
                            </th>
                            <th class="text-left">
                                Время
                            </th>
                            <th class="text-left">
                                Вид работ
                            </th>
                            <th class="text-left">
                                Объем вып. работ
                            </th>
                            <th class="text-left" width="150px">
                                Исполнители
                            </th>
                            <th class="text-left" >
                                Примечание
                            </th>
                            <th class="text-left" width="30px">
                            <!--Редактировать-->
                            </th>
                            <th class="text-left" width="50px">
                                <!--Удалить-->
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr
                                v-for="(item, index) in works"
                                :key="item.workid"
                        >
                            <td>{{ DateFunction.DateIsoToRUS(item.date.date) }}</td>
                            <td>{{ DateFunction.FormatTime(item.start_time.date) }} - {{ DateFunction.FormatTime(item.end_time.date) }}</td>
                            <td>{{ item.servicename }}</td>
                            <td>{{ new Intl.NumberFormat('ru-RU', { style: 'decimal'}).format(item.volume) }}</td>
                            <td>
                                <div
                                        v-for="value in item.tableworkscrossingemployees"
                                        :key="value.employee.employeesid"
                                        class="mb-1 mt-1"
                                >
                                {{ value.employee.initialsemployees }}

                                    <span class="font-italic red--text">{{ value.description }}</span>
                                </div>
                            </td>
                            <td> <span style="white-space: pre-line">{{ item.description}}</span></td>
                            <td>
                                <v-icon
                                        title="Редактировать"
                                        @click="editWork(index)"
                                        size="20"
                                >
                                    mdi-pencil-outline
                                </v-icon>
                            </td>
                            <td>
                                <v-icon
                                        title="Удалить"
                                        @click="deleteWork(item.workid)"
                                        size="20"
                                >
                                    mdi-trash-can-outline
                                </v-icon>
                            </td>
                        </tr>
                        </tbody>
                    </template>
                </v-simple-table>

                <div
                        class="mb-10 text-center"
                        v-show="works.length <= 0"
                >
                    <div>
                        <v-icon
                                size="40"
                                color="grey lighten-1"
                        >
                            mdi-file-document-remove-outline
                        </v-icon>
                    </div>
                    <div class="mt-3">
                        <span>Нет данных</span>
                    </div>
                </div>
            </v-card-text>
        </v-card>


    </div>
</template>


<script>
    import * as ExternalRules from "@/rules/ExternalRules";
    import WorksDataService from "@/services/WorksDataService";
    import DateFunction from "@/services/library/DateFunction";
    import EmployeeDataService from "@/services/EmployeeDataService";
    import ServiceViewDataService from "@/services/ServiceViewDataService";

    export default {
        name: "WorksTable",
        props: ['company'],
        data () {
            return {
                //Формат даты для календаря
                //Формат даты для календаря
                modelConfig: {
                    type: 'string',
                    // mask: 'YYYY-MM-DD',
                    mask: 'DD.MM.YYYY',
                },
                works: [], //Выполненные работы
                employeesItems: [], //Сотрудники
                serviseViewItems: [], //Виды услуг

                DateFunction: DateFunction,
                workEditDialog: false,
                addEmployeeDialog: false,
                editedIndex: -1,
                valid: true,
                //Переменные для редактирования работы
                date: null,
                startTime: null,
                endTime: null,
                volume: null,
                employees: [
                    { employeeid: '', description: '' }
                ],
                description: null,
                addEmployeeDescription: null,
                avatars: process.env.VUE_APP_AVATARS,

                addEmployeeid: null,
                currentWorkid: null,

                accounts: null,
                accountEditDialog: false,

                accountNumber: null,
                accountDate: null,
                accountSum: null,
                externalRules: ExternalRules,
                loadingTable: true,
                showNewAccountDialog:false,

                serviceid: null,
            }
        },
        methods: {
            addWork() {
                //this.date = new Date().toISOString().substr(0, 10) + ' 00:00:00.000000'
                this.workEditDialog = true

            },
            addEmployeer() {
              this.addEmployeeDialog = true
            },
            editWork(index) {
                this.editedIndex = index
                this.date = DateFunction.DateIsoToRUS(this.works[index].date.date)
                this.startTime = DateFunction.FormatTime(this.works[index].start_time.date)
                this.endTime = DateFunction.FormatTime(this.works[index].end_time.date)
                this.serviceid = this.works[index].serviceid
                this.volume = this.works[index].volume
                this.description = this.works[index].description
                this.currentWorkid = this.works[index].workid //Сохраняем значение id редактируемой задачи
                this.workEditDialog = true
            },
            addEmployee: function () {
                this.employees.push({employeeid: '', description: ''});
            },

            //Создание новой работы или сохранение редактируемой
            saveWork() {
                if (this.$refs.work.validate()) {
                    if (this.editedIndex > -1) { //Обновление
                        this.works[this.editedIndex].date = this.date
                        this.works[this.editedIndex].startTime = this.startTime
                        this.works[this.editedIndex].endTime = this.endTime
                        this.works[this.editedIndex].serviceid = this.serviceid
                        this.works[this.editedIndex].volume = this.volume
                        this.works[this.editedIndex].description = this.description
                        this.updateWork(this.editedIndex)
                    } else { //Создание новой
                        let data = {
                            orderid: this.$route.params.id,
                            serviceid: this.serviceid,
                            date: this.date,
                            starttime: this.startTime,
                            endtime: this.endTime,
                            volume: this.volume,
                            description: this.description,
                            employees: this.employees,
                        }
                        this.createWork(data)
                    }
                    this.closeDialog()
                }
            },

            saveAddEmployeeDialog() {
                if (this.$refs.addEmployeeForm.validate()) {
                        let data = {
                            workkod: this.currentWorkid,
                            employeekod:  this.addEmployeeid,
                            description: this.addEmployeeDescription,
                        }
                    WorksDataService.addEmployee(data)
                        .then(response => {
                            console.log(response.data)
                            this.getWorks(this.$route.params.id)
                        })
                        .catch(e => {
                            console.log(e)
                            // this.alertError = true
                        })
                    }
                    this.closeAddEmployeeDialog()
                },


            updateWork(item) {
                WorksDataService.update(this.works[item].workid, this.works[item])
                    .then(response => {
                        console.log(response.data)
                        this.getWorks(this.$route.params.id)
                    })
                    .catch(e => {
                        console.log(e)
                        // this.alertError = true
                    })
            },

            createWork(data) {
                WorksDataService.create(data)
                    .then(response => {
                        console.log(response.data)
                        this.getWorks(this.$route.params.id)
                    })
                    .catch(e => {
                        console.log(e)
                        // this.alertError = true
                    })
            },

            deleteWork(id) {
                WorksDataService.delete(id)
                    .then(response => {
                        console.log(response.data)
                        this.getWorks(this.$route.params.id)
                    })
                    .catch(e => {
                        console.log(e)
                        // this.alertError = true
                    })
            },

            deleteEmployee(employeeid) {
                WorksDataService.deleteEmployee(this.currentWorkid, employeeid)
                    .then(response => {
                        console.log(response.data)
                        this.getWorks(this.$route.params.id); //Код текущего договора
                    })
                    .catch(e => {
                        console.log(e)
                        // this.alertError = true
                    })
            },

            //Закрытие договора редактирования работы
            closeDialog() {
                this.workEditDialog = false
                this.$nextTick(() => {
                    this.date = null
                    this.startTime = null
                    this.endTime = null
                    this.volume = null
                    this.description = null
                    this.valid = true
                    this.employees = [ { employeeid: '', description: '' }]
                    this.currentWorkid = null
                    this.editedIndex = -1
                })
                this.getWorks(this.$route.params.id); //Код текущего договора
            },

            closeAddEmployeeDialog() {
                this.addEmployeeDialog = false
                this.$nextTick(() => {
                    this.addEmployeeDescription = null
                    this.addEmployeeid = null
                    this.valid = true
                })
            },

            //Запрос видов услуг
            getServiceView() {
                ServiceViewDataService.find()
                    .then(response => {
                        this.serviseViewItems = response.data
                    })
                    .catch(e => {
                        console.log(e)
                    })
            },

            //Запрос всех сотрудников для выбора в списке
            getEmployees() {
                EmployeeDataService.find()
                    .then(response => {
                        this.employeesItems = response.data
                    })
                    .catch(e => {
                        console.log(e)
                    })
            },

            //Запрос выполненных работ по договору
            getWorks(orderid) {
                WorksDataService.find(orderid)
                    .then( response => {
                        this.works = response.data
                        this.loadingTable = false
                    })
                    .catch(error => console.log(error));
            },

        },

        mounted () {
            this.getWorks(this.$route.params.id); //Запрос выполненных работ по договору
            this.getEmployees() //Запрос всех сотрудников для выбора в списке
            this.getServiceView() //Запрос видов услуг
        },
    }
</script>

<style scoped>

</style>