<template >
    <div class="t-container">
<!--        Выводим с помощью v-html, чтобы отобразить теги -->
        <span style="margin-right: 15px;">
            <v-avatar
                    v-if="noticeobj.creatoravatar"
            >
                <v-img
                        :src="`${avatars}${noticeobj.creatoravatar}`"

                ></v-img>
            </v-avatar>
            <v-avatar
                    v-if="!noticeobj.creatoravatar"
                    style="background-color: #e9eae8"
            >
<!--                <v-icon-->
<!--                   dark-->
<!--                >-->
<!--                    mdi-bell-->
<!--                </v-icon>-->
                <v-img
                        :src="`${avatars}hue_1.png`"
                ></v-img>
            </v-avatar>
        </span>
<!--        Сообщение-->
        <span class="noticestyle">
            <p v-if="noticeobj.creatorname">{{noticeobj.creatorname}} {{noticeobj.creatorsurname}} <span>{{noticeobj.noticedate.date | formatDateISOToRUS}} в {{noticeobj.noticetime.date | formatTimeISOToRUS}} </span></p>
            <p v-else>Кот Хью <span>{{noticeobj.noticedate.date | formatDateISOToRUS}} в {{noticeobj.noticetime.date | formatTimeISOToRUS}}</span></p>
            <span  v-html="noticeobj.notice"></span>
        </span>
<!--        Скрыть сообщение-->
        <v-btn
                dark
                icon
                style="position: absolute;right: 0;top: 0; padding: 0;margin: 0;"
                @click.stop="hideNotice(noticeobj.noticeid)"
        >
            <v-icon
                    size="20"
                    color="rgb(202, 202, 202)"
            >
                mdi-close
            </v-icon>
        </v-btn>
        <!--        Удалить сообщение-->
        <v-btn
                dark
                icon
                style="position: absolute; right: 0;top: 30px; padding: 0;margin: 0;"
                @click.stop="deleteNotice(noticeobj.noticeid)"
        >
            <v-icon
                    size="20"
                    color="rgb(202, 202, 202)"
            >
                mdi-trash-can-outline
            </v-icon>
        </v-btn>
    </div>
</template>
<script>
    import NoticeDataService from "@/services/NoticeDataService";

    export default {
        name: "Notice",
        props: ["noticeobj"],
        data () {
            return {
                publicPath: process.env.BASE_URL,
                avatars: process.env.VUE_APP_AVATARS
            }
        },
        methods: {
            deleteNotice(noticeid) {
                NoticeDataService.delete(noticeid)
                    .then(response => {
                        this.$emit("deletenotice");
                        this.$emit('close-toast');
                        console.log(response);
                    })
                    .catch(error => {
                        console.log(error);
                    })
            },
            hideNotice(noticeid) {
                NoticeDataService.hide(noticeid)
                    .then(response => {
                        this.$emit('close-toast');
                        console.log(response);
                    })
                    .catch(error => {
                        console.log(error);
                    })
            },

        },
        filters: {
            formatDateISOToRUS(date) {
                return date.split(' ')[0].split("-").reverse().join(".") //Преобразуем ISO дату в дату rus формата DD.MM.YYYY
            },
            formatTimeISOToRUS(time) {
                return time.slice(10,16) //Преобразуем ISO время в дату rus формата hh:mm
            }
        },
    };
</script>
<style>
    .t-container {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .action {
        /*height: 40px;*/
        padding: 5px 10px;
        border-radius: 5px;
        color: white;
        cursor: pointer;
        border: thin solid currentColor;
        font-size: 13px;
    }
    .action:hover {
        color: #bfd5dd;
    }
    .noticestyle {
        margin-right: 20px;
        color: white !important;
        font-size: 13px;
        text-decoration: none;
    }

    .noticestyle p {
        color: #ffce3c;
        font-size: 15px;
        padding-bottom: 5px;
    }

    .noticestyle p span {
        color: #dcdcdc;
        font-size: 11px;
    }

    .noticestyle a {
        color: #92c1ff;
    }
    .noticestyle a:hover {
        color: #538eff;
    }
    /*Перезаписываем свойство*/
    .Vue-Toastification__toast--default {
        background-color: #3f4c6b !important;
        border-radius: 0 !important;
    }
</style>