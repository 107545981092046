<!--Бухгалтерские счета на оплату-->
<template>
    <div>
        <v-card
                outlined
                class="mb-4"
        >
            <v-card-title>
                Акты выполненных работ
                <v-spacer></v-spacer>
                <v-btn
                        icon
                        @click="addAct"
                >
                    <v-icon color="primary">mdi-plus</v-icon>
                </v-btn>
            </v-card-title>
            <v-card-text>

        <!--      Диалоговое окно редактирования акта-->
        <v-dialog
                eager
                max-width="500"
                persistent
                v-model="actEditDialog"
        >
            <v-card>
                <v-card-title class="headline">
                    <span v-show="editedIndex === -1">Добавление</span>
                    <span v-show="editedIndex !== -1">Редактирование</span>
                    <v-spacer></v-spacer>
                    <v-btn
                            icon
                            @click="closeDialog"
                    >
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-card-title>
                <v-card-text>
                    <v-container>
                        <v-row>
                            <v-col>
                                <v-form
                                        ref="act"
                                        v-model="valid"
                                        lazy-validation
                                >
                                    <v-switch
                                            v-if="editedIndex !== -1"
                                            v-model="isRegistered"
                                            inset
                                            flat
                                            label="Зарегистрирован"
                                    ></v-switch>
                                    <v-text-field
                                            label="Номер"
                                            v-model="actNumber"
                                            :rules="externalRules.integerRules.concat(externalRules.requiredRules)"
                                    ></v-text-field>
                                    <vc-date-picker v-model="actDate" :model-config="modelConfig">
                                        <template v-slot="{ inputValue, inputEvents }">
                                            <v-text-field
                                                    required
                                                    v-on="inputEvents"
                                                    :value="inputValue"
                                                    readonly
                                                    :rules="externalRules.dateRules.concat(externalRules.requiredRules)"
                                            ></v-text-field>
                                        </template>
                                    </vc-date-picker>
                                    <div style="margin-bottom: 200px"></div>
                                </v-form>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                            color="blue darken-1"
                            text
                            @click="closeDialog"
                    >
                        Отмена
                    </v-btn>
                    <v-btn
                            :disabled="!valid"
                            color="blue darken-1"
                            text
                            @click="saveAct(false)"
                    >
                        Сохранить
                    </v-btn>
                    <v-btn
                            :disabled="!valid"
                            v-if="editedIndex === -1"
                            color="primary"
                            @click="saveAct(true)"
                            depressed
                    >
                        Зарегистрировать
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

                <!--      Диалоговое окно редактирования номенклатуры акта-->
                <v-dialog
                        eager
                        max-width="1100"
                        persistent
                        v-model="nomenclatureEditDialog"
                >
                    <v-card>
                        <v-card-title class="headline">
                            Работы и услуги
                            <v-spacer></v-spacer>
                            <v-btn
                                    icon
                                    @click="closeNomenclatureDialog"
                            >
                                <v-icon>mdi-close</v-icon>
                            </v-btn>
                        </v-card-title>
                        <v-card-text>
                            <v-container>
                                <v-row>
                                    <v-col>
                                        <v-simple-table dense>
                                            <template v-slot:default>
                                                <thead>
                                                <tr>
                                                    <th class="text-left">
                                                        Номенклатура
                                                    </th>
                                                    <th class="text-left">
                                                        Ед.изм.
                                                    </th>
                                                    <th class="text-left" >
                                                        Кол.
                                                    </th>
                                                    <th class="text-left">
                                                        Цена
                                                    </th>
                                                    <th class="text-left">
                                                        Сумма
                                                    </th>
                                                    <th class="text-left">
                                                        <!--                        Удалить-->
                                                    </th>
                                                </tr>
                                                </thead>
                                                <tfoot>
                                                <tr>
                                                    <td class="summ" colspan="2">Итого:</td>
                                                    <td></td>
                                                    <td></td>
                                                    <td style="padding: 3px;"></td>
                                                    <td style="padding: 3px;" class="summ">{{ getNomenclatureSumm().toFixed(2) }}</td>
                                                    <td></td>
                                                </tr>
                                                </tfoot>
                                                <tbody>
                                                <tr
                                                        v-for="(item, index) in nomenclature"
                                                        :key="item.serviceviewkod.serviceid"
                                                >
                                                    <td>{{ item.serviceviewkod.servicename}} <span style="color: #1f98ad">[{{item.serviceviewkod.activiti}}]</span></td>
                                                    <td>{{ item.serviceviewkod.servicemeasurekod.measureshortname }}</td>
                                                    <td>
                                                        <!--Количество-->
                                                        <span
                                                                class="editvalue"
                                                                @click="editCountValue(index)"
                                                                v-show="!item.isCountEditing"
                                                        >
                                                            {{ item.servicecount }}
                                                        </span>
                                                        <input
                                                                type="text"
                                                                style="width: 20px;"
                                                                class="inputinfocus"
                                                                @keypress="isLetterOrNumber($event)"
                                                                @keypress.enter="$event.target.blur()"
                                                                v-show="item.isCountEditing"
                                                                v-model="editedItem.servicecount"
                                                                ref="count_edit_input"
                                                                @blur="saveCountValue(item)"
                                                        >
                                                    </td>
                                                    <td>
                                                        <!--Стоимость-->
                                                        <span
                                                                class="editvalue"
                                                                @click="editPriceValue(index)"
                                                                v-show="!item.isPriceEditing"
                                                        >
                                                            {{ item.serviceprice }}
                                                        </span>
                                                        <input
                                                                type="text"
                                                                style="width: 50px;"
                                                                class="inputinfocus"
                                                                @keypress="onlyForCurrency"
                                                                @keypress.enter="$event.target.blur()"
                                                                v-show="item.isPriceEditing"
                                                                v-model="editedItem.serviceprice"
                                                                ref="price_edit_input"
                                                                @blur="savePriceValue(item)"
                                                        >
                                                    </td>
                                                    <!--Сумма-->
                                                    <td>{{ (item.serviceprice * item.servicecount).toFixed(2) }}</td>
                                                    <td>
                                                        <v-icon
                                                                title="Удалить"
                                                                @click="deleteNomenclature(item.serviceviewkod.serviceid)"
                                                                size="20"
                                                        >
                                                            mdi-trash-can-outline
                                                        </v-icon>
                                                    </td>
                                                </tr>
                                                </tbody>
                                            </template>
                                        </v-simple-table>
<!--                                        <v-autocomplete-->
<!--                                                label="Добавить услугу или материал"-->
<!--                                                v-model="serviseid"-->
<!--                                                :items="serviseViewItems"-->
<!--                                                item-value="serviceid"-->
<!--                                                item-text="servicename"-->
<!--                                                @change="addServiceToNomenclature"-->
<!--                                                clearable-->
<!--                                        >-->
<!--                                            <template v-slot:item="data">-->
<!--                                                &lt;!&ndash;                <v-list-item-content v-text="data.item.servicename"></v-list-item-content>&ndash;&gt;-->
<!--                                                <v-list-item-content>-->
<!--                                                    <v-list-item-title >{{ data.item.servicename }} <span style="color: #1f98ad;">[{{data.item.activiti}}]</span></v-list-item-title>-->
<!--                                                    <v-list-item-subtitle v-html="data.item.servicegroupkod.servicegroupname"></v-list-item-subtitle>-->
<!--                                                </v-list-item-content>-->
<!--                                            </template>-->
<!--                                        </v-autocomplete>-->
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card-text>
                        <v-divider></v-divider>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                                    color="blue darken-1"
                                    text
                                    @click="addNomenclatureByBill"
                            >
                                Заполнить
                            </v-btn>
                            <v-btn
                                    color="blue darken-1"
                                    text
                                    @click="closeNomenclatureDialog"
                            >
                                Закрыть
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>

        <div class="text-center">
            <v-progress-circular
                    indeterminate
                    color="gray"
                    v-show="loadingTable"
            ></v-progress-circular>
        </div>

        <v-simple-table dense>
            <template v-slot:default>
                <thead>
                <tr>
                    <th class="text-left" width="30px">
                   <!--иконка-->
                    </th>
                    <th class="text-left">
                        Номер
                    </th>
                    <th class="text-left">
                        Дата
                    </th>
                    <th class="text-left" >
                        Сумма
                    </th>
                    <th class="text-left" width="30px">
                    <!--Редактировать-->
                    </th>
                    <th class="text-left" width="30px">
                        <!--Список актов-->
                    </th>
                    <th class="text-left" width="30px">
                        <!--Печать-->
                    </th>
                    <th class="text-left" width="50px">
                        <!--Удалить-->
                    </th>
                </tr>
                </thead>
                <tfoot>
                    <tr>
                        <td colspan="2">Итого: <span style="background-color:  rgb(255, 90, 90);padding: 5px 5px;border-radius: 4px;margin-left: 10px;color: white;">
                            {{ new Intl.NumberFormat('ru-RU', { style: 'decimal' }).format(getActsSumm()) }}
                        </span>
                        </td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>
                </tfoot>
                <tbody>
                <tr
                        v-for="(item, index) in actitems"
                        :key="item.actid"
                >
                    <td>
                        <v-icon
                            size="20"
                            color="green darken-2"
                            v-if="item.isregistered"
                            title="Документ зарегистрирован"
                        >
                            mdi-file-document-check-outline
                        </v-icon>
                        <v-icon
                                size="20"
                                color="red"
                                v-if="!item.isregistered"
                                title="Документ не зарегистрирован"
                        >
                            mdi-file-document-alert-outline
                        </v-icon>
                    </td>
                    <td>{{ item.actnumber }}</td>
                    <td>{{ item.actdate.date | formatDate}}</td>
                    <td>{{  new Intl.NumberFormat('ru-RU', { style: 'decimal',  minimumFractionDigits: 0, useGrouping:true }).format(getActSum(item.tablecrossingserviceviewact)) }}</td>

                    <td>
                        <v-icon
                                title="Редактировать"
                                @click="editAct(index)"
                                size="20"
                        >
                            mdi-pencil-outline
                        </v-icon>
                    </td>
                    <td>
                        <v-icon
                                title="Номенклатура"
                                @click="editNomenclature(item.actid)"
                                size="20"
                        >
                            mdi-format-list-checkbox
                        </v-icon>
                    </td>
                    <td>
<!--                        <v-icon-->
<!--                                title="Печать"-->
<!--                                size="20"-->
<!--                                @click="printAct(item.actid)"-->
<!--                        >-->
<!--                            mdi-printer-outline-->

<!--                        </v-icon>-->
                        <v-menu
                                open-on-hover
                                offset-y
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-progress-circular
                                        indeterminate
                                        :width="2"
                                        :size="20"
                                        color="primary"
                                        v-show="item.showLoadingFileProgress"
                                ></v-progress-circular>
                                <v-icon
                                        title="Печать"
                                        size="20"
                                        v-show="!item.showLoadingFileProgress"
                                        v-bind="attrs"
                                        v-on="on"
                                >
                                    mdi-printer-outline
                                </v-icon>
                            </template>
                            <v-list dense light>
                                <v-list-item
                                        v-for="(templateitem, i) in templates"
                                        :key="i"
                                        @click="createDocumentFromTemplate(templateitem.id, item.actid)"
                                >
                                    <v-list-item-title>{{ templateitem.name }}</v-list-item-title>
                                </v-list-item>
                            </v-list>
                        </v-menu>

                    </td>
                    <td>
                        <v-icon
                                title="Удалить"
                                @click="deleteAct(item.actid)"
                                size="20"
                        >
                            mdi-trash-can-outline
                        </v-icon>
                    </td>
                </tr>
                </tbody>
            </template>
        </v-simple-table>


            </v-card-text>
        </v-card>


    </div>
</template>


<script>
    import ActDataService from "@/services/ActDataService";
    import TemplatesDataService from "@/services/TemplatesDataService";
    import PrintDataService from "@/services/PrintDataService";
    import * as ExternalRules from "@/rules/ExternalRules";

    export default {
        name: "ActsTable",
        props: ['company'],
        data () {
            return {
                //Формат даты для календаря
                modelConfig: {
                    type: 'string',
                    mask: 'YYYY-MM-DD 00:00:00.000000',
                },
                actitems: null,
                actEditDialog: false,
                nomenclatureEditDialog: false,
                valid: true,
                editedIndex: -1,
                actNumber: null,
                actDate: null,
                isRegistered: null,
                externalRules: ExternalRules,
                loadingTable: true,
                nomenclature: null,
                aktkod: null,
                editedItem: {}, //В этой переменной сохраняем объект редактирования
                templates: null, //Document templates
            }
        },
        methods: {
            addNomenclatureByBill() {
                ActDataService.addNomenclatureByBill(this.aktkod, this.$route.params.id)
                    .then(response => {
                        //this.nomenclature = response.data
                        this.getNomenclature(this.aktkod)
                        console.log(response)
                    })
                    .catch(e => {
                        console.log(e)
                    })
            },
            deleteNomenclature(serviceviewkod) {
                ActDataService.deleteNomenclature(serviceviewkod, this.aktkod)
                    .then(response => {
                        this.getNomenclature(this.aktkod)
                        console.log(response)
                    })
                    .catch(e => {
                        console.log(e)
                    })
            },
            addAct() {
                this.getNextActNumber()
                this.actDate = new Date().toISOString().substr(0, 10) + ' 00:00:00.000000'
                this.actEditDialog = true

            },
            editAct(index) {
                this.editedIndex = index
                this.actNumber = this.actitems[index].actnumber
                this.actDate = this.actitems[index].actdate.date
                this.isRegistered = this.actitems[index].isregistered
                this.actEditDialog = true
            },
            editNomenclature(actkod) {
                this.nomenclatureEditDialog = true
                this.aktkod = actkod
                this.getNomenclature(actkod)

            },
            getNomenclature(actkod) {
                ActDataService.getNomenclature(actkod)
                    .then(response => {
                        this.nomenclature = response.data
                        //Добавляем в массив оьбъектов свойства, необходимые для реализации редактирования значений таблицы сметы на лету
                        let index;
                        for (index = 0; index < this.nomenclature.length; ++index) {
                            this.$set(this.nomenclature[index],'isCountEditing',false) //Количество
                            this.$set(this.nomenclature[index],'isPriceEditing',false) //Цена
                        }
                        console.log(response.data)
                    })
                    .catch(e => {
                        console.log(e)
                    })
            },
            getNextActNumber() {
                ActDataService.getNextNumber()
                    .then(response => {
                        this.actNumber = response.data
                        console.log(response.data)
                    })
                    .catch(e => {
                        console.log(e)
                    })
            },
            closeDialog() {
                this.actEditDialog = false
                this.$nextTick(() => {
                    this.actNumber = null
                    this.actDate = null
                    this.editedIndex = -1
                })
            },

            closeNomenclatureDialog () {
                this.nomenclatureEditDialog = false
                this.aktkod = null
                this.getActs(this.$route.params.id)
            },

            getNomenclatureSumm() {
                let summ = 0
                this.nomenclature.forEach( function (item) {
                    summ = summ + Number(item.serviceprice * item.servicecount)
                })
                return summ
            },

            //Редактирование количества
            editCountValue(index) {
                this.editedItem.servicecount = this.nomenclature[index].servicecount
                this.nomenclature[index].isCountEditing = true
                this.$nextTick(() => {
                    // this.$refs.itemedit[index].focus();
                    this.$refs.count_edit_input[index].select();
                })
            },

            //Редактирование цены
            editPriceValue (index) {
                this.editedItem.serviceprice = this.nomenclature[index].serviceprice
                this.nomenclature[index].isPriceEditing = true
                this.$nextTick(() => {
                    this.$refs.price_edit_input[index].select();
                })
            },

            isLetterOrNumber(e) {
                let char = String.fromCharCode(e.keyCode);
                if (/^(?:\d+(?:\.\d+)?)?$/.test(char)) return true;
                else e.preventDefault();
            },
            onlyForCurrency ($event) {
                // console.log($event.keyCode); //keyCodes value
                let keyCode = ($event.keyCode ? $event.keyCode : $event.which);

                // only allow number and one dot
                if ((keyCode < 48 || keyCode > 57) && (keyCode !== 46 || this.price.indexOf('.') != -1)) { // 46 is dot
                    $event.preventDefault();
                }

                // restrict to 2 decimal places
                if(this.price!=null && this.price.indexOf(".")>-1 && (this.price.split('.')[1].length > 1)){
                    $event.preventDefault();
                }
            },

            saveCountValue(item) {
                if (this.editedItem.servicecount < 1) {this.editedItem.servicecount = 1} //Если количество меньше 1 , присваеваем 1

                item.servicecount  = this.editedItem.servicecount
                item.isCountEditing = false

                this.editedItem = {}

                ActDataService.updateNomenclature(this.aktkod, item.serviceviewkod.serviceid, item.servicecount, item.serviceprice)
                    .then( response => {
                        console.log(response)
                        this.getNomenclature(this.aktkod);
                    })
                    .catch(error => console.log(error));
            },

            savePriceValue(item) {
                if (this.editedItem.serviceprice === '') {this.editedItem.servicecount = 0.00} //Если цена пустая, присваиваем 0

                item.serviceprice  = this.editedItem.serviceprice
                item.isPriceEditing = false

                this.editedItem = {}

                ActDataService.updateNomenclature(this.aktkod, item.serviceviewkod.serviceid, item.servicecount, item.serviceprice)
                    .then( response => {
                        console.log(response)
                        this.getNomenclature(this.aktkod);
                    })
                    .catch(error => console.log(error));
            },

            updateAct(item) {
                ActDataService.update(this.actitems[item].actid, this.actitems[item])
                    .then(response => {
                        console.log(response.data)
                        this.getActs(this.$route.params.id)
                    })
                    .catch(e => {
                        console.log(e)
                        // this.alertError = true
                    })
            },

            getActs(orderid) {
                ActDataService.find(orderid)
                    .then( response => {
                        this.actitems = response.data
                        this.loadingTable = false
                    })
                    .catch(error => console.log(error));
            },

            // printAct(actid) {
            //     ActDataService.printAct(this.company.companyid, actid)
            //         .then( response => {
            //             let headers = response.headers;
            //             let fileURL = window.URL.createObjectURL(new Blob([response.data],{ type : headers['content-type']}));
            //             let fileLink = document.createElement('a');
            //             fileLink.href = fileURL;
            //             fileLink.setAttribute('download', 'Акт сдачи работ');
            //             document.body.appendChild(fileLink);
            //             fileLink.click();
            //         })
            //         .catch(error => console.log(error));
            // },
            createDocumentFromTemplate(templateid, actid) {
                PrintDataService.createDocument(actid, templateid)
                    .then(response => {
                        //this.$refs.refCorrespondenceFileTable.getFiles(this.$route.params.id) //Обновляем таблицу файлов корреспонденции в дочернем компоненте через refs
                        console.log(response)
                    })
                    .catch(e => {
                        console.log(e)
                    })
            },
            getActSum(nomenclature) {
                let sum = 0
                nomenclature.forEach( function (item) {
                    sum = sum + Number(item.servicecount * item.serviceprice)
                })
                return sum
            },
            getActsSumm() {
                let Summ = 0
                this.actitems.forEach(function (item) {
                    for (let i = 0; i < item.tablecrossingserviceviewact.length; i++)
                    {
                        Summ  = Summ + Number(item.tablecrossingserviceviewact[i].servicecount * item.tablecrossingserviceviewact[i].serviceprice)
                    }
                })
                return Summ
            },
            saveAct(isRegistered) {
                if (this.$refs.act.validate()) {
                    //Обновление акта
                    if (this.editedIndex > -1) {
                        this.actitems[this.editedIndex].actnumber = this.actNumber
                        this.actitems[this.editedIndex].actdate = this.actDate
                        this.actitems[this.editedIndex].isregistered = this.isRegistered
                        this.updateAct(this.editedIndex)
                    } else {
                        //Добавление акта
                        let data = {
                            orderid: this.$route.params.id,
                            actnumber: this.actNumber,
                            actdate: this.actDate,
                            registered: isRegistered //Зарегистрировать Да/Нет
                        }
                        this.createAct(data)
                    }
                    this.closeDialog()
                }
            },
            createAct(data) {
                ActDataService.create(data)
                    .then(response => {
                        console.log(response.data)
                        this.getActs(this.$route.params.id)
                        this.actEditDialog = false
                    })
                    .catch(e => {
                        console.log(e)
                        // this.alertError = true
                    })
            },
            deleteAct(id) {
                ActDataService.delete(id)
                    .then(response => {
                        console.log(response.data)
                        this.getActs(this.$route.params.id)
                    })
                    .catch(e => {
                        console.log(e)
                        // this.alertError = true
                    })
            },
            getTemplates() {
                TemplatesDataService.get('act')
                    .then(response => {
                        this.templates = response.data
                        console.log(response.data);
                    })
                    .catch(error => {
                        console.log(error);
                    })
            },

        },
        mounted () {
            this.getActs(this.$route.params.id) //Код текущего договора
            this.getTemplates() //Загружаем шаблоны печатных документов
        },
        watch: {
        },
        filters: {
            formatDate: function (date) {
                return [date.slice(8,10),date.slice(5,7),date.slice(0,4)].join('.')
            },
        },
    }
</script>

<style scoped>
    .summ {
        color: #A83C0F;
        font-weight: bold;
    }

    .editvalue {
        border-bottom: 1px dotted #3e3535;
    }

    .inputinfocus {
        border:0;
        border-bottom: 2px solid #00b028;
        outline:none;
    }

</style>