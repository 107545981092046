<template>
  <div>

      <!--      Диалоговое окно FeatureInfo-->
      <v-dialog
              max-width="500"
              persistent
              v-model="featureInfoDialog"
      >
          <v-card>
              <v-card-title class="headline">
                  Информация об объекте
                  <v-spacer></v-spacer>
                  <v-btn
                          icon
                          @click="closeFeatureInfoDialog"
                  >
                      <v-icon>mdi-close</v-icon>
                  </v-btn>
              </v-card-title>
              <v-card-text>
                  <v-container>
                      <v-row>
                          <v-col>
                              <div v-html="featureInfo ">  </div>
                          </v-col>
                      </v-row>
                  </v-container>
              </v-card-text>
              <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                          color="blue darken-1"
                          text
                          @click="closeFeatureInfoDialog"
                  >
                      Закрыть
                  </v-btn>
              </v-card-actions>
          </v-card>
      </v-dialog>



      <v-row>
          <v-col cols="2">
              <v-card flat>
                  <v-card-text>

                      <h3>Подложки</h3>
                      <v-radio-group
                              v-model="currentLayer"
                              column
                                @change="changeBackground"
                      >
                          <v-radio
                                  label="OpenStreetMap"
                                  value="OpenStreetMap"

                          ></v-radio>
                          <v-radio
                                  label="Google Satellite"
                                  value="GoogleSat"
                          ></v-radio>
                          <v-radio
                                  label="Bing Satellite"
                                  value="BingSat"
                          ></v-radio>
                      </v-radio-group>

                      <h3>Геодезические пункты</h3>
                      <h3 class="ml-5 mt-4">Воронежская область</h3>
                      <v-checkbox
                              class="ml-10"
                              v-model="layer"
                              label="ГГС"
                              value="red1"
                              hide-details
                              @change="changeLayer('vrn_ggs_layer_group_layer')"
                      ></v-checkbox>
                      <v-checkbox
                          class="ml-10"
                          v-model="layer"
                          label="ГСС"
                          value="red2"
                          hide-details
                          @change="changeLayer('vrn_pp_msk36_z1_layer')"
                      ></v-checkbox>
                      <v-checkbox
                              class="ml-10"
                              v-model="layer"
                              label="ОМС"
                              value="red3"
                              hide-details
                              @change="changeLayer('vrn_oms_layer_group_layer')"
                      ></v-checkbox>
                      <v-checkbox
                              class="ml-10"
                              v-model="layer"
                              label="Сети референцных БС"
                              value="red4"
                              hide-details
                              @change="changeLayer('vrn_gnss_layer')"
                      ></v-checkbox>

                      <h3 class="ml-5 mt-4">Липецкая область</h3>
                      <v-checkbox
                              class="ml-10"
                              v-model="layer"
                              label="ГГС"
                              value="red5"
                              hide-details
                              @change="changeLayer('lip_ggs_layer_group_layer')"
                      ></v-checkbox>

                      <h3 class="ml-5 mt-4">Белгородская область</h3>
                      <v-checkbox
                              class="ml-10"
                              v-model="layer"
                              label="ГГС"
                              value="red6"
                              hide-details
                              @change="changeLayer('bel_ggs_layer_group_layer')"
                      ></v-checkbox>
                      <h3 class="ml-5 mt-4">Тамбовская область</h3>
                      <v-checkbox
                              class="ml-10"
                              v-model="layer"
                              label="ГГС"
                              value="red25"
                              hide-details
                              @change="changeLayer('tamb_ggs_layer_group_layer')"
                      ></v-checkbox>

                      <h3>Планшеты</h3>
                      <v-checkbox
                              class="ml-10"
                              v-model="layer"
                              label="г.Воронеж"
                              value="red7"
                              hide-details
                              @change="changeLayer('vrn_planshets_layer')"
                      ></v-checkbox>

                      <h3>УАИГ</h3>
                      <h3 class="ml-5 mt-4">Планшеты УСК</h3>
                      <v-checkbox
                              class="ml-10"
                              v-model="layer"
                              label="Масштаб 1:500"
                              value="red8"
                              hide-details
                              @change="changeLayer('usk_500_layer')"
                      ></v-checkbox>
                      <v-checkbox
                              class="ml-10"
                              v-model="layer"
                              label="Масштаб 1:1000"
                              value="red9"
                              hide-details
                              @change="changeLayer('usk_1000_layer')"
                      ></v-checkbox>

                      <h3 class="ml-5 mt-4">Планшеты МСК-36</h3>
                      <v-checkbox
                              class="ml-10"
                              v-model="layer"
                              label="Масштаб 1:500 (зона 1)"
                              value="red10"
                              hide-details
                              @change="changeLayer('msk_500_z1_layer')"
                      ></v-checkbox>
                      <v-checkbox
                              class="ml-10"
                              v-model="layer"
                              label="Масштаб 1:500 (зона 2)"
                              value="red11"
                              hide-details
                              @change="changeLayer('msk_500_z2_layer')"
                      ></v-checkbox>
                      <v-checkbox
                              class="ml-10"
                              v-model="layer"
                              label="Масштаб 1:1000"
                              value="red12"
                              hide-details
                              @change="changeLayer('msk_1000_layer')"
                      ></v-checkbox>
                      <v-checkbox
                              class="ml-10"
                              v-model="layer"
                              label="Масштаб 1:2000"
                              value="red13"
                              hide-details
                              @change="changeLayer('msk_2000_layer')"
                      ></v-checkbox>

                      <h3 class="ml-5 mt-4">Геология</h3>
                      <v-checkbox
                              class="ml-10"
                              v-model="layer"
                              label="Скважины / Шурфы"
                              value="red14"
                              hide-details
                              @change="changeLayer('geological_hole_layer')"
                      ></v-checkbox>

                      <h3 class="ml-5 mt-4">Информационные</h3>
                      <v-checkbox
                              class="ml-10"
                              v-model="layer"
                              label="Границы районов"
                              value="red15"
                              hide-details
                              @change="changeLayer('borders_layer')"
                      ></v-checkbox>



                  </v-card-text>
              </v-card>
          </v-col>
          <v-col>
              <div ref="map"
                   style="position: absolute; margin: 0; padding: 0;  height: 800px;  width: 82%;">
              </div>
          </v-col>

      </v-row>

  </div>
</template>

<script>
    import Map from 'ol/Map';
    import View from 'ol/View';
    import TileLayer from 'ol/layer/Tile';
    import XYZ from 'ol/source/XYZ';
    import 'ol/ol.css'
    import {fromLonLat} from 'ol/proj';
    import BingMaps from 'ol/source/BingMaps'
    import ImageWMS from 'ol/source/ImageWMS'
    import ImageLayer from 'ol/layer/Image';
    import Zoom from 'ol/control/Zoom';
    import ZoomSlider from 'ol/control/ZoomSlider';
    import ScaleLine from 'ol/control/ScaleLine';
    import MousePosition from 'ol/control/MousePosition';
    import {format} from 'ol/coordinate';


    let mousePositionControl = new MousePosition( {
        // используется градусная проекция
        projection: 'EPSG:4326',
        // переопределяем функцию вывода координат
        coordinateFormat: function(coordinate) {
            // сначала широта, потом долгота и ограничиваем до 5 знаков после запятой
            return format(coordinate, '{y}, {x}', 5);
        }
    } );

export default {
  name: 'Gis',
  components: {
    // CustomersTable
  },

    data () {
        return {
            OlMap: null,
            currentLayer: 'OpenStreetMap',
            prevLayer: 'OpenStreetMap',

            featureInfoDialog: false,
            featureInfo: '',

            layer: []
        }
    },

mounted() {

    this.$nextTick(function () {
        this.initMap();
    })

},
    methods: {
        //Поиск слоя по имени.
        //Если найден - возвращаем объект слоя
        getLayerByName: function(layerName)  {
        let newLayer
        this.OlMap.getLayers().getArray().forEach(function (layer) {
            if (layer.get('name') === layerName) {
                newLayer = layer
            }
        });
        return newLayer
    },

        initMap() {
            // this is where we create the OpenLayers map
            this.OlMap = new Map({
                target: this.$refs['map'],
                 controls: [new Zoom(), new ZoomSlider(), mousePositionControl, new ScaleLine()],
            })

            //---------------SOURCE----------------------------

            //vrn-ggs-layer-group
            var vrn_ggs_layer_group_source = new ImageWMS({
                url: 'http://192.168.10.52:8080/geoserver/artgeocom/wms',
                params: {'LAYERS': 'artgeocom:vrn-ggs-layer-group', 'VERSION':'1.1.0'},
                serverType: 'geoserver',
                crossOrigin: 'anonymous'
            });

            //vrn-oms-layer-group
            var vrn_oms_layer_group_source = new ImageWMS({
                url: 'http://192.168.10.52:8080/geoserver/artgeocom/wms',
                params: {'LAYERS': 'artgeocom:vrn-oms-layer-group', 'VERSION':'1.1.0'},
                serverType: 'geoserver',
                crossOrigin: 'anonymous'
            });

            var lip_ggs_layer_group_source = new ImageWMS({
                url: 'http://192.168.10.52:8080/geoserver/artgeocom/wms',
                params: {'LAYERS': 'artgeocom:lip-ggs-layer-group', 'VERSION':'1.1.0'},
                serverType: 'geoserver',
                crossOrigin: 'anonymous'
            });

            var bel_ggs_layer_group_source = new ImageWMS({
                url: 'http://192.168.10.52:8080/geoserver/artgeocom/wms',
                params: {'LAYERS': 'artgeocom:bel-ggs-layer-group', 'VERSION':'1.1.0'},
                serverType: 'geoserver',
                crossOrigin: 'anonymous'
            });

            var tamb_ggs_layer_group_source = new ImageWMS({
                url: 'http://192.168.10.52:8080/geoserver/artgeocom/wms',
                params: {'LAYERS': 'artgeocom:tamb-ggs-layer-group', 'VERSION':'1.1.0'},
                serverType: 'geoserver',
                crossOrigin: 'anonymous'
            });

            //vrn-planshets-layer
            var vrn_planshets_layer_source = new ImageWMS({
                url: 'http://192.168.10.52:8080/geoserver/artgeocom/wms',
                params: {'LAYERS': 'artgeocom:vrn-planshets-layer', 'VERSION':'1.1.0'},
                serverType: 'geoserver',
                crossOrigin: 'anonymous'
            });

            //vrn-pp-msk36-z1-layer
            var vrn_pp_msk36_z1_layer_source = new ImageWMS({
                url: 'http://192.168.10.52:8080/geoserver/artgeocom/wms',
                params: {'LAYERS': 'artgeocom:vrn-pp-msk36-z1-layer', 'VERSION':'1.1.0'},
                serverType: 'geoserver',
                crossOrigin: 'anonymous'
            });

            //vrn-gnss-msk36-z1-layer
            var vrn_gnss_msk36_z1_layer_source = new ImageWMS({
                url: 'http://192.168.10.52:8080/geoserver/artgeocom/wms',
                params: {'LAYERS': 'artgeocom:vrn-gnss-msk36-z1-layer', 'VERSION':'1.1.0'},
                serverType: 'geoserver',
                crossOrigin: 'anonymous'
            });

            //-------http://npcenter.ru/mapex.html--------------------------------
            var usk_500 = new ImageWMS({
                url: 'http://80.82.42.139:8080/geoserver/npc/wms',
                params: {'LAYERS': 'npc:usk_500_db', 'VERSION':'1.1.0', 'height':'256', 'width':'256'},
                // serverType: 'geoserver',
                crossOrigin: 'anonymous'
            });

            var usk_1000 = new ImageWMS({
                url: 'http://80.82.42.139:8080/geoserver/npc/wms',
                params: {'LAYERS': 'npc:usk_1000_db', 'VERSION':'1.1.0', 'height':'256', 'width':'256'},
                // serverType: 'geoserver',
                crossOrigin: 'anonymous'
            });

            var msk_500_z1 = new ImageWMS({
                url: 'http://80.82.42.139:8080/geoserver/npc/wms',
                params: {'LAYERS': 'npc:msk_500_z1', 'VERSION':'1.1.0', 'height':'256', 'width':'256', 'CQL_FILTER':'status in (1,2)'},
                // serverType: 'geoserver',
                crossOrigin: 'anonymous'
            });

            var msk_500_z2 = new ImageWMS({
                url: 'http://80.82.42.139:8080/geoserver/npc/wms',
                params: {'LAYERS': 'npc:msk_500_z2', 'VERSION':'1.1.0', 'height':'256', 'width':'256', 'CQL_FILTER':'status in (1,2)'},
                // serverType: 'geoserver',
                crossOrigin: 'anonymous'
            });

            var msk_1000 = new ImageWMS({
                url: 'http://80.82.42.139:8080/geoserver/npc/wms',
                params: {'LAYERS': 'npc:msk_1000', 'VERSION':'1.1.0', 'height':'256', 'width':'256', 'CQL_FILTER':'status in (1,2)'},
                // serverType: 'geoserver',
                crossOrigin: 'anonymous'
            });

            var msk_2000 = new ImageWMS({
                url: 'http://80.82.42.139:8080/geoserver/npc/wms',
                params: {'LAYERS': 'npc:msk_2000', 'VERSION':'1.1.0', 'height':'256', 'width':'256', 'CQL_FILTER':'status in (1,2)'},
                // serverType: 'geoserver',
                crossOrigin: 'anonymous'
            });

            var borders = new ImageWMS({
                url: 'http://80.82.42.139:8080/geoserver/npc/wms',
                params: {'LAYERS': 'npc:borders', 'VERSION':'1.1.0', 'height':'256', 'width':'256'},
                // serverType: 'geoserver',
                crossOrigin: 'anonymous'
            });

            var geological_hole = new ImageWMS({
                url: 'http://80.82.42.139:8080/geoserver/npc/wms',
                params: {'LAYERS': 'npc:geological_hole', 'VERSION':'1.1.0', 'height':'256', 'width':'256'},
                // serverType: 'geoserver',
                crossOrigin: 'anonymous'
            });

            // ---------------------LAYERS------------------------------------
            //OpenStreetMap
            let OpenStreetMap = new TileLayer({
                source: new XYZ({
                    url: 'https://{a-c}.tile.openstreetmap.org/{z}/{x}/{y}.png'
                }),
                name: 'OpenStreetMap',
                visible: true
            });

            //Bing Satellite
            let BingSat = new TileLayer({
                source: new BingMaps({
                    key: 'AkPePzQAUoC7qKlVTNT4nw0Ykq2EJ4nLE9Iq8-7NZY4wI2Owxebqqur_4zMiboZh',
                    imagerySet: "AerialWithLabels",
                    maxZoom: 19
                }),
                name: 'BingSat',
                visible: false
            });

            //Google Satellite
            let GoogleSat = new TileLayer({
                source: new XYZ({
                    url: 'http://mt0.google.com/vt/lyrs=y&hl=en&x={x}&y={y}&z={z}&s=Ga'
                }),
                name: 'GoogleSat',
                visible: false
            });

            //----OTHER LAYERS-----------------------------------------------

            //vrn_ggs_layer_group_layer
            var vrn_ggs_layer_group_layer = new ImageLayer({
                source: vrn_ggs_layer_group_source,
                name: 'vrn_ggs_layer_group_layer',
                visible: false
            });

            //vrn_gnss_layer
            var vrn_gnss_layer = new ImageLayer({
                source: vrn_gnss_msk36_z1_layer_source ,
                name: 'vrn_gnss_layer',
                visible: false
            });

            //vrn_oms_layer_group_layer
            var vrn_oms_layer_group_layer = new ImageLayer({
                source: vrn_oms_layer_group_source,
                name: 'vrn_oms_layer_group_layer',
                visible: false
            });


            var lip_ggs_layer_group_layer = new ImageLayer({
                source: lip_ggs_layer_group_source,
                name: 'lip_ggs_layer_group_layer',
                visible: false
            });

            var bel_ggs_layer_group_layer = new ImageLayer({
                source: bel_ggs_layer_group_source,
                name: 'bel_ggs_layer_group_layer',
                visible: false
            });

            var tamb_ggs_layer_group_layer = new ImageLayer({
                source: tamb_ggs_layer_group_source,
                name: 'tamb_ggs_layer_group_layer',
                visible: false
            });

            //vrn_pp_msk36_z1_layer
            var vrn_pp_msk36_z1_layer = new ImageLayer({
                source: vrn_pp_msk36_z1_layer_source,
                name: 'vrn_pp_msk36_z1_layer',
                visible: false
            });

            //vrn-planshets-layer
            var vrn_planshets_layer = new ImageLayer({
                source: vrn_planshets_layer_source,
                name: 'vrn_planshets_layer',
                visible: false
            });

            //-------http://npcenter.ru/mapex.html--------------------------------
            var usk_500_layer = new ImageLayer({
                source: usk_500,
                name: 'usk_500_layer',
                visible: false
            });

            var usk_1000_layer = new ImageLayer({
                source: usk_1000,
                name: 'usk_1000_layer',
                visible: false
            });


            var msk_500_z1_layer = new ImageLayer({
                source: msk_500_z1,
                name: 'msk_500_z1_layer',
                visible: false
            });

            var msk_500_z2_layer = new ImageLayer({
                source: msk_500_z2,
                name: 'msk_500_z2_layer',
                visible: false
            });

            var msk_1000_layer = new ImageLayer({
                source: msk_1000,
                name: 'msk_1000_layer',
                visible: false
            });

            var msk_2000_layer = new ImageLayer({
                source: msk_2000,
                name: 'msk_2000_layer',
                visible: false
            });

            var geological_hole_layer = new ImageLayer({
                source: geological_hole,
                name: 'geological_hole_layer',
                visible: false
            });

            var borders_layer = new ImageLayer({
                source: borders,
                name: 'borders_layer',
                visible: false
            });

            //-------------------VIEW------------------------------------------

            let view = new View({
                center: fromLonLat([39.1843, 51.672]),
                zoom: 11
            });


            //Add Layers
            this.OlMap.addLayer(OpenStreetMap);
            this.OlMap.addLayer(BingSat);
            this.OlMap.addLayer(GoogleSat);
            this.OlMap.addLayer(vrn_ggs_layer_group_layer);
            this.OlMap.addLayer(vrn_oms_layer_group_layer);
            this.OlMap.addLayer(lip_ggs_layer_group_layer);
            this.OlMap.addLayer(bel_ggs_layer_group_layer);
            this.OlMap.addLayer(tamb_ggs_layer_group_layer);
            this.OlMap.addLayer(vrn_pp_msk36_z1_layer);
            this.OlMap.addLayer(vrn_planshets_layer);
            this.OlMap.addLayer(vrn_gnss_layer);

            this.OlMap.addLayer(msk_500_z1_layer);
            this.OlMap.addLayer(msk_500_z2_layer);
            this.OlMap.addLayer(msk_1000_layer);
            this.OlMap.addLayer(msk_2000_layer);
            this.OlMap.addLayer(usk_500_layer);
            this.OlMap.addLayer(usk_1000_layer);
            this.OlMap.addLayer(geological_hole_layer);
            this.OlMap.addLayer(borders_layer);

            this.OlMap.setView(view); //Set view


            //Source for getfeatureinfo
            let source = new ImageWMS({
                url: 'http://192.168.10.52:8080/geoserver/artgeocom/wms',
                params: {'LAYERS': 'artgeocom:vrn-gnss-msk36-z1-layer, artgeocom:vrn-ggs-layer-group, artgeocom:vrn-oms-layer-group,artgeocom:vrn-pp-msk36-z1-layer,artgeocom:lip-ggs-layer-group,artgeocom:bel-ggs-layer-group,artgeocom:tamb-ggs-layer-group', 'VERSION':'1.1.0'},
                serverType: 'geoserver',
                crossOrigin: 'anonymous'
            });


            this.OlMap.on('singleclick', (event) => {
                let viewResolution = /** @type {number} */ (view.getResolution());
                let url = source.getFeatureInfoUrl(
                    event.coordinate, viewResolution, 'EPSG:3857',
                    {'INFO_FORMAT': 'text/html', 'propertyName':'PointName,X,Y,H'});
                // {'INFO_FORMAT': 'application/json'});

                if (url) {
                    fetch(url)
                        .then((response) => { return response.text(); })
                        .then( (html) => {
                            this.featureInfo = html
                            this.featureInfoDialog = true
                            console.log(html)
                        });


                }
            });


        },

        //Смена карты (бекграунда)
        changeBackground () {
            let prevLayer = this.getLayerByName(this.prevLayer) //предыдущий слой
            prevLayer.setVisible(false)

            let nextLayer = this.getLayerByName(this.currentLayer) //Включаемый слой
            nextLayer.setVisible(true)

            this.prevLayer = this.currentLayer

        },
        //Смена карты (бекграунда)
        changeLayer (layerName) {
            let layer = this.getLayerByName(layerName);
            layer.setVisible(!layer.getVisible());

        },
        //Закрывакем диалог
        closeFeatureInfoDialog() {
            this.featureInfoDialog = false
        }
    },






}



</script>

<style scoped>
    .v-input--selection-controls {
        margin-top: 0;
    }

</style>