import axios from 'axios';

class AuthService {
    login(user) {
        return axios
            .post(process.env.VUE_APP_API_URL + 'login_check', {
                username: user.username,
                password: user.password
            })
            .then(response => {
                if (response.data.token) {
                    localStorage.setItem('user', JSON.stringify(response.data))
                }

                // const { can, rules } = new AbilityBuilder();//Новый код
                // const abilities_arr = response.data.roles2.reduce((acc, item) => {
                //     const ability_item = item.permissions
                //     acc.push(ability_item)
                // },[])  //Получаем способности из API
                // console.log(abilities_arr)
                // can(abilities_arr, 'all');
                // ability.update(rules); //Обновляем способности
                // // ability.update([ // switch to readonly mode
                // //     { action: 'read', subject: 'Post2' }
                // // ]);



                return response.data
            })
    }

    logout() {
        localStorage.removeItem('user')
    }


}

export default new AuthService()