import http from "../http-common"
import authHeader from './auth-header';

class AccountingDataService {
    //Запрос всех записей
    get() {
        return http.get(`/accounting`,{ headers: authHeader() });
    }
}

export default new AccountingDataService()