<template>
    <div>

        <!--Сообщение об успешной отправке e-mail или sms с рег данными ЛК-->
<!--        <v-snackbar-->
<!--                v-model="snackbarSendLogin"-->
<!--                color="green"-->
<!--                top-->
<!--                :timeout="4000"-->
<!--        >-->
<!--            Логин и новый пароль успешно отправлены-->
<!--            <template v-slot:action="{ attrs }">-->
<!--                <v-btn-->
<!--                        color="grey lighten-2"-->
<!--                        icon-->
<!--                        v-bind="attrs"-->
<!--                        @click="snackbarSendLogin = false"-->
<!--                >-->
<!--                    <v-icon>mdi-close</v-icon>-->
<!--                </v-btn>-->
<!--            </template>-->
<!--        </v-snackbar>-->

        <!--Сообщение об ОШИБКЕ отправке e-mail или sms с рег данными ЛК-->
<!--        <v-snackbar-->
<!--                v-model="snackbarErrorSendLogin"-->
<!--                color="red"-->
<!--                top-->
<!--                :timeout="4000"-->
<!--        >-->
<!--            Ошибка отправки логина и пароля-->
<!--            <template v-slot:action="{ attrs }">-->
<!--                <v-btn-->
<!--                        color="grey lighten-2"-->
<!--                        icon-->
<!--                        v-bind="attrs"-->
<!--                        @click="snackbarErrorSendLogin = false"-->
<!--                >-->
<!--                    <v-icon>mdi-close</v-icon>-->
<!--                </v-btn>-->
<!--            </template>-->
<!--        </v-snackbar>-->


        <!--      Диалоговое окно добавления email-->
        <v-dialog
                max-width="500"
                persistent
                v-model="addEmailDataDialog"
        >
            <v-card>
                <v-card-title class="headline">
                    Добавление e-mail
                    <v-spacer></v-spacer>
                    <v-btn
                            icon
                            @click="closeEmailDialog"
                    >
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-card-title>
                <v-card-text>
                    <v-container>
                        <v-row>
                            <v-col>
                                <v-text-field
                                        v-model="editedItem.email"
                                        label="E-mail"
                                        :rules="emailRules"
                                ></v-text-field>
                                <v-text-field
                                        v-model="editedItem.description"
                                        label="Комментарий"
                                ></v-text-field>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                            color="blue darken-1"
                            text
                            @click="closeEmailDialog"
                    >
                        Отмена
                    </v-btn>
                    <v-btn
                            color="blue darken-1"
                            text
                            @click="saveEmail"
                    >
                        Добавить
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <!--      Диалоговое окно редактирования email-->
        <v-dialog
                eager
                max-width="500"
                persistent
                v-model="editEmailDataDialog"
        >
            <v-card>
                <v-card-title class="headline">
                    Редактирование
                    <v-spacer></v-spacer>
                    <v-btn
                            icon
                            @click="closeEmailDialog"
                    >
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-card-title>
                <v-card-text>
                    <v-container>
                        <v-row>
                            <v-col>
                                <v-form
                                        ref="email"
                                        v-model="valid"
                                        lazy-validation
                                >
                                    <v-text-field
                                            v-model="editedItem.email"
                                            label="E-mail"
                                            :rules="emailRules"
                                    ></v-text-field>
                                    <v-text-field
                                            v-model="editedItem.description"
                                            label="Комментарий"
                                    ></v-text-field>
                                </v-form>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                            color="blue darken-1"
                            text
                            @click="closeEmailDialog"
                    >
                        Отмена
                    </v-btn>
                    <v-btn
                            :disabled="!valid"
                            color="blue darken-1"
                            text
                            @click="saveEmail"
                    >
                        Сохранить
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>





        <div class="data-text" style="margin-bottom: 10px" v-for="(item, index) in emails" :key="item.id">
            <!--                              {{ item.id }}-->
            <span style="color: #0d47a1; padding-right: 5px; font-weight: bold">{{ item.email }}</span>
            <span style="color: rgba(0, 0, 0, 0.6); font-size: 13px">{{ item.description }}</span>
            <v-icon
                    @click="editEmail(index)"
                    right
                    small
            >
                mdi-pencil
            </v-icon>
            <v-icon
                    @click="deleteEmail(item.id)"
                    right
                    small
            >
                mdi-trash-can-outline
            </v-icon>
<!--            <v-tooltip bottom>-->
<!--                <template v-slot:activator="{ on, attrs }">-->
<!--                    <v-icon-->
<!--                            @click="sendLoginToSms(item.phonenumber)"-->
<!--                            right-->
<!--                            small-->
<!--                            v-bind="attrs"-->
<!--                            v-on="on"-->
<!--                    >-->
<!--                        mdi-form-textbox-password-->
<!--                    </v-icon>-->
<!--                </template>-->
<!--                <span>Отправить логин и пароль в смс</span>-->
<!--            </v-tooltip>-->
            <v-icon
                    @click="copy(item.email)"
                    right
                    small
            >
                mdi-content-copy
            </v-icon>
        </div>
        <v-btn
                x-small
                rounded
                color="primary"
                depressed
                @click="addEmail"
        >
            Добавить
        </v-btn>
    </div>
</template>

<script>

    // import CustomerDataService from "@/services/CustomerDataService";
    import EmailsDataService from "@/services/EmailsDataService";
    export default {
        name: "Emails",
        data: function () {
            return {
                snackbarSendLogin: false,
                snackbarErrorSendLogin : false,
                valid: true,
                editEmailDataDialog: false,
                addEmailDataDialog: false,
                emails: {}, // массив email
                // phoneRules: [
                //     v => (!v || v.length === 18) || 'Пожалуйста, введите телефон',
                //     v => !!v || 'Пожалуйста, заполните это поле',
                // ],
                emailRules: [
                    v => (!v || /.+@.+\..+/.test(v)) || 'Пожалуйста, введите адрес электронной почты',
                    v => !!v || 'Пожалуйста, заполните это поле',
                ],
                editedItem: {},
                editedIndex: -1,
            }
        },
        methods: {
            //Отправка логина на E-mail
            // sendLoginToSms(phone) {
            //     CustomerDataService.sendLoginToSms(this.$route.params.id, phone)
            //         .then(response => {
            //             console.log(response.data)
            //              this.snackbarSendLogin = true
            //         })
            //         .catch(e => {
            //             console.log(e)
            //             this.snackbarErrorSendLogin = true
            //         })
            // },
            getEmails(id) {
                EmailsDataService.get(id)
                    .then(response => {
                        this.emails = response.data
                        console.log(response.data);
                    })
                    .catch(error => {
                        console.log(error);
                    })
            },
            updateEmail(item) {
                EmailsDataService.update(this.emails[item].id, this.emails[item])
                    .then(response => {
                        console.log(response.data)
                    })
                    .catch(e => {
                        console.log(e)
                        // this.alertError = true
                    })
            },
            createEmail(id, data) {
                EmailsDataService.create(id, data)
                    .then(response => {
                        console.log(response.data)
                        this.getEmails(this.$route.params.id)
                        // this.phones.push(this.editedItem)
                    })
                    .catch(e => {
                        console.log(e)
                        // this.alertError = true
                    })
            },
            deleteEmail(id) {
                EmailsDataService.delete(id)
                    .then(response => {
                        console.log(response.data)
                        this.getEmails(this.$route.params.id)
                    })
                    .catch(e => {
                        console.log(e)
                        // this.alertError = true
                    })
            },
            closeEmailDialog () {
                this.editEmailDataDialog = false
                this.addEmailDataDialog = false
                this.$nextTick(() => {
                    this.editedItem = {}
                    this.editedIndex = -1
                })
            },
            addEmail () {
                this.addEmailDataDialog = true
            },
            editEmail(item) {
                this.editedIndex = item
                this.editedItem.email = this.emails[item].email
                this.editedItem.description = this.emails[item].description
                this.editEmailDataDialog = true
            },
            saveEmail () {
                if (this.$refs.email.validate()) {
                    if (this.editedIndex > -1) {
                        this.emails[this.editedIndex].email = this.editedItem.email
                        this.emails[this.editedIndex].description = this.editedItem.description
                        this.updateEmail(this.editedIndex)
                    } else {
                        this.createEmail(this.$route.params.id, this.editedItem)
                    }
                    this.closeEmailDialog()
                }
            },
            copy (text) {
                this.$copyText(text).then(response => {
                    this.snackbarCopy = true
                    console.log(response)
                })
                    .catch(error => {
                        console.log(error);
                    })
            },
        },
        mounted() {
            this.getEmails(this.$route.params.id); //Код текущего клиента
        },
    }
</script>

<style scoped>

</style>