<!--ПКО таблица-->
<template>
    <div>
        <v-card
                outlined
                class="mb-4"
        >
            <v-card-title>
                Расходы (себестоимость)
                <v-spacer></v-spacer>
                <v-btn
                        icon
                        @click.stop="showNewDocumentDialog = true"
                >
                    <v-icon color="primary">mdi-plus</v-icon>
                </v-btn>
                <AddDocumentForm
                        v-model="showNewDocumentDialog"
                        @update-documents-table="get"
                        :order_id="$route.params.id"
                >
                </AddDocumentForm>
            </v-card-title>
            <v-card-text>

        <div class="text-center">
            <v-progress-circular
                    indeterminate
                    color="gray"
                    v-show="loadingTable"
            ></v-progress-circular>
        </div>

        <v-simple-table dense>
            <template v-slot:default>
                <thead>
                <tr>
                    <th class="text-left">
                        Номер
                    </th>
                    <th class="text-left">
                        Дата
                    </th>
                    <th class="text-left" >
                        Сумма
                    </th>
                    <th class="text-left" >
                        Контрагент
                    </th>
                    <th class="text-left" >
                        Статья
                    </th>
                    <th class="text-left" >
                        Напр.деят.
                    </th>
                </tr>
                </thead>
                <tfoot>
                    <tr>
                        <td colspan="2">Итого: <span style="background-color: rgb(230, 230, 230); padding: 5px 5px; border-radius: 4px; margin-left: 10px; font-weight: 500;">{{ new Intl.NumberFormat('ru-RU', { style: 'currency', currency: 'RUB' }).format(getDocumentsSumm()) }}</span></td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>
                </tfoot>
                <tbody>
                <tr
                        v-for="(item) in documents"
                        :key="item.id"
                >
                    <td>{{ item.number }}</td>
                    <td>{{ item.date.date | formatCreditDate}}</td>
                    <td>{{ item.sum }}</td>
                    <td>{{ item.customername }}</td>
                    <td>{{ item.accountingname }}</td>
                    <td>{{ item.activityname }}</td>
                </tr>
                </tbody>
            </template>
        </v-simple-table>
            </v-card-text>
        </v-card>
    </div>
</template>


<script>
    import * as ExternalRules from "@/rules/ExternalRules";
    import DocumentsDataService from "@/services/DocumentsDataService";
    import AddDocumentForm from "@/components/AddDocumentForm";

    export default {
        name: "DocumentsTable",
        components: {AddDocumentForm},
        data () {
            return {
                showNewDocumentDialog: false,
                documents: null,
                externalRules: ExternalRules,
                loadingTable: true,
            }
        },
        methods: {
            get(orderid) {
                DocumentsDataService.findByOrderid(orderid)
                    .then( response => {
                        this.documents = response.data
                        this.loadingTable = false
                    })
                    .catch(error => console.log(error));
            },

            getDocumentsSumm() {
                let summ = 0
                this.documents.forEach( function (item) {
                   summ = summ + Number(item.sum)
               })
                this.$store.dispatch('setExpensesSum', summ ) //Сохраняем сумму расходов в хранилище (для экономики)
                return summ
            },


        },
        mounted () {
            this.get(this.$route.params.id)
        },
        watch: {
        },
        filters: {
            formatCreditDate: function (creditdate) {
                return [creditdate.slice(8,10),creditdate.slice(5,7),creditdate.slice(0,4)].join('.')
            },
        },
    }
</script>

<style scoped>


</style>