<!--Исполнители работ-->
<template>
    <div>


        <!--      Диалоговое окно добавления Исполнителя-->
        <v-dialog
                eager
                max-width="600"
                persistent
                v-model="addEmployeerDialog"
        >
            <v-card>
                <v-card-title class="headline">
                    <span>Добавление исполнителя</span>
                    <v-spacer></v-spacer>
                    <v-btn
                            icon
                            @click="closeDialog"
                    >
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-card-title>
                <v-card-text>
                    <v-container>
                        <v-row>
                            <v-col>
                                <v-autocomplete
                                        label="Выберете исполнителя"
                                        v-model="employeesid"
                                        :items="employeesItems"
                                        item-value="employeesid"
                                        item-text="fullname"
                                        clearable
                                >
                                    <template v-slot:item="data">
                                        <!--<v-list-item-content v-text="data.item.servicename"></v-list-item-content>-->
                                        <v-list-item-content>
                                            <v-list-item-title v-html="data.item.fullname"></v-list-item-title>
<!--                                            <v-list-item-subtitle v-html="data.item.servicegroupkod.servicegroupname"></v-list-item-subtitle>-->
                                        </v-list-item-content>
                                    </template>
                                </v-autocomplete>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                            color="blue darken-1"
                            text
                            @click="closeDialog"
                    >
                        Отмена
                    </v-btn>
                    <v-btn
                            :disabled="!valid"
                            color="blue darken-1"
                            text
                            @click="addEmploeer"
                    >
                        Добавить
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>


        <div v-for="item in nomenclature" :key="item.servicekod.serviceid" style="margin-bottom: 20px">
            <span style="background-color: rgb(25, 118, 210); padding: 3px 5px; border-radius: 3px; color: white;"> {{ item.servicekod.servicename }} <span style="color: rgb(255, 217, 176)">[{{ item.servicekod.activiti}}]</span></span>
            <v-icon
                    title="Добавить исполнителя"
                    @click="openDialog(item.servicekod.serviceid)"
                    style="margin-left: 10px"
            >
                mdi-account-plus-outline
            </v-icon>

            <div v-for="item_y in serviveviewEmploeers" :key="item_y.servicekod.serviceid">
                <div v-if="item.servicekod.serviceid === item_y.servicekod.serviceid" style="padding: 10px">

                    <v-avatar
                            size="50"
                    >
                        <img
                                :src="`${avatars}${item_y.employeeskod.avatar}`"
                        >
                    </v-avatar>

                    {{ item_y.employeeskod.fullname}}
                    <v-icon
                            title="Удалить"
                            @click="deleteItem(item_y.servicekod.serviceid, item_y.employeeskod.employeesid)"
                    >
                        mdi-trash-can-outline
                    </v-icon>
                </div>


            </div>
        </div>

    </div>
</template>


<script>
    import ServiceviewEmployeerDataService from "@/services/ServiceviewEmployeerDataService";
    //import * as ExternalRules from "@/rules/ExternalRules";
    import NomenclatureDataService from "@/services/NomenclatureDataService";
    import EmployeeDataService from "@/services/EmployeeDataService";

    export default {
        name: "ServiceviewEmployeer",
        data () {
            return {
                nomenclature: null,
                serviveviewEmploeers: null,
                publicPath: process.env.BASE_URL,
                addEmployeerDialog: false,
                employeesItems: null,
                valid: true,
                employeesid: null,
                serviceid: null,
                avatars: process.env.VUE_APP_AVATARS
            }
        },
        methods: {
            getServiveviewEmploeer(orderid) {
                ServiceviewEmployeerDataService.find(orderid)
                    .then(response => {
                        this.serviveviewEmploeers = response.data
                        console.log(response.data);
                })
                    .catch(error => {
                        console.log(error);
                    })
            },
            getNomenclature(orderid) {
                NomenclatureDataService.find(orderid)
                    .then( response => {
                        this.nomenclature = response.data
                    })
                    .catch(error => console.log(error));
            },
            deleteItem(serviceid, employeesid) {
                ServiceviewEmployeerDataService.delete(serviceid, employeesid, this.$route.params.id)
                    .then(response => {
                        this.getServiveviewEmploeer(this.$route.params.id); //Код текущего договора
                        console.log(response.data);
                    })
                    .catch(error => {
                        console.log(error);
                    })
            },
            addEmploeer() {
                ServiceviewEmployeerDataService.create(this.serviceid, this.employeesid, this.$route.params.id)
                    .then(response => {
                        this.getServiveviewEmploeer(this.$route.params.id);
                        console.log(response.data);
                    })
                    .catch(error => {
                        console.log(error);
                    })
                this.closeDialog()
            },
            openDialog(serviceid) {
                this.addEmployeerDialog = true
                this.serviceid = serviceid
            },
            closeDialog() {
                this.addEmployeerDialog = false
                this.serviceid = null
                this.employeesid = null
            },
            getEmployees() {
                EmployeeDataService.find()
                    .then(response => {
                        this.employeesItems = response.data
                    })
                    .catch(e => {
                        console.log(e)
                    })
            },

        },
        mounted () {
            this.getServiveviewEmploeer(this.$route.params.id); //Код текущего договора
            //Запрос номенклатуры. Код повторяется в даннйо странице. Лучше в дальнейшем реализовать запрос данных из компонента Номенклатура. Тогда при удалении там данных тут тоже будет обновлятся без перезагрузки
            this.getNomenclature(this.$route.params.id); //Код текущего договора
            this.getEmployees() //Сотрудники
        },
        watch: {
        },
        filters: {

        },
    }
</script>

<style scoped>

</style>