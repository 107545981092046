import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify';
import '../src/assets/css/style.css'
import router from './router'
import store from './store'
import VueTheMask from 'vue-the-mask'
import VueClipboard from 'vue-clipboard2'
import VTooltip from "v-tooltip";
import VCalendar from 'v-calendar';
// import { abilitiesPlugin } from '@casl/vue';
//import ability from './services/ability';

//https://github.com/Maronato/vue-toastification/tree/main
import Toast from "vue-toastification"
import "vue-toastification/dist/index.css";


//Обображаем уведомление, которые еще не были скрыты с экрана
// const filterToasts = (toasts) => {
//     return toasts.reduce((aggToasts, toast) => {
//         // Check if type was not seen before
//         if (toast.content.props.noticeobj.showonscreen == "1") {
//             aggToasts.push(toast);
//         }
//         return aggToasts;
//     }, []);
// }


Vue.use(VueTheMask)
Vue.use(VueClipboard)
Vue.use(VTooltip)
Vue.use(VCalendar, {
  componentPrefix: 'vc', // Now use vc-calendar and vc-date-picker
})


//https://github.com/Maronato/vue-toastification
//Vue.use(Toast, { filterToasts, position: "bottom-right", timeout: false, closeOnClick: false, draggable: false, icon: false});
Vue.use(Toast, {position: "bottom-right", timeout: false, closeOnClick: false, draggable: false, icon: false});

//CASL
// Vue.use(abilitiesPlugin, ability);
// Vue.use(abilitiesPlugin, store.getters.ability);
// console.log(store.getters.ability)

Vue.config.productionTip = false

new Vue({
  vuetify,
  router,
  store,
  render: h => h(App)
}).$mount('#app')
