class DateFunction {

    //Трансформирует дату в формате ISO в дату формата RUS
    //2022-01-01 -> 01.01.2022
    DateIsoToRUS (isodate) {
        return [isodate.slice(8,10),isodate.slice(5,7),isodate.slice(0,4)].join('.')
    }

    //Трансформирует дату в формате RUS в дату формата ISO
    //01.01.2022 -> 2022-01-01
    DateRUSToIso (rusdate) {
        return [rusdate.slice(6,10),rusdate.slice(3,5),rusdate.slice(0,2)].join('-') + ' 00:00:00.000000'
    }

    //Форматирует время к формату чч:мм
    FormatTime (time) {
        return time.slice(11,16)
    }

}

export default new DateFunction()