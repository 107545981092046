<template>
    <!--      Диалоговое окно генерации от отправки пароля ЛК -->
    <v-dialog
            max-width="500"
            persistent
            v-model="show"
    >
        <v-card>
            <v-card-title class="headline">
                Создание пароля к ЛК
                <v-spacer></v-spacer>
                <v-btn
                        icon
                        @click="closeDialog"
                        :disabled="load_btn"
                >
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-card-title>
            <v-card-text>

                            <div>Будет сформирован новый пароль от личного кабинета и отправлен клиенту на выбранные сервисы/устройства коммуникации.</div>

                            <div class="mt-5">E-mail</div>
                            <div v-for="item in emails" :key="item.id">
                                <v-checkbox
                                        v-model="selectEmailsArr"
                                        :value="item.email"
                                        :label="`${item.email}`"
                                        :readonly="sending"
                                        :success-messages="item.success_msg"
                                        :error-messages="item.error_msg"
                                >
                                    <template v-slot:label>
                                        <div class="bold-blue-text">
                                            {{item.email}} <span class="sublabel-text">{{item.description}}</span>
                                        </div>
                                    </template>
                                </v-checkbox>
                            </div>

                            <div class="mt-5">Телефоны</div>
                            <div v-for="item in phones" :key="item.id">
                                <v-checkbox
                                        v-model="selectPhonesArr"
                                        :value="item.phonenumber"
                                        :label="`${item.phonenumber}`"
                                        :readonly="sending"
                                        :success-messages="item.success_msg"
                                        :error-messages="item.error_msg"
                                >
                                    <template v-slot:label>
                                        <div class="bold-blue-text">
                                            {{item.phonenumber}} <span class="sublabel-text">{{item.description}}</span>
                                        </div>
                                    </template>
                                </v-checkbox>
                            </div>
                <div class="text-center">
                            <v-btn
                                @click="sendPassword"
                                class="mt-10 mb-3"
                                depressed
                                color="primary"
                                :disabled="((selectEmailsArr.length === 0) && (selectPhonesArr.length === 0)) || (sending == true)"
                                :loading="load_btn"
                            >
                                Отправить
                            </v-btn>
                </div>
            </v-card-text>
        </v-card>
    </v-dialog>

</template>

<script>
    import PhonesDataService from "@/services/PhonesDataService";
    import EmailsDataService from "@/services/EmailsDataService";
    import Security from "@/services/library/Security";
    import CustomerDataService from "@/services/CustomerDataService";



    export default {
        name: "LKPasswordGenerateAndSend",
        props: {
            value: Boolean,
            customerid: Number,
        },
        data: function () {
            return {
                //passwordGenLKDialog: false, //Диалог генерации от отправки пароля ЛК
                phones: [], // массив телефонов
                emails: [], // массив email
                selectEmailsArr: [],// массив выбранных для отправки email
                selectPhonesArr: [],// массив выбранных для отправки телефонов,
                sending: false, //Флаг. Если TRUE - делаем чекбоксы и кнопку отправки неактивной, чтобы нельзя было отпраивить еще раз
                load_btn: false //Флаг. При отправке сообщение показываем на кнопке значет загрузки
            }
        },
        computed: {
            show: {
                get() {
                    return this.value
                },
                set(value) {
                    this.$emit('input', value)
                }
            },
        },
        watch: {
            show(show) {
                if (show) {
                    // Here you would put something to happen when dialog opens up
                    this.getPhones(this.customerid);
                    this.getEmails(this.customerid);
                    console.log("Dialog was opened!")
                } else {
                    this.phones = [] // массив телефонов
                    this.emails = [] // массив email
                    this.selectEmailsArr = [] // массив выбранных для отправки email
                    this.selectPhonesArr = []// массив выбранных для отправки телефонов
                    this.sending = false, //Флаг. Если TRUE - делаем чекбоксы и кнопку отправки неактивной, чтобы нельзя было отпраивить еще раз
                    this.load_btn = false //Флаг. При отправке сообщение показываем на кнопке значет загрузки
                    console.log("Dialog was closed!")
                }
            }
        },
        methods: {
            //Генерируем и записываем новый пароль клиента в БД
            sendPassword() {
                this.sending = true //Делаем неактивным чекбоксы и кнопку отправки
                this.load_btn = true //показываем на кнопке значек загрузки и блокируем кнопку закрытия окна

                let genPassword = Security.genPassword(7) //Генерируем пароль 7 символов

                CustomerDataService.changePassword(this.customerid, genPassword)
                    .then(response => {



                        this.selectEmailsArr.forEach((curritem) => {
                            CustomerDataService.sendLoginToEmail(this.customerid, curritem, genPassword)
                                .then(response => {
                                    if (response.data.result === 1) {
                                        let index = this.emails.findIndex(item => item.email === curritem)
                                        this.emails[index].success_msg = 'Сообщение отправлено'
                                    } else {
                                        let index = this.emails.findIndex(item => item.email === curritem)
                                        this.emails[index].error_msg  = 'Ошибка отправки'
                                    }
                                    //console.log(response.data.result)
                                })
                                .catch(e => {
                                    console.log(e)
                                })
                        })

                        this.selectPhonesArr.forEach((curritem) => {
                            CustomerDataService.sendLoginToSms(this.customerid, curritem, genPassword)
                                .then(response => {
                                    if (response.data.result === 1) {
                                        let index = this.phones.findIndex(item => item.phonenumber === curritem)
                                        this.phones[index].success_msg = 'Сообщение отправлено'
                                    } else {
                                        let index = this.phones.findIndex(item => item.phonenumber === curritem)
                                        this.phones[index].error_msg = 'Ошибка отправки'
                                    }
                                    //console.log(response.data.result)
                                })
                                .catch(e => {
                                    console.log(e)
                                })
                        })

                        this.load_btn = false
                        console.log(response.data);
                    })
                    .catch(error => {
                        console.log(error);
                    })
            },

            closeDialog() {
                this.show = false
            },
            getPhones(id) {
                PhonesDataService.get(id)
                    .then(response => {
                        //Добавляем в полученный массив дополнительные свойства необходимые для чекбокса (текст ошибки и текст успеха). При ошибки или успехе отправки будет изменять эти свойства
                        this.phones = response.data.map(function(current) {
                            let item = Object.assign({}, current)
                            item.error_msg = ''
                            item.success_msg = ''
                            return item
                        });
                        console.log(response.data);
                    })
                    .catch(error => {
                        console.log(error);
                    })
            },
            getEmails(id) {
                EmailsDataService.get(id)
                    .then(response => {
                        //Добавляем в полученный массив дополнительные свойства необходимые для чекбокса (текст ошибки и текст успеха). При ошибки или успехе отправки будет изменять эти свойства
                        this.emails = response.data.map(function(current) {
                            let item = Object.assign({}, current)
                            item.error_msg = ''
                            item.success_msg = ''
                            return item
                        });
                        console.log(response.data);
                    })
                    .catch(error => {
                        console.log(error);
                    })
            },

        },
        mounted() {

        },

    }
</script>

<style scoped>
    .bold-blue-text {
        color: rgb(13, 71, 161);
        padding-right: 5px;
        font-weight: bold;
    }
    .sublabel-text {
        color: rgba(0, 0, 0, 0.6);
        font-size: 13px;
        font-weight: normal;
    }
</style>